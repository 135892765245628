<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import CruResource from '../components/CruResource';
import Tabbed from '@shell/components/Tabbed';
import Tab from '@shell/components/Tabbed/Tab';
import SectionTitle from '../components/form/SectionTitle';
import { _EDIT, _CREATE } from '@shell/config/query-params';
import { MANAGEMENT, NAMESPACE, SCHEMA } from '@/shell/config/types';
import { sortBy } from '@/shell/utils/sort';
import { filterBy } from '@/shell/utils/array';
import UnitInput from '@shell/components/form/UnitInput';
import { ADDRESS_ASSIGNMENT_TYPE, MAC_VLAN_MODES, NETWORK_MODES } from '../config/settings';
import { deepClone } from '../utils';

import { ALIAS, NAD_ANNOTATIONS } from '../config/labels-annotations';
import { set, clone } from '@/shell/utils/object';

import { createYaml } from '@shell/utils/create-yaml';

import { PAI_RESOURCES, PAI_NAMESPACE } from '../config/types';
import { rangeToArr } from '../utils/string';
import AliasNsDescription from '../components/form/AliasNsDescription.vue';
import { v4 as uuidv4 } from 'uuid';
import { PRODUCT_NAME } from '../config/pai';

export default {
  components: {
    CruResource,
    UnitInput,
    Tabbed,
    Tab,
    SectionTitle,
    AliasNsDescription,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      },
    },
    mode: {
      type:    String,
      default: _EDIT,
    },
    realMode: {
      type:    String,
      default: _EDIT,
    },
  },
  async fetch() {
    const cluster = await this.$store.getters['currentCluster'];
    let projects = await this.$store.getters['management/all'](MANAGEMENT.PROJECT);

    this.nadList = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.NAD });

    projects = projects.filter((p) => {
      return this.currentProduct?.hideSystemResources ? !p.isSystem && p?.spec?.clusterName === cluster?.id : p?.spec?.clusterName === cluster?.id;
    });
    projects = sortBy(filterBy(projects, 'spec.clusterName', cluster.id), ['nameDisplay']);
    this.projectOptions = projects.map((v) => {
      return {
        label: v.spec.displayName,
        value: v.metadata.name,
      };
    });
    this.projectOptions.push({
      label: this.t('resourceTable.groupLabel.notInAProject'),
      value: '',
    });
    let namespaces = [];
    const inStore = this.$store.getters['currentStore'](NAMESPACE);

    if (inStore) {
      namespaces = sortBy(
        this.$store.getters[`${ inStore }/all`](NAMESPACE),
        ['nameDisplay']
      );
    }
    if (this.value.metadata && this.value.metadata.namespace) {
      const namespace = namespaces.find((v) => v.metadata.name === this.value.metadata.namespace);

      if (namespace) {
        const project = projects.find((v) => v.namespace === this.value.namespace && v.metadata.name === namespace.projectId);

        if (project) {
          this.project = project.metadata.name;
        } else {
          this.project = '';
        }
        await this.getAuthorized(namespace);
        this.networkSchema = true;
      }
    } else if (this.projectOptions.length > 0) {
      this.project = this.projectOptions[0].value;
    }
    this.namespaces = namespaces;
    // 默认初始化default命名空间的网络选项
    if (namespaces && this.mode === _CREATE) {
      const defaultNamespace = namespaces.find((v) => v.metadata.name === 'default');

      if (defaultNamespace) {
        this.$set(this.value.metadata, 'namespace', 'default');// 命名空间默认为default
        await this.getAuthorized(defaultNamespace);
      }
    }
  },
  data() {
    const config = JSON.parse(this.value.spec.config);
    const ipam = config.ipam;
    let type, addressType;

    if (this.realMode === _CREATE) {
      type = NETWORK_MODES.BRIDGE;
      addressType = ADDRESS_ASSIGNMENT_TYPE.DHCP;
    } else {
      type = config.type;
      addressType = config.ipam?.type;
    }
    const configs = {};

    if (config?.dns?.nameservers) {
      config.dns = config.dns.nameservers[0];
    }
    Object.values(NETWORK_MODES).forEach((v) => {
      configs[v] = { type: v, ...config };
    });

    const ipams = {};

    Object.values(ADDRESS_ASSIGNMENT_TYPE).forEach((v) => {
      if (this.realMode === _CREATE) {
        ipams[v] = { type: v };
        if (v === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
          ipams[v].ranges = [[{
            subnet: '/8', rangeMask: 4, gateway: ''
          }]];
        }
        if (v === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
          ipams[v].addresses = [{ address: '/16', gateway: '' }];
        }
      } else {
        ipams[v] = ipam;
      }
    });

    const networkModes = [];

    Object.values(NETWORK_MODES).forEach((mode) => {
      networkModes.push({
        src:         require(`../assets/images/network/${ mode }.svg`),
        name:        mode,
        displayName: this.t(`pai.network.type.${ mode }`),
        desc:        this.t(`pai.network.desc.${ mode }`)
      });
    });

    if (!this.value.metadata.name) {
      set(this.value, 'metadata.name', `network${ parseInt(new Date().getTime() / 1000) }`);
    }
    const metadata = this.value.metadata;
    const alias = metadata?.annotations?.[ALIAS];

    return {
      ALIAS,
      alias,
      projectOptions:   [],
      project:          '',
      namespaces:       [],
      namespaceOptions: [],
      configs,
      ipams,
      networkModes,
      macVlanModes:     Object.values(MAC_VLAN_MODES),
      addressTypes:     Object.values(ADDRESS_ASSIGNMENT_TYPE),
      NETWORK_MODES,
      ADDRESS_ASSIGNMENT_TYPE,
      type,
      addressType,
      isShowCard:       this.realMode === _CREATE,
      bridgeType:       configs[type]?.bridge ? 'bridge' : 'host',
      bridgeTypes:      [{ value: 'host', label: this.t('pai.network.bridge.host') }, { value: 'bridge', label: this.t('pai.network.bridge.bridge') }],
      masterOptions:    [],
      bridgeOptions:    [],
      subnetOptions:    ['8', '16'],
      vlans:            [],
      trunks:           [],
      vlanRangeStr:     '',
      trunkRangeStr:    '',
      nadList:          [],
      networkSchema:    false,
      gwPlaceholder:    ''
    };
  },
  computed: {
    isDisabled() {
      return this.realMode !== _CREATE;
    }
  },
  methods: {
    doneOverride() {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          resource: this.$route?.params?.resource,
        },
      });
    },
    addRoute() {
      if (this.ipams[this.addressType]?.routes?.length) {
        this.ipams[this.addressType].routes.push({
          dst: '', gw: '', mask: ''
        });
      } else {
        this.$set(this.ipams[this.addressType], 'routes', [{
          dst: '', gw: '', mask: ''
        }]);
      }
    },
    removeRoute(index) {
      this.ipams[this.addressType].routes.splice(index, 1);
    },
    removeAllRoutes() {
      this.$set(this.ipams[this.addressType], 'routes', []);
    },
    showTip(message, buttonCb) {
      this.$message({
        type:    'warning',
        message: message + this.t('pai.verify.required')
      });
      buttonCb(false);
    },
    async saveOverride(buttonCb) {
      // verify
      if (!this.value.metadata.namespace) {
        this.showTip(this.t('namespaceList.selectLabel'), buttonCb);

        return;
      }
      if (!this.value.metadata.annotations?.[ALIAS]) {
        this.showTip(this.t('pai.vmset.network.name'), buttonCb);

        return;
      } else {
        const nadData = this.nadList.find((item, index) => item?.namespace === this.value?.metadata?.namespace && item.metadata?.annotations?.[ALIAS] === this.value?.metadata?.annotations?.[ALIAS]);

        if (nadData) {
          this.$message({
            type:    'warning',
            message: this.t('pai.network.tooltip.alias')
          });
          buttonCb(false);

          return;
        }
      }

      if (!this.type) {
        this.showTip(this.t('workload.networking.networkMode.label'), buttonCb);

        return;
      }

      if (!this.type) {
        this.showTip(this.t('workload.networking.networkMode.label'), buttonCb);

        return;
      }
      if ((this.type === NETWORK_MODES.BRIDGE && !this.configs[this.type].uplink && this.bridgeType === 'host') || ((this.type === NETWORK_MODES.MAC_VLAN || this.type === NETWORK_MODES.IP_VLAN) && !this.configs[this.type].master) || (this.type === NETWORK_MODES.HOST_DEVICE && !this.configs[this.type].device)) {
        this.showTip(this.t('pai.list.network.hostNetwork'), buttonCb);

        return;
      }

      if (this.type === NETWORK_MODES.BRIDGE && this.bridgeType === 'bridge') {
        if (!this.configs[this.type].bridge) {
          this.showTip(this.t('pai.list.network.bridge'), buttonCb);

          return;
        }
      }

      if (this.type === NETWORK_MODES.BRIDGE && typeof this.configs[this.type].vlan === 'number' && !this.vlans.includes(this.configs[this.type].vlan)) {
        this.$message({
          type:    'warning',
          message: this.vlanRangeStr ? `${ this.t('pai.network.label.vlan') + this.t('pai.network.warm.ranges') }:` + `[${ this.vlanRangeStr }],  ${ this.t('pai.network.tooltip.netResourceQuota') }` : `${ this.t('pai.network.label.vlan') + this.t('pai.network.warm.ranges') }, ${ this.t('pai.network.tooltip.netResourceQuota') }`
        });
        buttonCb(false);

        return;
      }

      if (this.type === NETWORK_MODES.BRIDGE && typeof this.configs[this.type].trunk === 'number' && !this.trunks.includes(this.configs[this.type].trunk)) {
        this.$message({
          type:    'warning',
          message: this.trunkRangeStr ? `${ this.t('pai.network.label.trunk') + this.t('pai.network.warm.ranges') }:` + `[${ this.trunkRangeStr }],  ${ this.t('pai.network.tooltip.netResourceQuota') }` : `${ this.t('pai.network.label.trunk') + this.t('pai.network.warm.ranges') },  ${ this.t('pai.network.tooltip.netResourceQuota') }`
        });
        buttonCb(false);

        return;
      }
      if (this.addressType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
        if (!this.ipams[this.addressType].ranges[0][0].subnet) {
          this.showTip(this.t('pai.list.network.subnet'), buttonCb);

          return;
        }
        /* 分配网段掩码位只能是8或16 */
        const subnetRegex = /^[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+\/(8|16)$/;

        if (!this.ipams[this.addressType].ranges[0][0].subnet || !subnetRegex.test(this.ipams[this.addressType].ranges[0][0].subnet)) {
          this.$message({
            type:    'warning',
            message: this.t('pai.network.warm.subnet')
          });
          buttonCb(false);

          return;
        }
      }

      if (this.addressType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
        if (!this.ipams[this.addressType].addresses[0].address) {
          this.showTip(this.t('pai.list.network.subnet'), buttonCb);

          return;
        }
        /* 静态ip分配网段掩码位只能是0-32 */
        const subnetRegex = /^[0-9]+[.][0-9]+[.][0-9]+[.][0-9]+\/([12][0-9]|32|[0-9])$/;

        if (!this.ipams[this.addressType].addresses[0].address || !subnetRegex.test(this.ipams[this.addressType].addresses[0].address)) {
          this.$message({
            type:    'warning',
            message: this.t('pai.network.warm.address')
          });
          buttonCb(false);

          return;
        }
      }
      if (this.configs[this.type]?.dns?.includes(',')) {
        this.showTip(this.t('pai.network.tooltip.dnsOnly'), buttonCb);

        return;
      }

      this.setData(this.value);
      try {
        await this.value.save();
        await this.doneOverride();
      } catch (e) {
        buttonCb(false);
        await this.$message({
          type:    'warning',
          message: e.message
        });
      }
    },
    onClickCard(item) {
      this.type = item.name;
      this.configs[this.type].type = item.name;
      this.isShowCard = false;
    },
    /* 通过命名空间获取宿主机网卡列表与虚拟交换机列表。同时获取vlan id和trunk id的范围 */
    async  getAuthorized(namespaceParam) {
      // 强制更新一次
      const namespace = await this.$store.dispatch('cluster/find', {
        type: NAMESPACE,
        id:   namespaceParam.id,
        opt:  { force: true },
      });
      const annotations = namespace.annotations;

      if (this.realMode === _CREATE) {
        this.$set(this.configs['bridge'], 'mtu', 1500);
        this.$set(this.configs[this.type], 'master', '');
        this.$set(this.configs[this.type], 'uplink', '');
        this.$set(this.configs[this.type], 'device', '');
        this.$set(this.configs[this.type], 'bridge', '');
      }
      if (annotations) {
        const masters = annotations[NAD_ANNOTATIONS.MASTERS];

        if (masters) {
          this.masterOptions = masters.split(',');
        } else {
          this.masterOptions = [];
        }
        const bridges = annotations[NAD_ANNOTATIONS.BRIDGES];

        if (bridges) {
          this.bridgeOptions = bridges.split(',');
        } else {
          this.bridgeOptions = [];
        }
        const vlans = annotations[NAD_ANNOTATIONS.VLANS];

        this.vlanRangeStr = vlans;
        this.vlans = rangeToArr(vlans);
        const trunks = annotations[NAD_ANNOTATIONS.TRUNKS];

        this.trunkRangeStr = trunks;
        this.trunks = rangeToArr(trunks);
      } else {
        this.masterOptions = [];
        this.bridgeOptions = [];
        this.vlanRangeStr = '';
        this.trunkRangeStr = '';
      }
    },
    generateYaml() {
      const resource = this.value;

      const schemas = this.$store.getters[`cluster/all`](SCHEMA);
      const clonedResource = clone(resource);

      delete clonedResource.isSpoofed;
      this.setData(clonedResource);

      return createYaml(schemas, PAI_RESOURCES.NAD, clonedResource);
    },
    setData(resource) {
      const ipam = deepClone(this.ipams[this.addressType]);
      const config = deepClone(this.configs[this.type]);
      const random = uuidv4().replace(/-/g, '').substring(0, 16);

      if (typeof config.trunk !== 'number') {
        this.$delete(config, 'trunk');
      }
      if (this.addressType !== ADDRESS_ASSIGNMENT_TYPE.DHCP) {
        if (ipam && ipam.routes) {
          for (let i = 0; i < ipam.routes.length; i++) {
            if (ipam.routes[i].dst && ipam.routes[i].mask >= 0) {
              ipam.routes[i].dst = `${ ipam.routes[i].dst }/${ ipam.routes[i].mask }`;
            }
          }
        }
      }
      if (config.dns) {
        const dnsData = [];

        dnsData.push(config.dns);
        this.$set(config, 'dns', { nameservers: dnsData } );
      }
      config.ipam = ipam;
      if (this.type === NETWORK_MODES.HOST_DEVICE) {
        this.$delete(config, 'ipam');
      }
      config.name = config.name !== '' && config.name !== undefined ? config.name : random;
      resource.spec.config = JSON.stringify(config);
    },
    createNetworkLocation() {
      setTimeout(() => {
        this.$refs.networkSelect.blur();
      }, 20);
      const routeData = this.$router.resolve({ path: `/pai/c/${ this.$route.params.cluster }/${ PAI_NAMESPACE }/${ this.value.metadata.namespace }?mode=edit#limit` });

      window.open(routeData.href, '_blank');
    },
    async refreshNetwork() { // 更新宿主机网卡数据
      const namespace = this.namespaces.find((v) => v.metadata.name === this.value.metadata.namespace);

      if (namespace) {
        await this.getAuthorized(namespace);
      }
    },
    gatewayBlur(val) {
      this.gwPlaceholder = val;
      if (val) {
        const obj = {
          dst: '0.0.0.0', gw: val, mask: 0, disabledRow: 'true'
        };

        if (this.ipams[this.addressType]?.routes?.length) {
          const row = this.ipams[this.addressType].routes?.find((item) => item?.disabledRow !== undefined && item?.disabledRow === 'true');

          if (row) {
            this.ipams[this.addressType].routes[0] = obj;
          } else {
            this.ipams[this.addressType].routes.unshift(obj);
          }
        } else {
          this.$set(this.ipams[this.addressType], 'routes', [obj]);
        }
      } else {
        this.ipams[this.addressType]?.routes?.shift();
      }
    }
  },
  watch: {
    project(nue) {
      const namespaceOptions = this.namespaces.filter((v) => {
        if (nue === '') {
          return !v.projectId;
        } else {
          return v.projectId === nue;
        }
      }).map((v) => {
        return {
          label: v.metadata.name,
          value: v,
        };
      });

      this.namespaceOptions = namespaceOptions;
      if (namespaceOptions.length > 0) {
        this.$set(this.value.metadata, 'namespace', this.namespaceOptions[0].label);
      } else {
        this.$set(this.value.metadata, 'namespace', '');
      }
    },
    'value.metadata.namespace'(nue) {
      const namespace = this.namespaces.find((v) => v.metadata.name === nue);

      if (namespace) {
        this.getAuthorized(namespace);
      }
    },
  },
};
</script>
<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :apply-hooks="applyHooks"
    :generate-yaml="generateYaml"
    :can-yaml="!isShowCard"
    @error="e=>errors = e"
    @finish="saveOverride"
    @cancel="doneOverride"
  >
    <!--  选择网络类型  -->
    <div v-if="isShowCard">
      <div style="margin-left: 10px">
        {{ t('pai.network.tooltip.type') }}
        <el-tooltip effect="light">
          <div
            slot="content"
            v-html="t('pai.network.tooltip.func', {}, true)"
          />
          <i class="icon icon-info" />
        </el-tooltip>
      </div>
      <div class="cards">
        <div
          v-for="item in networkModes"
          :key="item.name"
          class="card"
          @click="onClickCard(item)"
        >
          <el-popover
            width="800"
            trigger="hover"
          >
            <img
              :src="item.src"
              alt=""
            >
            <img
              slot="reference"
              :src="item.src"
              alt=""
            >
          </el-popover>
          <div class="content">
            <h2>{{ item.displayName }}</h2>
            <div class="line" />
            {{ item.desc }}
          </div>
        </div>
      </div>
    </div>
    <template v-else>
      <!--      基本信息-->
      <AliasNsDescription
        v-model="value"
        :mode="mode"
      />
      <Tabbed :side-tabs="true">
        <!--      网络设置-->
        <Tab
          name="port"
          label-key="pai.vmset.network.config"
          :weight="98"
          :show-header="false"
        >
          <SectionTitle
            :value="t('pai.vmset.network.config')"
            style="margin-bottom: 20px"
          />
          <el-descriptions
            title=""
            direction="vertical"
            :column="2"
            :colon="false"
          >
            <template v-if="type === NETWORK_MODES.BRIDGE">
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.network.bridge.type') }}
                </template>
                <el-select
                  v-model="bridgeType"
                  :disabled="isDisabled"
                  filterable
                >
                  <el-option
                    v-for="(item) in bridgeTypes"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-descriptions-item>

              <el-descriptions-item v-if="bridgeType === 'host'">
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.hostNetwork') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.name', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-select
                  v-if="!masterOptions.includes('eth*')"
                  ref="networkSelect"
                  v-model="configs[type].uplink"
                  :disabled="isDisabled"
                  filterable
                  @focus="refreshNetwork"
                >
                  <el-option
                    v-for="(item,i) in masterOptions"
                    :key="item+i"
                    :label="item"
                    :value="item"
                  />
                  <!-- 自定义列 -->
                  <el-option
                    :class="masterOptions.length !== 0 ? 'option-line': ''"
                    style="width: 100%; font-weight: normal;"
                  >
                    <span v-if="masterOptions.length === 0">
                      <span style="color: #cccccc">{{ t('pai.detail.vmset.noData') }}，</span>
                      <span
                        v-if="!networkSchema"
                        style="color: #cccccc"
                      >{{ t('pai.vmset.tips.contactAdmin') }}</span>
                    </span>
                    <a
                      v-if="networkSchema"
                      href="#"
                      @click.prevent="createNetworkLocation"
                    >
                      {{ t('pai.list.network.add') }}{{ t('pai.list.network.hostNetwork') }}
                    </a>
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="configs[type].uplink"
                  :disabled="isDisabled"
                  :placeholder="t('pai.network.tooltip.master')"
                />
              </el-descriptions-item>
              <el-descriptions-item v-if="bridgeType === 'bridge'">
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.bridge') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.bridge', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-select
                  v-if="!bridgeOptions.includes('br-eth*')"
                  v-model="configs[type].bridge"
                  filterable
                  :disabled="isDisabled"
                >
                  <el-option
                    v-for="(item,i) in bridgeOptions"
                    :key="item+i"
                    :label="item"
                    :value="item"
                  />
                </el-select>
                <el-input
                  v-else
                  v-model="configs[type].bridge"
                  :disabled="isDisabled"
                  :placeholder="t('pai.network.tooltip.bridge')"
                />
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.network.label.vlan') }}
                  <el-tooltip effect="light">
                    <div slot="content">
                      {{ t('pai.network.tooltip.vlan') }}
                    </div>
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-input-number
                  v-model="configs[type].vlan"
                  :min="0"
                  :max="4094"
                  :disabled="isDisabled"
                  :placeholder="vlanRangeStr"
                />
              </el-descriptions-item>
              <el-descriptions-item />
            </template>
            <template v-if="type === NETWORK_MODES.IP_VLAN">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.hostNetwork') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.name', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-select
                  v-if="!masterOptions.includes('eth*')"
                  ref="networkSelect"
                  v-model="configs[type].master"
                  filterable
                  :disabled="isDisabled"
                  @focus="refreshNetwork"
                >
                  <el-option
                    v-for="(item,i) in masterOptions"
                    :key="item+i"
                    :label="item"
                    :value="item"
                  />
                  <!-- 自定义列 -->
                  <el-option
                    :class="masterOptions.length !== 0 ? 'option-line': ''"
                    style="width: 100%; font-weight: normal;"
                  >
                    <span v-if="masterOptions.length === 0">
                      <span style="color: #cccccc">{{ t('pai.detail.vmset.noData') }}，</span>
                      <span
                        v-if="!networkSchema"
                        style="color: #cccccc"
                      >{{ t('pai.vmset.tips.contactAdmin') }}</span>
                    </span>
                    <a
                      v-if="networkSchema"
                      href="#"
                      @click.prevent="createNetworkLocation"
                    >
                      {{ t('pai.list.network.add') }}{{ t('pai.list.network.hostNetwork') }}
                    </a>
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="configs[type].master"
                  :placeholder="t('pai.network.tooltip.master')"
                  :disabled="isDisabled"
                />
              </el-descriptions-item>
              <el-descriptions-item />
            </template>
            <template v-if="type === NETWORK_MODES.MAC_VLAN">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.hostNetwork') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.name', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-select
                  v-if="!masterOptions.includes('eth*')"
                  ref="networkSelect"
                  v-model="configs[type].master"
                  filterable
                  :disabled="isDisabled"
                  @focus="refreshNetwork"
                >
                  <el-option
                    v-for="(item,i) in masterOptions"
                    :key="item+i"
                    :label="item"
                    :value="item"
                  />
                  <!-- 自定义列 -->
                  <el-option
                    :class="masterOptions.length !== 0 ? 'option-line': ''"
                    style="width: 100%; font-weight: normal;"
                  >
                    <span v-if="masterOptions.length === 0">
                      <span style="color: #cccccc">{{ t('pai.detail.vmset.noData') }}，</span>
                      <span
                        v-if="!networkSchema"
                        style="color: #cccccc"
                      >{{ t('pai.vmset.tips.contactAdmin') }}</span>
                    </span>
                    <a
                      v-if="networkSchema"
                      href="#"
                      @click.prevent="createNetworkLocation"
                    >
                      {{ t('pai.list.network.add') }}{{ t('pai.list.network.hostNetwork') }}
                    </a>
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="configs[type].master"
                  :placeholder="t('pai.network.tooltip.master')"
                  :disabled="isDisabled"
                />
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  mode
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.mode', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-select
                  v-model="configs[type].mode"
                  :disabled="isDisabled"
                  filterable
                >
                  <el-option
                    v-for="item in macVlanModes"
                    :key="item"
                    :label="item"
                    :value="item"
                  />
                </el-select>
              </el-descriptions-item>
            </template>
            <template v-if="type === NETWORK_MODES.HOST_DEVICE">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.hostNetwork') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.name', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-select
                  v-if="!masterOptions.includes('eth*')"
                  ref="networkSelect"
                  v-model="configs[type].device"
                  filterable
                  :disabled="isDisabled"
                  @focus="refreshNetwork"
                >
                  <el-option
                    v-for="(item,i) in masterOptions"
                    :key="item+i"
                    :label="item"
                    :value="item"
                  />
                  <!-- 自定义列 -->
                  <el-option
                    :class="masterOptions.length !== 0 ? 'option-line': ''"
                    style="width: 100%; font-weight: normal;"
                  >
                    <span v-if="masterOptions.length === 0">
                      <span style="color: #cccccc">{{ t('pai.detail.vmset.noData') }}，</span>
                      <span
                        v-if="!networkSchema"
                        style="color: #cccccc"
                      >{{ t('pai.vmset.tips.contactAdmin') }}</span>
                    </span>
                    <a
                      v-if="networkSchema"
                      href="#"
                      @click.prevent="createNetworkLocation"
                    >
                      {{ t('pai.list.network.add') }}{{ t('pai.list.network.hostNetwork') }}
                    </a>
                  </el-option>
                </el-select>
                <el-input
                  v-else
                  v-model="configs[type].device"
                  :placeholder="t('pai.network.tooltip.master')"
                  :disabled="isDisabled"
                />
              </el-descriptions-item>
              <el-descriptions-item />
            </template>
          </el-descriptions>
          <template v-if="type === NETWORK_MODES.BRIDGE">
            <SectionTitle
              :value="t('pai.vmset.advancedOptions')"
              style="margin-bottom: 20px"
            />
            <el-descriptions
              title=""
              direction="vertical"
              :column="2"
              :colon="false"
            >
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.network.label.trunk') }}
                  <el-tooltip effect="light">
                    <div slot="content">
                      {{ t('pai.network.tooltip.trunk') }}
                    </div>
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-input-number
                  v-model="configs[type].trunk"
                  :min="0"
                  :max="4094"
                  :disabled="isDisabled || !configs[type].vlan"
                  :placeholder="trunkRangeStr"
                />
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  MTU
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.mtu', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-input-number
                  v-model="configs[type].mtu"
                  :output-modifier="true"
                  :min="64"
                  :max="65553"
                  :mode="mode"
                />
              </el-descriptions-item>
            </el-descriptions>
          </template>
        </Tab>
        <!--      地址分配-->
        <Tab
          v-if="type !== 'host-device'"
          name="address"
          label-key="pai.list.network.assignment"
          :weight="97"
          :show-header="false"
        >
          <SectionTitle
            :value="t('pai.list.network.assignment')"
            style="margin-bottom: 20px"
          />
          <el-descriptions
            title=""
            direction="vertical"
            :column="2"
            :colon="false"
          >
            <el-descriptions-item>
              <template slot="label">
                {{ t('pai.list.network.assignmentType') }}
                <el-tooltip effect="light">
                  <div
                    slot="content"
                    v-html="t('pai.network.tooltip.assignmentType', {}, true)"
                  />
                  <i class="icon icon-info" />
                </el-tooltip>
              </template>
              <el-select
                v-model="addressType"
                :disabled="isDisabled"
                filterable
              >
                <el-option
                  v-for="item in addressTypes"
                  :key="item"
                  :label="t(`pai.network.type.${item}`)"
                  :value="item"
                />
              </el-select>
            </el-descriptions-item>
            <template v-if="addressType === ADDRESS_ASSIGNMENT_TYPE.DHCP">
              <el-descriptions-item>
                <template slot="label">
                  DNS
                  <el-tooltip effect="light">
                    <div slot="content">
                      {{ t('pai.network.tooltip.dns') }}
                    </div>
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-input
                  v-model="configs[type].dns"
                  :disabled="isDisabled"
                />
              </el-descriptions-item>
            </template>
            <template v-if="addressType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.subnet') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.network.tooltip.subnet', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <template v-for="(v) in ipams[addressType].ranges">
                  <template v-for="(item,i) in v">
                    <div
                      :key="i+''"
                      class="subnet"
                    >
                      <el-input
                        :value="item.subnet.split('/')[0]"
                        placeholder="eg: 10.10.0.0"
                        :disabled="isDisabled"
                        class="input-with-select"
                        @input="!$event.includes('/') && $set(item,'subnet',$event +'/'+ item.subnet.split('/')[1])"
                      />
                      <div style="margin: 0 5px">
                        /
                      </div>
                      <el-select
                        :value="item.subnet.split('/')[1]"
                        :disabled="isDisabled"
                        style="width: 90px"
                        @change="$set(item,'subnet',item.subnet.split('/')[0]+'/'+$event)"
                      >
                        <el-option
                          v-for="(option) in subnetOptions"
                          :key="option"
                          :label="option"
                          :value="option"
                        />
                      </el-select>
                    </div>
                  </template>
                </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.list.network.gateway') }}
                </template>
                <template v-for="(v) in ipams[addressType].ranges">
                  <template v-for="(item,i) in v">
                    <el-input
                      :key="i"
                      v-model="item.gateway"
                      placeholder="eg: 10.10.0.254"
                      :disabled="isDisabled"
                      @blur="gatewayBlur(item.gateway)"
                    />
                  </template>
                </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  DNS
                  <el-tooltip effect="light">
                    <div slot="content">
                      {{ t('pai.network.tooltip.dns') }}
                    </div>
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-input
                  v-model="configs[type].dns"
                  :disabled="isDisabled"
                />
              </el-descriptions-item>
              <el-descriptions-item />
            </template>
            <template v-if="addressType === ADDRESS_ASSIGNMENT_TYPE.STATIC">
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.list.network.subnet') }}
                </template>
                <template v-for="(item, i) in ipams[addressType].addresses">
                  <div
                    :key="i+''"
                    class="subnet"
                  >
                    <el-input
                      :value="item.address.split('/')[0]"
                      placeholder="eg: 10.10.0.0"
                      :disabled="isDisabled"
                      class="input-with-select"
                      @input="!$event.includes('/') && $set(item,'address',$event +'/'+ item.address.split('/')[1])"
                    />
                    <div style="margin: 0 5px">
                      /
                    </div>
                    <el-input
                      :key="i+1"
                      :value="item.address.split('/')[1]"
                      :disabled="isDisabled"
                      style="width: 90px"
                      @input="$set(item,'address',item.address.split('/')[0]+'/'+$event)"
                    />
                  </div>
                </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.list.network.gateway') }}
                </template>
                <template v-for="(item,i) in ipams[addressType].addresses">
                  <el-input
                    :key="item+i"
                    v-model="item.gateway"
                    :disabled="isDisabled"
                    placeholder="eg: 10.10.0.254"
                    @blur="gatewayBlur(item.gateway)"
                  />
                </template>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  DNS
                  <el-tooltip effect="light">
                    <div slot="content">
                      {{ t('pai.network.tooltip.dns') }}
                    </div>
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-input
                  v-model="configs[type].dns"
                  :disabled="isDisabled"
                />
              </el-descriptions-item>
            </template>
          </el-descriptions>
          <template v-if="addressType!== ADDRESS_ASSIGNMENT_TYPE.DHCP">
            <SectionTitle
              :value="t('pai.list.network.route')"
              style="margin-bottom: 20px"
            />
            <div
              v-if="!isDisabled"
              class="row mt-15"
            >
              <button
                type="button"
                class="btn role-primary"
                style="margin-right: 10px"
                @click="addRoute"
              >
                {{ t('generic.add') }}
              </button>
              <button
                type="button"
                class="btn bg-error"
                @click="removeAllRoutes"
              >
                {{ t('pai.labels.removeAll') }}
              </button>
            </div>
            <el-table
              :data="ipams[addressType].routes"
              style="width: 100%"
            >
              <el-table-column
                width="200"
                :label="t('pai.list.network.dst')"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="ipams[addressType].routes[scope.$index].dst"
                    :disabled="isDisabled || scope.row?.disabledRow === 'true'"
                  />
                </template>
              </el-table-column>
              <el-table-column
                width="200"
                :label="t('pai.list.network.mask')"
              >
                <template slot-scope="scope">
                  <el-input-number
                    v-model="ipams[addressType].routes[scope.$index].mask"
                    :style="{width:'100%'}"
                    :min="0"
                    :max="32"
                    :disabled="isDisabled || scope.row?.disabledRow === 'true'"
                  />
                </template>
              </el-table-column>
              <el-table-column
                width="200"
                :label="t('pai.list.network.gateway')"
              >
                <template slot-scope="scope">
                  <el-input
                    v-model="ipams[addressType].routes[scope.$index].gw"
                    :placeholder="gwPlaceholder"
                    :disabled="isDisabled || scope.row?.disabledRow === 'true'"
                  />
                </template>
              </el-table-column>
              <el-table-column
                width="200"
                label=""
              >
                <template slot-scope="scope">
                  <button
                    v-if="!scope.row.disabledRow"
                    type="button"
                    class="btn bg-error"
                    :disabled="isDisabled"
                    @click="removeRoute(scope.$index)"
                  >
                    {{ t('generic.remove') }}
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </template>
        </Tab>
      </Tabbed>
    </template>
  </CruResource>
</template>
<style lang="scss" scoped>
.el-select, .el-input, .labeled-input, .el-input-number{
  width: 60%;
}
.el-input-number{
  line-height: 33px;
}

.subnet{
  width: 60%;
  display: flex;
  align-items: center;
}

.el-table{
  .el-input{
    width: 100%;
  }
}

.cards{
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 10px;
  .card{
    height: 160px;
    width: 45%;
    background: #EFEFEF;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    padding: 0 10px;
    cursor: pointer;
    img{
      width: 140px;
      margin-right: 10px;
    }
    .line{
      border-bottom: 2px solid var(--primary);
      height: 2px;
      margin: 10px 0;
    }
    .content{
      padding-right: 30px;
    }
  }
}
.option-line {
  border-top: 1px solid #cccccc;
}
</style>
