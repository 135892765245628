<script>
import AsyncButton from '@shell/components/AsyncButton';
import Loading from '@shell/components/Loading';
import { Banner } from '@components/Banner';
import SelectIconGrid from '../../../../../components/SelectIconGrid';
import {
  _CREATE,
  _FLAGGED,
  CATEGORY,
  CHART,
  DEPRECATED,
  HIDDEN,
  OPERATING_SYSTEM,
  REPO,
  REPO_TYPE,
  SEARCH_QUERY,
  VERSION,
  MODE
} from '@shell/config/query-params';
import { lcFirst } from '@shell/utils/string';
import { compare, sortBy } from '@shell/utils/sort';
import { mapGetters } from 'vuex';
import { Checkbox } from '@components/Form/Checkbox';
import Select from '@shell/components/form/Select';
import {
  HIDE_REPOS, mapPref, NAMESPACE_FILTERS, SHOW_CHART_MODE, SHOW_PRE_RELEASE
} from '@shell/store/prefs';
import { addObject, findBy, removeObject } from '@shell/utils/array';
import { compatibleVersionsFor, filterAndArrangeCharts } from '@shell/store/catalog';
import { CATALOG } from '@shell/config/labels-annotations';
import { isUIPlugin } from '@shell/config/uiplugins';
import { PRODUCT_NAME as PAI } from '../../../../../config/pai';
import { MANAGEMENT, NAMESPACE, PVC } from '@shell/config/types';
import { PAI_RESOURCES } from '../../../../../config/types';
import { BUILT_IN } from '../../../../../config/labels-annotations';
import { COMMAND_HASH_MPA } from '../../../../../config/settings';

import dayjs from 'dayjs';

export default {
  layout:     'pai/default',
  components: {
    AsyncButton,
    Banner,
    Loading,
    Checkbox,
    Select,
    SelectIconGrid,
  },

  async fetch() {
    try {
      await this.$store.dispatch('catalog/load', { force: true, reset: true });
    } catch (e) {
      console.log(e);
    }

    const query = this.$route.query;

    this.searchQuery = query[SEARCH_QUERY] || '';
    this.showDeprecated = query[DEPRECATED] === _FLAGGED;
    this.showHidden = query[HIDDEN] === _FLAGGED;
    this.category = query[CATEGORY] || '';
    this.allRepos = this.areAllEnabled();
    try {
      this.namespaces = await this.$store.dispatch('cluster/findAll', { type: NAMESPACE });
    } catch (e) {
      console.log(e);
    }

    try {
      this.images = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_IMAGE });
    } catch (e) {
      console.log(e);
    }

    this.$store.dispatch('cluster/findAll', { type: PVC });
    this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });
  },
  data() {
    const hash = this.$route.hash;

    return {
      COMMAND_HASH_MPA,
      allRepos:        null,
      category:        null,
      operatingSystem: null,
      searchQuery:     null,
      showDeprecated:  null,
      showHidden:      null,
      activeName:      hash || COMMAND_HASH_MPA.container,
      chartOptions:    [
        {
          label: 'Browse',
          value: 'browse',
        },
        {
          label: 'Featured',
          value: 'featured'
        }
      ],
      namespaces:    [],
      sourceOptions: [{ label: this.t('generic.all'), value: '' }, { label: this.t('pai.apps.vm.system'), value: 'system' }, { label: this.t('pai.apps.vm.user'), value: 'user' }],
      typeOptions:   [{ label: this.t('generic.all'), value: '' }, { label: this.t('pai.detail.vmset.operationSystemImage'), value: 'image' }, { label: this.t('pai.vmset.image.toolkit'), value: 'toolkit' }],
      filter:        {
        namespace: '', type: '', value: '', source: '',
      },
      images: [],
    };
  },

  computed: {
    ...mapGetters(['currentCluster']),
    ...mapGetters({ allCharts: 'catalog/charts', loadingErrors: 'catalog/errors' }),
    chartMode: mapPref(SHOW_CHART_MODE),

    hideRepos: mapPref(HIDE_REPOS),

    repoOptions() {
      let nextColor = 0;
      // Colors 3 and 4 match `rancher` and `partner` colors, so just avoid them
      const colors = [1, 2, 5, 6, 7, 8];

      let out = this.$store.getters['catalog/repos'].map((r) => {
        return {
          _key:    r._key,
          label:   r.nameDisplay,
          color:   r.color,
          weight:  ( r.isRancher ? 1 : ( r.isPartner ? 2 : 3 ) ),
          enabled: !this.hideRepos.includes(r._key),
        };
      });

      out = sortBy(out, ['weight', 'label']);

      for ( const entry of out ) {
        if ( !entry.color ) {
          entry.color = `color${ colors[nextColor] }`;
          nextColor++;
          if ( nextColor >= colors.length ) {
            nextColor = 0;
          }
        }
      }

      return out;
    },
    allVmImages() {
      return this.images;
    },
    repoOptionsForDropdown() {
      return [{
        label: this.t('catalog.repo.all'), all: true, enabled: this.areAllEnabled()
      }, ...this.repoOptions];
    },

    flattenedRepoNames() {
      const allChecked = this.repoOptionsForDropdown.find((repo) => repo.all && repo.enabled);

      if (allChecked) {
        return allChecked.label;
      }

      // None checked
      if (!this.repoOptionsForDropdown.find((repo) => repo.enabled)) {
        return this.t('generic.none');
      }

      const shownRepos = this.repoOptions.filter((repo) => !this.hideRepos.includes(repo._key));
      const reducedRepos = shownRepos.reduce((acc, c, i) => {
        acc += c.label;
        const length = shownRepos.length;

        if (i < length - 1) {
          acc += ', ';
        }

        return acc;
      }, '');

      return reducedRepos;
    },

    enabledCharts() {
      return (this.allCharts || []).filter((c) => {
        if ( c.deprecated && !this.showDeprecated ) {
          return false;
        }

        if ( c.hidden && !this.showHidden ) {
          return false;
        }

        if ( this.hideRepos.includes(c.repoKey) ) {
          return false;
        }

        if (isUIPlugin(c)) {
          return false;
        }

        return true;
      });
    },

    filteredCharts() {
      const enabledCharts = (this.enabledCharts || []);
      const clusterProvider = this.currentCluster.status.provider || 'other';

      return filterAndArrangeCharts(enabledCharts, {
        clusterProvider,
        category:       this.category,
        searchQuery:    this.searchQuery,
        showDeprecated: this.showDeprecated,
        showHidden:     this.showHidden,
        hideRepos:      this.hideRepos,
        hideTypes:      [CATALOG._CLUSTER_TPL],
        showPrerelease: this.$store.getters['prefs/get'](SHOW_PRE_RELEASE),
      });
    },
    categories() {
      const map = {};

      for ( const chart of this.enabledCharts ) {
        for ( const c of chart.categories ) {
          if ( !map[c] ) {
            const labelKey = `catalog.charts.categories.${ lcFirst(c) }`;

            map[c] = {
              label: this.$store.getters['i18n/withFallback'](labelKey, null, c),
              value: c,
              count: 0
            };
          }

          map[c].count++;
        }
      }

      const out = Object.values(map);

      out.unshift({
        label: this.t('catalog.charts.categories.all'),
        value: '',
        count: this.enabledCharts.length
      });

      return out;
    },
    filters() {
      if (this.currentCluster) {
        const filters = this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster.id];

        // 用户选择的项目/命名空间
        if (filters) {
          if (filters.join(',').includes('all://')) {
            return [];
          } else {
            return filters;
          }
        }
      }

      return [];
    },
    filteredImages() {
      const filterNamespaces = this.filterNamespaces;

      return this.allVmImages.filter((v) => !filterNamespaces.length || filterNamespaces.includes(v.namespace)).filter((v) => {
        const nameFilter = v.metadata.name.includes(this.filter.value) || (v.spec && v.spec.alias?.includes(this.filter.value));
        const namespaces = v.spec?.share_to;
        const isGlobal = !namespaces;
        const namespaceFilter = isGlobal ? true : (!this.filter.namespace || namespaces.includes(this.filter.namespace));
        const sourceFilter = this.filter.source === 'system' ? v.metadata?.annotations && v.metadata?.annotations[BUILT_IN] === true : true;

        const typeFilter = this.filter.type === 'toolkit' ? (!v.spec.os || v.spec.os === 'tool') : this.filter.type === 'image' ? (!!v.spec.os && v.spec.os !== 'tool') : true;

        return nameFilter && namespaceFilter && sourceFilter && typeFilter;
      }).sort((a, b) => {
        return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
      });
    },
    filterNamespaces() {
      const projectsById = {};
      const namespaces = sortBy(
        this.$store.getters[`cluster/all`](NAMESPACE),
        ['nameDisplay'],
      );
      const projects = this.$store.getters['management/all'](
        MANAGEMENT.PROJECT,
      );

      const namespacesByProject = {};

      namespacesByProject[null] = []; // For namespaces not in a project
      for (const project of projects) {
        projectsById[project.metadata.name] = project;
      }
      for (const namespace of namespaces) {
        let projectId = namespace.projectId;

        if (!projectId || !projectsById[projectId]) {
          // If there's a projectId but that project doesn't exist, treat it like no project
          projectId = null;
        }

        let entry = namespacesByProject[projectId];

        if (!entry) {
          entry = [];
          namespacesByProject[namespace.projectId] = entry;
        }
        entry.push(namespace);
      }
      // 用户选择的命名空间列表
      const filters = [];

      for (const value of this.filters) {
        if (value.includes('ns://')) {
          filters.push(value.split('//')[1]);
        } else if (value.includes('project://')) {
          const namespaces = namespacesByProject[value.split('//')[1]]?.map((v) => v.metadata.name);

          if (namespaces && namespaces.length) {
            namespaces?.forEach((v) => filters.push(v));
          } else {
            filters.push('empty-project');
          }
        }
      }

      return filters;
    },
    vmSchema() {
      return this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VMSET);
    }
  },

  watch: {
    searchQuery(q) {
      this.$router.applyQuery({ [SEARCH_QUERY]: q || undefined });
    },

    category(cat) {
      this.$router.applyQuery({ [CATEGORY]: cat || undefined });
    },

    operatingSystem(os) {
      this.$router.applyQuery({ [OPERATING_SYSTEM]: os || undefined });
    },
  },

  mounted() {
    if ( typeof window !== 'undefined' ) {
      window.c = this;
    }
    this.hideRepos = [];
  },

  methods: {
    colorForChart(chart) {
      const repos = this.repoOptions;
      const repo = findBy(repos, '_key', chart.repoKey);

      if ( repo ) {
        return repo.color;
      }

      return null;
    },

    toggleAll(on) {
      for ( const r of this.repoOptions ) {
        this.toggleRepo(r, on, false);
      }

      this.$nextTick(() => {
        this.allRepos = this.areAllEnabled();
      });
    },

    areAllEnabled() {
      const all = this.$store.getters['catalog/repos'];

      for ( const r of all ) {
        if ( this.hideRepos.includes(r._key) ) {
          return false;
        }
      }

      return true;
    },

    toggleRepo(repo, on, updateAll = true) {
      const hidden = this.hideRepos;

      if ( on ) {
        removeObject(hidden, repo._key);
      } else {
        addObject(hidden, repo._key);
      }

      this.hideRepos = hidden;

      if ( updateAll ) {
        this.allRepos = this.areAllEnabled();
      }
    },

    selectChart(chart) {
      let version;
      const OSs = this.currentCluster.workerOSs;
      const showPrerelease = this.$store.getters['prefs/get'](SHOW_PRE_RELEASE);
      const compatibleVersions = compatibleVersionsFor(chart, OSs, showPrerelease);
      const versions = chart.versions;

      if (compatibleVersions.length > 0) {
        version = compatibleVersions[0].version;
      } else {
        version = versions[0].version;
      }

      this.$router.push({
        name:   `${ PAI }-c-cluster-apps-charts-chart`,
        params: {
          cluster: this.$route.params.cluster,
          product: this.$store.getters['productId'],
        },
        query: {
          [REPO_TYPE]: chart.repoType,
          [REPO]:      chart.repoName,
          [CHART]:     chart.chartName,
          [VERSION]:   version,
        }
      });
    },

    focusSearch() {
      if ( this.$refs.searchQuery ) {
        this.$refs.searchQuery.focus();
        this.$refs.searchQuery.select();
      }
    },

    async refresh(btnCb) {
      try {
        await this.$store.dispatch('catalog/refresh');
        btnCb(true);
      } catch (e) {
        this.$store.dispatch('growl/fromError', e);
        btnCb(false);
      }
    },

    onClickCreate() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          product:  PAI,
          cluster:  this.currentCluster.id,
          resource: PAI_RESOURCES.VM_IMAGE,
        },
        query: { [MODE]: _CREATE },
      });
    },
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <div v-else>
    <el-tabs v-model="activeName">
      <el-tab-pane
        :label="t('pai.apps.container.label')"
        :name="COMMAND_HASH_MPA.container"
      >
        <div class="left-right-split">
          <Select
            :searchable="false"
            :options="repoOptionsForDropdown"
            :value="flattenedRepoNames"
            class="checkbox-select"
            :close-on-select="false"
            @option:selecting="$event.all ? toggleAll(!$event.enabled) : toggleRepo($event, !$event.enabled) "
          >
            <template #selected-option="selected">
              {{ selected.label }}
            </template>
            <template #option="repo">
              <Checkbox
                :value="repo.enabled"
                :label="repo.label"
                class="pull-left repo in-select"
                :class="{ [repo.color]: true}"
                :color="repo.color"
              >
                <template #label>
                  <span>{{ repo.label }}</span><i
                    v-if="!repo.all"
                    class=" pl-5 icon icon-dot icon-sm"
                    :class="{[repo.color]: true}"
                  />
                </template>
              </Checkbox>
            </template>
          </Select>

          <Select
            v-model="category"
            :clearable="false"
            :searchable="false"
            :options="categories"
            placement="bottom"
            label="label"
            style="min-width: 200px;"
            :reduce="opt => opt.value"
          >
            <template #option="opt">
              {{ opt.label }} ({{ opt.count }})
            </template>
          </Select>

          <div class="filter-block">
            <input
              ref="searchQuery"
              v-model="searchQuery"
              type="search"
              class="input-sm"
              :placeholder="t('catalog.charts.search')"
            >

            <button
              v-shortkey.once="['/']"
              class="hide"
              @shortkey="focusSearch()"
            />
            <AsyncButton
              class="refresh-btn"
              mode="refresh"
              size="sm"
              @click="refresh"
            />
          </div>
        </div>

        <Banner
          v-for="err in loadingErrors"
          :key="err"
          color="error"
          :label="err"
        />

        <div v-if="allCharts.length">
          <div
            v-if="filteredCharts.length === 0"
            style="width: 100%;"
          >
            <div class="m-50 text-center">
              <h1>{{ t('catalog.charts.noCharts') }}</h1>
            </div>
          </div>
          <SelectIconGrid
            v-else
            :rows="filteredCharts"
            type="chart"
            name-field="chartNameDisplay"
            description-field="chartDescription"
            :color-for="colorForChart"
            @clicked="(row) => selectChart(row)"
          />
        </div>
        <div
          v-else
          class="m-50 text-center"
        >
          <h1>{{ t('catalog.charts.noCharts') }}</h1>
        </div>
      </el-tab-pane>
      <el-tab-pane
        v-if="vmSchema"
        :label="t('pai.apps.vm.label')"
        :name="COMMAND_HASH_MPA.vm"
      >
        <div class="vm_app_nav">
          <div class="vm_app_create">
            <button
              class="btn role-primary"
              style="width:95px"
              @click="onClickCreate"
            >
              <div style="text-align: center; width:95px">
                <i class="el-icon-plus" /> {{ t('generic.create') }}
              </div>
            </button>
          </div>
          <el-descriptions
            :column="4"
            class="vm_app_filter"
            :colon="false"
          >
            <el-descriptions-item>
              <template slot="label">
                {{ t('pai.vmset.image.auth') }}:
              </template>
              <el-select
                v-model="filter.namespace"
                filterable
              >
                <el-option
                  :label="t('nav.ns.all')"
                  value=""
                />
                <el-option
                  v-for="(item) in namespaces.map(v=>v.metadata.name)"
                  :key="item"
                  :label="item"
                  :value="item"
                />
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                {{ t('pai.vmset.image.app_type') }}:
              </template>
              <el-select
                v-model="filter.type"
                filterable
              >
                <el-option
                  v-for="(item) in typeOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item>
              <template slot="label">
                {{ t('pai.vmset.image.app_source') }}:
              </template>
              <el-select
                v-model="filter.source"
                filterable
              >
                <el-option
                  v-for="(item) in sourceOptions"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value"
                />
              </el-select>
            </el-descriptions-item>
            <el-descriptions-item>
              <el-input
                ref="searchQuery"
                v-model="filter.value"
                type="search"
                :placeholder="t('catalog.charts.search')"
                style="padding:0 10px"
              />
            </el-descriptions-item>
          </el-descriptions>
        </div>
        <div v-if="filteredImages.length">
          <div
            v-if="filteredImages.length === 0"
            style="width: 100%;"
          >
            <div class="m-50 text-center">
              <h1>{{ t('pai.apps.vm.noApps') }}</h1>
            </div>
          </div>
          <SelectIconGrid
            v-else
            type="vm"
            :rows="filteredImages"
            name-field="chartNameDisplay"
            description-field="chartDescription"
            :color-for="colorForChart"
            @clicked="(row) => selectChart(row)"
          />
        </div>
        <div
          v-else
          class="m-50 text-center"
        >
          <h1>{{ t('pai.apps.vm.noApps') }}</h1>
        </div>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<style lang="scss" scoped>
.left-right-split {
    ::v-deep .el-input__inner {
      height: 100%;
    }
    padding: 0 0 20px 0;
    width: 100%;
    z-index: z-index('fixedTableHeader');
    background: transparent;
    display: grid;
    grid-template-columns: 40% auto auto;
    align-content: center;
    grid-column-gap: 10px;

    .filter-block {
      display: flex;
    }
    .refresh-btn {
      margin-left: 10px;
    }

    &.with-os-options {
      grid-template-columns: 40% auto auto auto;
    }

    @media only screen and (max-width: map-get($breakpoints, '--viewport-12')) {
      &{
        grid-template-columns: auto auto !important;
        grid-template-rows: 40px 40px;
        grid-row-gap: 20px;
      }
    }

    @media only screen and (max-width: map-get($breakpoints, '--viewport-7')) {
      &{
        &{
          grid-template-columns: auto !important;
          grid-template-rows: 40px 40px 40px !important;

          &.with-os-options {
            grid-template-rows: 40px 40px 40px 40px !important;
          }
        }
      }
    }
  }

.checkbox-select {
   .vs__search {
    position: absolute;
    right: 0
  }

 .vs__selected-options  {
    overflow: hidden;
    white-space: nowrap;
    text-overflow: ellipsis;
    display: inline-block;
    line-height: 2.4rem;
  }

}

.checkbox-outer-container.in-select {
  transform: translateX(-5px);
  padding: 7px 0 6px 13px;
  width: calc(100% + 10px);

  ::v-deep.checkbox-label {
    display: flex;
    align-items: center;

    & i {
      line-height: inherit;
    }
  }

  &:first-child {
    &:hover {
      background: var(--input-hover-bg);
    }
  }

  &:hover ::v-deep.checkbox-label {
      color: var(--body-text);
    }

  &.rancher {
      &:hover {
      background: var(--app-rancher-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-rancher-accent-text);
    }
    & i {
      color: var(--app-rancher-accent)
    }
  }

  &.partner {
      &:hover {
      background: var(--app-partner-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-partner-accent-text);
    }
    & i {
      color: var(--app-partner-accent)
    }
  }

  &.color1 {
    &:hover {
      background: var(--app-color1-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color1-accent-text);
    }
    & i {
      color: var(--app-color1-accent)
    }
  }
  &.color2 {
    &:hover {
      background: var(--app-color2-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color2-accent-text);
    }
        & i {
      color: var(--app-color2-accent)
    }
  }
  &.color3 {
    &:hover {
      background: var(--app-color3-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color3-accent-text);
    }
    & i {
      color: var(--app-color3-accent)
    }
  }
  &.color4 {
    &:hover {
      background: var(--app-color4-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color4-accent-text);
    }
    & i {
      color: var(--app-color4-accent)
    }
  }
  &.color5 {
    &:hover {
      background: var(--app-color5-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color5-accent-text);
    }
    & i {
      color: var(--app-color5-accent)
    }
  }
  &.color6 {
    &:hover {
      background: var(--app-color6-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color6-accent-text);
    }
    & i {
      color: var(--app-color6-accent)
    }
  }
  &.color7 {
    &:hover {
      background: var(--app-color7-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color7-accent-text);
    }
    & i {
      color: var(--app-color7-accent)
    }
  }
  &.color8 {
    &:hover {
      background: var(--app-color8-accent);
    }
    &:hover ::v-deep.checkbox-label {
      color: var(--app-color8-accent-text);
    }
    & i {
      color: var(--app-color8-accent)
    }
  }
}

.cards{
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  flex-wrap: wrap;
}

.vm_app_nav {
  display: flex;
  .vm_app_create {
    flex: 0.2
  }
  .vm_app_filter {
    flex: 0.8
  }
}

::v-deep .el-descriptions-row {
  display: flex;
  justify-content: right;
}
</style>
<style lang="scss">
.el-tabs__item.is-active, .el-tabs__item:hover{
  color: var(--primary);
}
.el-tabs__active-bar{
  background: var(--primary);
}
</style>
