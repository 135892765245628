<script>
import ResourceTable from '@/pkg/pai/components/ResourceTable';
import Loading from '@shell/components/Loading';
import { SERVICE, NODE } from '@shell/config/types';

export default {
  name:       'ListMachine',
  components: { ResourceTable, Loading },
  props:      {
    resource: {
      type:     String,
      required: true,
    },
    schema: {
      type:     Object,
      required: true,
    },
    loading: {
      type:     Boolean,
      required: false,
    },
    useQueryParamsForSimpleFiltering: {
      type:    Boolean,
      default: false
    }
  },
  async fetch() {
    this.$store.dispatch('management/findAll', {
      type: this.resource,
      opt:  { force: true, watch: true },
    });

    const servicesResult = await this.$store.dispatch('pai-common/getLbServices', { cluster: `${ this.$store.getters['currentCluster'].id }` });

    for (const key in servicesResult.data) {
      const backendPort = servicesResult.data[key];

      this.lbData.push(backendPort);
    }
    const inStore = this.$store.getters['currentStore'](SERVICE);

    await this.$store.dispatch(`${ inStore }/findAll`, { type: SERVICE });
    this.nodeData = await this.$store.dispatch('cluster/findAll', { type: NODE });
  },
  computed: {
    rows() {
      const machines = this.$store.getters['management/all'](this.resource);

      const inStore = this.$store.getters['currentStore'](SERVICE);

      const serviceData = this.$store.getters[`${ inStore }/all`](SERVICE);

      this.rowActions = false;

      return machines.map((item) => {
        if (this.lbData) {
          item.setAnnotation('lbData', JSON.stringify(this.lbData));
        }
        if (this.nodeData?.find((node) => node?.metadata?.annotations['k3s.io/external-ip'] === item.spec?.ip)) { // 判断当前节点是否加入集群
          item.setAnnotation('isCluster', 'true');
        }
        const service = serviceData?.find((service) => service?.spec?.ports !== undefined && service?.spec?.ports[0]?.name === item.name);

        item.setAnnotation('sshPort', service?.spec?.ports ? service?.spec?.ports[0]?.nodePort.toString() : '-');
        item.setAnnotation('isSSH', service ? 'true' : 'false');
        this.rowActions = true;

        return item;
      });
    },
  },
  data() {
    return {
      lbData: [], nodeData: [], rowActions: false,
    };
  },
  $loadingResources() {
    return { loadIndeterminate: true };
  },
};
</script>

<template>
  <Loading
    v-if="$fetchState.pending"
    :delayed="true"
  />
  <ResourceTable
    v-else
    v-bind="$attrs"
    :rows="rows"
    :namespaced="false"
    :groupable="false"
    :schema="schema"
    key-field="_key"
    :loading="loading"
    :use-query-params-for-simple-filtering="useQueryParamsForSimpleFiltering"
    :rowActions="rowActions"
    v-on="$listeners"
  />
</template>
