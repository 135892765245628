<script>
import LazyImage from '../../../components/LazyImage';
import { mapGetters } from 'vuex';
import windowsIcon from '../../../assets/vmImage/windows.png';
import linuxIcon from '../../../assets/vmImage/linux.png';
import { PRODUCT_NAME } from '../../../config/pai';
import { COMMAND_HASH_MPA } from '../../../config/settings';
import { IMAGE } from '../../../config/labels-annotations';
import { PAI_RESOURCES } from '../../../config/types';
import { compare } from '@shell/utils/sort';
import VmQuickForm from '../../../components/VmQuickForm';

export default {
  components: { VmQuickForm, LazyImage },
  async fetch() {
    // 云主机商店
    const vms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_IMAGE, opt: { force: true } });

    this.allVms = vms.sort((a, b) => {
      return compare(b.starTimeStamp, a.starTimeStamp);
    });
    await this.setData();
  },
  data() {
    return {
      vms:    [],
      allVms: [],
      PRODUCT_NAME,
      COMMAND_HASH_MPA,
      vmId:   '',
      vmForm: false,
      image:  {},
      IMAGE
    };
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    ...mapGetters(['currentCluster']),
    filterNamespaces() {
      return this.$parent.getFilterNamespaces();
    },
  },
  methods: {
    compare,
    onClickApp(app) {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-app`,
        params: {
          cluster: this.currentCluster.id,
          product: PRODUCT_NAME,
        },
        // 通过sc查找vmimage，需要sc打label。暂时这么处理
        query: { image: app.id },
      });
    },
    icon(item) {
      if (item?.spec?.logo) {
        const logoName = item?.spec?.logo;

        try {
          const logo = require(`../../../assets/images/imageLogo/${ logoName }`);

          if (logo) {
            return logo;
          }
        } catch (e) {}
      }

      return item.spec?.os?.includes('windows') ? windowsIcon : linuxIcon;
    },
    setData() {
      // 云主机商店
      this.vms = this.allVms.filter((vm) => {
        let flag = true;

        if (this.filterNamespaces.length > 0 && !this.filterNamespaces.includes(vm.namespace)) {
          flag = false;
        }

        return flag;
      });
    },
    handleOpenVM(item) {
      this.image = item;
      this.vmForm = true;
    },
    hanleCloseVM() {
      this.vmForm = false;
    }
  },
  watch: {
    filterNamespaces: {
      deep:      true,
      immediate: true,
      handler() {
        this.setData();
      }
    }
  }
};
</script>
<template>
  <div>
    <div class="title">
      {{ t('pai.apps.vm.store') }}
    </div>
    <div
      v-loading="$fetchState.pending"
      class="list"
    >
      <div
        v-for="(item) in vms.slice(0, 3)"
        :key="item.id"
        class="item"
      >
        <div
          class="box"
          @mouseenter="vmId = item.id"
        >
          <el-tooltip
            :content="t('pai.vmset.tips.toolNotSupportQuickInstall')"
            effect="light"
          >
            <div class="logo-bg">
              <LazyImage
                :src="icon(item)"
                class="logo"
              />
            </div>
          </el-tooltip>
        </div>
        <div
          v-if="vmId === item.id && (!!item.spec.os && (!item.labels || (item.labels && item.labels[IMAGE.TOOL] !== 'true')))"
          class="modalBox"
          @mouseleave="vmId = ''"
          @click="handleOpenVM(item)"
        >
          {{ t('pai.apps.vm.quickInstallation') }}
        </div>
        <div
          class="name"
          style="font-size: 14px; cursor: pointer"
          @click="onClickApp(item)"
        >
          <a>{{ item.spec.alias || item.metadata.name }}</a>
        </div>
      </div>
      <div class="item">
        <div
          class="box"
          @click="$router.push({name:'pai-c-cluster-apps-charts',params:{product:PRODUCT_NAME,cluster:currentCluster.id},hash:COMMAND_HASH_MPA.vm})"
        >
          {{ t('pai.labels.moer') }}>>
        </div>
      </div>
    </div>
    <vm-quick-form
      v-if="vmForm"
      :on-close="hanleCloseVM"
      :vm-id="vmId"
      :image="image"
    />
  </div>
</template>
<style lang="scss" scoped>
.title {
    color: #0C5489;
    font-size: 18px;
    margin-bottom: 10px;
}

.list {
    display: flex;
    flex-wrap: wrap;

    .item {
        margin-right: 10px;
        margin-bottom: 10px;

        .box {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #EFEFEF;
            border: 1px solid #CECECE;
            cursor: pointer;

            img {
                width: 50px;
            }
        }
      .modalBox {
        width: 80px;
        height: 80px;
        display: flex;
        justify-content: center;
        align-items: center;
        cursor: pointer;
        background: #3D7EAD;
        opacity: 0.9;
        color: #fff;
        margin-top: -80px;
        z-index: 999;
      }

        .name {
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            max-width: 80px;
        }
    }
}
</style>
