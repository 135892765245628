<script>
import {
  SCHEMA, NORMAN, NODE, PVC, PV, POD
} from '@/shell/config/types';
import { clone } from '@/shell/utils/object';
import { createYaml } from '@/shell/utils/create-yaml';
import { mapGetters } from 'vuex';
import { PAI_RESOURCES } from '../../../config/types';

export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    const params = this.$route.params;
    const time = new Date().valueOf();

    return {
      params,
      snapshotList:                [],
      createSnapshotDialogVisible: false,
      form:                        { name: `snapshot-${ this.value.metadata.namespace }-${ time }` },
      allDisk:                     [],
      yamlList:                    [],
      diskInfoValue:               {},
      filterValue:                 '',
    };
  },
  async fetch() {
    this.snapshotList = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.SNAPSHOT });
  },
  computed: {
    ...mapGetters([{ t: 'i18n/t' }, 'isRancher', 'currentCluster']),
    principal() {
      if (this.isRancher) {
        return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
      } else {
        const user = this.$store.getters['auth/v3User'];

        if (user) {
          return { loginName: user.name };
        }

        return { loginName: 'admin' };
      }
    },
    newTableData() {
      const snapshotTableData = this.snapshotList;
      const newData = {};

      snapshotTableData.forEach((item) => {
        const podName = item.metadata.annotations ? item.metadata.annotations.podName : '';

        if (podName === this.podName && item.metadata?.annotations ) {
          const displayName = item.metadata?.name;
          let restoreSize = 0;

          if (!newData[displayName]) {
            for (const key in item?.status?.snapshotStatus) {
              if (item.status.snapshotStatus[key].restoreSize) {
                restoreSize += Number((item.status?.snapshotStatus[key]?.restoreSize)?.replace('Gi', ''));
              }
            }
            newData[displayName] = {
              name:            displayName,
              vmName:          item.metadata.annotations.vmName,
              createTimeStamp: item.metadata.annotations.createTime,
              nameSpace:       item.metadata.namespace,
              tempList:        [],
              status:          this.snapshotStatusFn(item.status?.phase ? item.status?.phase : 'Other' ),
              size:            `${ restoreSize }`,
              volumeList:      [],
              createBy:        item.spec.creator,
              createTime:      new Date(Number(item.metadata.annotations.createTime)).toLocaleString().replace('/', '-').replace('/', '-'),
              podName:         item.metadata?.annotations.podName,
              markRemoved:     item.status?.markRemoved || false
            };
          }
          newData[displayName].volumeList.push(item.metadata?.labels?.persistentvolumeclaim);
          newData[displayName].tempList.push({ tempName: item.metadata?.name, pvName: item.spec?.volume });
          if (!this.diskInfoValue[displayName]) {
            this.diskInfoValue[displayName] = item.metadata?.labels?.persistentvolumeclaim;
          }
        }
      });

      return Object.values(newData).filter((item) => item?.markRemoved === false);
    }
  },
  watch: {
    'form.name': {
      handler(newVal) {
        this.form.name = newVal;
      },
      deep: true
    }
  },
  methods: {
    snapshotStatusFn(status) {
      let out;

      switch (status) {
      case 'Succeeded':
        out = this.t('pai.detail.vmset.succeeded');
        break;
      case 'Failed':
        out = this.t('pai.detail.vmset.failed');
        break;
      case 'Running':
        out = this.t('pai.detail.vmset.snapshot.running');
        break;
      case 'Other':
        out = '-';
        break;
      default:
      }

      return out;
    },
    snapshotShow() {
      const time = new Date().valueOf();

      this.createSnapshotDialogVisible = true;
      this.form = { name: `snapshot-${ this.value.metadata.namespace }-${ time }` };
    },
    onFormName(value) {
      this.form.name = value;
    },

    async onSnapshot() {
      if (this.form.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.form.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      }

      const time = new Date().valueOf();

      try {
        const snapshot = await this.$store.dispatch(`cluster/create`, {
          type:     PAI_RESOURCES.SNAPSHOT,
          metadata: {
            name:        `${ this.form.name }-${ time }`,
            namespace:   this.value.metadata.namespace,
            annotations: {
              createTime: String(time),
              podName:    this.podName,
              vmName:     this.value.metadata.name
            }
          },
          spec: {
            vm:         this.value.metadata.name,
            vmInstance: this.podName,
            creator:    this.principal.loginName
          }
        });

        await snapshot.save({ url: `/k8s/clusters/${ this.currentCluster.id }/v1/${ PAI_RESOURCES.SNAPSHOT }` });
        // 刷新数据
        await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.SNAPSHOT, opt: { force: true } });
        this.$message({
          type:    'success',
          message: `${ this.t('pai.labels.success') }`,
        });
      } catch (e) {
        this.$message({
          type:    'warning',
          message: e.message
        });
      }
      this.createSnapshotDialogVisible = false;
      this.$emit('update');
    },

    deleteSnapshot(value) {
      this.$confirm(this.t('pai.detail.vmset.confirmDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(() => {
        const index = this.snapshotList.findIndex((item) => {
          return value.name === item.metadata.name && value.createBy === item.spec.creator && value.nameSpace === item.metadata.namespace && value.podName === item.metadata.annotations.podName;
        });

        this.snapshotList[index].remove();
        this.$message({
          type:    'success',
          message: `${ this.t('pai.detail.vmset.deleteSuccess') }`
        });
      }).catch((e) => {
        console.log(e);
      });
    },
    restoreSnapShot(value) {
      if (this.value.spec.power !== 'Off') {
        //  请在关机状态下恢复，请确认是否关机
        this.$confirm(this.t('pai.vmset.tips.vmOffRestore'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.edit.machine.yes'),
          cancelButtonText:  this.t('pai.edit.machine.no'),
          type:              'warning'
        }).then(async() => {
          try {
            this.powerState = 'Off';
            await this.value.power('Off');
            this.$message({
              type:    'success',
              message: `${ this.t('pai.labels.success') }`,
            });
          } catch (e) {
            this.$message({
              type:    'warning',
              message: this.t('pai.vmset.tips.cancelOffRestore')
            });
          }
        }).catch(() => {
        });
      } else {
        this.$confirm(this.t('pai.detail.vmset.confirmRestore'), this.t('pai.detail.vmset.tooltip'), {
          confirmButtonText: this.t('pai.detail.vmset.confirm'),
          cancelButtonText:  this.t('pai.detail.vmset.cancel'),
          type:              'warning'
        }).then(async() => {
          await this.$message({
            type:    'warning',
            message: this.t('pai.detail.vmset.restoring')
          });

          const time = new Date().valueOf();
          const revert = await this.$store.dispatch(`cluster/create`, {
            type:     PAI_RESOURCES.VM_REVERT,
            metadata: {
              name:      `vmrevert-${ value.nameSpace }-${ time }`,
              namespace: value.nameSpace,
            },
            spec: { snapshot: value.name }
          });

          await revert.save({ url: `/k8s/clusters/${ this.currentCluster.id }/v1/${ PAI_RESOURCES.VM_REVERT }` });
          // 刷新数据
          await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.SNAPSHOT, opt: { force: true } });
          this.$message({
            type:    'success',
            message: this.t('pai.labels.success')
          });
        }).catch((e) => {
          this.$message({
            type:    'error',
            message: this.t('pai.detail.vmset.restorefailed')
          });
        });
      }
    },
  }
};
</script>

<template>
  <el-card>
    <el-row>
      <el-col :span="24">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <el-button
              class="btn role-primary"
              @click="snapshotShow"
            >
              <i class="el-icon-plus" /> {{ t('pai.detail.vmset.tab.snapshotManagement.newSnapshot') }}
            </el-button>
          </div>
          <el-input
            v-model="filterValue"
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
            size="small"
          />
        </div>
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item.name.includes(filterValue)):newTableData"
          class="snapShotTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
          />
          <el-table-column
            prop="name"
            :label="t('pai.detail.vmset.tab.snapshotManagement.name')"
            width="300"
          />
          <el-table-column
            prop="status"
            :label="t('pai.detail.vmset.status')"
          />
          <el-table-column
            prop="size"
            :label="t('pai.detail.vmset.tab.snapshotManagement.size')"
          />
          <!--          <el-table-column-->
          <!--            prop="diskInfo"-->
          <!--            :label="t('pai.detail.vmset.tab.snapshotManagement.diskInfo')"-->
          <!--            width="300"-->
          <!--          >-->
          <!--            <template slot-scope="scope">-->
          <!--              <el-tooltip effect="light">-->
          <!--                <div slot="content">-->
          <!--                  <div-->
          <!--                    v-for="(item, index) in scope.row.volumeList"-->
          <!--                    :key="index"-->
          <!--                    class="content"-->
          <!--                  >-->
          <!--                    <p style="font-size: 14px">-->
          <!--                      {{ item }}-->
          <!--                    </p>-->
          <!--                  </div>-->
          <!--                </div>-->
          <!--                <template>-->
          <!--                  <div class="content">-->
          <!--                    {{ scope.row.volumeList[0] }}-->
          <!--                    <i class="icon el-icon-s-unfold" />-->
          <!--                  </div>-->
          <!--                </template>-->
          <!--              </el-tooltip>-->
          <!--            </template>-->
          <!--          </el-table-column>-->
          <el-table-column
            prop="createBy"
            :label="t('pai.detail.vmset.tab.snapshotManagement.createBy')"
          />
          <el-table-column
            prop="createTime"
            :label="t('pai.detail.vmset.tab.snapshotManagement.createTime')"
            width="200"
          />
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                @click="restoreSnapShot(scope.row)"
              >
                <i class="el-icon-refresh-left" /> <span>{{ t('pai.detail.vmset.tab.snapshotManagement.resume') }}</span>
              </el-button>
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.status === 'removing'"
                @click="deleteSnapshot(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="t('pai.detail.vmset.tab.snapshotManagement.newSnapshot')"
          :visible.sync="createSnapshotDialogVisible"
          width="500px"
        >
          <el-descriptions
            :column="1"
            :colon="false"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.snapshotManagement.name') }}
              </template>
              <el-input
                v-model="form.name"
                @change="onFormName"
              />
            </el-descriptions-item>
          </el-descriptions>
          <p>{{ t('pai.labels.tip')+'：' +t('pai.detail.vmset.tab.snapshotManagement.snapshotTip') + '，' + t('pai.detail.vmset.tab.snapshotManagement.longhornTip') }}</p>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button @click="createSnapshotDialogVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
            <el-button
              type="primary"
              @click="onSnapshot"
            >{{ t('pai.detail.vmset.confirm') }}</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </el-card>
</template>
<style lang="scss" scoped>
.snapShotTable{
  padding: 0 20px;
  width: 100%;
}
.el-input, .el-select, .el-input-number{
  width: 300px;
}
::v-deep .el-descriptions-item__label {
  width: 100px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
