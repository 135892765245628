<script>
import { MONITOR_PRODUCT_TYPES, PRODUCT_NAME as MONITOR } from '~/pkg/pai/config/monitor';
import { PAI_PRODUCT_TYPES, PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
import { DEPLOY_PRODUCT_TYPES, PRODUCT_NAME as DEPLOY } from '~/pkg/pai/config/deploy';
import { PLATFORM_PRODUCT_TYPES, PRODUCT_NAME as PLATFORM } from '~/pkg/pai/config/platform';

export default {
  props: {
    row: {
      type:     Object,
      required: true,
    },
  },

  computed: {
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else {
        return 'local';
      }
    },
    product() {
      if (PAI_PRODUCT_TYPES.includes(this.row.type)) {
        return PAI;
      }
      if (MONITOR_PRODUCT_TYPES.includes(this.row.type)) {
        return MONITOR;
      }
      if (DEPLOY_PRODUCT_TYPES.includes(this.row.type)) {
        return DEPLOY;
      }
      if (PLATFORM_PRODUCT_TYPES.includes(this.row.type)) {
        return PLATFORM;
      }

      return PAI;
    },
    url() {
      const name = `${ this.product }-c-cluster-resource${ this.row.namespace ? '-namespace' : '' }-id`;

      const params = {
        resource:  this.row.type,
        namespace: this.row.namespace,
        id:        this.row.name,
        product:   this.product,
        cluster:   this.currentCluster
      };

      return {
        name,
        params,
      };
    },
  },
};
</script>

<template>
  <span v-if="row">
    <nuxt-link :to="url">
      {{ row.nameDisplay }}
    </nuxt-link>
  </span>
</template>
