<template>
  <div class="contentTemplate">
    <div class="titleContent">
      <el-tooltip
        :content="content"
        effect="light"
      >
        <span class="tipsIcon">
          <i class="icon el-icon-question" />
        </span>
      </el-tooltip>
    </div>
    <div
      class="contentBorder"
      :style="{'background':background}"
    >
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name:  'TitleTips',
  props: {
    content: {
      type:    String,
      default: ''
    },
    background: {
      type:    String,
      default: '#FAFAFA'
    }
  }
};
</script>

<style lang="scss" scoped>
.titleContent {
  width: 70%;
  position: relative;
  top: -36px;
  left: 180px;
  color: #CECECE;
}
.tipsIcon {
  margin-top: 0px;
  margin-right: 18px;
  font-size: 16px;
}
.contentBorder {
  border: 1px dashed #BBBBBB;
  padding: 22px 14px;
}
.flex{
  display: flex;
  justify-content: flex-start;
}
</style>
