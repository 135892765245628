<script>
import { PVC, POD, NAMESPACE } from '@/shell/config/types';
import { PVC_LABELS } from '@/pkg/pai/config/labels-annotations';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { Banner } from '@components/Banner';

export default {
  components: { Banner },
  data() {
    return {
      pods:            [],
      pvcs:            [],
      checkList:       [],
      isIndeterminate: false,
      checkAll:        false,
      loading:         false,
      isForce:         false,
    };
  },
  async fetch() {
    this.pvcs = await this.$store.dispatch('cluster/findAll', { type: PVC });
    this.pods = await this.$store.dispatch('cluster/findAll', { type: POD });
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'deleteVmModal';
    },
    values() {
      const values = this.$store.state['pai-common'].currentItem;

      if (Array.isArray(values)) {
        return values;
      } else {
        return [values];
      }
    },
    allVolumes() {
      const result = [];
      const existPvc = [];

      for (const value of this.values) {
        const vmPvcs = this.pvcs.filter((pvc) => {
          const mountTo = pvc.metadata.labels ? pvc.metadata.labels['com.tdology.pvc.mounto'] : null;
          const vmsetName = pvc.metadata.labels ? pvc.metadata.labels['com.tdology.virt.vmsets'] : null;

          if (value.spec?.volumes?.length > 0) {
            value.spec?.volumes?.filter((v) => {
              if (v.name === pvc.metadata.name) {
                existPvc.push(pvc);
              }
            });
          }

          return mountTo && vmsetName && vmsetName === value.metadata.name && pvc.metadata.namespace === value.metadata.namespace;
        });

        result.push(...vmPvcs, ...existPvc);
      }

      return result;
    },
    allVolumesData() {
      return this.allVolumes.map((item) => {
        return {
          disabled: item.spec.accessModes[0] === 'ReadOnlyMany',
          name:     item.metadata.name,
        };
      });
    }
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    async onConfirm() {
      // 用户选择了需要删除的磁盘后，删除选择的磁盘，清空清除剩余pvc的annotations
      try {
        this.loading = true;

        const deleteList = this.allVolumes.filter((v) => this.checkList.includes(v.metadata.name));
        const list = this.allVolumes.filter((v) => !this.checkList.includes(v.metadata.name));

        for (const item of deleteList) {
          await item.remove();
        }
        for (const item of list) {
          item.setLabel(PVC_LABELS.MOUNT_POD, '');
          item.setLabel(PVC_LABELS.MOUNT_VM, '');
          await item.save();
        }

        for (const value of this.values) {
          const vm = await this.$store.getters['cluster/byId'](PAI_RESOURCES.VMSET, value.id);
          vm.setAnnotation('removed', 'true')
          await vm._save();
          if (this.isForce) {
            await vm.forceRemove();
          } else {
            await vm.remove();
          }
        }
        // 强制刷新缓存
        await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET, opt: { force: true } });
        this.$emit('updateVms');
        this.loading = false;
        await this.handleClose();
        await this.$message({
          type:    'success',
          message: this.t('pai.labels.success')
        });
        if (this.$route.name === 'pai-c-cluster-resource-namespace-id') {
          await this.$router.push({
            name:   `${ PAI }-c-cluster-resource`,
            params: {
              product:  PAI,
              cluster:  this.$route.params.cluster,
              resource: PAI_RESOURCES.VMSET,
            },
          });
        }
      } catch (e) {
        this.loading = false;
        await this.$message({
          type:    'warning',
          message: e.message
        });
      }
    },
    handleCheckAllChange(val) {
      this.checkList = val ? this.allVolumes.map((v) => v.name) : [];
      this.isIndeterminate = false;
    },
    handleCheckedChange(value) {
      const checkedCount = value.length;

      this.checkAll = checkedCount === this.allVolumes.length;
      this.isIndeterminate = checkedCount > 0 && checkedCount < this.allVolumes.length;
    },
  },
};
</script>
<template>
  <el-dialog
    :title="t('dialog.deleteDialog.title')"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="handleClose"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <div class="content">
      <div
        v-if="allVolumesData.length > 0"
        class="title"
      >
        <div>{{ t('pai.vmset.tips.delete') }}</div>
      </div>
      <div class="disks">
        <div
          v-if="allVolumesData.length > 0"
          class="list"
        >
          <el-checkbox-group
            v-for="(item,i) in allVolumesData"
            :key="item.name+i"
            v-model="checkList"
            @change="handleCheckedChange"
          >
            <el-checkbox
              :label="item.name"
              :disabled="item.disabled"
            />
          </el-checkbox-group>
        </div>
        <div
          v-else
          class="text"
        >
          {{ t('pai.vmset.tips.nodisk') }}
        </div>
      </div>
      <div>
        <el-checkbox
          v-model="checkAll"
          :indeterminate="isIndeterminate"
          @change="handleCheckAllChange"
        >
          {{ t('pai.labels.selectAll') }}
        </el-checkbox>

        <el-checkbox v-model="isForce">
          {{ t('pai.vmset.tips.force') }}
        </el-checkbox>
      </div>
      <Banner
        color="warning"
        label-key="pai.vmset.tips.forceRemoveWarning"
      />
    </div>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >{{ t('pai.detail.vmset.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.content {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  .title {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
  .disks {
    width: 100%;
    margin: 10px 0;
    padding: 10px;
    text-align: left;
    border: 1px #dedede dashed;
    .list {
      display: flex;
      flex-wrap: wrap;

      .el-checkbox-group {
        margin-right: 10px;
      }
    }
    .text{
      text-align: center;
    }
  }
}
</style>
