import SteveModel from '@shell/plugins/steve/steve-class';
import { POD, PVC, SERVICE, WORKLOAD_TYPES } from '@shell/config/types';
import { ALIAS, PVC_LABELS } from '~/pkg/pai/config/labels-annotations';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { COMMAND_HASH_MPA, IMAGE } from '../config/settings';
import { PRODUCT_NAME } from '../config/pai';
import StarMixin from '~/pkg/pai/mixins/star';
import { Message } from 'element-ui';

export default class extends StarMixin(SteveModel) {
  async remove() {
    // 删除镜像前判断是否有云主机或pvc挂载了该镜像
    if (this.usedVms.length) {
      throw new Error(this.t('pai.vmset.tips.image.usedVm'));
    } else if (this.usedPvcs.length) {
      throw new Error(this.t('pai.vmset.tips.image.usedPvc'));
    } else {
      const clusterId = this.$rootGetters['currentCluster']?.id;

      // 删除镜像的同时删除对应的backingimage
      await this._remove();
      const backingImageName = `${ this.namespace }-${ this.name }`;
      const opt = {
        url:    `/k8s/clusters/${ clusterId }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages/${ backingImageName }?action=backingImageCleanup`,
        method: 'delete',
      };

      try {
        await this.$dispatch('request', { opt } );
      } catch (e) {
        console.log(e);
      }
    }
  }

  async export() {
    const clusterId = this.$rootGetters['currentCluster']?.id;

    if (this.spec?.driver === IMAGE.DRIVER[0]) { // 分布式存储
      const backingImageName = `${ this.namespace }-${ this.name }`;
      const opt = {
        url:    `/k8s/clusters/${ clusterId }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages`,
        method: 'get',
      };

      try {
        const list = await this.$dispatch('request', { opt } );
        const backingImage = list.data.find((v) => v.name === backingImageName);

        if (backingImage) {
          const diskFileStatus = Object.values(backingImage.diskFileStatusMap).map((v) => v.state);

          if (diskFileStatus.includes('ready')) {
            window.location.href = `${ window.location.origin }/api/v1/namespaces/longhorn-system/services/http:longhorn-frontend:80/proxy/v1/backingimages/${ backingImageName }/download`;
          } else {
            Message.warning(this.t('pai.vmset.tips.image.noReady'));
          }
        } else {
          Message.warning(this.t('pai.vmset.tips.image.noBackingImage'));
        }
      } catch (e) {
        console.log(e);
      }
    } else { // 本地存储
      setTimeout(async() => {
        this.setAnnotation('com.tdology.pvc.download', 'true');
        await this.save();
        const imageData = await this.$dispatch('findAll', { type: PAI_RESOURCES.VM_IMAGE, opt: { force: true } });
        const image = imageData?.find((item) => item?.metadata?.namespace === this.namespace && item?.metadata?.name === this.name);
        let intervalTimer = null;
        let fileMessage = null;

        if (image) {
          if (image.status?.preparationProgress !== undefined && image.status?.preparationProgress === 100) {
            fileMessage = Message({
              message:  this.t('pai.vmset.image.fileLoading'),
              type:     'warning',
              duration: 0
            });
            intervalTimer = setInterval(async() => {
              const jobData = await this.$dispatch('findAll', { type: WORKLOAD_TYPES.JOB, opt: { force: true } });
              const job = jobData?.find((item) => item?.metadata?.namespace === this.namespace && item?.metadata?.labels['com.tdology.virt.vmimages.downloader.export.vmimage'] === this.name);

              if (job) {
                if (job?.status?.active === job?.status?.ready) {
                  clearInterval(intervalTimer);
                  fileMessage.close();
                  window.open(`${ window.location.origin }/k8s/clusters/${ clusterId }/api/v1/namespaces/${ image?.metadata?.namespace }/services/http:exporter-${ image?.metadata?.name }:8080/proxy/vmimage`);
                }
              }
            }, 3000);
          } else {
            Message.warning(this.t('pai.vmset.tips.image.noReady'));
          }
        }
      });
    }
  }

  get usedPvcs() {
    const pvcs = this.$rootGetters[`cluster/all`](PVC);
    const result = [];

    pvcs.forEach((v) => {
      const sc = v.spec.storageClassName;

      if (sc === `${ this.namespace }-${ this.name }`) {
        result.push(v);
      }
    });

    return result;
  }

  get usedVms() {
    const pvcs = this.usedPvcs;
    const vms = this.$rootGetters[`cluster/all`](PAI_RESOURCES.VMSET);
    const result = [];

    pvcs.forEach((v) => {
      if (v.labels && v.labels[PVC_LABELS.MOUNT_VM]) {
        const name = v.labels[PVC_LABELS.MOUNT_VM];
        const vm = vms.find((v) => v.name === name);

        if (vm) {
          result.push(vm);
        }
      }
    });

    return result;
  }

  get doneOverride() {
    return {
      name:   'pai-c-cluster-apps-charts',
      params: { product: PRODUCT_NAME, cluster: this.$rootGetters['clusterId'] },
      hash:   COMMAND_HASH_MPA.vm
    };
  }

  get alias() {
    return (this.spec && this.spec.alias) ? this.spec.alias : '';
  }

  get nameDisplay() {
    return this.alias ? this.alias : super.nameDisplay;
  }
}
