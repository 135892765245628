<script>
import ClusterSingleResource from './components/ClusterSingleResource';
import ClusterSingleModule from './components/ClusterSingleModule';
import ClusterSingleChart from './components/ClusterSingleChart';
import RunResourcesTable from './components/runResourcesTable';
import StaticResourcesTable from './components/staticResourcesTable';
import { PAI_RESOURCES } from '../../../../config/types';
import { MANAGEMENT, NAMESPACE } from '@shell/config/types';

export default {
  layout:     'pai/default',
  components: {
    ClusterSingleResource,
    ClusterSingleModule,
    ClusterSingleChart,
    RunResourcesTable,
    StaticResourcesTable,
  },
  data() {
    return {
      cloudData:     [],
      podData:       [],
      activeName:    'run',
      isExpandAll:   false,
      fullScreen:    false,
      runVlaue:      '',
      staticValue:   '',
      allNamespaces: [],
      projects:      [],
      cloudDatas:    {
        active: 0,
        all:    0
      },
      podDatas: {
        active: 0,
        all:    0
      },
      statefulsetData: {
        active: 0,
        all:    0
      },
      deploymentData: {
        active: 0,
        all:    0
      },
      jobData: {
        active: 0,
        all:    0
      },
      cronjobData: {
        active: 0,
        all:    0
      },
      daemonsetData: {
        active: 0,
        all:    0
      }
    };
  },
  async fetch() {
    this.allNamespaces = this.$store.getters[`cluster/all`](NAMESPACE);
    this.projects = this.$store.getters['management/all'](MANAGEMENT.PROJECT);
    try {
      this.podData = await this.$store.dispatch('cluster/findAll', { type: 'pod' });
      //  容器组
      const activePod = [];

      this.podData.forEach((item) => {
        if (item.metadata.state.name === 'Running' || item.metadata.state.name === 'running' || item.metadata.state.name === 'Completed' || item.metadata.state.name === 'completed') {
          activePod.push(item) ;
        }
      });
      this.$set(this.podDatas, 'all', this.podData.length);
      this.$set(this.podDatas, 'active', activePod.length);
    } catch (e) {
      console.log(e);
    }
    // 云主机组
    try {
      this.cloudData = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });
      const activeCloud = [];

      this.cloudData.forEach((item) => {
        if (item.spec.power === 'On') {
          activeCloud.push(item) ;
        }
      });

      this.$set(this.cloudDatas, 'all', this.cloudData.length);
      this.$set(this.cloudDatas, 'active', activeCloud.length);
    } catch (e) {
      console.log(e);
    }
    //  有状态负载
    try {
      const statefulsetData = await this.$store.dispatch('cluster/findAll', { type: 'apps.statefulset' });
      const activeStatefulset = [];

      statefulsetData.forEach((item) => {
        if (item.metadata.state.name === 'active') {
          activeStatefulset.push(item) ;
        }
      });
      this.$set(this.statefulsetData, 'all', statefulsetData.length);
      this.$set(this.statefulsetData, 'active', activeStatefulset.length);
    } catch (e) {
      console.log(e);
    }

    //  无状态负载
    try {
      const deploymentData = await this.$store.dispatch('cluster/findAll', { type: 'apps.deployment' });
      const activeDeployment = [];

      deploymentData.forEach((item) => {
        if (item.metadata.state.name === 'active') {
          activeDeployment.push(item) ;
        }
      });
      this.$set(this.deploymentData, 'all', deploymentData.length);
      this.$set(this.deploymentData, 'active', activeDeployment.length);
    } catch (e) {
      console.log(e);
    }

    //  普通任务
    try {
      const jobData = await this.$store.dispatch('cluster/findAll', { type: 'batch.job' });
      const activeJob = [];

      jobData.forEach((item) => {
        if (item.metadata.state.name === 'active') {
          activeJob.push(item) ;
        }
      });
      this.$set(this.jobData, 'all', jobData.length);
      this.$set(this.jobData, 'active', activeJob.length);
    } catch (e) {
      console.log(e);
    }

    //  定时任务
    try {
      const cronjobData = await this.$store.dispatch('cluster/findAll', { type: 'batch.cronjob' });
      const activeCronjob = [];

      cronjobData.forEach((item) => {
        if (item.metadata.state.name === 'active') {
          activeCronjob.push(item) ;
        }
      });
      this.$set(this.cronjobData, 'all', cronjobData.length);
      this.$set(this.cronjobData, 'active', activeCronjob.length);
    } catch (e) {
      console.log(e);
    }

    //  守护进程
    try {
      const daemonsetData = await this.$store.dispatch('cluster/findAll', { type: 'apps.daemonset' });
      const activeDaemonset = [];

      daemonsetData.forEach((item) => {
        if (item.metadata.state.name === 'active') {
          activeDaemonset.push(item) ;
        }
      });
      this.$set(this.daemonsetData, 'all', daemonsetData.length);
      this.$set(this.daemonsetData, 'active', activeDaemonset.length);
    } catch (e) {
      console.log(e);
    }
  },
  methods: {
    handleTabs(val) {
      this.runVlaue = '';
      this.staticValue = '';
    },
    handleExpand() {
      this.isExpandAll = !this.isExpandAll;
    },
    handleScreen() {
      this.fullScreen = !this.fullScreen;
    },
  }
};

</script>

<template>
  <div class="cluster-single">
    <div class="cluster-single-title">
      {{ t('pai.overview.label') }}
    </div>
    <ClusterSingleResource
      v-show="!fullScreen"
      :cloud-data="cloudData"
      :pod-data="podData"
    />
    <ClusterSingleModule
      v-show="!fullScreen"
      :cloudData="cloudDatas"
      :podData="podDatas"
      :statefulsetData="statefulsetData"
      :deploymentData="deploymentData"
      :jobData="jobData"
      :cronjobData="cronjobData"
      :daemonsetData="daemonsetData"
    />
    <ClusterSingleChart v-show="!fullScreen" />
    <div class="clusterSingleTable bgCard">
      <div class="tableOperate flex">
        <span>
          <el-input
            v-if="activeName == 'run'"
            v-model="runVlaue"
            style="width:220px;position: relative;top: -10px;"
            :placeholder="t('pai.vmset.nameSpace')"
          />
          <el-input
            v-else
            v-model="staticValue"
            style="width:220px;position: relative;top: -10px;"
            :placeholder="t('pai.vmset.nameSpace')"
          />
        </span>

        <button
          class="btn role-primary"
          style="position: relative;top: -12px;margin: 0px 10px"
          @click="handleExpand"
        >
          <i
            class="el-icon-finished"
          />{{ isExpandAll ? t('pai.list.collapseAll') : t('pai.list.expandAll') }}
        </button>
        <span
          style="font-size: 34px;position: relative;top: -3px;cursor: pointer"
          @click="handleScreen"
        >
          <i
            :class="fullScreen ? 'el-icon-rank' : 'el-icon-full-screen'"
          />
        </span>
      </div>
      <el-tabs
        v-model="activeName"
        type="card"
        @tab-click="handleTabs"
      >
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.overview.dynamicResourceAllocation')"
          name="run"
        >
          <RunResourcesTable
            :is-expand-all="isExpandAll"
            :input-value="runVlaue"
            :all-namespaces="allNamespaces"
            :projects="projects"
          />
        </el-tab-pane>
        <el-tab-pane
          :label="t('pai.detail.vmset.tab.overview.staticResourceAllocation')"
          name="static"
        >
          <StaticResourcesTable
            :is-expand-all="isExpandAll"
            :input-value="staticValue"
            :cloud-data="cloudData"
            :pod-data="podData"
            :all-namespaces="allNamespaces"
            :projects="projects"
          />
        </el-tab-pane>
      </el-tabs>
    </div>
  </div>
</template>

<style lang="scss">
.cluster-single{
  background-color: #EFEFEF;
  .cluster-single-title{
    font-size: 16px;
    color: #2F76A9;
  }
  .bgCard{
    padding: 10px;
    margin-top: 14px;
    background-color: #FFFFFF;
    border-radius: 5px;
  }
  .cluster-title {
    font-size: 14px;
    font-weight: 600;
    margin: 0px 0px 10px 0px;
  }
  .cluster-flex {
    display: flex;
    justify-content: space-between;
  }
  .cluster-num{
    font-size: 18px;
    font-weight: 600;
  }
  .clusterSingleTable{
    overflow: hidden;
    .tableOperate{
      float: right;
    }
  }
  .el-tabs--card>.el-tabs__header {
    width: 480px;
  }
  .el-table__header{
    width: 100% !important;
  }
  .el-table__body{
    width: 100% !important;
  }
}
</style>
