import Pod from '@shell/models/pod';
import { convertType, RUST_DESK_URL, WINDOW_OPEN_PARAMS } from '../utils';
import { PRODUCT_NAME } from '~/pkg/pai/config/pai';
import { PAI_NODE } from '~/pkg/pai/config/types';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import { ALIAS, RUST_DESK_ANNOTATIONS, VIRT } from '~/pkg/pai/config/labels-annotations';
import ContainerTypeMixin from '~/pkg/pai/mixins/containerType';
import { base64Decode, base64Encode } from '@shell/utils/crypto';
import { randomStr } from '@shell/utils/string';
import {
  sendCommandsToPodFirstContainerWithCallback,
  sendCommandsToPodFirstContainerWithResult,
} from '~/pkg/pai/utils/guest-command';
import { MessageBox } from 'element-ui';

export default class extends ContainerTypeMixin(PaiLocationMixin(Pod)) {
  get _availableActions() {
    const out = super._availableActions;

    const clone = out.find((action) => action.action === 'goToClone');

    if (clone) {
      clone.enabled = !!this.spec?.template?.spec;
    }

    return out;
  }

  get openShellMenuItem() {
    return {
      action:  'openShell',
      enabled: !!this.links.view && this.isRunning,
      icon:    'icon icon-fw icon-chevron-right',
      label:   this.t('pai.detail.vmset.control'),
      total:   1,
    };
  }

  get openLogsMenuItem() {
    return {
      action:  'openLogs',
      enabled: !!this.links.view,
      icon:    'icon icon-fw icon-chevron-right',
      label:   this.t('pai.detail.vmset.viewLog'),
      total:   1,
    };
  }

  get details() {
    const product = this.$rootGetters['productId'];
    const cluster = this.$rootGetters['clusterId'];

    if (product === PRODUCT_NAME) {
      const out = [
        {
          label:   this.t('workload.detailTop.podIP'),
          content: this.status.podIP,
        },
      ];

      if (this.workloadRef) {
        out.push({
          label:         'Workload',
          formatter:     'PaiLinkName',
          formatterOpts: {
            value:     this.workloadRef.name,
            type:      convertType(this.workloadRef.type),
            namespace: this.workloadRef.namespace,
            cluster,
            product,
          },
          content: this.workloadRef.name,
        });
      }

      if (this.spec.nodeName) {
        out.push({
          label:         'Node',
          formatter:     'PaiLinkName',
          formatterOpts: {
            type:  PAI_NODE,
            value: this.spec.nodeName,
            cluster,
            product,
          },
          content: this.spec.nodeName,
        });
      }

      return out;
    }

    return super.details;
  }

  openShell(containerName = this.defaultContainerName) {
    // const clusterId = this.$rootGetters['clusterId'];
    // const vncLocation = `/k8s/clusters/${ clusterId }/api/v1/namespaces/vncproxy/services/http:vncproxy:80/proxy/console/${ clusterId }/${ this.metadata?.namespace }/${ this.metadata?.name }?title=${ this.alias }`;
    const pod = `/dashboard/novnc/vnc.html?path=api/v1/namespaces/${ this.metadata?.namespace }/pods/http:${ this.metadata?.name }:`;
    const path = `/proxy/&title=${ this.alias }&autoconnect=true`;

    if (this.metadata.labels?.[VIRT] === 'vm' || this.metadata.labels?.[VIRT] === 'lightvm') {
      let vncLocation;

      if (this.metadata.annotations[RUST_DESK_ANNOTATIONS.ID] && this.metadata.annotations[RUST_DESK_ANNOTATIONS.PW]) {
        vncLocation = `${ RUST_DESK_URL }?id=${ this.metadata.annotations[RUST_DESK_ANNOTATIONS.ID] }&pw=${ base64Decode(this.metadata.annotations[RUST_DESK_ANNOTATIONS.PW]) }`;
      } else {
        vncLocation = pod + 15900 + path;
      }
      window.open(vncLocation, this.alias, WINDOW_OPEN_PARAMS);

      return;
    }
    if (this.spec.containers) {
      const containers = this.spec.containers.filter((c) => c.name === containerName);

      if (containers.length > 0 && containers[0].ports) {
        const ports = containers[0].ports;
        let port = ports.filter((p) => p.containerPort === 15900);

        if (port.length) {
          window.open(pod + 15900 + path, this.alias, WINDOW_OPEN_PARAMS);

          return;
        }
        port = ports.filter((p) => p.containerPort === 5900);

        if (port.length) {
          window.open(pod + 5900 + path, this.alias, WINDOW_OPEN_PARAMS);

          return;
        }
        port = ports.filter((p) => p.containerPort === 5901);

        if (port.length) {
          window.open(pod + 5901 + path, this.alias, WINDOW_OPEN_PARAMS);

          return;
        }
      }
    }

    // no vnc, open normal shell
    this.$dispatch('wm/open', {
      id:        `${ this.id }-shell`,
      label:     this.nameDisplay,
      icon:      'terminal',
      component: 'ContainerShell',
      attrs:     {
        pod:              this,
        initialContainer: containerName,
      },
    }, { root: true });
  }

  get alias() {
    return (this.metadata.annotations && this.metadata.annotations[ALIAS]) ? this.metadata.annotations[ALIAS] : this.nameDisplay;
  }

  get isXApp() {
    return this.metadata.labels && this.metadata.labels['type'] && this.metadata.labels['type'] === 'containerdesk';
  }

  async openXApp(currentCluster, callback) {
    const widowWidth = screen.width;
    const windowHeight = screen.height;

    try {
      const result = await sendCommandsToPodFirstContainerWithResult(currentCluster, this, ['/bin/sh', '-c', "grep 'Virtual size' /var/log/Xorg.0.log | awk '{print $(NF-2)}'"]);

      if (result.success && result.data) {
        const area = result.data.split('x');

        if (area.length === 2) {
          const containerWidth = parseInt(area[0]);
          const containerHeight = parseInt(area[1].trim()); // 获得的结果1920x1080\n,移除\n后缀

          if (widowWidth <= containerWidth) {
            MessageBox.alert(this.t('dialog.resolutionDialog.notFit'), this.t('generic.notification.title.warning'), {
              confirmButtonText: this.t('generic.confirm'),
              callback:          (action) => {
                this.openXappWindow(currentCluster, callback, widowWidth, windowHeight);
              },
            });
          } else {
            this.openXappWindow(currentCluster, callback, containerWidth, containerHeight, (widowWidth - containerWidth) / 2); //居中显示
          }
        } else {
          this.openXappWindow(currentCluster, callback, widowWidth, windowHeight);
        }
      } else {
        this.openXappWindow(currentCluster, callback, widowWidth, windowHeight);
      }
    } catch (e) {
      console.error(e);
      this.openXappWindow(currentCluster, callback, widowWidth, windowHeight);
    }
  }

  openXappWindow(currentCluster, callback, widowWidth, windowHeight, left = 0) {
    const rustDeskId = this.metadata.annotations[RUST_DESK_ANNOTATIONS.ID];
    const pw = this.metadata.annotations[RUST_DESK_ANNOTATIONS.PW];
    const oldRestartCount = this.metadata.annotations[RUST_DESK_ANNOTATIONS.RESTART_COUNT];
    const restartCount = this.status.containerStatuses[0]?.restartCount;
    const windowOpenParams = `top=0,left=${ left },width=${ widowWidth },height=${ windowHeight },fullscreen=1,directories=no,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no`;

    if (rustDeskId && pw && oldRestartCount == restartCount) {
      const password = base64Decode(pw);

      window.open(`${ RUST_DESK_URL }?id=${ rustDeskId }&pw=${ password }`, `${ this.namespace }/${ this.name }`, windowOpenParams);
    } else {
      const password = randomStr();

      sendCommandsToPodFirstContainerWithCallback(currentCluster, this, ['/bin/sh', '-c', `rustdesk --password ${ password } > /dev/null && rustdesk --get-id`], (result) => {
        if (result.success && result.data) {
          const rustDeskId = result.data;

          window.open(`${ RUST_DESK_URL }?id=${ rustDeskId }&pw=${ password }`, `${ this.namespace }/${ this.name }`, windowOpenParams);
          const encodedPw = base64Encode(password);

          this.setAnnotation(RUST_DESK_ANNOTATIONS.ID, `${ rustDeskId }`);
          this.setAnnotation(RUST_DESK_ANNOTATIONS.PW, encodedPw);
          this.setAnnotation(RUST_DESK_ANNOTATIONS.RESTART_COUNT, `${ restartCount }`);
          const data = {
            metadata: {
              annotations: {
                [RUST_DESK_ANNOTATIONS.ID]:            `${ rustDeskId }`,
                [RUST_DESK_ANNOTATIONS.PW]:            encodedPw,
                [RUST_DESK_ANNOTATIONS.RESTART_COUNT]: `${ restartCount }`,
              },
            },
          };

          super.patch(data, {}, true, true);
        }
      });
    }
    callback && callback();
  }
}
