<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import UnitInput from '@shell/components/form/UnitInput';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import { set } from '@shell/utils/object';

export default {
  components: {
    LabeledInput,
    UnitInput,
    SectionTitle,
  },

  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    if (!this.value.global.registry) {
      this.value.global.registry = 'docker.io';
    }

    return {};
  },
  computed: {},
  methods:  {
    onReplicasChange(e) {
      const replicas = Number(e);
      const globalReplicas = Number(`-${ e }`);

      set(this.value, 'read.autoscaling.maxReplicas', replicas);
      set(this.value, 'write.autoscaling.maxReplicas', replicas);
      set(this.value, 'backend.autoscaling.maxReplicas', replicas);
      set(this.value, 'singleBinary.autoscaling.maxReplicas', replicas);
      set(this.value, 'gateway.autoscaling.maxReplicas', replicas);
      set(this.value, 'global.replicas', globalReplicas);
    },
  },
};
</script>

<template>
  <div style="margin-bottom: 10px">
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.global.registry"
          :label="t('cluster.tabs.registry')"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.read.persistence.size"
          :label="t('catalog.charts.loki.disk.read')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <UnitInput
          v-model="value.write.persistence.size"
          :label="t('catalog.charts.loki.disk.write')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.backend.persistence.size"
          :label="t('catalog.charts.loki.disk.backend')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model.number="value.promtail.deployment.autoscaling.maxReplicas"
          type="number"
          :label="t('catalog.charts.loki.disk.replicas')"
          @input="onReplicasChange"
        />
      </div>
    </div>
    <div class="row mt-10">
      <SectionTitle
        value="Minio"
        class="row"
      />
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model.number="value.minio.replicas"
          type="number"
          :label="t('tableHeaders.replicas')"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.minio.persistence.size"
          :label="t('catalog.charts.loki.minio.size')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
</style>
