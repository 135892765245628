<script>
import CruResource from '@shell/components/CruResource';
import { _EDIT } from '@shell/config/query-params';
import CreateEditView from '@shell/mixins/create-edit-view';
import FormValidation from '@shell/mixins/form-validation';
import { exceptionToErrorsArray } from '@shell/utils/error';
import { HOSTNAME } from '@shell/config/labels-annotations';
import { clear } from '@shell/utils/array';
import { OPEN_LOCAL } from '~/pkg/pai/config/types';
import { convertUnitToG } from '@/pkg/pai/utils/units';
import draggable from 'vuedraggable';
import Tabbed from '../components/Tabbed/index.vue';
import Tab from '../components/Tabbed/Tab.vue';
import ContentTemplate from '../components/open-local/contentTemplate';
import { PRODUCT_NAME } from '../config/pai';
import { MANAGEMENT } from '@shell/config/types';

export default {
  layout:     'pai/default',
  components: {
    Tab,
    Tabbed,
    CruResource,
    ContentTemplate,
    draggable
  },
  props: {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      },
    },
    mode: {
      type:    String,
      default: _EDIT,
    },
  },
  mixins: [CreateEditView, FormValidation],
  data() {
    const params = this.$route.params;

    // if (!this.value.spec?.nodesConfig) {
    //   this.value.spec.nodesConfig = [
    //     {
    //       listConfig: {
    //         devices: { include: [] },
    //         vgs:     { includes: [] },
    //         zpools:  { includes: [] }
    //       },
    //       resourceToBeInited: { mountpoints: [], vgs: [] },
    //       selector:           { matchLabels: { 'kubernetes.io/hostname': '' } }
    //     }
    //   ];
    // }

    if (!this.value.spec?.globalConfig?.listConfig) {
      this.value.spec.globalConfig = {
        listConfig: {
          devices: { include: [] },
          vgs:     { include: [] },
          zpools:  { include: [] }
        }
      };
    }
    if (!this.value.spec?.globalConfig?.listConfig?.vgs) {
      this.value.spec.globalConfig.listConfig.vgs = { include: [] };
    }
    if (!this.value.spec?.globalConfig?.listConfig?.devices) {
      this.value.spec.globalConfig.listConfig.devices = { include: [] };
    }
    if (!this.value.spec?.globalConfig?.listConfig?.zpools) {
      this.value.spec.globalConfig.listConfig.zpools = { include: [] };
    }

    const globalConfig = this.value?.spec?.globalConfig;
    // const nodesConfig = this.value?.spec?.nodesConfig;
    let lvmGlobalData = [];
    let blockGlobalData = [];
    let zpoolsGlobalData = [];

    if (globalConfig) {
      lvmGlobalData = globalConfig?.listConfig?.vgs?.include !== undefined ? globalConfig.listConfig.vgs.include?.map((item) => {
        return { rules: item };
      }) : [];

      blockGlobalData = globalConfig?.listConfig?.devices?.include !== undefined ? globalConfig.listConfig.devices.include?.map((item) => {
        return { rules: item };
      }) : [];

      zpoolsGlobalData = globalConfig?.listConfig?.zpools?.include !== undefined ? globalConfig.listConfig.zpools.include?.map((item) => {
        return { rules: item };
      }) : [];
    }

    return {
      params,
      data:           '',
      globalConfig,
      // nodesConfig,
      lvmGlobalData,
      blockGlobalData,
      zpoolsGlobalData,
      nodeValue:      '',
      nodeOptions:    [],
      lvmNodeData:    [],
      blockNodeData:  [],
      zpoolsNodeData: [],
      // 拖出源母元素下标
      ondragendIndex: '',
      // 拖入母元素下标
      ondropIndex:    '',
      // 拖出子元素下标
      oldIndex:       '',
      // 拖入子元素下标
      newIndex:       '',
      vgs:            [{ name: this.t('catalog.charts.openLocal.diskPool'), devices: [] }],
      diskIndex:      0,
      groupName:      [],
      usedDiskArr:    []
    };
  },
  async fetch() {
    await this.$store.getters['cluster/schemaFor'](OPEN_LOCAL.INIT_CONFIG);
    await this.$store.dispatch('cluster/findAll', { type: OPEN_LOCAL.INIT_CONFIG });

    // 获取节点数据
    await this.getDisk();
  },
  computed: {
    optionsLeft() {
      return {
        animation: 0,
        group:     'items',
      };
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata?.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
  },
  watch: {
    // nodeValue(val) {
    //   if (val) {
    //     const newDiskArr = this.nodeOptions.filter((item) => item.id === val);
    //
    //     // 初始化硬盘池数据
    //     this.vgs = [{ name: this.t('catalog.charts.openLocal.diskPool'), devices: [] }];
    //     const diskDevices = newDiskArr[0]?.status?.nodeStorageInfo?.deviceInfo?.map((item) => {
    //       return {
    //         ...item,
    //         total: convertUnitToG(`${ item?.total }b`)
    //       };
    //     }).filter((d) => d.total !== 0);
    //
    //     this.vgs[0].devices = diskDevices;
    //
    //     // 获取所有成功状态的卷名称和对应的硬盘
    //     this.groupName = [];
    //     this.usedDiskArr = [];
    //     newDiskArr[0]?.status?.nodeStorageInfo?.volumeGroups?.forEach((v) => {
    //       this.groupName.push(v?.name);
    //       this.usedDiskArr.push(v?.physicalVolumes);
    //     });
    //     const nodeData = this.nodesConfig.find((node) => node.selector?.matchLabels?.[HOSTNAME] === val);
    //
    //     if (nodeData) {
    //       this.lvmNodeData = nodeData.listConfig?.vgs?.include?.length !== 0 ? nodeData.listConfig?.vgs?.include?.map((r) => {
    //         let obj = {};
    //
    //         if (r) {
    //           obj = { rules: r };
    //         } else {
    //           this.globalConfig?.listConfig?.vgs?.include?.map((v) => {
    //             obj = { rules: v };
    //           });
    //         }
    //
    //         return obj;
    //       }) : this.globalConfig?.listConfig?.vgs?.include?.map((item) => {
    //         return { rules: item };
    //       });
    //       this.blockNodeData = nodeData?.listConfig?.devices?.include?.length !== 0 ? nodeData?.listConfig?.devices?.include?.map((r) => {
    //         let obj = {};
    //
    //         if (r) {
    //           obj = { rules: r };
    //         } else {
    //           this.globalConfig?.listConfig?.devices?.include?.map((v) => {
    //             obj = { rules: v };
    //           });
    //         }
    //
    //         return obj;
    //       }) : this.globalConfig?.listConfig?.devices?.include?.map((item) => {
    //         return { rules: item };
    //       });
    //       this.zpoolsNodeData = nodeData?.listConfig?.zpools?.include?.length !== 0 ? nodeData?.listConfig?.zpools?.include?.map((r) => {
    //         let obj = {};
    //
    //         if (r) {
    //           obj = { rules: r };
    //         } else {
    //           this.globalConfig?.listConfig?.zpools?.include?.map((v) => {
    //             obj = { rules: v };
    //           });
    //         }
    //
    //         return obj;
    //       }) : this.globalConfig?.listConfig?.zpools?.include?.map((item) => {
    //         return { rules: item };
    //       });
    //       nodeData.resourceToBeInited?.vgs?.forEach((vgs) => {
    //         if (vgs?.name !== this.t('catalog.charts.openLocal.diskPool')) {
    //           this.vgs.push({
    //             ...vgs,
    //             state:   this.groupName?.includes(vgs?.name) && this.usedDiskArr.flat().concat(vgs.devices).some((v, i, arr) => arr.indexOf(v) != i), // 判断当前卷组是否正常
    //             devices: vgs?.devices?.map((v) => { // 转换数据用于页面展示
    //               if (diskDevices) {
    //                 return diskDevices?.find((disk) => disk?.name === v) !== undefined ? diskDevices?.find((disk) => disk?.name === v) : {
    //                   name:  v,
    //                   total: ''
    //                 };
    //               } else {
    //                 return {
    //                   ...v,
    //                   name:  v,
    //                   total: ''
    //                 };
    //               }
    //             })
    //           });
    //           vgs?.devices?.map((v) => { // 过滤硬盘池展示的数据
    //             if (diskDevices?.find((disk) => disk?.name === v)) {
    //               diskDevices?.forEach((disk, index) => {
    //                 if (disk?.name === v) {
    //                   diskDevices.splice(index, 1);
    //                 }
    //               });
    //             }
    //           });
    //         }
    //       });
    //     } else {
    //       if (this.globalConfig) { // 节点没有配置数据则引用全局的配置数据
    //         this.lvmNodeData = this.globalConfig?.listConfig?.vgs?.include?.map((item) => {
    //           return { rules: item };
    //         }) || [];
    //         this.blockNodeData = this.globalConfig?.listConfig?.devices?.include?.map((item) => {
    //           return { rules: item };
    //         }) || [];
    //         this.zpoolsNodeData = this.globalConfig?.listConfig?.zpools?.include?.map((item) => {
    //           return { rules: item };
    //         }) || [];
    //       }
    //     }
    //   }
    // }
  },
  methods: {
    addRulesData(type) {
      if (type === 'lvmGlobal') {
        this.lvmGlobalData.push({ rules: '' });
      } else if (type === 'blockGlobal') {
        this.blockGlobalData.push({ rules: '' });
      } else if (type === 'zpoolsGlobal') {
        this.zpoolsGlobalData.push({ rules: '' });
      } else if (type === 'lvmNode') {
        this.lvmNodeData = this.lvmNodeData || [];
        this.lvmNodeData?.push({ rules: '' });
      } else if (type === 'blockNode') {
        this.blockNodeData = this.blockNodeData || [];
        this.blockNodeData?.push({ rules: '' });
      } else if (type === 'zpoolsNode') {
        this.zpoolsNodeData = this.zpoolsNodeData || [];
        this.zpoolsNodeData?.push({ rules: '' });
      }
    },
    delRulesData(type, index) {
      if (type === 'lvmGlobal') {
        this.lvmGlobalData.splice(index, 1);
      } else if (type === 'blockGlobal') {
        this.blockGlobalData.splice(index, 1);
      } else if (type === 'zpoolsGlobal') {
        this.zpoolsGlobalData.splice(index, 1);
      } else if (type === 'lvmNode') {
        this.lvmNodeData.splice(index, 1);
      } else if (type === 'blockNode') {
        this.blockNodeData.splice(index, 1);
      } else if (type === 'zpoolsNode') {
        this.zpoolsNodeData.splice(index, 1);
      }
    },
    delAll(type) {
      if (type === 'lvmGlobal') {
        this.lvmGlobalData = [];
      } else if (type === 'blockGlobal') {
        this.blockGlobalData = [];
      } else if (type === 'zpoolsGlobal') {
        this.zpoolsGlobalData = [];
      } else if (type === 'lvmNode') {
        this.lvmNodeData = [];
      } else if (type === 'blockNode') {
        this.blockNodeData = [];
      } else if (type === 'zpoolsNode') {
        this.zpoolsNodeData = [];
      }
    },

    //  拖拽
    addLVM() {
      this.diskIndex += 1;
      this.vgs.push({
        name: `open-local-pool-${ this.diskIndex }`, devices: [], state: 'no'
      });
    },
    delLVM(index) {
      this.vgs[index]?.devices?.forEach((item) => {
        this.vgs[0]?.devices?.push(item);
      });
      this.vgs.splice(index, 1);
    },
    // 监听滑动添加时
    onadd(e) {
      this.oldIndex = e.oldIndex;
      this.newIndex = e.newIndex;
    },
    // 完成母元素拖放后触发,经测试发现此函数可以不使用，不影响数据的移动操作
    ondragend(item, index) {
      // this.ondragendIndex = index;
    },
    // 监听源母元素拖动下标
    onDrop(element, index) {
      this.ondropIndex = index;
    },
    async getDisk() { // 获取节点的下拉数据
      const diskUrl = { url: `/k8s/clusters/${ this.params.cluster }/v1/${ OPEN_LOCAL.STORAGES }` };
      const diskResult = await this.$store.dispatch('pai-common/getUse', diskUrl);
      const diskData = diskResult.data?.data;

      if (diskData) {
        this.nodeOptions = diskData;
        if (this.nodeOptions && this.nodeOptions.length > 0) {
          this.nodeValue = this.nodeOptions[0].id;
        }
      }
    },
    setData() {
      const globalDevice = [];
      const globalVgs = [];
      const globalZpools = [];
      // const nodeDevice = [];
      // const nodeVgs = [];
      // const nodeZpools = [];
      // let vgsArr = [];

      this.blockGlobalData?.forEach((item) => {
        globalDevice.push(item.rules);
      });
      this.lvmGlobalData?.forEach((item) => {
        globalVgs.push(item.rules);
      });
      this.zpoolsGlobalData?.forEach((item) => {
        globalZpools.push(item.rules);
      });
      // this.blockNodeData?.forEach((item) => {
      //   nodeDevice.push(item.rules);
      // });
      // this.lvmNodeData?.forEach((item) => {
      //   nodeVgs.push(item.rules);
      // });
      // this.zpoolsNodeData?.forEach((item) => {
      //   nodeZpools.push(item.rules);
      // });
      // vgsArr = this.vgs?.filter((item) => item?.name !== this.t('catalog.charts.openLocal.diskPool'));
      this.$set(this.value?.spec?.globalConfig?.listConfig?.devices, 'include', globalDevice);
      this.$set(this.value?.spec?.globalConfig?.listConfig?.vgs, 'include', globalVgs);

      this.$set(this.value?.spec?.globalConfig?.listConfig?.zpools, 'include', globalZpools);

      // 处理传给后台的LVM卷组数据格式
      // const newvgsArr = vgsArr.map((item) => {
      //   const vgsNames = [];
      //
      //   item.devices.forEach((d) => {
      //     vgsNames.push(d?.name);
      //   });
      //
      //   return {
      //     ...item,
      //     devices: vgsNames
      //   };
      // });
      // const nodesConfig = [];
      //
      // this.nodeOptions.map((node) => {
      //   let obj = {};
      //
      //   if (node.metadata.name === this.nodeValue) {
      //     obj = {
      //       listConfig: {
      //         devices: { include: nodeDevice },
      //         vgs:     { include: nodeVgs },
      //         zpools:  { include: nodeZpools }
      //       },
      //       resourceToBeInited: { mountpoints: [], vgs: newvgsArr },
      //       selector:           { matchLabels: { 'kubernetes.io/hostname': this.nodeValue } }
      //     };
      //   } else {
      //     obj = {
      //       listConfig: {
      //         devices: { include: node?.spec?.listConfig?.devices?.include?.length ? node.spec.listConfig.devices.include : [] },
      //         vgs:     { include: node?.spec?.listConfig?.vgs?.include?.length ? node?.spec?.listConfig?.vgs?.include : [] },
      //         zpools:  { include: node?.spec?.listConfig?.zpools?.include?.length ? node?.spec?.listConfig?.zpools?.include : [] }
      //       },
      //       resourceToBeInited: {
      //         mountpoints: [],
      //         vgs:         node.spec?.resourceToBeInited?.vgs ? node.spec?.resourceToBeInited?.vgs : []
      //       },
      //       selector: { matchLabels: { 'kubernetes.io/hostname': node.metadata.name } }
      //     };
      //   }
      //   nodesConfig.push(obj);
      // });
      this.$set(this.value?.spec, 'nodesConfig', []);
    },
    saveOverride(buttonCb) {
      this.setData(this.value);

      if (this.errors) {
        clear(this.errors);
      }
      try {
        // const nameReg = /^[^\u4E00-\u9FA5]*$/;
        // const vgs = this.value?.spec?.nodesConfig[0]?.resourceToBeInited?.vgs;
        // const vgsNameArr = [];
        //
        // vgs.forEach((item) => {
        //   vgsNameArr.push(item?.name);
        //   if (!nameReg.test(item?.name)) {
        //     this.errors.push(this.t('catalog.charts.openLocal.groupNameTip'));
        //   }
        //   if (item.devices?.length === 0) {
        //     this.errors.push(this.t('catalog.charts.openLocal.groupEmpty'));
        //   }
        // });
        //
        // // 卷组名称重复校验
        // const isRepeat = vgsNameArr.some((item, index, arr) => arr.indexOf(item) !== index);
        //
        // if (isRepeat) {
        //   this.errors.push(this.t('catalog.charts.openLocal.groupRepeat'));
        // }

        if (this.errors?.length > 0) {
          this.errors = [this.errors[0]];
          buttonCb(false);

          return;
        } else {
          this.value.save();
          buttonCb(true);
        }
      } catch (e) {
        this.errors = exceptionToErrorsArray(e);
        buttonCb(false);
      }
    },
  }
};
</script>

<template>
  <CruResource
    :cancel-event="false"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :errors="errors"
    :apply-hooks="applyHooks"
    :show-cancel="false"
    @error="e=>errors = e"
    @finish="saveOverride"
  >
    <Tabbed
      class="deployment-tabs"
      :show-tabs-add-remove="true"
      :flat="true"
    >
      <div class="monitorBtn">
        <button
          type="text"
          class="btn role-primary"
          style="margin-right: 20px"
        >
          <a
            :href="`/k8s/clusters/${params.cluster}/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/d/VY5NDa55k/smart-autogenerated?orgId=1&refresh=1m`"
            target="_blank"
            rel="noopener"
          ><i class="el-icon-data-line" />
            {{ t('pai.detail.vmset.tab.monitor.diskMonitoring') }}</a>
        </button>
        <button
          type="text"
          class="btn role-primary"
          style="margin-right: 10px"
        >
          <a
            :href="`/k8s/clusters/${params.cluster}/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-grafana:80/proxy/?orgId=1`"
            target="_blank"
            rel="noopener"
          ><i class="el-icon-s-data" />
            {{ t('catalog.charts.openLocal.monitor') }}</a>
        </button>
      </div>

      <div class="settings-tip">
        <i
          class="icon el-icon-info"
          style="color: rgba(154, 154, 154, 1); margin-right: 5px;"
        />{{ t('catalog.charts.openLocal.tips') }}
      </div>
      <!--   全局配置   -->
      <Tab
        :label="t('catalog.charts.openLocal.global')"
        name="global"
        :weight="99"
        :required-tab="false"
      >
        <Tabbed :side-tabs="true">
          <!--  LVM卷组调度  -->
          <Tab
            label-key="catalog.charts.openLocal.lvmDispatch"
            name="lvmDispatch"
            :weight="99"
          >
            <contentTemplate :content="t('catalog.charts.openLocal.lvmTips')">
              <div class="row mt-10 mb-10">
                <button
                  type="button"
                  class="btn role-primary"
                  style="margin-right: 10px"
                  @click="addRulesData('lvmGlobal')"
                >
                  <i class="el-icon-plus" />
                  {{ t('generic.add') }}
                </button>
                <button
                  type="button"
                  class="btn bg-error"
                  @click="delAll('lvmGlobal')"
                >
                  <i class="el-icon-delete" />
                  {{ t('pai.labels.removeAll') }}
                </button>
              </div>
              <div
                v-for="(item,index) in lvmGlobalData"
                :key="index"
                class="flex"
              >
                <el-input
                  v-model="item.rules"
                  :placeholder="t('catalog.charts.openLocal.lvmRules')"
                  class="rules-input"
                />
                <el-button
                  type="text"
                  class="del-button"
                  @click="delRulesData('lvmGlobal',index)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </contentTemplate>
          </Tab>
          <!--  块设备卷组调度  -->
          <Tab
            label-key="catalog.charts.openLocal.blockDispatch"
            name="blockDispatch"
            :weight="98"
          >
            <contentTemplate :content="t('catalog.charts.openLocal.blockTips')">
              <div class="row mt-10 mb-10">
                <button
                  type="button"
                  class="btn role-primary"
                  style="margin-right: 10px"
                  @click="addRulesData('blockGlobal')"
                >
                  <i class="el-icon-plus" />
                  {{ t('generic.add') }}
                </button>
                <button
                  type="button"
                  class="btn bg-error"
                  @click="delAll('blockGlobal')"
                >
                  <i class="el-icon-delete" />
                  {{ t('pai.labels.removeAll') }}
                </button>
              </div>
              <div
                v-for="(item,index) in blockGlobalData"
                :key="index"
                class="flex"
              >
                <el-input
                  v-model="item.rules"
                  :placeholder="t('catalog.charts.openLocal.blockRules')"
                  class="rules-input"
                />
                <el-button
                  type="text"
                  class="del-button"
                  @click="delRulesData('blockGlobal',index)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </contentTemplate>
          </Tab>
          <!--  ZPOOLS卷组调度  -->
          <Tab
            label-key="catalog.charts.openLocal.zpoolsDispatch"
            name="zpoolsDispatch"
            :weight="97"
          >
            <contentTemplate :content="t('catalog.charts.openLocal.zpoolsTips')">
              <div class="row mt-10 mb-10">
                <button
                  type="button"
                  class="btn role-primary"
                  style="margin-right: 10px"
                  @click="addRulesData('zpoolsGlobal')"
                >
                  <i class="el-icon-plus" />
                  {{ t('generic.add') }}
                </button>
                <button
                  type="button"
                  class="btn bg-error"
                  @click="delAll('zpoolsGlobal')"
                >
                  <i class="el-icon-delete" />
                  {{ t('pai.labels.removeAll') }}
                </button>
              </div>
              <div
                v-for="(item,index) in zpoolsGlobalData"
                :key="index"
                class="flex"
              >
                <el-input
                  v-model="item.rules"
                  :placeholder="t('catalog.charts.openLocal.zpoolsRules')"
                  class="rules-input"
                />
                <el-button
                  type="text"
                  class="del-button"
                  @click="delRulesData('zpoolsGlobal',index)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </contentTemplate>
          </Tab>
        </Tabbed>
      </Tab>
      <!--   指定节点配置   -->
      <!--      <Tab-->
      <!--        :label="t('catalog.charts.openLocal.node')"-->
      <!--        name="node"-->
      <!--        :weight="98"-->
      <!--        :required-tab="false"-->
      <!--      >-->
      <!--        <div class="node-settings">-->
      <!--          <div class="node-select">-->
      <!--            <span style="margin-right: 6px">{{ t('catalog.charts.openLocal.selectNode') }}： </span>-->
      <!--            <el-select-->
      <!--              v-model="nodeValue"-->
      <!--              :placeholder="t('pai.edit.SelectPlaceholder')"-->
      <!--              filterable-->
      <!--              style="width: 400px"-->
      <!--            >-->
      <!--              <el-option-->
      <!--                v-for="(item,i) in nodeOptions"-->
      <!--                :key="i"-->
      <!--                :label="item.id"-->
      <!--                :value="item.id"-->
      <!--              />-->
      <!--            </el-select>-->
      <!--          </div>-->
      <!--        </div>-->
      <!--        <Tabbed :side-tabs="true">-->
      <!--          &lt;!&ndash;  LVM卷组调度  &ndash;&gt;-->
      <!--          <Tab-->
      <!--            label-key="catalog.charts.openLocal.lvmDispatch"-->
      <!--            name="lvmDispatch"-->
      <!--            :weight="99"-->
      <!--          >-->
      <!--            <ContentTemplate-->
      <!--              :content="t('catalog.charts.openLocal.lvmTips')"-->
      <!--            >-->
      <!--              <div class="row mt-10 mb-10">-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn role-primary"-->
      <!--                  style="margin-right: 10px"-->
      <!--                  @click="addRulesData('lvmNode')"-->
      <!--                >-->
      <!--                  <i class="el-icon-plus" />-->
      <!--                  {{ t('generic.add') }}-->
      <!--                </button>-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn bg-error"-->
      <!--                  @click="delAll('lvmNode')"-->
      <!--                >-->
      <!--                  <i class="el-icon-delete" />-->
      <!--                  {{ t('pai.labels.removeAll') }}-->
      <!--                </button>-->
      <!--              </div>-->
      <!--              <div-->
      <!--                v-for="(item,index) in lvmNodeData"-->
      <!--                :key="index"-->
      <!--                class="flex"-->
      <!--              >-->
      <!--                <el-input-->
      <!--                  v-model="item.rules"-->
      <!--                  :placeholder="t('catalog.charts.openLocal.lvmRules')"-->
      <!--                  class="rules-input"-->
      <!--                />-->
      <!--                <el-button-->
      <!--                  type="text"-->
      <!--                  class="del-button"-->
      <!--                  @click="delRulesData('lvmNode', index)"-->
      <!--                >-->
      <!--                  {{ t('pai.labels.remove') }}-->
      <!--                </el-button>-->
      <!--              </div>-->
      <!--            </ContentTemplate>-->
      <!--          </Tab>-->
      <!--          &lt;!&ndash;  块设备卷组调度  &ndash;&gt;-->
      <!--          <Tab-->
      <!--            label-key="catalog.charts.openLocal.blockDispatch"-->
      <!--            name="blockDispatch"-->
      <!--            :weight="98"-->
      <!--          >-->
      <!--            <ContentTemplate :content="t('catalog.charts.openLocal.blockTips')">-->
      <!--              <div class="row mt-10 mb-10">-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn role-primary"-->
      <!--                  style="margin-right: 10px"-->
      <!--                  @click="addRulesData('blockNode')"-->
      <!--                >-->
      <!--                  <i class="el-icon-plus" />-->
      <!--                  {{ t('generic.add') }}-->
      <!--                </button>-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn bg-error"-->
      <!--                  @click="delAll('blockNode')"-->
      <!--                >-->
      <!--                  <i class="el-icon-delete" />-->
      <!--                  {{ t('pai.labels.removeAll') }}-->
      <!--                </button>-->
      <!--              </div>-->
      <!--              <div-->
      <!--                v-for="(item,index) in blockNodeData"-->
      <!--                :key="index"-->
      <!--                class="flex"-->
      <!--              >-->
      <!--                <el-input-->
      <!--                  v-model="item.rules"-->
      <!--                  :placeholder="t('catalog.charts.openLocal.blockRules')"-->
      <!--                  class="rules-input"-->
      <!--                />-->
      <!--                <el-button-->
      <!--                  type="text"-->
      <!--                  class="del-button"-->
      <!--                  @click="delRulesData('blockNode', index)"-->
      <!--                >-->
      <!--                  {{ t('pai.labels.remove') }}-->
      <!--                </el-button>-->
      <!--              </div>-->
      <!--            </ContentTemplate>-->
      <!--          </Tab>-->
      <!--          &lt;!&ndash;  ZPOOLS卷组调度  &ndash;&gt;-->
      <!--          <Tab-->
      <!--            label-key="catalog.charts.openLocal.zpoolsDispatch"-->
      <!--            name="zpoolsDispatch"-->
      <!--            :weight="97"-->
      <!--          >-->
      <!--            <ContentTemplate :content="t('catalog.charts.openLocal.zpoolsTips')">-->
      <!--              <div class="row mt-10 mb-10">-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn role-primary"-->
      <!--                  style="margin-right: 10px"-->
      <!--                  @click="addRulesData('zpoolsNode')"-->
      <!--                >-->
      <!--                  <i class="el-icon-plus" />-->
      <!--                  {{ t('generic.add') }}-->
      <!--                </button>-->
      <!--                <button-->
      <!--                  type="button"-->
      <!--                  class="btn bg-error"-->
      <!--                  @click="delAll('zpoolsNode')"-->
      <!--                >-->
      <!--                  <i class="el-icon-delete" />-->
      <!--                  {{ t('pai.labels.removeAll') }}-->
      <!--                </button>-->
      <!--              </div>-->
      <!--              <div-->
      <!--                v-for="(item,index) in zpoolsNodeData"-->
      <!--                :key="index"-->
      <!--                class="flex"-->
      <!--              >-->
      <!--                <el-input-->
      <!--                  v-model="item.rules"-->
      <!--                  :placeholder="t('catalog.charts.openLocal.zpoolsRules')"-->
      <!--                  class="rules-input"-->
      <!--                />-->
      <!--                <el-button-->
      <!--                  type="text"-->
      <!--                  class="del-button"-->
      <!--                  @click="delRulesData('zpoolsNode', index)"-->
      <!--                >-->
      <!--                  {{ t('pai.labels.remove') }}-->
      <!--                </el-button>-->
      <!--              </div>-->
      <!--            </ContentTemplate>-->
      <!--          </Tab>-->
      <!--          &lt;!&ndash;  配置LVM卷组  &ndash;&gt;-->
      <!--          <Tab-->
      <!--            label-key="catalog.charts.openLocal.lvmSettings"-->
      <!--            name="lvmSettings"-->
      <!--            :weight="96"-->
      <!--          >-->
      <!--            <ContentTemplate-->
      <!--              :content="t('catalog.charts.openLocal.lvmSettingsTips')"-->
      <!--              :background="'#fff'"-->
      <!--            >-->
      <!--              <div class="settings-tip">-->
      <!--                <i-->
      <!--                  class="icon el-icon-info"-->
      <!--                  style="color: rgba(154, 154, 154, 1); margin-right: 5px;"-->
      <!--                />{{ t('catalog.charts.openLocal.groupTip') }}-->
      <!--              </div>-->
      <!--              <div class="disk">-->
      <!--                <div class="disk-board">-->
      <!--                  <div-->
      <!--                    v-for="(item, index) in vgs"-->
      <!--                    :key="index"-->
      <!--                    class="disk-item"-->
      <!--                    @drop="onDrop(item, index)"-->
      <!--                  >-->
      <!--                    <div class="disk-flag">-->
      <!--                      <div class="h3-title">-->
      <!--                        <span-->
      <!--                          v-if="item?.name !== t('catalog.charts.openLocal.diskPool')"-->
      <!--                          class="disk-img"-->
      <!--                        >-->
      <!--                          <img-->
      <!--                            v-if="item.state === true"-->
      <!--                            src="../components/open-local/img/pass.svg"-->
      <!--                          >-->
      <!--                          <el-tooltip-->
      <!--                            :content="t('catalog.charts.openLocal.groupErrorTips')"-->
      <!--                            effect="light"-->
      <!--                          >-->
      <!--                            <img-->
      <!--                              v-if="item.state === false"-->
      <!--                              src="../components/open-local/img/err.svg"-->
      <!--                            >-->
      <!--                          </el-tooltip>-->
      <!--                        </span>-->
      <!--                        <span-->
      <!--                          v-if="item?.name !== t('catalog.charts.openLocal.diskPool')"-->
      <!--                          :for="item?.name"-->
      <!--                        >-->
      <!--                          {{ t('catalog.charts.openLocal.volumeGroup') }}:-->
      <!--                          <input-->
      <!--                            v-model="item.name"-->
      <!--                            class="title-input"-->
      <!--                          >-->
      <!--                        </span>-->

      <!--                        <div v-else>-->
      <!--                          {{ item.name }}-->
      <!--                        </div>-->
      <!--                      </div>-->
      <!--                      <div-->
      <!--                        v-if="item.name !== t('catalog.charts.openLocal.diskPool') && item.state !== true"-->
      <!--                        class="del-img"-->
      <!--                        @click="delLVM(index)"-->
      <!--                      >-->
      <!--                        <img-->
      <!--                          src="../components/open-local/img/del.svg"-->
      <!--                        >-->
      <!--                      </div>-->
      <!--                    </div>-->

      <!--                    <div class="disk-type">-->
      <!--                      <draggable-->
      <!--                        v-if="item.devices"-->
      <!--                        :disabled="item.state === true"-->
      <!--                        :options="optionsLeft"-->
      <!--                        :list="item.devices"-->
      <!--                        @add="onadd"-->
      <!--                      >-->
      <!--                        <transition-group-->
      <!--                          tag="ul"-->
      <!--                          class="disk-item-ul"-->
      <!--                        >-->
      <!--                          <div-->
      <!--                            v-for="(d,index) in item.devices"-->
      <!--                            :key="index"-->
      <!--                            class="disk-content"-->
      <!--                            @dragend="ondragend(item, index)"-->
      <!--                          >-->
      <!--                            <div-->
      <!--                              v-if="d"-->
      <!--                              class="disk-row"-->
      <!--                            >-->
      <!--                              <span class="disk-path"><img src="../components/open-local/img/drag.svg"> {{ d.name }}</span>-->
      <!--                              <span>{{ d.total }}<span v-if="d.total">G</span></span>-->
      <!--                            </div>-->
      <!--                          </div>-->
      <!--                        </transition-group>-->
      <!--                      </draggable>-->
      <!--                      <div-->
      <!--                        v-else-->
      <!--                        style="text-align: center; line-height: 50px"-->
      <!--                      >-->
      <!--                        {{ t('pai.detail.vmset.noData') }}-->
      <!--                      </div>-->
      <!--                    </div>-->
      <!--                  </div>-->
      <!--                  <div-->
      <!--                    class="disk-item"-->
      <!--                    style="text-align: center;line-height: 430px;background: #FFFFFF"-->
      <!--                  >-->
      <!--                    <el-button-->
      <!--                      type="text"-->
      <!--                      @click="addLVM"-->
      <!--                    >-->
      <!--                      <i class="el-icon-plus" />-->
      <!--                      {{ t('catalog.charts.openLocal.addLVM') }}-->
      <!--                    </el-button>-->
      <!--                  </div>-->
      <!--                </div>-->
      <!--              </div>-->
      <!--            </ContentTemplate>-->
      <!--          </Tab>-->
      <!--        </Tabbed>-->
      <!--      </Tab>-->
    </Tabbed>
  </CruResource>
</template>

<style lang="scss" scoped>
::v-deep button[data-testid='form-footer-cancel']{
  display: none;
}
.title{
  margin-bottom: 10px;
}
.settings-tip{
  border-radius: 5px;
  background-color: rgba(228, 233, 245, 1);
  font-size: 12px;
  text-align: left;
  border: 1px solid rgba(52, 124, 175, 1);
  padding: 8px;
  margin: 14px;
}
.node-settings{
  background: #F5F4F6;
  width: 100%;
  padding: 3px 0;

  .node-select{
    background: #FFFFFF;
    margin: 12px 0px;
    padding: 22px 10px;
  }
}
.del-button{
  margin-left: 50px;
}
.disk-board {
  height: 100%;
  width: 100%;
  display:flex;
  flex-wrap: wrap;
  margin: 10px;

  .disk-item {
    width: 260px;
    height: 440px;
    border: 1px solid rgba(239, 239, 239, 1);
    background-color: rgba(250, 250, 250, 1);
    overflow-y: auto;
    margin: 10px;
    .disk-type {
      min-height: 50px;
      padding: 2px 4px;
      margin: 4px;
      background: #e3e9ec;
    }
    .disk-flag {
      display: flex;
      flex-direction: row;
      .h3-title {
        flex-grow: 1;
        font-weight: 500;
        padding: 8px;
        font-size: 14px;
        border-bottom: 1px solid rgba(239, 239, 239, 1);
      }
      .disk-img {
        display: inline-block;
        position: relative;
        top: 3px;
      }
      .title-input {
        width: 150px;
        display: inline-block;
        height: 22px;
        line-height: 22px;
      }
      .del-img {
        width: 15px;
        height: 16px;
        margin: 11px 6px 0px 0px;
        img{
          width: 100%;
          height: 100%;
        }
      }
    }
  }
}
.disk-content{
  .disk-row {
    height: 30px;
    line-height: 30px;
    display: flex;
    justify-content: space-between;
    background: #FFFFFF;
    padding: 0px 6px;
    margin: 10px 0px;
    border-radius: 3px;
    .disk-path{
      width: 100px;
      overflow:hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      -o-text-overflow:ellipsis;
    }
    img {
      position: relative;
      top: 2px;
    }
  }
}
.deployment-tabs {
  position: relative;
}
.monitorBtn{
  position: absolute;
  top: 3px;
  right: 0;
  a {
    color: #FFFFFF;
  }
}
.rules-input {
  width: 720px;
}

::v-deep .disk-item-ul{
  min-height: 200px;
  padding: 0px;
}
</style>
