<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import CruResource from '../components/vmset/CruResource';
import { _CREATE, _EDIT, _VIEW, MODE } from '@shell/config/query-params';
import Tabbed from '../components/Tabbed/index.vue';
import Tab from '../components/Tabbed/Tab.vue';
import FormValidation from '@shell/mixins/form-validation';
import Labels from '../components/form/Labels';
import SectionTitle from '../components/form/SectionTitle';
import {
  NETWORK_ATTACHMENT, PVC, STORAGE_CLASS, NODE, SCHEMA
} from '@shell/config/types';
import { LabeledInput } from '@components/Form/LabeledInput';
import { exceptionToErrorsArray } from '@shell/utils/error';
import ContainerResourceLimit from '@/pkg/pai/components/ContainerResourceLimit';
import { cleanUp, clone, get } from '@/shell/utils/object';
import { PAI_ANNOTATIONS, PAI_RESOURCES } from '@/pkg/pai/config/types';
import { convertUnitToG } from '@/pkg/pai/utils/units';
import {
  ALIAS, IMAGE, PVC_ANNOTATIONS, PVC_LABELS, VMSET_ANNOTATIONS
} from '../config/labels-annotations';
import { ADDRESS_ASSIGNMENT_TYPE, IMAGE as IMAGE_PARAM, PVC_TYPES } from '../config/settings';
import { PRODUCT_NAME as PAI } from '@/pkg/pai/config/pai';
import { mapGetters } from 'vuex';
import { createYaml } from '@shell/utils/create-yaml';
import { HOSTNAME } from '@shell/config/labels-annotations';
import { addOffset } from '@/pkg/pai/utils/ip';
import HealthCheck from '../components/form/HealthCheck';
import Tolerations from '../components/form/Tolerations';
import AliasNsDescription from '~/pkg/pai/components/form/AliasNsDescription.vue';
import EditableCell from '~/pkg/pai/components/EditableCell.vue';
import { clear } from '@shell/utils/array';
import Sortable from 'sortablejs';
import DeleteVmModal from '~/pkg/pai/components/DeleteVmModal.vue';

export default {
  components: {
    DeleteVmModal,
    AliasNsDescription,
    CruResource,
    Tabbed,
    Tab,
    Labels,
    SectionTitle,
    LabeledInput,
    ContainerResourceLimit,
    HealthCheck,
    EditableCell,
    Tolerations
  },

  mixins: [CreateEditView, FormValidation],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      },
    },
    mode: {
      type:    String,
      default: _EDIT,
    },
    realMode: {
      type:    String,
      default: _EDIT,
    },
  },
  created() {
    if (this.registerAfterHook) {
      this.registerAfterHook(this.done);
    }
  },
  async fetch() {
    this.storageClasses = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
    this.images = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_IMAGE });
    this.updateImageOptions();
    if (this.$route.query.image) {
      // 从云主机应用市场选择的镜像进行创建
      const imageName = this.$route.query.image;

      this.getInitImage(imageName);
    }
    // 数据盘存储类
    // 筛选非系统镜像(数据盘是除了所有的vmimage创建的sc之外的，都可以选择)
    this.storageClassOptions = this.storageClasses.filter((v) => !(v.annotations && v.annotations[IMAGE.NAME]))
      .map((s) => {
        return {
          label: s.alias ? s.alias : s.name,
          value: s.name,
        };
      }).sort();
    this.persistentVolumeClaims = await this.$store.dispatch('cluster/findAll', { type: PVC });
    if (this.isCreate && this.isVmId) {
      this.tmplVolumes = this.value.metadata.annotations[VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL]) : [] ;
      this.volumes = this.value.metadata.annotations[VMSET_ANNOTATIONS.VOLUMES_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.VOLUMES_TMPL]) : [];
    }
    if (!this.isCreate) {
      if (this.isTmpl !== true && !this.isVmId) {
        // 将虚机绑定的磁盘信息转换为便于UI展示的形式
        const mountVmPvcs = this.persistentVolumeClaims.filter((v) => v.labels && (v.labels[PVC_LABELS.MOUNT_VM] === this.value.name));

        this.value.spec.volumeClaimTemplates.forEach((v) => {
          const pvc = mountVmPvcs.find((tmpl) => tmpl.name.includes(v.metadata.name));
          const storageSize = convertUnitToG(pvc?.spec?.resources?.requests?.storage ? pvc.spec.resources.requests.storage : v.spec.resources?.requests?.storage);

          this.tmplVolumes.push({
            type:          pvc?.annotations[PVC_ANNOTATIONS.TYPE] ? pvc?.annotations[PVC_ANNOTATIONS.TYPE] : this.t('pai.vmset.loadingText'),
            name:          v.metadata.name,
            // 镜像/存储类
            image:         v.spec.storageClassName,
            size:          storageSize,
            minSize:       storageSize,
            disabled:      true,
            pvc:           pvc?.name,
            id:            pvc?.name,
            inputDisabled: true,
          });
        });
        this.value.spec.volumes.forEach((v) => {
          const pvc = this.persistentVolumeClaims.find((item) => item.name === v.name);

          this.volumes.push({
            type:          pvc?.annotations[PVC_ANNOTATIONS.TYPE],
            name:          v.name,
            size:          pvc?.spec?.resources?.requests?.storage ? convertUnitToG(pvc?.spec?.resources?.requests?.storage) : convertUnitToG(pvc?.status?.capacity?.storage),
            disabled:      true,
            id:            v.name,
            inputDisabled: true,
          });
        });
      } else {
        this.tmplVolumes = this.value.metadata.annotations[VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL]) : [] ;
        this.volumes = this.value.metadata.annotations[VMSET_ANNOTATIONS.VOLUMES_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.VOLUMES_TMPL]) : [];
      }
    } else if (!this.$route.query.image && !this.isVmId) {
      // 新建时设置一个默认存储
      this.tmplVolumes.push({
        type:          PVC_TYPES.OS,
        name:          'volume-1',
        // 镜像/存储类
        image:         '',
        size:          0,
        minSize:       0,
        inputDisabled: false,
      });
    } else if (this.$route.query.boot && this.$route.query?.boot === 'cdrom') {
      this.tmplVolumes = [{
        type:          PVC_TYPES.ISO,
        name:          'volume-1',
        // 镜像/存储类
        image:         this.$route.query.image,
        size:          this.$route.query.diskSize ? parseFloat(this.$route.query.diskSize) : 0,
        minSize:       0,
        inputDisabled: false,
      }];
    } else if (this.$route.query.boot && this.$route.query?.boot === 'disk') {
      this.tmplVolumes = [{
        type:          PVC_TYPES.OS,
        name:          'volume-1',
        // 镜像/存储类
        image:         this.$route.query.image,
        size:          this.$route.query.diskSize ? parseFloat(this.$route.query.diskSize) : 0,
        minSize:       0,
        inputDisabled: false,
      }];
    } else if (this.isVmId) {
      this.tmplVolumes = this.value.metadata.annotations[VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL]) : [] ;
      this.volumes = this.value.metadata.annotations[VMSET_ANNOTATIONS.VOLUMES_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.VOLUMES_TMPL]) : [];
    }

    const vmPvcs = this.persistentVolumeClaims.filter((v) => (v.spec.volumeMode === 'Block' && (!v.metadata.labels || !v.metadata.labels[PVC_LABELS.MOUNT_VM] || (v.metadata.labels && v.metadata.labels[PVC_LABELS.MOUNT_VM] && v.metadata.labels[PVC_LABELS.MOUNT_VM] === '') || v.spec.accessModes[0] === 'ReadOnlyMany')));

    // 数据盘options
    this.persistentVolumeClaimOptions = vmPvcs.filter((v) => v.annotations && v.annotations[PVC_ANNOTATIONS.TYPE] === PVC_TYPES.DATA)
      .map((v) => {
        return {
          label:     v.metadata.name,
          value:     v.metadata.name,
          namespace: v.metadata.namespace,
        };
      });
    // 系统盘options
    this.osPvcOptions = vmPvcs.filter((v) => v.annotations && v.annotations[PVC_ANNOTATIONS.TYPE] === PVC_TYPES.OS)
      .map((v) => {
        return {
          label:     v.metadata?.name,
          value:     v.metadata?.name,
          namespace: v.metadata?.namespace,
        };
      });
    this.toolkitOptions = vmPvcs.filter((v) => v.annotations && v.annotations[PVC_ANNOTATIONS.TYPE] === PVC_TYPES.TOOL)
      .map((v) => {
        return {
          label:     v.metadata?.name,
          value:     v.metadata?.name,
          namespace: v.metadata?.namespace,
        };
      });
    this.ISOOptios = vmPvcs.filter((v) => v.annotations && v.annotations[PVC_ANNOTATIONS.TYPE] === PVC_TYPES.ISO)
      .map((v) => {
        return {
          label:     v.metadata?.name,
          value:     v.metadata?.name,
          namespace: v.metadata?.namespace,
        };
      });
    const nodes = await this.$store.dispatch('cluster/findAll', { type: NODE });

    this.nodeOptions = [{
      label: this.t('pai.vmset.nodeScheduling.auto'),
      value: '',
    }, ...nodes?.map((node) => {
      return {
        value:    node.metadata.name,
        label:    node.metadata.name + ((!!node.stateObj.error || !!node.spec.unschedulable) ? `（${ node.metadata?.labels['beta.kubernetes.io/arch'] } | ${ this.t('pai.vmset.nodeScheduling.unschedulable') }）` : `（${ node.metadata?.labels['beta.kubernetes.io/arch'] } | ${ this.t('pai.vmset.nodeScheduling.schedulable') })`),
        disabled: !!node.stateObj.error || !!node.spec.unschedulable,
      };
    })];
    if (this.$store.getters['cluster/schemaFor'](NETWORK_ATTACHMENT)) {
      this.networks = await this.$store.dispatch('cluster/findAll', { type: NETWORK_ATTACHMENT });
      this.networkSchema = true;
    }
    let networkData = [];

    if (this.isTmpl !== true) {
      networkData = this.value?.spec?.nomanagenic ? [] : [this.defaultNetwork];

      if (this.value.spec.nics) {
        this.value.spec.nics.filter((v) => v !== 'default').forEach((v) => {
          const network = this.networks.find((network) => network.namespace === this.value.namespace && network.metadata.name === v);

          if (network) {
            const config = JSON.parse(network.spec.config);

            const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
            let ip = '-';
            let srcIP = '-';

            if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
              if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
                ip = config?.ipam?.ranges[0][0].subnet;
              }
            } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
              if (config?.ipam?.addresses?.length && config?.ipam?.addresses[0]) {
                ip = addOffset(config?.ipam?.addresses[0].address, this.ipOffset ? this.ipOffset : 0);
                srcIP = config?.ipam?.addresses[0].address;
              }
            }
            networkData.push({
              name:        network.metadata.name,
              alias:       network.alias,
              networkType: config.type,
              hostNetwork: config.uplink,
              assignmentType,
              ip,
              srcIP,
            });
          }
        });
      }
    } else {
      networkData = this.value?.metadata?.annotations[VMSET_ANNOTATIONS.NOMANAGENIC_TMPL] === 'true' ? [] : [this.defaultNetwork];
      const newNetwork = this.value?.metadata?.annotations[VMSET_ANNOTATIONS.NETWORK_TMPL] ? JSON.parse(this.value?.metadata?.annotations[VMSET_ANNOTATIONS.NETWORK_TMPL]) : [];

      if (newNetwork.length !== 0) {
        newNetwork.forEach((item) => {
          networkData.push(item);
        });
      }
    }
    this.networkData = networkData;

    this.oldVmTemplateSpec.tolerations = JSON.parse(JSON.stringify(this.value?.spec?.template?.spec?.tolerations)) || [];
    this.oldVmTemplateSpec.priority = this.value?.spec?.template?.spec?.priority || '';
    this.oldVmTemplateSpec.priorityClassName = this.value?.spec?.template?.spec?.priorityClassName || '';

    this.updateNetworkOptions();
    await this.$store.dispatch('catalog/load', { force: true, reset: true });
  },
  data() {
    // init
    const GPU_KEY = 'nvidia.com/gpu';

    if (!this.value.spec) {
      this.value.spec = {
        boot:     'disk',
        template: {
          spec: {
            containers: [{
              name:            'os',
              image:           'rancher/mirrored-pause:3.6',
              imagePullPolicy: 'IfNotPresent',
              resources:       {
                limits: {
                  cpu:    '1',
                  memory: '2Gi',
                },
                requests: {
                  cpu:    '',
                  memory: '',
                },
              },
              ports: [{
                containerPort: 9100,
                name:          'metrics',
                protocol:      'TCP',
              }],
              securityContext: {
                runAsNonRoot:             false,
                readOnlyRootFilesystem:   false,
                privileged:               false,
                allowPrivilegeEscalation: false,
              }
            }]
          }
        },
        agent:  'guest',
        pcigpu: false,
      };
    }
    if (!this.value.spec.power) {
      this.value.spec.power = 'On';
    }
    if (!this.value.spec.replicas) {
      this.value.spec.replicas = 1;
    }
    if (!this.value.metadata.name) {
      this.value.metadata.name = `vm${ parseInt(new Date().getTime() / 1000) }`;
    }
    if (!this.value.metadata.annotations[ALIAS]) {
      this.value.setAnnotation(ALIAS, '');
    }
    if (!this.value.spec.nodeSelector) {
      this.$set(this.value.spec, 'nodeSelector', { 'kubernetes.io/hostname': '' });
    }
    if (!this.value.spec.volumeClaimTemplates) {
      this.value.spec.volumeClaimTemplates = [];
    }

    if (!this.value.spec.template.spec.volumeClaimTemplates) {
      this.value.spec.template.spec.volumeClaimTemplates = [{}];
    }

    if (!this.value.spec.volumes) {
      this.value.spec.volumes = [];
    }
    const defaultNetwork = {
      alias:     this.t('pai.vmset.defaultNetwork'),
      isDefault: true,
    };

    if (!this.value.spec.nodeSelector || !this.value.spec.nodeSelector['kubernetes.io/hostname']) {
      this.$set(this.value.spec.nodeSelector, 'kubernetes.io/hostname', '');
    }

    if (this.$route.query.qosType) {
      const query = this.$route.query;

      if ( query.qosType === 'bestEffort' ) {
        this.value.spec.template.spec.containers[0].resources.limits = { cpu: query?.cpu, memory: query?.memory };
        delete this.value.spec.template.spec.containers[0].resources.requests;
      } else {
        this.value.spec.template.spec.containers[0].resources.requests = { cpu: query?.cpu, memory: query?.memory };
        delete this.value.spec.template.spec.containers[0].resources.limits;
      }
      this.value.metadata.annotations[ALIAS] = query.vmAlias;
      this.value.metadata.namespace = query.namespace;
    }

    const tmplVolumes = [];

    const volumes = [];
    const qosType = this.value.metadata?.annotations?.qosType;

    return {
      ipOffset:                     this.value.spec.ipOffset,
      removePvcNames:               [],
      unloadPvcNames:               [],
      agent:                        this.value.spec.agent,
      IMAGE,
      PVC_LABELS,
      volumeNameIdx:                1,
      nodeOptions:                  [],
      storageClasses:               [],
      images:                       [],
      storageClassOptions:          [],
      imageOptions:                 [],
      osPvcOptions:                 [],
      toolkitOptions:               [],
      ISOOptios:                    [],
      persistentVolumeClaims:       [],
      persistentVolumeClaimOptions: [],
      networks:                     [],
      networkOptions:               [],
      tmplTypeOptions:              [
        { value: PVC_TYPES.ISO, label: this.t('pai.vmset.storage.types.iso') },
        { value: PVC_TYPES.TOOL, label: this.t('pai.vmset.image.toolkit') },
        { value: PVC_TYPES.OS, label: this.t('pai.vmset.storage.types.os') },
        { value: PVC_TYPES.DATA, label: this.t('pai.vmset.storage.data') }
      ],
      volumeTypeOptions: [
        { value: PVC_TYPES.OS, label: this.t('pai.vmset.storage.types.os') },
        { value: PVC_TYPES.DATA, label: this.t('pai.vmset.storage.data') },
      ],
      osTypeOptions: [
        {
          label: this.t('pai.vmset.storage.newOs'),
          value: 'new',
        },
        {
          label: this.t('pai.vmset.storage.existingOs'),
          value: 'exists',
        },
      ],
      networkData:       [],
      container:         this.value.spec.template.spec.containers[0],
      GPU_KEY,
      systemTypeOptions: [
        {
          value: '',
          label: this.t('pai.detail.vmset.all'),
        },
        {
          value: 'linux',
          label: 'Linux',
        }, {
          value: 'windows',
          label: 'Windows',
        }],
      nodeSelector:     this.value.spec.nodeSelector[HOSTNAME],
      defaultNetwork,
      disabled:         this.mode === _VIEW,
      replicas:         this.value.spec.replicas,
      needRestart:      false,
      aliasArr:         [],
      livenessProbe:    this.value.spec.template.spec.containers[0].livenessProbe,
      isIso:            !!this.value.labels[IMAGE.ISO],
      isoOptions:       [],
      IMAGE_PARAM,
      tmplVolumes,
      volumes,
      boot:             this.value.spec.boot,
      dialogVisible:    false,
      isDeletePvc:      false,
      currentDeletePvc: {},
      sortableInstance: null,
      resMode:          '',
      qosType,
      networkSchema:    false,
      vmTemplateSpec:   {
        tolerations:       this.value.spec.template.spec.tolerations || [],
        priority:          this.value.spec.template.spec.priority || '',
        priorityClassName: this.value.spec.template.spec.priorityClassName || ''
      },
      oldVmTemplateSpec: {
        tolerations:       [],
        priority:          '',
        priorityClassName: ''
      }
    };
  },
  mounted() {
    this.initSortable();
    this.initData();
  },
  computed: {
    ...mapGetters(['currentCluster']),
    ...mapGetters({ allCharts: 'catalog/charts' }),
    deleteVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'deleteVmModal';
    },
    namespace() {
      return this.value?.metadata?.namespace || '';
    },
    flatResources: {
      get() {
        const {
          limits = {},
          requests = {},
        } = this.container.resources || {};
        const {
          cpu:            limitsCpu,
          memory:         limitsMemory,
          [this.GPU_KEY]: limitsGpu,
        } = limits;
        const {
          cpu:    requestsCpu,
          memory: requestsMemory,
        } = requests;

        // 其他显卡资源
        const otherResource = { ...limits };

        delete otherResource.cpu;
        delete otherResource.memory;

        return {
          limitsCpu,
          limitsMemory,
          requestsCpu,
          requestsMemory,
          limitsGpu,
          otherResource
        };
      },
      set(neu) {
        const {
          limitsCpu,
          limitsMemory,
          requestsCpu,
          requestsMemory,
          otherResource
        } = neu;
        const requests = {};

        if ( requestsCpu ) {
          requests.cpu = requestsCpu;
        }
        if ( requestsMemory) {
          requests.memory = requestsMemory;
        }
        let limits = {};

        if ( otherResource) {
          limits = { ...otherResource };
        }
        if ( limitsCpu ) {
          limits.cpu = limitsCpu;
        }
        if ( limitsMemory) {
          limits.memory = limitsMemory;
        }
        this.$set(this.container, 'resources', cleanUp({ requests, limits }));
      },
    },
    currentClusterId() {
      return this.currentCluster?.id || 'local';
    },

    isCreate() {
      return this.realMode === _CREATE;
    },

    isExistsVolumeEditable() {
      return !this.replicas || this.replicas === 1;
    },
    isNetWork() {
      return this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.NAD);
    },
    chartResource() {
      return this.allCharts.filter((item) => {
        return item?.chartName === 'multus';
      });
    },
    nomanagenic() {
      if (this.networkData.find((item) => item.isDefault === true)) {
        return false;
      } else {
        return true;
      }
    },
    isTmpl() {
      return !!this.$route.query['tmpl'];
    },
    isVmId() {
      return !!this.$route.query.vmId;
    },
    toleraChange() {
      if (JSON.stringify(this.oldVmTemplateSpec.tolerations) !== JSON.stringify(this.vmTemplateSpec.tolerations) ||
          this.oldVmTemplateSpec.priority !== this.vmTemplateSpec.priority ||
          this.oldVmTemplateSpec.priorityClassName !== this.vmTemplateSpec.priorityClassName) {
        return true;
      } else {
        return false;
      }
    }
  },
  watch: {
    namespace() {
      this.updateNetworkOptions();
      this.updateImageOptions();
    },
    ipOffset(newValue, oldValue) {
      if (newValue !== oldValue) {
        this.setRestartState();

        this.networkData = this.networkData.map((item) => {
          let ip = '';

          if (item?.assignmentType === 'static' && item?.ip) {
            ip = addOffset(item?.srcIP, newValue);
          } else {
            ip = item?.ip;
          }

          return {
            ...item,
            ip
          };
        });
      }
    },
    vmTemplateSpec: {
      handler(nue) {
        if (this.vmTemplateSpec.tolerations) {
          this.$set(this.value.spec.template.spec, 'tolerations', this.vmTemplateSpec.tolerations);
        }
        if (this.vmTemplateSpec.priority) {
          this.$set(this.value.spec.template.spec, 'priority', this.vmTemplateSpec.priority);
        }
        if (this.vmTemplateSpec.priorityClassName) {
          this.$set(this.value.spec.template.spec, 'priorityClassName', this.vmTemplateSpec.priorityClassName);
        }
      },
      deep:      true,
      immediate: true,
    },
  },
  methods: {
    onAddVolume(type) {
      this.setRestartState();
      if (type === 'tmpl') {
        this.tmplVolumes.push({
          type:          this.boot === 'disk' && !this.tmplVolumes.find((item) => item.type === 'vmosdisk') ? PVC_TYPES.OS : this.boot === 'cdrom' && !this.tmplVolumes.find((item) => item.type === 'cdromdisk') ? PVC_TYPES.ISO : PVC_TYPES.DATA,
          name:          this.generateVolumeName(),
          // 镜像/存储类
          image:         '',
          size:          0,
          minSize:       0,
          id:            this.generateVolumeName(),
          inputDisabled: false,
        });
      } else {
        this.volumes.push({
          type:          this.boot === 'disk' && !this.volumes.find((item) => item.type === 'vmosdisk') ? PVC_TYPES.OS : PVC_TYPES.DATA,
          name:          '',
          size:          0,
          id:            new Date().toISOString(),
          inputDisabled: false,
        });
      }
    },
    bootChange() { // 切换启动模式
      this.setRestartState();
      this.$set(this.value.spec, 'boot', this.boot);
      if (this.tmplVolumes.length === 1 && this.tmplVolumes[0].size === 0) {
        this.tmplVolumes = [];
        this.tmplVolumes.push({
          type:    this.boot === 'disk' ? PVC_TYPES.OS : this.boot === 'cdrom' ? PVC_TYPES.ISO : PVC_TYPES.DATA,
          name:    this.generateVolumeName(),
          // 镜像/存储类
          image:   '',
          size:    0,
          minSize: 0,
          id:      this.generateVolumeName(),
        });
      }
    },
    valueChange(key, val) { // yaml文件同步修改
      this.$set(this.value.spec, key, val);
    },
    onRemoveVolume(type, index, disabled) {
      // 卸载磁盘时分为两类：工具包盘和ISO盘卸载时直接删除对应的pvc，其余的磁盘需要用户选择直接删除pvc还是只是清空label
      // 只有禁止编辑的pvc信息才会弹出确认卸载
      if (!this.isCreate && disabled) {
        if (type === 'tmpl') {
          this.currentDeletePvc = this.tmplVolumes[index];
        } else {
          this.currentDeletePvc = this.volumes[index];
        }
        if (this.currentDeletePvc.type === PVC_TYPES.ISO || this.currentDeletePvc.type === PVC_TYPES.TOOL) {
          this.$confirm(this.t('pai.vmset.tips.deletePvc'), this.t('pai.detail.vmset.tooltip'), {
            confirmButtonText: this.t('pai.detail.vmset.confirm'),
            cancelButtonText:  this.t('pai.detail.vmset.cancel'),
            type:              'warning'
          }).then(async() => {
            try {
              this.setRestartState();
              if (this.currentDeletePvc.image) {
                this.tmplVolumes.splice(this.tmplVolumes.findIndex((v) => v.name === this.currentDeletePvc.name), 1);
                this.removePvcNames.push(this.currentDeletePvc.pvc);
              } else {
                this.volumes.splice(this.volumes.findIndex((v) => v.name === this.currentDeletePvc.name), 1);
                this.removePvcNames.push(this.currentDeletePvc.name);
              }
              this.onClose();
              this.$message({
                type:    'success',
                message: `${ this.t('pai.labels.success') }`,
              });
            } catch (e) {
              console.log(e);
            }
          });
        } else {
          this.dialogVisible = true;
        }
      } else if (type === 'tmpl') {
        this.tmplVolumes.splice(index, 1);
      } else {
        this.volumes.splice(index, 1);
      }
    },
    onConfirm() {
      this.setRestartState();
      if (this.currentDeletePvc.image) {
        this.tmplVolumes.splice(this.tmplVolumes.findIndex((v) => v.name === this.currentDeletePvc.name), 1);
        if (this.isDeletePvc) {
          this.removePvcNames.push(this.currentDeletePvc.pvc);
        } else {
          this.unloadPvcNames.push(this.currentDeletePvc.pvc);
        }
      } else {
        this.volumes.splice(this.volumes.findIndex((v) => v.name === this.currentDeletePvc.name), 1);
        if (this.isDeletePvc) {
          this.removePvcNames.push(this.currentDeletePvc.name);
        } else {
          this.unloadPvcNames.push(this.currentDeletePvc.name);
        }
      }
      this.onClose();
    },
    onClose() {
      this.dialogVisible = false;
      this.isDeletePvc = false;
      this.currentDeletePvc = {};
    },
    onImageChange(row, index, diskType) {
      this.setRestartState();
      const image = this.storageClasses.find((v) => v.name === row.image);

      if (image) {
        this.tmplVolumes[index].size = diskType === 'datadisk' ? (convertUnitToG(image.annotations[IMAGE.SIZE]) !== 0 ? convertUnitToG(image.annotations[IMAGE.SIZE]) : '') : convertUnitToG(image.annotations[IMAGE.SIZE]);
        this.tmplVolumes[index].minSize = convertUnitToG(image.annotations[IMAGE.SIZE]);
      }
    },
    onVolumeChange(row, index) {
      this.setRestartState();
      const volume = this.persistentVolumeClaims.find((v) => v.name === row.name);

      if (volume) {
        this.volumes[index].size = convertUnitToG(volume.status?.capacity?.storage);
      }
    },
    onTypeChange(type, index) {
      if (type === 'tmpl') {
        this.tmplVolumes[index].image = '';
        this.tmplVolumes[index].size = 0;
        this.tmplVolumes[index].minSize = 0;
      } else {
        this.volumes[index].name = '';
        this.volumes[index].size = 0;
      }
    },
    // 创建新磁盘的option
    getStorageOptions(row) {
      if (row.type === PVC_TYPES.OS ) {
        return this.imageOptions;
      } else if (row.type === PVC_TYPES.ISO) { // 判断是否为iso镜像
        return this.isoOptions.filter((v) => v.sc?.labels[IMAGE.ISO] && v.sc?.labels[IMAGE.ISO] === 'true' && !v.sc?.labels[IMAGE.TOOL]);
      } else if (row.type === PVC_TYPES.DATA) {
        return this.storageClassOptions;
      } else if (row.type === PVC_TYPES.TOOL) { // com.tdology/toolkit 是工具包特有的字段
        return this.isoOptions.filter((v) => v.sc?.labels[IMAGE.TOOL] && v.sc?.labels[IMAGE.TOOL] === 'true');
      } else {
        return [];
      }
    },
    // 使用已有磁盘option
    getVolumeOptions(row) {
      if (row.type === PVC_TYPES.OS ) {
        return this.osPvcOptions.filter((v) => v.namespace === this.value.metadata.namespace && !this.volumes.map((v) => v.name).includes(v.value));
      } else if (row.type === PVC_TYPES.DATA) {
        return this.persistentVolumeClaimOptions.filter((v) => v.namespace === this.value.metadata.namespace && !this.volumes.map((v) => v.name).includes(v.value));
      } else if (row.type === PVC_TYPES.TOOL) {
        return this.toolkitOptions.filter((v) => v.namespace === this.value.metadata.namespace && !this.volumes.map((v) => v.name).includes(v.value));
      } else if (row.type === PVC_TYPES.ISO) {
        return this.ISOOptios.filter((v) => v.namespace === this.value.metadata.namespace && !this.volumes.map((v) => v.name).includes(v.value));
      } else {
        return [];
      }
    },
    // 行拖拽
    initSortable() {
      const tmplVolumes = document.getElementById('tmplVolumes').querySelector('.el-table__body-wrapper tbody');
      const volumes = document.getElementById('volumes').querySelector('.el-table__body-wrapper tbody');
      const _this = this;

      this.sortableInstance = Sortable.create(tmplVolumes, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.tmplVolumes.splice(oldIndex, 1)[0];

          _this.tmplVolumes.splice(newIndex, 0, currRow);
        },
        handle: '.draggable',
      });
      Sortable.create(volumes, {
        onEnd({ newIndex, oldIndex }) {
          const currRow = _this.volumes.splice(oldIndex, 1)[0];

          _this.volumes.splice(newIndex, 0, currRow);
        },
        handle: '.draggable',
      });
    },
    // table组件重新渲染后重新绑定Sortable实例
    onToggleTable() {
      const element = document.getElementById('tmplVolumes').querySelector('.el-table__body-wrapper tbody');

      if (this.sortableInstance.el !== element) {
        this.initSortable();
      }
    },
    forceFetch() {
      return this.$store.dispatch('cluster/find', {
        type: PAI_RESOURCES.VMSET,
        id:   this.value.id,
        opt:  { force: true },
      });
    },
    done() {
      if (this.realMode === _CREATE) {
        this.forceFetch();
        let location = {};

        if (this.isTmpl === true) {
          location = {
            name:   `${ PAI }-c-cluster-tmpl-resource`,
            params: {
              product:  PAI,
              resource: PAI_RESOURCES.VMSET,
            },
            query: { tmpl: 'true' },
          };
        } else {
          location = {
            name:   `${ PAI }-c-cluster-resource-namespace-id`,
            params: {
              id:        this.value.metadata.name,
              product:   PAI,
              resource:  PAI_RESOURCES.VMSET,
              cluster:   this.currentClusterId,
              namespace: this.value.metadata.namespace,
            },
            hash: '#eventLog',
          };
        }
        this.$router.push(location);

        return;
      }
      if (this.realMode === _EDIT && this.needRestart) {
        if (this.isTmpl !== true) {
          this.forceFetch()
            .then(() => {
              const resource = this.$store.getters['cluster/byId'](PAI_RESOURCES.VMSET, this.value.id);

              const callback = () => {
                const location = {
                  name:   `${ PAI }-c-cluster-resource-namespace-id`,
                  params: {
                    id:        resource.metadata.name,
                    product:   PAI,
                    resource:  PAI_RESOURCES.VMSET,
                    cluster:   this.currentClusterId,
                    namespace: resource.metadata.namespace,
                  },
                  hash: '#eventLog',
                };

                this.$router.push(location);
              };
              const titleKey = this.value.powerState !== 'Off' ? 'dialog.generic.mustRestart.title' : 'dialog.generic.mustPowerOn.title';
              const bodyKey = this.value.powerState !== 'Off' ? 'dialog.generic.mustRestart.body' : 'dialog.generic.mustPowerOn.body';

              resource.needPowerRestart(resource, callback, titleKey, bodyKey);
            });
        } else {
          this.forceFetch();
        }
      }
    },
    cancel() {
      this.$router.go(-1);
    },
    generateVolumeName() {
      this.volumeNameIdx = 1;
      let name = '';
      let hasName = true;

      while (hasName) {
        name = `volume-${ this.volumeNameIdx }`;
        // 因为不允许在云主机里对磁盘大小进行编辑，所以避免新增的磁盘与原有磁盘名称重复
        hasName = this.tmplVolumes.find((v) => v.name === name) || this.volumes.includes(name);
        this.volumeNameIdx++;
      }

      return name;
    },
    getAliasArr() {
      const newArr = [];

      this.networkData?.forEach((item) => {
        newArr.push(item.alias);
      });
      this.aliasArr = [...new Set(newArr)];
    },
    addNetwork() {
      this.setRestartState();
      this.networkData.push({});
      this.getAliasArr();
    },
    addManagementNetwork() {
      this.$set(this.value.spec, 'nomanagenic', false);
      this.networkData.push(this.defaultNetwork);
      if (this.isTmpl === true) {
        this.value.setAnnotation(VMSET_ANNOTATIONS.NOMANAGENIC_TMPL, false);
      }
    },
    removeAllNetworks() {
      const tipMessage = !this.nomanagenic ? this.t('pai.vmset.network.removeDefaultTips') : this.t('pai.labels.isOk');

      this.$confirm(tipMessage, this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.labels.confirm'),
        cancelButtonText:  this.t('pai.labels.cancel'),
        type:              'warning',
      }).then(() => {
        this.setRestartState();
        this.networkData.splice(0, this.networkData.length);
        // this.networkData.push(this.defaultNetwork);
        this.networkData = [];
        this.getAliasArr();
      }).catch(() => {
      });
    },
    onNetworkChange(e, index) {
      this.setRestartState();
      const config = JSON.parse(e.spec.config);
      const assignmentType = config.ipam && config.ipam.type ? config.ipam.type : '';
      let ip = '-';

      if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.HOST_LOCAL) {
        if (config?.ipam?.ranges?.length && config?.ipam?.ranges[0]?.length && config?.ipam?.ranges[0][0]) {
          ip = config?.ipam?.ranges[0][0].subnet;
        }
      } else if (assignmentType === ADDRESS_ASSIGNMENT_TYPE.STATIC) {
        if (config?.ipam?.addresses?.length) {
          ip = config?.ipam?.addresses[0].address;
        }
      }
      this.$set(this.networkData, index, {
        name:        e.metadata.name,
        alias:       e.alias,
        networkType: config.type,
        hostNetwork: config.uplink,
        assignmentType,
        ip,
        srcIP:       ip,
      });
      this.networkData = this.networkData.map((item) => {
        let ip = '';

        if (item?.assignmentType === 'static' && item?.ip) {
          ip = addOffset(item?.srcIP, this.ipOffset ? this.ipOffset : 0);
        } else {
          ip = item?.ip;
        }

        return {
          ...item,
          ip
        };
      });
      this.getAliasArr();
    },
    removeNetwork(row, index) {
      if (row.alias) {
        const tipMessage = row.isDefault === true ? this.t('pai.vmset.network.removeDefaultTips') : this.t('pai.labels.isOk');

        this.$confirm(tipMessage, this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.labels.confirm'),
          cancelButtonText:  this.t('pai.labels.cancel'),
          type:              'warning',
        }).then(() => {
          this.setRestartState();
          this.networkData.splice(index, 1);
          this.getAliasArr();
        });
      } else {
        this.setRestartState();
        this.networkData.splice(index, 1);
        this.getAliasArr();
      }
    },
    updateNetworkOptions() {
      this.networkOptions = this.networks.filter((v) => v.metadata.namespace === this.value.metadata.namespace).map((v) => {
        return {
          label: v.alias,
          value: v,
        };
      });
    },
    createNadLocation() {
      setTimeout(() => {
        this.$refs.networkSelect.blur();
      }, 20);
      const routeData = this.$router.resolve({ path: `/pai/c/${ this.currentClusterId }/${ PAI_RESOURCES.NAD }/create` });

      window.open(routeData.href, '_blank');
    },
    updateImageOptions() {
      // 包含vmimages的存储类为系统镜像(需要根据命名空间判断权限)
      this.imageOptions = this.storageClasses.filter((v) => v.annotations && v.annotations[IMAGE.NAME] && !v.labels[IMAGE.ISO])
        .filter((v) => !v.metadata.annotations?.['com.tdology.cloud.nss'] || v.metadata.annotations?.['com.tdology.cloud.nss'].includes(this.namespace))
        .map((s) => {
          const size = (s.metadata.annotations && s.metadata.annotations[IMAGE.SIZE]) ? `(${ convertUnitToG(s.metadata.annotations[IMAGE.SIZE]) }Gi)` : '';

          return {
            label: s.annotations[ALIAS] ? s.annotations[ALIAS] + size : s.annotations[IMAGE.NAME] + size,
            value: s.metadata.name,
            sc:    s,
          };
        }).sort();
      this.isoOptions = this.storageClasses.filter((v) => v.annotations && v.annotations[IMAGE.NAME] && !!v.labels[IMAGE.ISO])
        .filter((v) => !v.metadata.annotations?.['com.tdology.cloud.nss'] || v.metadata.annotations?.['com.tdology.cloud.nss'].includes(this.namespace))
        .map((v) => {
          const size = (v.metadata.annotations && v.metadata.annotations[IMAGE.SIZE]) ? `(${ convertUnitToG(v.metadata.annotations[IMAGE.SIZE]) }Gi)` : '';

          return {
            sc:    v,
            value: v.name,
            label: v.annotations[ALIAS] ? v.annotations[ALIAS] + size : v.annotations[IMAGE.NAME] + size,
          };
        });
    },
    async saveOverride(buttonCb) {
      if (this.errors) {
        clear(this.errors);
      }
      const installedVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET, opt: { force: true } });

      try {
        // 字段验证
        const numRegex = /^[1-9]\d*$/;

        if (!this.value.metadata.annotations[ALIAS]) {
          this.errors.push(this.t('pai.vmset.tips.nameTipNull'));
        }
        if (this.value.metadata.annotations[ALIAS]) { // 同命名空间名称不能重复
          let passStatus = true;
          const newInstalledVms = installedVms.filter((vm) => this.isTmpl ? vm.annotations[VMSET_ANNOTATIONS.TEMPLATE] === 'true' : !vm.annotations[VMSET_ANNOTATIONS.TEMPLATE]);

          newInstalledVms.forEach((item) => {
            if (item.namespace === this.value.metadata.namespace && item.annotations[ALIAS] === this.value.metadata.annotations[ALIAS] && item?.metadata?.uid !== this.value?.metadata?.uid) {
              passStatus = false;
            }
          });
          if (!passStatus) {
            this.errors.push(this.t('pai.vmset.tips.namespaceRepeat'));
          }
        }

        // 新磁盘校验
        for (let i = 0;i < this.tmplVolumes.length;i++) {
          if (!this.tmplVolumes[i].image) {
            this.errors.push(`${ this.t('pai.vmset.validation.tmplVolume', { key: i + 1 }, true) }: ${ this.t('validation.required', { key: '镜像/存储类' }, true) }`);
          }
          if (!this.tmplVolumes[i].size) {
            this.errors.push(`${ this.t('pai.vmset.validation.tmplVolume', { key: i + 1 }, true) }: ${ this.t('validation.required', { key: '磁盘大小' }, true) }`);
          }
          if (this.tmplVolumes[i].type === PVC_TYPES.OS && this.tmplVolumes[i].size < this.tmplVolumes[i].minSize) {
            this.errors.push(`${ this.t('pai.vmset.validation.tmplVolume', { key: i + 1 }, true) }: ${ this.t('pai.vmset.image.sizeOver') }`);
          }
        }
        // 已有磁盘校验
        if (this.value?.spec?.replicas > 1 && this.volumes.length !== 0) {
          this.errors.push(this.t('pai.vmset.tips.replicas'));
        }
        for (let i = 0;i < this.volumes.length;i++) {
          if (!this.volumes[i].name) {
            this.errors.push(`${ this.t('pai.vmset.validation.volume', { key: i + 1 }, true) }: ${ this.t('validation.required', { key: '磁盘名称' }, true) }`);
          }
        }
        if (!this.container.resources?.limits?.cpu && !this.container.resources?.requests?.cpu) {
          this.errors.push(this.t('pai.vmset.tips.CPULimitsRequireTip'));
        }
        if (!this.container.resources?.limits?.memory && !this.container.resources?.requests?.memory) {
          this.errors.push(this.t('pai.vmset.tips.ramLimitsRequireTip'));
        }
        if (this.container.resources?.limits?.cpu && !numRegex.test(this.container.resources?.limits?.cpu)) {
          this.errors.push(this.t('pai.vmset.tips.CPULimitsFormatTip'));
        }
        if (this.container.resources?.limits?.memory && !numRegex.test(parseFloat(convertUnitToG(this.container.resources?.limits?.memory)))) {
          this.errors.push(this.t('pai.vmset.tips.ramLimitsFormatTip'));
        }
        if (this.container.resources?.requests?.cpu && !numRegex.test(this.container.resources?.requests?.cpu)) {
          this.errors.push(this.t('pai.vmset.tips.CPULimitsFormatTip'));
        }
        if (this.container.resources?.requests?.memory && !numRegex.test(parseFloat(convertUnitToG(this.container.resources?.requests?.memory)))) {
          this.errors.push(this.t('pai.vmset.tips.ramLimitsFormatTip'));
        }
        let netWorkFlag = true;

        if (this.networkData.length > 0) {
          this.networkData.forEach((item) => {
            if (!item?.alias) {
              netWorkFlag = false;
            }
          });
        }
        if (!netWorkFlag) {
          this.errors.push(`${ this.t('pai.detail.vmset.tab.networkManagement.networkManagement') }${ this.t('pai.verify.required') }`);
        }
        if (this.errors.length) {
          this.errors = [this.errors[0]];
          buttonCb(false);

          return;
        }
        // 当只有光盘/工具包的时候，弹窗提醒一下用户
        if (!this.volumes.length && this.tmplVolumes.length === 1 && (this.tmplVolumes[0].type === PVC_TYPES.ISO || this.tmplVolumes[0].type === PVC_TYPES.TOOL)) {
          this.$confirm(this.t('pai.edit.tips.noDisks'), this.t('pai.labels.tip'), {
            confirmButtonText: this.t('pai.detail.vmset.confirm'),
            cancelButtonText:  this.t('pai.detail.vmset.cancel'),
            type:              'warning'
          }).then(async() => {
            await this.confirmSave(buttonCb);
          }).catch(() => {
            // 设置为cancelled会将创建按钮的文字恢复为创建，false会显示为创建时间。
            buttonCb('cancelled');
          });
        } else {
          await this.confirmSave(buttonCb);
        }
      } catch (e) {
        this.errors = exceptionToErrorsArray(e);
        buttonCb(false);
      }
    },
    async confirmSave(buttonCb) {
      this.setData(this.value);
      try { // 判断使用的已有磁盘与当前虚机不匹配，则更新当前已有磁盘的mounto和vmsets
        for (const name of this.volumes) {
          const pvc = this.persistentVolumeClaims.find((v) => v.name === name.name);

          if (pvc && !(pvc.metadata.name.includes(this.value.name))) {
            pvc.setLabel(PVC_LABELS.MOUNT_VM, this.value.name);
            if (!this.isCreate) {
              pvc.setLabel(PVC_LABELS.MOUNT_POD, this.value.name);
            }
          }
          await pvc.save();
        }
      } catch (e) {
        console.log(e);
      }
      if (this.isCreate) {
        await this.save(buttonCb);
      } else {
        try {
          for (const name of this.unloadPvcNames) {
            const pvc = this.persistentVolumeClaims.find((v) => v.name === name);

            if (pvc) {
              pvc.setLabel(PVC_LABELS.MOUNT_VM, '');
              pvc.setLabel(PVC_LABELS.MOUNT_POD, '');
            }
            await pvc.save();
          }
          for (const name of this.removePvcNames) {
            const pvc = this.persistentVolumeClaims.find((v) => v.name === name);

            if (pvc) {
              await pvc.remove();
            }
          }
          const resourceResult = await this.value.forceFetch();

          this.value.metadata.generation = resourceResult.metadata.generation;
          this.value.metadata.resourceVersion = resourceResult.metadata.resourceVersion;
          await this.value.save();
          if (this.isTmpl === true) {
            const location = {
              name:   `${ PAI }-c-cluster-tmpl-resource`,
              params: {
                product:  PAI,
                resource: PAI_RESOURCES.VMSET,
              },
              query: { tmpl: 'true' },
            };

            this.$router.push(location);
          }
          if (this.toleraChange === true) { // 容忍度有修改需要重启虚机
            this.setRestartState();
          }
        } catch (e) {
          if (e?._status === 409 && this.isTmpl !== true) {
            this.errors.push(this.t('dialog.generic.saveNeedRestart.body'));

            const resource = await this.value.forceFetch();

            const callback = () => {
              const location = {
                name:   `${ PAI }-c-cluster-resource-namespace-id`,
                params: {
                  id:        resource.metadata.name,
                  product:   PAI,
                  resource:  PAI_RESOURCES.VMSET,
                  cluster:   this.currentClusterId,
                  namespace: resource.metadata.namespace,
                },
                hash: '#eventLog',
              };

              this.$router.push(location);
            };

            buttonCb(false);
            resource.needPowerRestart(resource, callback, 'dialog.generic.saveNeedRestart.title', 'dialog.generic.saveNeedRestart.body');

            return;
          }
        }

        buttonCb(true);
        this.done();
      }
    },
    getInitImage(imageName) {
      // 目前通过vmimage的namespace-name找到对应的sc，后期通过label找到
      const sc = this.storageClasses.find((v) => v.metadata.name === imageName);

      if (sc) {
        if (sc.metadata && sc.metadata.annotations && sc.metadata.annotations[PAI_ANNOTATIONS.NAMESPACES]) {
          const nss = sc.metadata.annotations[PAI_ANNOTATIONS.NAMESPACES].split(',');

          if (nss.length > 0 && (!nss.includes(sc.metadata.namespace))) {
            this.$set(this.value.metadata, 'namespace', nss[0]);
          }
        }
        const annotations = get(sc, `metadata.annotations`);

        if (annotations) {
          this.tmplVolumes = [];
          this.tmplVolumes.push({
            type:    PVC_TYPES.OS,
            name:    'volume-1',
            // 镜像/存储类
            image:   sc.name,
            size:    Math.ceil(convertUnitToG(annotations[IMAGE.SIZE])),
            minSize: Math.ceil(convertUnitToG(annotations[IMAGE.SIZE])),
          });
        }
      }
    },
    limitChange() {
      this.setRestartState();
    },
    labelsChange(e) {
      this.value.spec.nodeSelector['kubernetes.io/hostname'] = e;
      this.setRestartState();
    },
    generateYaml() {
      this.diskYaml(); // 磁盘数据同步到yaml
      const resource = this.value;

      const schemas = this.$store.getters[`cluster/all`](SCHEMA);
      const clonedResource = clone(resource);

      delete clonedResource.isSpoofed;

      return createYaml(schemas, PAI_RESOURCES.VMSET, clonedResource);
    },

    initData() {
      const resource = this.value;
      const clonedResource = clone(resource);

      delete clonedResource.isSpoofed;
      this.setData(clonedResource);
    },

    setData(resource) {
      // 处理资源配额数据
      const quotaData = resource.spec.template.spec.containers[0].resources;

      const out = {
        requests: {
          cpu:    quotaData?.requests?.cpu,
          memory: quotaData?.requests?.memory,
        },
        limits: { ...quotaData.limits },
      };

      if ((!out.requests?.cpu && !out.requests?.memory) || (out.requests?.cpu === '' && out.requests?.memory === '')) {
        delete out.requests;
      }
      if ((!out.limits || out.limits === {}) && ((!out.limits?.cpu && !out.limits?.memory) || (out.limits?.cpu === '' && out.limits?.memory === ''))) {
        delete out.limits;
      }
      this.$set(this.container, 'resources', cleanUp(out));
      resource.spec.volumeClaimTemplates = [];
      resource.spec.volumes = [];
      this.tmplVolumes.forEach((v) => {
        resource.spec.volumeClaimTemplates.push({
          metadata: { name: v.name },
          spec:     {
            resources:        { requests: { storage: `${ v.size }Gi` } },
            storageClassName: v.image,
            volumeMode:       'Block'
          }
        });
      });
      this.volumes.forEach((v) => {
        resource.spec.volumes.push({
          name:                  v.name,
          persistentVolumeClaim: { claimName: v.name }
        });
      });

      // 处理网卡
      const nics = this.networkData.filter((v) => !v.isDefault).map((v) => v.name);

      resource.spec.nics = [...nics];
      resource.spec.nomanagenic = this.nomanagenic;

      // 处理nodeSelector
      if (!this.nodeSelector) {
        this.$delete(resource.spec.nodeSelector, HOSTNAME);
      }
      // 添加imageAlias
      const currentImage = this.images?.find((item) => this.osDisk?.sc?.includes(item.metadata.name));

      if (currentImage) {
        this.value.setAnnotation(VMSET_ANNOTATIONS.IMAGE_ALIAS, currentImage.spec.alias);
      }
      if (this.isTmpl === true) { // 云主机模板传参
        this.value.setAnnotation(VMSET_ANNOTATIONS.TEMPLATE, 'true');
        this.value.spec.power = 'Off';
        if (this.tmplVolumes.length !== 0) {
          this.value.setAnnotation(VMSET_ANNOTATIONS.TMPLVOLUMES_TMPL, JSON.stringify(this.tmplVolumes));
        }
        if (this.volumes.length !== 0) {
          this.value.setAnnotation(VMSET_ANNOTATIONS.VOLUMES_TMPL, JSON.stringify(this.volumes));
        }
        if (this.networkData.length !== 0) {
          this.value.setAnnotation(VMSET_ANNOTATIONS.NETWORK_TMPL, JSON.stringify(this.networkData.filter((v) => !v.isDefault)));
          this.value.setAnnotation(VMSET_ANNOTATIONS.NOMANAGENIC_TMPL, this.nomanagenic === true ? 'true' : 'false');
        }
      }
    },
    setRestartState() {
      if (!this.isCreate) {
        this.needRestart = true;
      }
    },
    onReplicasChange(e) {
      const replicas = parseInt(e) > 0 ? parseInt(e) : 1;

      this.value.spec.replicas = replicas;
      this.replicas = replicas;
    },
    handleMultus() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-apps-charts-chart`,
        params: {
          cluster: this.$route.params.cluster,
          product: PAI,
        },
        query: {
          'repo-type': this.chartResource[0]?.repoType,
          repo:        this.chartResource[0]?.repoName,
          chart:       this.chartResource[0]?.chartName
        }
      });
    },
    handleTab(e) {
      if (e.selectedName === 'network' && !this.isNetWork) {
        this.$message({
          type:    'warning',
          message: `${ this.t('pai.vmset.tips.netWorkTips') }`,
        });
      }
    },
    createImage() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          product:  PAI,
          cluster:  this.currentCluster.id,
          resource: PAI_RESOURCES.VM_IMAGE,
        },
        query: { [MODE]: _CREATE },
      });
    },
    setRequestData(type) {
      this.resMode = type;

      let out = {};

      // requests独享模式
      // 回显输入框的值
      if (type === 'requests') {
        out = {
          requests: {
            cpu:    this.container.resources.limits.cpu,
            memory: this.container.resources.limits.memory
          },
          limits: { ...this.container.resources.limits },
        };
        if (out.limits.cpu) {
          delete out.limits.cpu;
        }
        if (out.limits.memory) {
          delete out.limits.memory;
        }
      } else {
        out = {
          limits: {
            cpu:    this.container.resources.requests.cpu,
            memory: this.container.resources.requests.memory,
            ...this.container.resources.limits
          },
        };
      }
      this.$set(this.container, 'resources', cleanUp(out));
    },
    diskYaml() {
      if (this.tmplVolumes.length !== 0) {
        this.value.spec.volumeClaimTemplates = [];
        this.tmplVolumes.forEach((v) => {
          this.value.spec.volumeClaimTemplates.push({
            metadata: { name: v.name },
            spec:     {
              resources:        { requests: { storage: `${ v.size }Gi` } },
              storageClassName: v.image
            }
          });
        });
      }

      if (this.volumes.length !== 0) {
        this.value.spec.volumes = [];
        this.volumes.forEach((v) => {
          this.value.spec.volumes.push({
            name:                  v.name,
            persistentVolumeClaim: { claimName: v.name }
          });
        });
      }
    }
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    :generate-yaml="generateYaml"
    @error="e=>errors = e"
    @finish="saveOverride"
    @cancel="cancel"
  >
    <div class="row">
      <AliasNsDescription
        :value="value"
        :mode="mode"
        class="col span-10"
        @isNamespaceNew="isNamespaceNew = $event"
      />
      <LabeledInput
        :value="replicas"
        :mode="mode"
        :label="t('pai.vmset.replicas')"
        class="col span-2 mb-20"
        style="width: 15%;"
        type="number"
        required
        :min="1"
        @input="onReplicasChange"
      />
    </div>
    <Tabbed
      ref="containersTabbed"
      class="deployment-tabs"
      :show-tabs-add-remove="true"
      :flat="true"
      @changed="handleTab"
    >
      <!--   基本信息   -->
      <Tab
        :label="t('pai.vmset.info')"
        name="vm"
        :weight="99"
        :required-tab="true"
      >
        <div class="tab-main">
          <SectionTitle
            :value="t('pai.edit.machine.bootMode')"
            class="sectionTitle"
          >
            <span style="color: red">
              *
            </span>
          </SectionTitle>
          <div class="block">
            <template>
              <el-radio-group
                v-model="boot"
                :disabled="disabled"
                @change="bootChange"
              >
                <el-radio
                  label="net"
                >
                  {{ t('pai.vmset.boot.net') }}
                </el-radio>
                <el-radio
                  label="cdrom"
                >
                  {{ t('pai.vmset.boot.cdrom') }}
                </el-radio>
                <el-radio
                  label="disk"
                >
                  {{ t('pai.vmset.boot.disk') }}
                </el-radio>
              </el-radio-group>
            </template>
          </div>
          <SectionTitle
            :value="t('pai.vmset.storage.label')"
            class="sectionTitle"
          >
            <span style="color: red">
              *
            </span>
          </SectionTitle>
          <div class="block">
            <template>
              <div class="storageTip">
                <i
                  class="icon el-icon-info"
                  style="color: rgba(154, 154, 154, 1); margin-right: 5px;"
                />{{ t('pai.vmset.tips.lastDisk') }}
              </div>
              <div class="storages">
                <h2>
                  {{ t('pai.vmset.storage.pvc.new') }}
                </h2>
                <h2>
                  {{ t('pai.vmset.storage.pvc.exists') }}
                </h2>
              </div>
              <div class="storages">
                <el-table
                  id="tmplVolumes"
                  :data="tmplVolumes"
                  style="width: 100%;margin-right: 10px;"
                  :header-cell-style="{ color: '#606266',fontWeight: 400}"
                  row-key="id"
                  align="center"
                  @cell-mouse-enter="onToggleTable"
                >
                  <el-table-column
                    :label="t('pai.detail.vmset.index')"
                    width="55"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.$index+1 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="!disabled"
                    label=""
                    width="50"
                    class-name="draggable"
                  >
                    <i class="el-icon-rank" />
                  </el-table-column>
                  <el-table-column :label="t('pai.vmset.diskType')">
                    <EditableCell
                      v-model="scope.row.type"
                      slot-scope="scope"
                      editable-component="el-select"
                      close-event="visible-change"
                      :can-edit="!scope.row.disabled"
                      :input-disabled="disabled || scope.row.inputDisabled"
                      @input="onTypeChange('tmpl',scope.$index)"
                    >
                      <span slot="content">{{ tmplTypeOptions.find(v=>v.value === scope.row.type)?.label }}</span>
                      <template slot="edit-component-slot">
                        <el-option
                          v-for="(item) in tmplTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </template>
                    </EditableCell>
                  </el-table-column>
                  <el-table-column :label="`${t('pai.detail.vmset.operationSystemImage')}/${t('pai.detail.vmset.tab.diskManagement.storageClass')}`">
                    <template slot-scope="scope">
                      <EditableCell
                        v-model="scope.row.image"
                        editable-component="el-select"
                        close-event="visible-change"
                        :can-edit="!scope.row.disabled"
                        :input-disabled="disabled|| scope.row.inputDisabled"
                        @input="onImageChange(scope.row,scope.$index, scope.row.type)"
                      >
                        <span slot="content">
                          <span v-if="getStorageOptions(scope.row).find(v => v.value === scope.row.image)">{{ getStorageOptions(scope.row).find(v=>v.value === scope.row.image)?.label }}</span>
                          <span v-else>-</span>
                        </span>
                        <template slot="edit-component-slot">
                          <el-option
                            v-if="!getStorageOptions(scope.row).length && !(scope.row.type === 'datadisk')"
                            value=""
                          >
                            <a
                              style="display: block;height: 37px; line-height: 37px; text-align: center;cursor: pointer"
                              @click="createImage"
                            >
                              {{ t('pai.vmset.tips.emptyImage',{key:scope.row.type},true) }}
                            </a>
                          </el-option>
                          <el-option
                            v-for="(item) in getStorageOptions(scope.row)"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </template>
                      </EditableCell>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('pai.vmset.diskSize')+'(GiB)'"
                    width="110"
                  >
                    <template slot-scope="scope">
                      <span v-if="scope.row.type === 'cdromdisk' || scope.row.type === 'toolkitdisk'">{{ scope.row.size }}</span>
                      <EditableCell
                        v-else
                        v-model.number="scope.row.size"
                        :can-edit="!scope.row.disabled"
                        editable-component="el-input"
                        :input-disabled="disabled|| scope.row.inputDisabled"
                        @input="setRestartState"
                      >
                        <span slot="content">{{ scope.row.size }}</span>
                      </EditableCell>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('catalog.operation.tableHeaders.action')"
                    width="66"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        :disabled="(!volumes.length && tmplVolumes.length<2) || disabled"
                        @click="onRemoveVolume('tmpl',scope.$index,scope.row.disabled)"
                      >
                        {{ t('pai.labels.remove') }}
                      </el-button>
                    </template>
                  </el-table-column>
                </el-table>
                <el-table
                  id="volumes"
                  :data="volumes"
                  :header-cell-style="{ color: '#606266',fontWeight: 400}"
                  style="width: 100%"
                  row-key="id"
                  align="center"
                >
                  <el-table-column
                    :label="t('pai.detail.vmset.index')"
                    width="55"
                  >
                    <template slot-scope="scope">
                      <span>{{ scope.$index+1 }}</span>
                    </template>
                  </el-table-column>
                  <el-table-column
                    v-if="!disabled"
                    label=""
                    width="50"
                    class-name="draggable"
                  >
                    <i class="el-icon-rank" />
                  </el-table-column>
                  <el-table-column :label="t('pai.vmset.diskType')">
                    <EditableCell
                      v-model="scope.row.type"
                      slot-scope="scope"
                      editable-component="el-select"
                      close-event="visible-change"
                      :can-edit="!scope.row.disabled"
                      :input-disabled="disabled|| scope.row.inputDisabled"
                      @input="onTypeChange('volume',scope.$index)"
                    >
                      <span slot="content">{{ tmplTypeOptions.find(v=>v.value === scope.row.type)?.label }}</span>
                      <template slot="edit-component-slot">
                        <el-option
                          v-for="(item) in tmplTypeOptions"
                          :key="item.value"
                          :label="item.label"
                          :value="item.value"
                        />
                      </template>
                    </EditableCell>
                  </el-table-column>
                  <el-table-column :label="t('pai.vmset.diskName')">
                    <template slot-scope="scope">
                      <EditableCell
                        v-model="scope.row.name"
                        editable-component="el-select"
                        close-event="visible-change"
                        :can-edit="!scope.row.disabled"
                        :input-disabled="disabled|| scope.row.inputDisabled"
                        @input="onVolumeChange(scope.row,scope.$index)"
                      >
                        <span slot="content">
                          <span v-if="scope.row.name">{{ scope.row.name }}</span>
                          <span v-else>-</span>
                        </span>
                        <template slot="edit-component-slot">
                          <el-option
                            v-for="(item) in getVolumeOptions(scope.row)"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value"
                          />
                        </template>
                      </EditableCell>
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('pai.vmset.diskSize')+'(GiB)'"
                    width="110"
                  >
                    <template slot-scope="{row}">
                      {{ row.size }}
                    </template>
                  </el-table-column>
                  <el-table-column
                    :label="t('catalog.operation.tableHeaders.action')"
                    width="66"
                  >
                    <template slot-scope="scope">
                      <el-button
                        type="text"
                        :disabled="(volumes.length<2 && !tmplVolumes.length) || disabled"
                        @click="onRemoveVolume('volume',scope.$index,scope.row.disabled)"
                      >
                        {{ t('pai.labels.remove') }}
                      </el-button>
                    </template>
                  </el-table-column>
                  <div
                    slot="empty"
                    style="text-align: left;"
                    class="useDiskEmpty"
                  >
                    <el-empty
                      :description="t('pai.detail.vmset.noData')"
                    />
                  </div>
                </el-table>
              </div>
              <div
                v-if="!disabled"
                class="storages"
              >
                <el-button
                  type="text"
                  @click="onAddVolume('tmpl')"
                >
                  <i class="el-icon-plus" />
                  {{ t('pai.vmset.newDisk') }}
                </el-button>
                <div>
                  <el-button
                    type="text"
                    :disabled="replicas>1"
                    @click="onAddVolume"
                  >
                    <i class="el-icon-plus" />
                    {{ t('pai.vmset.existingDisk') }}
                  </el-button>
                  <el-tooltip
                    v-if="replicas>1"
                    :content="t('pai.vmset.tips.replicas')"
                  >
                    <i class="icon icon-info" />
                  </el-tooltip>
                </div>
              </div>
            </template>
          </div>
          <SectionTitle
            :value="t('charts.paistore.group.otherSettings')"
            class="sectionTitle"
          >
            <span style="color: red">
              *
            </span>
          </SectionTitle>
          <div class="block">
            <el-descriptions
              title=""
              direction="vertical"
              :column="2"
              :colon="false"
            >
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.vmset.agent.label') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.vmset.agent.tip', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                  <span style="color:red">*</span>
                </template>
                <el-select
                  v-model="agent"
                  :disabled="disabled"
                  style="width: 60%"
                  class="col span-6"
                  @change="valueChange('agent', agent)"
                >
                  <el-option
                    v-for="item of IMAGE_PARAM.AGENT.map(v=>{return{value:v,label:v==='guest' ? t('generic.yes') : t('generic.no')}})"
                    :key="item.value"
                    :label="item.label"
                    :value="item.value"
                  />
                </el-select>
              </el-descriptions-item>
              <el-descriptions-item>
                <template slot="label">
                  {{ t('pai.vmset.pcigpu.label') }}
                  <el-tooltip effect="light">
                    <div
                      slot="content"
                      v-html="t('pai.vmset.pcigpu.pcigpuTip', {}, true)"
                    />
                    <i class="icon icon-info" />
                  </el-tooltip>
                </template>
                <el-checkbox
                  v-model="value.spec.pcigpu"
                  :disabled="disabled"
                  @change="$forceUpdate()"
                >
                  {{ t('pai.vmset.pcigpu.name') }}
                </el-checkbox>
              </el-descriptions-item>
            </el-descriptions>
          </div>
          <SectionTitle
            :value="t('pai.vmset.limit.label')"
            class="sectionTitle"
            style="margin: 10px 0"
          />
          <div class="block">
            <template>
              <div class="storageTip">
                <i
                  class="icon el-icon-info"
                  style="color: rgba(154, 154, 154, 1); margin-right: 5px;"
                />{{ t('pai.vmset.tips.reasonableAllocation') }}
              </div>
              <ContainerResourceLimit
                v-model="flatResources"
                :mode="mode"
                :show-tip="false"
                :setRequestData="setRequestData"
                :inputDisabled="disabled"
                :qosType="qosType"
                @input="limitChange"
              />
            </template>
          </div>
        </div>
      </Tab>
      <!--   网络   -->
      <Tab
        :label="t('nav.group.Networking')"
        name="network"
        :weight="97"
        class="tab"
        :required-tab="false"
      >
        <SectionTitle
          :value="t('pai.vmset.system.network')"
          class="row sectionTitle"
        />
        <div class="storageBox">
          <div v-if="isNetWork">
            <div class="row mt-10 mb-10">
              <button
                type="button"
                class="btn role-primary"
                :disabled="mode === 'view'"
                style="margin-right: 10px"
                @click="addNetwork"
              >
                <i class="el-icon-plus" />
                {{ t('generic.add') }}
              </button>
              <button
                v-if="nomanagenic"
                type="button"
                class="btn role-primary"
                :disabled="mode === 'view'"
                style="margin-right: 10px"
                @click="addManagementNetwork"
              >
                <i class="el-icon-plus" />
                {{ t('generic.add') + t('pai.vmset.defaultNetwork') }}
              </button>
              <button
                type="button"
                class="btn bg-error"
                :disabled="mode === 'view'"
                @click="removeAllNetworks"
              >
                <i class="el-icon-delete" />
                {{ t('pai.labels.removeAll') }}
              </button>
            </div>
            <el-table
              :data="networkData"
              style="width: 100%"
            >
              <el-table-column
                :label="t('nav.group.Networking')"
                width="190"
              >
                <template slot-scope="scope">
                  <el-select
                    ref="networkSelect"
                    v-model="networkData[scope.$index].alias"
                    :placeholder="t('pai.edit.SelectPlaceholder')"
                    :disabled="scope.row.isDefault || mode === 'view'"
                    filterable
                    @change="onNetworkChange($event,scope.$index)"
                    @focus="updateNetworkOptions"
                  >
                    <el-option
                      v-for="(item,i) in networkOptions"
                      :key="item.value+i"
                      :label="item.label"
                      :value="item.value"
                      :disabled="aliasArr.includes(item.label)"
                    />
                    <!-- 自定义列 -->
                    <el-option
                      :class="networkOptions.length !== 0 ? 'option-line': ''"
                      style="width: 100%; font-weight: normal;"
                    >
                      <span v-if="networkOptions.length === 0">
                        <span style="color: #cccccc">{{ t('pai.detail.vmset.noData') }}，</span>
                        <span
                          v-if="!networkSchema"
                          style="color: #cccccc"
                        >{{ t('pai.vmset.tips.contactAdmin') }}</span>
                      </span>
                      <a
                        v-if="networkSchema"
                        href="#"
                        @click.prevent="createNadLocation"
                      >
                        {{ t('pai.vmset.tips.emptyNad') }}
                      </a>
                    </el-option>
                  </el-select>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('pai.list.network.hostNetwork')"
                prop="hostNetwork"
                width="200"
              >
                <template slot-scope="scope">
                  <div class="tableCellBox">
                    {{ scope.row.isDefault || !scope.row.hostNetwork ? '-' : scope.row.hostNetwork }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('pai.detail.vmset.tab.networkManagement.type')"
                prop="networkType"
                width="200"
              >
                <template slot-scope="scope">
                  <div class="tableCellBox">
                    {{ scope.row.isDefault || !scope.row.networkType ? '-' : (scope.row.networkType === 'bridge' ? `${t('pai.vmset.network.bridge')}` : scope.row.networkType) }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('pai.list.network.assignmentType')"
                prop="assignmentType"
                width="200"
              >
                <template slot-scope="scope">
                  <div
                    class="tableCellBox"
                  >
                    {{ scope.row.isDefault || !scope.row.assignmentType ? '-' : scope.row.assignmentType }}
                  </div>
                </template>
              </el-table-column>

              <el-table-column
                prop="ip"
                :width="value.spec.power === 'On' && !isCreate? 396: 200"
              >
                <!-- eslint-disable vue/no-unused-vars -->
                <template
                  slot="header"
                  slot-scope="scope"
                >
                  <!--eslint-enable-->
                  <div style="display: flex;justify-content: flex-start;align-items: center;">
                    <span style="margin: 0px 6px 0px 0px;">
                      <el-tooltip
                        :content="t('pai.vmset.network.ipOffsetTip')"
                        effect="light"
                      >
                        <i
                          class="icon icon-info"
                          style="transform:scaleX(-1)"
                        />
                      </el-tooltip>
                      {{ t('pai.detail.vmset.tab.overview.ipAddress') }}
                    </span>
                    <LabeledInput
                      v-model.number="ipOffset"
                      :mode="mode"
                      label=""
                      class="col span-1 mb-20"
                      style="width: 110px;position: relative;top: 10px;"
                      type="number"
                      required
                      :min="0"
                      :disabled="value.spec.power !== 'Off' && !isCreate"
                      :placeholder="t('pai.vmset.network.editOffset')"
                      @input="value.spec.ipOffset = Number($event)"
                    />
                    <span
                      v-if="value.spec.power === 'On' && !isCreate"
                      style="color: #f64747;"
                    >{{ t('pai.vmset.tips.ipOffsetTips') }}</span>
                  </div>
                </template>
                <template slot-scope="scope">
                  <div
                    class="tableCellBox"
                  >
                    {{ scope.row.ip || '-' }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column label="">
                <template slot-scope="scope">
                  <button
                    type="button"
                    size="mini"
                    class="btn role-primary"
                    :disabled="mode === 'view' || networkData.length <= 1"
                    @click="removeNetwork(scope.row, scope.$index)"
                  >
                    {{ t('generic.remove') }}
                  </button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <div
            v-else
          >
            {{ t('pai.vmset.tips.noNetWork') }}，<a
              href="#"
              @click.prevent="handleMultus"
            >{{ t('pai.vmset.tips.installLink') }}</a>
          </div>
        </div>
      </Tab>
      <!--   高级选项   -->
      <Tab
        :label="t('pai.vmset.advancedOptions')"
        name="vms"
        :weight="96"
        :required-tab="false"
      >
        <Tabbed :side-tabs="true">
          <Tab
            label-key="generic.labelsAndAnnotations"
            name="general"
            :weight="100"
            :tooltip="t('pai.vmset.tips.label')"
          >
            <Labels
              v-model="value"
              :mode="value.powerState !== 'Off' && !isCreate ? 'view' : mode"
            />
          </Tab>
          <Tab
            label-key="pai.vmset.nodeScheduling.label"
            name="nodeScheduling"
            :weight="99"
          >
            <SectionTitle
              :value="t('pai.vmset.nodeScheduling.label')"
              class="sectionTitle"
            />
            <div class="row mt-15">
              <el-select
                v-model="nodeSelector"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                class="col span-6"
                filterable
                :disabled="mode === 'view'"
                @change="labelsChange"
              >
                <el-option
                  v-for="(item,i) in nodeOptions"
                  :key="item.value+i"
                  :label="item.label"
                  :value="item.value"
                  :disabled="item.disabled"
                />
              </el-select>
            </div>
          </Tab>
          <Tab
            label-key="pai.vmset.nodeScheduling.health"
            name="healthCheck"
            :weight="98"
          >
            <HealthCheck
              :value="container"
              :mode="mode"
              :readiness-probe-hidden="true"
              :startup-probe-hidden="true"
              @input="Object.assign(container, $event)"
            />
          </Tab>
          <Tab
            label-key="pai.vmset.nodeScheduling.resource"
            name="tolerations"
            :weight="98"
          >
            <h3 class="mb-10">
              <t k="workload.scheduling.titles.tolerations" />
            </h3>
            <Tolerations
              v-model="vmTemplateSpec.tolerations"
              class="tolerations"
              :mode="mode"
            />
            <div>
              <div class="spacer" />
              <h3 class="mb-10">
                <t k="workload.scheduling.titles.priority" />
              </h3>
              <div class="row">
                <div class="col span-6">
                  <LabeledInput
                    v-model.number="vmTemplateSpec.priority"
                    :mode="mode"
                    type="number"
                    min="0"
                    :label="t('workload.scheduling.priority.priority')"
                    @input="$event ? vmTemplateSpec.priority = Number($event) : $delete(vmTemplateSpec, 'priority');"
                  />
                </div>
                <div class="col span-6">
                  <LabeledInput
                    v-model="vmTemplateSpec.priorityClassName"
                    :mode="mode"
                    :label="t('workload.scheduling.priority.className')"
                  />
                </div>
              </div>
            </div>
          </Tab>
        </Tabbed>
      </Tab>
    </Tabbed>
    <el-dialog
      :title="t('pai.labels.tip')"
      :visible.sync="dialogVisible"
      width="40%"
      :close-on-click-modal="false"
      :modal-append-to-body="false"
    >
      <div
        class="content"
      >
        <div>
          {{ t('pai.vmset.tips.deletePvc') }}
        </div>
        <el-checkbox
          v-model="isDeletePvc"
          :checked="isDeletePvc"
        >
          {{ t('pai.vmset.tips.relativeDisk') }}
        </el-checkbox>
      </div>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="dialogVisible=false">{{ t('pai.detail.vmset.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="onConfirm"
        >{{ t('pai.detail.vmset.confirm') }}</el-button>
      </span>
    </el-dialog>
    <DeleteVmModal v-if="deleteVmModalVisible" />
  </CruResource>
</template>
<style lang="scss" scoped>
.block {
  border-radius: 5px;
  background-color: rgba(250, 250, 250, 1);
  border: 1px dashed rgba(187, 187, 187, 1);
  padding: 12px;
}

.storageTip {
  border-radius: 5px;
  background-color: rgba(228, 233, 245, 1);
  font-size: 12px;
  text-align: left;
  border: 1px solid rgba(52, 124, 175, 1);
  padding: 8px;
}

.storages {
  margin-top: 10px;
  display: flex;
  justify-content: space-around;
  h2 {
    font-size: 16px;
  }
  .el-table th.el-table__cell {
    background: var(--primary);
    color: var(--primary-text);
  }
  .el-button--text {
    color: var(--primary);
  }
  .el-button.is-disabled {
    color: #C0C4CC;
  }
  i {
    color: var(--primary);
    cursor: pointer;
  }
}

.sectionTitle {
  margin: 10px 0;
  font-weight: 600;
}

.tab {
  padding: 20px;
}

.el-tag {
  margin: 0 5px;
  cursor: pointer;
}

.active {
  border-color: var(--primary);
}
.tab-main {
  padding: 20px;
}

.el-input-group {
  display: -webkit-box;
}

.el-input-pend {
  background-color: #f5f7fa;
  color: #909399;
  vertical-align: middle;
  display: table-cell;
  position: relative;
  border: 1px solid #dcdfe6;
  border-radius: 4px;
  padding: 0 10px;
  width: 1px;
  white-space: nowrap;
  border-left: unset;
}
.selectedInfo-box {
  width: 60%;
  padding: 15px;
  margin-top: -30px;
  border: 1px dashed rgba(187, 187, 187, 1);
  background-color: rgba(250, 250, 250, 1);
}
.replicasIcon {
  position: relative;
  top: -20px;
  left: 286px;
}
.storageBox {
  background-color: #FAFAFA;
  border: 1px solid #DCDEE7;
  padding: 20px 10px;
  border-radius: 3px;
}
.padding-tb {
  display: flex;
  padding: 10px 0 0 0;
}
.removeBtn{
  color: #246FA5;
}
.newBtn{
  position: relative;
  right: 16%;
  top: 11px;
}
.existsBtn {
  position: relative;
  right: 60%;
  top: 11px;
}
::v-deep .el-table .cell{
  overflow: visible;
}
.tableCellBox {
  width: 180px;
  height: 34px;
  padding: 0px 10px;
  line-height: 34px;
  background-color: #EFEFEF;
  border: none;
  border-radius: 3px;
}
.customNetworkIcon {
  position: relative;
  top: -16px;
  left: 88px;
}
.option-line {
  border-top: 1px solid #cccccc;
}
::v-deep .el-empty__image svg{
  display: none;
}
::v-deep .el-table__empty-text{
  line-height: 0;
}
.content {
  display: flex;
  flex-direction: column;
}
::v-deep .el-descriptions__title {
  font-size: 14px;
  font-weight: unset;
}

::v-deep .el-input-number.is-controls-right .el-input-number__decrease, .el-input-number.is-controls-right .el-input-number__increase {
  height: auto;
  line-height: 22px !important;
}

::v-deep .el-descriptions :not(.is-bordered) .el-descriptions-item__cell {
  padding-bottom: 10px;
}
::v-deep {
  .labeled-input INPUT.no-label,
  .labeled-input INPUT:hover.no-label,
  .labeled-input INPUT:focus.no-label {
    padding: 6px 0px 6px 0px;
  }
  // 此代码影响了rancher原生输入框样式展示（注释掉暂时未发现影响页面整体样式）
  //.labeled-input.compact-input {
  //  min-height: 0;
  //}
}
::v-deep .el-descriptions__body {
  background-color: transparent;
}
::v-deep .el-input.is-disabled .el-input__inner {
  background-color: #EFEFEF;
  border: none;
  color: #C0C4CC;
  cursor: not-allowed;
}
</style>
