<template>
  <div class="alarmStatistics">
    <div class="box-title flex">
      <div>{{ t('pai.detail.vmset.tab.monitor.alarmStatistics') }}</div>
      <div>
        <el-select
          v-model="clustersVal"
          class="mySelect"
          :placeholder="t('pai.detail.vmset.allCluster')"
          filterable
          @change="clusterValChange"
        >
          <el-option
            v-for="(item, index) in kubeClusters"
            :key="index"
            :label="item.nameDisplay"
            :value="item.id"
            :disabled="item.metadata.state.name !== 'active'"
          />
        </el-select>
      </div>
    </div>
    <div
      ref="alarmStatistics"
      style="width: 100%;height: 220px"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import monitorMixins from '../../../../../mixins/monitor';
import metricPoller from '@shell/mixins/metric-poller';

export default {
  name:   'AlarmStatistics',
  mixins: [monitorMixins, metricPoller],
  data() {
    return { clustersVal: '' };
  },
  fetch() {
    // 集群
    this.clusterData();
    this.clustersVal = this.activeCluster[0].id;
  },
  watch: {
    alarmStatisticsData: {
      handler(data) {
        if (data) {
          this.$nextTick(() => {
            this.getAlarmStatistics();
          });
          this.$forceUpdate();
        }
      },
      deep: true,
    },
  },
  methods: {
    getAlarmStatistics() {
      const t = this.$store.getters['i18n/t'];

      if (!this.$refs.alarmStatistics) {
        return;
      }
      const myChart = echarts.init(this.$refs.alarmStatistics);
      let option = {};
      const data = this.alarmStatisticsData;
      const colors = ['#C85352', '#FCCA00', '#4095E5'];
      let errNum = 0;

      data?.forEach((item) => {
        errNum += item.value;
      });
      myChart.clear();
      if (errNum !== 0) {
        option = {
          animation: false,
          tooltip:   { trigger: 'item' },
          legend:    {
            orient: 'vertical',
            top:    '25%',
            left:   '65%',
            icon:   'circle',
            data:   [t('pai.detail.vmset.tab.monitor.emergent'), t('pai.detail.vmset.tab.monitor.warning'), t('pai.detail.vmset.tab.monitor.prompt')],
            formatter(name) {
              let total = 0;
              let target;

              for (let i = 0; i < data.length; i++) {
                total += data[i].value;
                if (data[i].name === name) {
                  target = data[i].value === null ? 0 : data[i].value;
                }
              }
              const arr = [
                `{name|${ name }}`,
                `{target|${ target }}`,
                `{percent|${ ((target / total) * 100).toFixed(2) }%}`
              ];

              return arr.join(' ');
            },
            textStyle: {
              rich: {
                name: {
                  fontSize: 15,
                  width:    40
                },
                target: {
                  fontSize: 15,
                  width:    40,
                },
                percent: {
                  fontSize: 15, width: 40, color: '#c1c1c1'
                }
              }
            }
          },
          color:  colors,
          series: [
            {
              type:      'pie',
              radius:    ['45%', '65%'],
              right:     '35%',
              itemStyle: {
                borderRadius: 10,
                borderWidth:  2,
              },
              emphasis: { labelLine: { show: true } },
              data
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      t('pai.detail.vmset.tab.monitor.noMonitor'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    async clusterValChange() {
      try {
        await this.getAlarmNodes([this.kubeClusters.find((i) => i.id === this.clustersVal)]);
        await this.getResMonitor([this.kubeClusters.find((i) => i.id === this.clustersVal)], 'noMessage');
        this.getStatisticsData();
      } catch (e) {
        console.log(e);
      }
    },
    async loadMetrics() {
      this.clusterValChange();
    }
  }
};
</script>

<style scoped lang="scss">
.alarmStatistics{
  .box-title {
    margin-bottom: 16px;
    margin-top: 6px;
    font-weight: bold;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
}
</style>
