<template>
  <el-dialog
    :title="t('pai.edit.license.updateAuthorization')"
    :visible.sync="dialogVisible"
    :width="isAdmin ? '60%' : '25%'"
    :show-close="false"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
    :class="!isAdmin? 'no-header':'dialog'"
  >
    <div v-if="!licenseErr">
      <div v-if="isAdmin">
        <div class="dialog-main">
          <div class="main-tips">
            <i class="el-icon-warning-outline" />
            {{ t('pai.edit.license.tip') }}
          </div>
          <div class="main-contact">
            <div class="title">
              {{ t('pai.edit.license.contact') }}
            </div>
            <div class="mt-10">
              {{ t('pai.edit.license.contactTel') }}: 400-050-8660转2
            </div>
            <div class="mt-10">
              {{ t('pai.edit.license.contactEmail') }}: sales@tdology.com
            </div>
          </div>
          <div class="main-import">
            <div class="title">
              {{ t('pai.edit.license.authorization') }}{{ t('pai.edit.license.update') }}
            </div>
            <div class="flex">
              <button
                type="button"
                class="btn role-tertiary add mr-20"
                @click="downloadFile"
              >
                {{ t('pai.edit.license.fileDown') }}
              </button>
              <FileSelector
                class="btn role-secondary pull-left"
                :label="t('pai.edit.license.fileImport')"
                :multiple="true"
                :include-file-name="true"
                @selected="onFileSelected"
              />
            </div>
            <div
              class="mt-10"
              @drop="eventDrop"
            >
              <YamlEditor
                ref="yamleditor"
                v-model="currentYaml.value"
                :editor-mode="editorMode"
                class="yaml-editor"
              />
            </div>
            <Banner
              v-for="(err, i) in errors"
              :key="i"
              color="error"
              :label="err"
            />
          </div>
        </div>
        <div
          slot="footer"
          class="dialog-footer"
        >
          <AsyncButton
            v-if="!done"
            mode="import"
            :action-label="t('pai.edit.license.update')"
            :disabled="!currentYaml.value.length"
            @click="importYaml"
          />
        </div>
      </div>
      <div v-if="!isAdmin">
        <i class="el-icon-warning color-red font-40" />
        <div class="title">
          {{ t('pai.edit.license.authorizationExpires') }}
        </div>
        <div class="mt-20 mb-20">
          {{ t('pai.edit.license.contactAdmin') }}
        </div>
      </div>
    </div>
    <div v-else-if="licenseErr === true">
      <i class="el-icon-warning color-red font-40" />
      <div class="title">
        {{ t('pai.edit.license.authorizationErr') }}
      </div>
      <div class="mt-20 mb-20">
        {{ t('pai.edit.license.obtainAuth') }}
      </div>
    </div>
  </el-dialog>
</template>

<script>
import { mapGetters } from 'vuex';
import { Card } from '@components/Card';
import { Banner } from '@components/Banner';
import Loading from '@shell/components/Loading';
import YamlEditor, { EDITOR_MODES } from '@shell/components/YamlEditor';
import FileSelector from '@shell/components/form/FileSelector';
import AsyncButton from '@shell/components/AsyncButton';
import LabeledSelect from '@shell/components/form/LabeledSelect';
import SortableTable from '@shell/components/SortableTable';

import {
  NAME as NAME_COL, STATE, TYPE, NAMESPACE as NAMESPACE_COL, AGE
} from '@shell/config/table-headers';
import { exceptionToErrorsArray } from '@shell/utils/error';
import { PAI_PLATFORM_SETTING_TYPES } from '~/pkg/pai/config/types';
export default {
  name:       'LicenseDialog',
  components: {
    AsyncButton,
    Banner,
    Card,
    Loading,
    YamlEditor,
    FileSelector,
    LabeledSelect,
    SortableTable
  },
  props: {
    licenseDialogVisible: {
      type:    Boolean,
      default: false
    },
    defaultNamespace: {
      type:    String,
      default: 'default'
    },
    isAdmin: {
      type:    Boolean,
      default: false
    },
    licenseErr: {
      type:    Boolean,
      default: false
    },
    goFresh: {
      type:    Function,
      default: null
    }
  },
  data() {
    return {
      dialogVisible: false,
      loading:       false,
      errors:        null,
      rows:          [],
      done:          false,
      yamls:         [],
      currentYaml:   { value: '', type: '' },
    };
  },
  computed: {
    ...mapGetters(['currentCluster']),

    headers() {
      return [
        STATE,
        TYPE,
        NAME_COL,
        NAMESPACE_COL,
        AGE
      ];
    },
    editorMode() {
      return this.currentYaml.type === 'success' ? EDITOR_MODES.VIEW_CODE : EDITOR_MODES.EDIT_CODE;
    }
  },
  watch: {
    licenseDialogVisible(val) {
      this.dialogVisible = val;
    }
  },
  methods: {
    handleClose() {
      this.$emit('close');
    },
    async downloadFile() {
      if (this.$store.getters[`management/schemaFor`](PAI_PLATFORM_SETTING_TYPES.LICENSE)) {
        const licenseData = await this.$store.dispatch('management/findAll', { type: PAI_PLATFORM_SETTING_TYPES.LICENSE });

        licenseData[0]?.download();
      }
    },
    onFileSelected(files) {
      files.forEach((file, i) => {
        let name = file.name;
        let j = 1;

        while (this.yamls.map((v) => v.name).includes(name)) {
          name = `${ files[i].name }(${ j })`;
          j++;
        }
        this.yamls.push({ value: file.value, name });
      });
      this.onSelectedYaml(this.yamls[this.yamls.length - 1]);
    },

    async importYaml(btnCb) {
      let successFileNum = 0;

      try {
        this.errors = [];
        if (!this.yamls.length) {
          const res = await this.currentCluster.doAction('apply', {
            yaml:             this.currentYaml.value,
            defaultNamespace: this.defaultNamespace,
          });

          if (res && res.length > 0) {
            for (let i = 0; i < res.length; i++) {
              const loadResource = await this.$store.dispatch(`cluster/load`, { data: res[i] });

              this.rows.push(loadResource);
            }
          }
        } else {
          for (let i = 0; i < this.yamls.length; i++) {
            if (this.yamls[i].type !== 'success') {
              const res = await this.currentCluster.doAction('apply', {
                yaml:             this.yamls[i].value,
                defaultNamespace: this.defaultNamespace,
              });

              if (res && res.length > 0) {
                for (let j = 0; j < res.length; j++) {
                  const loadResource = await this.$store.dispatch(`cluster/load`, { data: res[j] });

                  this.rows.push(loadResource);
                }
              }
              this.yamls[i].type = 'success';
              successFileNum++;
            }
          }
        }
        if (!this.yamls.length || successFileNum === this.yamls.length) {
          const licenseData = await this.$store.dispatch('pai-common/getLicense');

          if (licenseData?.status?.valid === true) {
            btnCb(true);
            this.done = true;
            this.dialogVisible = false;
            this.$message({
              message: this.t('pai.edit.license.success'),
              type:    'success'
            });
            await this.goFresh();
          } else {
            this.done = false;
            btnCb(false);
            this.$message({
              message: this.t('pai.edit.license.error'),
              type:    'error'
            });
          }
        }
      } catch (err) {
        this.errors = exceptionToErrorsArray(err);
        this.done = false;
        btnCb(false);
      }
    },

    onSelectedYaml(tag) {
      this.currentYaml = tag;
      const component = this.$refs.yamleditor;

      if (component) {
        this.errors = null;
        component.updateValue(tag.value);
      }
    },
    async eventDrop(e) {
      e.stopPropagation();
      e.preventDefault();
      const fileData = Array.from(e.dataTransfer.files || []);

      function getFileContents(file) {
        return new Promise((resolve, reject) => {
          const reader = new FileReader();

          reader.onload = (ev) => {
            const value = ev.target.result;
            const name = file.name;
            const fileContents = { value, name };

            resolve(fileContents);
          };

          reader.onerror = (err) => {
            reject(err);
          };
          reader.readAsText(file);
        });
      }

      try {
        const asyncFileContents = fileData.map(getFileContents);
        const fileContents = await Promise.all(asyncFileContents);

        this.onFileSelected(fileContents);
      } catch (error) {
        if (this.showGrowlError) {
          this.$store.dispatch('growl/fromError', { title: 'Error reading file', error }, { root: true });
        }
      }
    },
  }
};
</script>

<style lang="scss" scoped>
::v-deep .el-dialog__footer {
  border-top: 1px solid #EAECEE;
  margin: 0px 15px;
}
.dialog ::v-deep .el-dialog__body {
  min-height: 600px;
}
.no-header ::v-deep .el-dialog__header {
  display: none;
}
.mt-10{
  margin-top: 10px;
}
.mt-20{
  margin-top: 20px;
}
.mr-20{
  margin-right: 20px;
}
.mb-20{
  margin-bottom: 20px;
}
.flex {
  display: flex;
}
.color-red {
color: #FF1C1C;
}
.font-40{
  font-size: 30px;
}
.title {
  font-size: 20px;
  margin: 10px 0px;
}
.dialog-main {
  text-align: left;
  .main-tips {
    color: #FF1C1C;
  }
  .main-contact,.main-import {
    margin-top: 20px;
  }
}
$min: 22vh;
$max: 22vh;

.yaml-editor {
  flex: 1;
  min-height: $min;
  max-height: $max;

  ::v-deep .code-mirror {
    .CodeMirror {
      position: initial;
    }

    .CodeMirror,
    .CodeMirror-scroll,
    .CodeMirror-gutters {
      min-height: $min;
      max-height: $max;
    }
  }
}
.dialog-footer {
  border-top: 1px solid #EAECEE;
  padding-top: 15px;
  text-align: right;
}
</style>
