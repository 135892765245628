<script>
import Header from '../../components/nav/Header';
import BrowserTabVisibility from '@shell/mixins/browser-tab-visibility';
import PageHeaderActions from '@shell/mixins/page-actions';
import { keyboardToRancher } from '../../utils';
import Brand from '../../mixins/brand';
import GrowlManager from '@shell/components/GrowlManager';
import WindowManager from '@shell/components/nav/WindowManager';
import DraggableZone from '@shell/components/DraggableZone';
import { BOTTOM } from '@shell/utils/position';
import { mapState } from 'vuex';

export default {
  mixins:     [PageHeaderActions, Brand, BrowserTabVisibility],
  middleware: ['authenticated'],
  // Note - These will run on route change
  components: {
    Header, GrowlManager, WindowManager, DraggableZone
  },
  data() {
    return {
      unwatchPin: undefined, wmPin: null, draggable: false
    };
  },
  computed: {
    ...mapState(['managementReady']),
    pinClass() {
      return `pin-${ this.wmPin }`;
    },
  },
  methods: {
    syncNav() {
      const refs = this.$refs.groups;

      if (refs) {
        // Only expand one group - so after the first has been expanded, no more will
        // This prevents the 'More Resources' group being expanded in addition to the normal group
        let canExpand = true;
        const expanded = refs.filter(grp => grp.isExpanded)[0];

        if (expanded.id !== 'root' && expanded && expanded.hasActiveRoute()) {
          this.$nextTick(() => expanded.syncNav());

          return;
        }
        refs.forEach((grp) => {
          if (!grp.group.isRoot) {
            grp.isExpanded = false;
            if (canExpand) {
              const isActive = grp.hasActiveRoute();

              if (isActive) {
                grp.isExpanded = true;
                canExpand = false;
                this.$nextTick(() => grp.syncNav());
              }
            }
          }
        });
      }
    },
  },
  mounted() {
    keyboardToRancher();
    // Sync the navigation tree on fresh load
    this.$nextTick(() => this.syncNav());

    this.wmPin = window.localStorage.getItem('wm-pin') || BOTTOM;

    // two-way binding this.wmPin <-> draggableZone.pin
    this.$refs.draggableZone.pin = this.wmPin;
    this.unwatchPin = this.$watch('$refs.draggableZone.pin', (pin) => {
      this.wmPin = pin;
    });
  },
  unmounted() {
    this.unwatchPin();
  },
};
</script>

<template>
  <div>
    <div class="dashboard-root">
      <div
        class="dashboard-content"
        :class="{[pinClass]: true}"
      >
        <Header v-if="managementReady" />
        <main class="main-layout">
          <nuxt class="outlet" />
        </main>
        <div
          v-if="$refs.draggableZone"
          class="wm"
          :class="{
            'drag-end': !$refs.draggableZone.drag.active,
            'drag-start': $refs.draggableZone.drag.active,
          }"
          :draggable="draggable"
          @dragstart="$refs.draggableZone.onDragStart($event)"
          @dragend="$refs.draggableZone.onDragEnd($event)"
        >
          <WindowManager @draggable="draggable=$event" />
        </div>
      </div>
    </div>
    <GrowlManager />
    <DraggableZone ref="draggableZone" />
  </div>
</template>

<style lang="scss" scoped>
::v-deep .el-header {
  padding: 0;
  display: block;
}

::v-deep .el-main {
  padding: 10px 0 0 0;
  height: calc(100vh - 50px);
  position: relative;
  z-index: 1;
}
HEADER {
  height: 50px;
}

.dashboard-content {
  display: grid;
  position: relative;
  flex: 1 1 auto;
  overflow-y: auto;
  min-height: 0;

  &.pin-right {
    grid-template-areas:
        "header  header  header"
        "main      main     wm";
    grid-template-rows:    var(--header-height) auto;
    grid-template-columns: var(--nav-width)     auto var(--wm-width, 0px);
  }

  &.pin-bottom {
    grid-template-areas:
        "header  header"
        "main       main"
        "wm         wm";
    grid-template-rows:    var(--header-height) auto  var(--wm-height, 0px);
    grid-template-columns: var(--nav-width)     auto;
  }

  &.pin-left {
    grid-template-areas:
        "header  header  header"
        "wm       main     main";
    grid-template-rows:    var(--header-height) auto;
    grid-template-columns: var(--wm-width, 0px) var(--nav-width) auto;
  }

  > HEADER {
    grid-area: header;
    height: 50px;
  }

  NAV {
    grid-area: nav;
    position: relative;
    background-color: var(--nav-bg);
    border-right: var(--nav-border-size) solid var(--nav-border);
    overflow-y: auto;

    H6, .root.child .label {
      margin: 0;
      letter-spacing: normal;
      line-height: initial;

      A { padding-left: 0; }
    }
  }

  NAV .tools {
    display: flex;
    margin: 10px;
    text-align: center;

    A {
      align-items: center;
      border: 1px solid var(--border);
      border-radius: 5px;
      color: var(--body-text);
      display: flex;
      justify-content: center;
      outline: 0;
      flex: 1;
      padding: 10px;

      &:hover {
        background: var(--nav-hover);
        text-decoration: none;
      }

      > I {
        margin-right: 4px;
      }
    }

    &.nuxt-link-active:not(:hover) {
      A {
        background-color: var(--nav-active);
      }
    }
  }

  NAV .version {
    cursor: default;
    margin: 0 10px 10px 10px;
  }

  NAV .footer {
    margin: 20px;

    display: flex;
    flex: 0;
    flex-direction: row;
    > * {
      flex: 1;
      color: var(--link);

      &:last-child {
        text-align: right;
      }

      &:first-child {
        text-align: left;
      }

      text-align: center;
    }

    .version {
      cursor: default;
      margin: 0px;
    }

    .locale-chooser {
      cursor: pointer;
    }
  }
}
</style>
