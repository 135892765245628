<script>
import UnitInput from '@/shell/components/form/UnitInput';
import { MANAGEMENT, NAMESPACE, STORAGE_CLASS } from '@shell/config/types';
import { PRODUCT_NAME as PAI } from '../config/pai';
import { PAI_RESOURCES } from '../config/types';
import { COMMAND_HASH_MPA, IMAGE as IMAGE_PARAM } from '../config/settings';
import { IMAGE } from '~/pkg/pai/config/labels-annotations';
import { convertUnitToG, convertCpuToCore } from '@/pkg/pai/utils/units';

export default {
  name:       'VmQuickForm',
  components: { UnitInput },
  props:      {
    onClose: { type: Function, default: null },
    vmId:    {
      type:     String,
      required: true,
    },
    image: {
      type:     Object,
      required: true,
    },
  },
  async fetch() {
    if (this.image.spec.source_parameters.global === 'true' || !this.image.spec.share_to) {
      this.namespaceOptions = await this.$store.getters[`cluster/all`](NAMESPACE).map((item) => {
        return item.metadata.name;
      });
    } else {
      this.namespaceOptions = this.image.spec.share_to;
    }
    this.form.namespace = this.namespaceOptions.find((item) => {
      return item === 'default';
    }) || this.namespaceOptions[0];

    this.storageClasses = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
    // 数据盘存储类
    // 筛选非系统镜像(数据盘是除了所有的vmimage创建的sc之外的，都可以选择)
    this.storageClassOptions = this.storageClasses.filter((v) => !(v.annotations && v.annotations[IMAGE.NAME]))
      .map((s) => {
        return {
          label: s.alias ? s.alias : s.name,
          value: s.name,
        };
      }).sort();
    this.form.dataDiskName = this.storageClassOptions?.length > 0 ? this.storageClassOptions[0].value : '';
  },
  data() {
    const time = new Date().valueOf();
    const desc = this.image?.spec?.desc ? this.image.spec.desc : this.t('pai.apps.vm.nodesc');
    const showName = this.image?.spec?.alias ? this.image.spec.alias : this.image?.metadata?.name;
    const isoImage = this.image?.metadata?.labels[IMAGE.ISO];

    return {
      dialogVisible: true,
      form:          {
        name:         `vm${ time }`,
        alias:        '',
        namespace:    '',
        diskSize:     this.image.spec.size === 'undefinedGi' ? 0 : `${ parseInt(this.image.spec.size) }Gi`,
        diskName:     this.vmId.replace('/', '-'),
        dataDiskSize: '',
        dataDiskName: '',
        cpu:          '1',
        ram:          '2Gi'
      },
      namespaceOptions:    [],
      allImages:           [],
      storageClasses:      [],
      storageClassOptions: [],
      desc,
      showName,
      isoImage,
      qosRadio:            'bestEffort'
    };
  },
  computed: {
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
  },
  watch: {
    'form.diskSize': {
      deep: true,
      handler() {
        if (this.form.diskSize && parseInt(this.form.diskSize) < parseInt(this.image.spec.size)) {
          this.form.diskSize = `${ parseInt(this.image.spec.size) }Gi`;
        }
      }
    },
    'form.dataDiskSize': {
      deep: true,
      handler() {
        if (this.form.dataDiskSize && parseInt(this.form.dataDiskSize) < 1) {
          this.form.dataDiskSize = `${ 1 }Gi`;
        }
      }
    },
    'form.cpu': {
      deep: true,
      handler() {
        if (this.form.cpu && parseInt(this.form.cpu) < 1) {
          this.form.cpu = 1;
        }
      }
    },
    'form.ram': {
      deep: true,
      handler() {
        if (this.form.ram && parseInt(this.form.ram) < 1) {
          this.form.ram = `${ 1 }Gi`;
        }
      }
    }
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    createLocation() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          cluster:  this.currentCluster,
          product:  PAI,
          resource: PAI_RESOURCES.VMSET,
        },
        query: {
          boot:      this.isoImage === 'true' ? 'cdrom' : 'disk',
          image:     this.form.diskName,
          vmAlias:   this.form.alias,
          namespace: this.form.namespace,
          cpu:       this.form.cpu,
          memory:    this.form.ram,
          qosType:   this.qosRadio,
          diskSize:  this.form.diskSize,
        }
      });
    },
    async handleCreate() {
      // 快速创建云主机
      if (!this.form.alias || !this.form.namespace || !this.form.diskSize) {
        this.$message.error(this.t('pai.edit.tips.required'));

        return;
      }
      const iso = {
        volumeClaimTemplates: [
          {
            metadata: {
              name:   'vmoscdrom',
              labels: { [IMAGE.ISO]: 'true' }
            },
            spec: {
              resources:        { requests: { storage: this.form.diskSize } },
              storageClassName: `${ this.image.metadata.namespace }-${ this.image.metadata.name }`,
            }
          },
          // {
          //   metadata: { name: 'vmosdisk' },
          //   spec:     { resources: { requests: { storage: this.form.diskSize } }, storageClassName: this.image?.spec?.driver === IMAGE_PARAM.DRIVER[0] ? 'longhorn' : 'local-path' }
          // },
          {
            metadata: { name: 'datadisk' },
            spec:     { resources: { requests: { storage: this.form.dataDiskSize } }, storageClassName: this.form.dataDiskName }
          },
        ]
      };
      const osVmDisk = {
        volumeClaimTemplates: [
          {
            metadata: { name: 'vmosdisk' },
            spec:     { resources: { requests: { storage: this.form.diskSize } }, storageClassName: this.form.diskName }
          }
        ]
      };
      let resource = {};

      if (this.qosRadio === 'bestEffort') {
        resource = {
          limits: {
            cpu:    this.form.cpu,
            memory: this.form.ram,
          },
        };
      } else {
        resource = {
          requests: {
            cpu:    this.form.cpu,
            memory: this.form.ram,
          },
        };
      }
      const vm = await this.$store.dispatch(`cluster/create`, {
        type:     PAI_RESOURCES.VMSET,
        metadata: {
          namespace:   this.form.namespace,
          name:        this.form.name,
          annotations: {
            'com.tdology/alias': this.form.alias,
            qosType:             this.qosRadio
          }
        },
        spec: {
          volumeClaimTemplates: this.isoImage === 'true' ? iso.volumeClaimTemplates : osVmDisk.volumeClaimTemplates,
          boot:                 this.isoImage === 'true' ? 'cdrom' : 'disk',
          template:             {
            spec: {
              containers: [{
                name:            'os',
                image:           'rancher/mirrored-pause:3.6',
                imagePullPolicy: 'IfNotPresent',
                resources:       resource,
                ports:           [{
                  containerPort: 9100,
                  name:          'metrics',
                  protocol:      'TCP',
                }],
              }]
            }
          },
          power:    'On',
          replicas: 1,
        }
      });

      await vm.save();
      this.dialogVisible = false;
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-namespace-id`,
        params: {
          product:   PAI,
          cluster:   this.currentCluster,
          resource:  PAI_RESOURCES.VMSET,
          namespace: this.form.namespace,
          id:        this.form.name,
        },
        hash: COMMAND_HASH_MPA['log'],
      });
    },
  }
};
</script>

<template>
  <div class="vmForm">
    <el-dialog
      :title="t('pai.apps.vm.quickInstallation')+`：${showName}`"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-descriptions
        :column="1"
        :colon="false"
      >
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.nameSpace') }}
          </template>
          <el-select
            v-model="form.namespace"
            filterable
            style="width: 364px;"
          >
            <el-option
              v-for="(item) in namespaceOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.vm')+t('pai.edit.machine.name') }}
          </template>
          <el-input
            v-model="form.alias"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item v-if="isoImage === 'false'">
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.system.size') }}
          </template>
          <UnitInput
            v-model="form.diskSize"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :mode="'edit'"
            :required="true"
            :min="1"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item v-if="isoImage === 'true'">
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.diskManagement.storageClass') }}
          </template>
          <el-select
            v-model="form.dataDiskName"
            filterable
            style="width: 364px;"
          >
            <el-option
              v-for="(item) in storageClassOptions"
              :key="item.value"
              :label="item.label"
              :value="item.value"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item v-if="isoImage === 'true'">
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.system.dataDiskSize') }}
          </template>
          <UnitInput
            v-model="form.dataDiskSize"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :mode="'edit'"
            :required="true"
            :min="1"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            QoS
          </template>
          <el-radio-group v-model="qosRadio">
            <el-radio
              v-model="qosRadio"
              label="bestEffort"
            >
              {{ t('pai.vmset.lock.share')+'['+t('pai.vmset.lock.bestEffort')+']' }}
            </el-radio>
            <el-radio
              v-model="qosRadio"
              label="guaranteed"
            >
              {{ t('pai.vmset.lock.exclusive')+'['+t('pai.vmset.lock.guaranteed')+']' }}
            </el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>CPU
          </template>
          <UnitInput
            v-model="form.cpu"
            :mode="'edit'"
            :output-modifier="true"
            :base-unit="t('suffix.cores')"
            style="width: 364px;"
          />
        </el-descriptions-item><el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span> {{ t('pai.vmset.ram') }}
          </template>
          <UnitInput
            v-model="form.ram"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :mode="'edit'"
            :required="true"
            :min="1"
            style="width: 364px;"
          />
        </el-descriptions-item>
      </el-descriptions>
      <div class="image-des">
        <p>{{ t('pai.vmset.image.selectedInfo') }}：</p>
        <p>{{ t('pai.vmset.image.hardwareArchitecture') }}：{{ image.spec.arch }}</p>
        <p>{{ t('pai.vmset.image.description') }}：{{ desc }}</p>
      </div>
      <a
        style="display: block; margin: 2px 24px 0 0; color: #246FA5; float: right; cursor: pointer"
        @click="createLocation"
      >
        {{ t('pai.apps.vm.enterAdvancedCreation') }}
      </a>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="handleCreate"
        >{{ t('pai.detail.vmset.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.vmForm{
  ::v-deep .el-form-item__label {
    margin-top: 0px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0px;
  }
  ::v-deep .el-descriptions-item__label{
    width: 105px;
  }
  ::v-deep .el-descriptions__body .el-descriptions__table{
    width: unset;
  }
  ::v-deep .el-dialog__body, .theme-light .el-dialog__body{
    overflow-y: unset;
  }
  span{
    text-align: left;
    line-height: 36px;
  }
  .image-des{
    width: 364px;
    height: 91px;
    margin: 8px 0 17px 115px;
    border-radius: 3px;
    text-align: left;
    background: rgb(250, 250, 250);
    border: 1px dashed rgb(194, 194, 194);
    p{
      margin-top: 10px;
      margin-left: 10px;
    }
  }
}
</style>
