<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import UnitInput from '@shell/components/form/UnitInput';
export default {
  components: {
    LabeledInput, LabeledSelect, SectionTitle, UnitInput
  },
  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    return {
      storageOptions: [{
        label: this.t('pai.detail.vmset.localStorage'),
        value: 'local-path'
      }, {
        label: this.t('pai.detail.vmset.distStorage'),
        value: 'longhorn'
      }]
    };
  },
  created() {
    this.$emit('register-before-hook', this.willSave, 'willSave');
  },
  methods: {
    willSave() {
      const errors = [];

      if (!this.value.host) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.largeScreen.host') }` }, true));
      }
      if (!this.value.images.largeScreenManage) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.largeScreen.images') }largeScreenManage` }, true));
      }
      if (!this.value.images.minio) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.largeScreen.images') }minio` }, true));
      }
      if (!this.value.images.mysql) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.largeScreen.images') }mysql` }, true));
      }
      if (!this.value.images.nginx) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.largeScreen.images') }nginx` }, true));
      }
      if (!this.value.images.redis) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.largeScreen.images') }redis` }, true));
      }

      if (errors.length > 0) {
        return Promise.reject(errors);
      } else {
        return Promise.resolve();
      }
    },
  }
};
</script>

<template>
  <div style="margin-bottom: 20px">
    <div class="row mt-10">
      <div class="col span-5">
        <LabeledInput
          v-model="value.host"
          :label="t('catalog.charts.largeScreen.host')"
          :required="true"
        />
      </div>
    </div>
    <!--  PVC 配置  -->
    <div class="row mt-20">
      <SectionTitle :value="t('catalog.charts.largeScreen.pvc')" />
    </div>
    <div class="row mt-20">
      <div class="col span-5">
        <LabeledSelect
          v-model="value.pvc.confPvc.storageClass"
          :label="t('catalog.charts.largeScreen.confPvc')+t('catalog.charts.largeScreen.storage')"
          :options="storageOptions"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <UnitInput
          v-model="value.pvc.confPvc.storageRequest"
          :label="t('catalog.charts.largeScreen.confPvc')+t('catalog.charts.largeScreen.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-5">
        <LabeledSelect
          v-model="value.pvc.dataPvc.storageClass"
          :label="t('catalog.charts.largeScreen.dataPvc')+t('catalog.charts.largeScreen.storage')"
          :options="storageOptions"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <UnitInput
          v-model="value.pvc.dataPvc.storageRequest"
          :label="t('catalog.charts.largeScreen.dataPvc')+t('catalog.charts.largeScreen.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-5">
        <LabeledSelect
          v-model="value.pvc.minioDataPvc.storageClass"
          :label="'minioDataPvc '+t('catalog.charts.largeScreen.storage')"
          :options="storageOptions"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <UnitInput
          v-model="value.pvc.minioDataPvc.storageRequest"
          :label="'minioDataPvc '+t('catalog.charts.largeScreen.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          :required="true"
        />
      </div>
    </div>

    <!--镜像配置-->
    <div class="row mt-10">
      <SectionTitle :value="t('catalog.charts.largeScreen.images')" />
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.largeScreenManage"
          label="largeScreenManage"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.minio"
          label="minio"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.mysql"
          label="mysql"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.nginx"
          label="nginx"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.redis"
          label="redis"
          :required="true"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>
