<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import UnitInput from '@shell/components/form/UnitInput';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import { get, set } from '@shell/utils/object';
import { v1 } from 'uuid';
import { randomStr } from '@shell/utils/string';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import { RadioGroup } from '@components/Form/Radio';

const DEFAULT_HOST = 'wukong.tdology.com';

export default {
  components: {
    LabeledSelect,
    LabeledInput,
    UnitInput,
    SectionTitle,
    RadioGroup,
  },

  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    if (!this.value.isInitial) {
      this.setHost(DEFAULT_HOST);
      this.setCiHost(this.$route.query.version === '2.0.0' ? 'wukongci.tdology.com' : '/ci');
      this.setClient(v1());
      this.setSecret(`gto_${ randomStr(32).toLowerCase() }`);
      this.setWoodpeckerSecret(randomStr(32).toLowerCase());
      this.setStorage('local-path');
      this.setSize('10Gi');
      set(this.value, 'isInitial', true);
      this.setEnabled(false);
      this.setClusterDomain('cluster.local');
      this.setInsecureRegistry(DEFAULT_HOST);
    }

    return {
      version:        this.$route.query.version,
      storageOptions: [{
        label: this.t('pai.detail.vmset.localStorage'),
        value: 'local-path'
      }, {
        label: this.t('pai.detail.vmset.distStorage'),
        value: 'longhorn'
      }]
    };
  },
  computed: {
    mailerEnabled() {
      return this.value.gitea.gitea.config.mailer.ENABLED;
    }
  },
  methods: {
    // 配置悟空源码管理域名
    setHost(e) {
      this.value.gitea.ingress.hosts[0].host = e;
      this.value.gitea.ingress.tls[0].hosts[0] = e;
      set(this.value, 'woodpecker.server.env.WOODPECKER_GITEA_URL', `https://${ e }`);
      if (this.$route.query.version !== '2.0.0') {
        const envs = get(this.value, 'gitea.deployment.env');
        const envIndex = envs.map((v) => v.name).indexOf('WUKONGCIHOST');

        this.value.gitea.deployment.env[envIndex].value = e + this.value['ci-root-path'];
        set(this.value, 'ci-host', e + this.value['ci-root-path']);
        set(this.value, 'wukong-url', `https://${ e }`);
        set(this.value, 'woodpecker.server.env.WOODPECKER_HOST', `https://${ e }`);
        set(this.value, 'gitea.config.webhook.ALLOWED_HOST_LIST', e);
        set(this.value, 'host', e);
        set(this.value, 'woodpecker.server.host', e);
        this.value.woodpecker.server.ingress.hosts[0].host = e;
        this.value.woodpecker.server.ingress.tls[0].hosts[0] = e;
      } else {
        set(this.value, 'woodpecker.agent.dind.insecureRegistry', e);
      }
    },
    // 配置悟空编译域名
    setCiHost(e) {
      if (this.$route.query.version === '2.0.0') {
        const envs = get(this.value, 'gitea.statefulset.env');
        const envIndex = envs.map((v) => v.name).indexOf('WUKONGCIHOST');

        this.value.gitea.statefulset.env[envIndex].value = e;
        set(this.value, 'gitea.gitea.config.webhook.ALLOWED_HOST_LIST', e);
        set(this.value, 'woodpecker.server.env.WOODPECKER_HOST', `https://${ e }`);
        this.value.woodpecker.server.ingress.hosts[0].host = e;
        this.value.woodpecker.server.ingress.tls[0].hosts[0] = e;
      } else {
        const envs = get(this.value, 'gitea.deployment.env');
        const envIndex = envs.map((v) => v.name).indexOf('WUKONGCIHOST');

        this.value.gitea.deployment.env[envIndex].value = this.value.gitea.ingress.hosts[0].host + e;
        set(this.value, 'ci-host', this.value.gitea.ingress.hosts[0].host + e);
        set(this.value, 'woodpecker.server.env.WOODPECKER_ROOT_PATH', e);
        set(this.value, 'woodpecker.server.subPath', e);
        this.value.woodpecker.server.ingress.hosts[0].paths[0].path = e;
      }
    },

    // clusterDomain
    setClusterDomain(e) {
      if (this.$route.query.version !== '2.0.0') {
        set(this.value, 'clusterDomain', e);
        set(this.value, 'woodpecker.agent.clusterDomain', e);
      }
    },
    // WUKONGCLIENT
    setClient(e) {
      if (this.$route.query.version === '2.0.0') {
        const envs = get(this.value, 'gitea.statefulset.env');
        const envIndex = envs.map((v) => v.name).indexOf('WUKONGCLIENT');

        this.value.gitea.statefulset.env[envIndex].value = e;
      }

      set(this.value, 'secrets.woodpecker-gitea-client', e);
    },
    // WUKONGSECRET
    setSecret(e) {
      if (this.$route.query.version === '2.0.0') {
        const envs = get(this.value, 'gitea.statefulset.env');
        const envIndex = envs.map((v) => v.name).indexOf('WUKONGSECRET');

        this.value.gitea.statefulset.env[envIndex].value = e;
      }

      set(this.value, 'secrets.woodpecker-gitea-secret', e);
    },
    // woodpecker-secret
    setWoodpeckerSecret(e) {
      set(this.value, 'secrets.woodpecker-secret', e);
    },
    // 存储方式
    setStorage(e) {
      set(this.value, 'gitea.global.storageClass', e);
      set(this.value, 'gitea.mariadb.primary.persistence.storageClass', e);
      set(this.value, 'woodpecker.server.persistentVolume.storageClass', e);
      set(this.value, 'woodpecker.agent.env.WOODPECKER_BACKEND_K8S_STORAGE_CLASS', e);
      if (this.$route.query.version !== '2.0.0') {
        set(this.value, 'gitea.persistence.storageClass', e);
        set(this.value, 'storageClass', e);
      }
    },
    // 存储容量
    setSize(e) {
      set(this.value, 'gitea.mariadb.primary.persistence.size', e);
      set(this.value, 'woodpecker.server.persistentVolume.size', e);
    },
    setEnabled(e) {
      set(this.value, 'gitea.gitea.config.mailer.IS_TLS_ENABLED', e);
    },
    setInsecureRegistry(e) {
      set(this.value, 'woodpecker.agent.dind.insecureRegistry', e.split(','));
    }
  },
};
</script>

<template>
  <div style="margin-bottom: 10px">
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.gitea.ingress.hosts[0].host"
          :label="t('catalog.charts.wukong.host')"
          :required="true"
          @input="setHost"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-if="version === '2.0.0'"
          v-model="value.gitea.gitea.config.webhook.ALLOWED_HOST_LIST"
          :label="t('catalog.charts.wukong.ci')"
          :required="true"
          @input="setCiHost"
        />
        <LabeledInput
          v-else
          v-model="value['ci-root-path']"
          :label="t('catalog.charts.wukong.subPath')"
          :required="true"
          @input="setCiHost"
        />
      </div>
    </div>

    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.secrets['woodpecker-gitea-client']"
          label="WUKONGCLIENT"
          :required="true"
          @input="setClient"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.secrets['woodpecker-gitea-secret']"
          label="WUKONGSECRET"
          :required="true"
          @input="setSecret"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.gitea.gitea.admin.username"
          :label="t('catalog.charts.wukong.username')"
          :required="true"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.gitea.gitea.admin.password"
          :label="t('catalog.charts.wukong.password')"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledSelect
          v-model="value.gitea.global.storageClass"
          :label="t('catalog.charts.wukong.storage')"
          :options="storageOptions"
          @input="setStorage"
        />
      </div>
      <div class="col span-6">
        <UnitInput
          v-model="value.gitea.mariadb.primary.persistence.size"
          :label="t('catalog.charts.wukong.size')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          @input="setSize"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.secrets['woodpecker-secret']"
          label="woodpecker-secret"
          :required="true"
        />
      </div>
      <div class="col span-6">
        <LabeledInput
          v-model="value.gitea.clusterDomain"
          label="clusterDomain"
          @input="setClusterDomain"
        />
      </div>
    </div>
    <div
      v-if="version !== '2.0.0'"
      class="row mt-10"
    >
      <div class="col span-6">
        <LabeledInput
          v-model="value.woodpecker.agent.env['WOODPECKER_MAX_WORKFLOWS']"
          :label="t('catalog.charts.wukong.pipelines')"
          :required="true"
        />
      </div>
      <div class="col span-6">
        <span style="position: relative;top: 12px;left: 150px;z-index: 1;">
          <el-tooltip
            :content="t('catalog.charts.wukong.insecureRegistryTips')"
            effect="light"
          >
            <i class="icon icon-info" />
          </el-tooltip>
        </span>
        <LabeledInput
          v-model="value.woodpecker.agent.dind.insecureRegistry"
          style="position: relative;top: -17px;"
          label="insecure-registry list"
          :required="true"
          @input="setInsecureRegistry"
        />
      </div>
    </div>

    <div class="row mt-10">
      <SectionTitle :value="t('catalog.charts.wukong.email.label')" />
    </div>
    <div class="row mt-10">
      <RadioGroup
        v-model="value.gitea.gitea.config.mailer.ENABLED"
        name="enabled"
        :label="t('catalog.charts.wukong.email.enabled')"
        :options="[{label:t('generic.yes'),value:true},{label:t('generic.no'),value:false}]"
        :row="true"
      />
    </div>
    <template v-if="mailerEnabled">
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.gitea.gitea.config.mailer.FROM"
            :label="t('catalog.charts.wukong.email.from')"
            :required="true"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-if="version === '2.0.0'"
            v-model="value.gitea.gitea.config.mailer.MAILER_TYPE"
            :label="t('catalog.charts.wukong.email.type')"
            :required="true"
          />
          <LabeledInput
            v-else
            v-model="value.gitea.gitea.config.mailer.PROTOCOL"
            :label="t('catalog.charts.wukong.email.type')"
            :required="true"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.gitea.gitea.config.mailer.USER"
            :label="t('catalog.charts.wukong.email.user')"
            :required="true"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model="value.gitea.gitea.config.mailer.PASSWD"
            :label="t('catalog.charts.wukong.email.pwd')"
            :required="true"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col span-6">
          <LabeledInput
            v-model="value.gitea.gitea.config.mailer.SMTP_ADDR"
            :label="t('catalog.charts.wukong.email.smtp_addr')"
            :required="true"
          />
        </div>
        <div class="col span-6">
          <LabeledInput
            v-model.number="value.gitea.gitea.config.mailer.SMTP_PORT"
            :label="t('catalog.charts.wukong.email.smtp_port')"
            :required="true"
            type="number"
          />
        </div>
      </div>
      <div class="row mt-10">
        <div class="col span-6">
          <RadioGroup
            v-model="value.gitea.gitea.config.mailer.IS_TLS_ENABLED"
            name="smtp_enabled"
            class="smtp_enabled"
            :label="t('catalog.charts.wukong.email.smtp_enabled')"
            :options="[{label:t('generic.yes'),value:true},{label:t('generic.no'),value:false}]"
            :row="true"
            @input="setEnabled"
          />
        </div>
      </div>
    </template>
  </div>
</template>
<style scoped lang="scss">
.smtp_enabled {
  ::v-deep .radio-group h3 {
    font-size: 14px;
  }
}
</style>
