<script>
const validRoute = (route, router) => {
  return !!route && !!router.resolve(route)?.resolved?.matched?.length;
};

export default {
  middleware({ redirect, store, app } ) {
    const dashboardHome = { name: 'pai-home' };
    const afterLoginRouteObject = store.getters['prefs/afterLoginRoute'];
    const targetRoute = app.router.resolve(afterLoginRouteObject);

    // If target route is /, then we will loop with endless redirect - so detect that here and
    // redirect to /home, which is what we would do below, if there was no `afterLoginRouteObject`
    if (targetRoute?.route?.fullPath === '/pai') {
      return redirect(dashboardHome);
    }
    // Confirm this is a valid route (it could have come from an uninstalled plugin)
    if (validRoute(afterLoginRouteObject, app.router)) {
      // Take the user to the configured login route
      return redirect(afterLoginRouteObject);
    }

    return redirect(dashboardHome);
  }
};
</script>
