import SteveModel from '@shell/plugins/steve/steve-class';
import PlatformLocationMixin from '~/pkg/pai/mixins/platformLocation';
import { parseSi } from '@shell/utils/units';
export default class extends PlatformLocationMixin(SteveModel) {
  get realtime() {
    return (this.spec && this.spec.realtime) ? this.spec.realtime : 'false';
  }

  get ramUsagePercentage() {
    return (this.memoryUsage / this.memoryCapacity*100).toString();
  }

  get cpuUsagePercentage() {
    return (this.cpuUsage / this.cpuCapacity*100).toString();
  }

  get cpuCapacity() {
    return parseSi(this?.limits?.cpu || '0');
  }

  get memoryCapacity() {
    return parseSi(this?.limits?.memory || '0');
  }

  get cpuUsage() {
    return parseSi(this?.used?.cpu || '0');
  }

  get memoryUsage() {
    return parseSi(this?.used?.memory || '0');
  }

  get used() {
    return this?.status?.used;
  }

  get limits() {
    return this?.spec?.limits;
  }

  get availableActions() {
    let out = super._availableActions;
    const toFilter = ['goToEdit', 'promptRemove', 'goToEditYaml'];

    out = out.filter((action) => {
      if (!toFilter.includes(action.action)) {
        return action;
      }
    });

    return [{
      action: 'goToViewYaml',
      label:  this.t( 'action.viewYaml'),
      icon:   'icon icon-file',
    },
    {
      action: 'goToViewConfig',
      label:  this.t('action.view'),
      icon:   'icon icon-edit',
    },
    ...out];
  }
}
