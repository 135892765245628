<script>
import ContainerCloudDesktopInstall from '../../../../components/ContainerCloudDesktopInstall.vue';
import { CATALOG, MANAGEMENT, POD, PVC } from '@shell/config/types';
import { NAMESPACE_FILTERS } from '@shell/store/prefs';
import svgIcon from '~/pkg/pai/assets/images/cloudDesktop/svgIcon';
import Loading from '@shell/components/Loading.vue';
import { PAI_CATALOG } from '~/pkg/pai/config/types';
import { mapGetters } from 'vuex';

export default {
  layout:     'pai/default',
  components: { ContainerCloudDesktopInstall, Loading },
  async fetch() {
    await this.$store.dispatch('pai-common/getCloudDesktop');
    await this.$store.dispatch('cluster/findAll', { type: PVC });
    await this.$store.dispatch('cluster/findAll', { type: POD });
    const isRancher = await this.$store.getters['isRancher'];

    if (isRancher) {
      await this.$store.dispatch('cluster/findAll', { type: CATALOG.APP });
    } else {
      await this.$store.dispatch('cluster/findAll', { type: PAI_CATALOG.HELM_CHART });
    }
  },
  data() {
    return {
      desktopRepoPath:           '',
      filterStoreValue:          '',
      filterInstalledValue:      '',
      currentApp:                {},
      deleteAppVisible:          false,
      cloudDesktopListInstalled: [],
      loading:                   true,
      loadingRustDesk:           false,
    };
  },
  computed: {
    ...mapGetters(['isRancher']),
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    podList() {
      return this.$store.getters['cluster/all'](POD).filter((pod) => pod?.isXApp && pod.status?.phase !== 'Terminating' && !pod.metadata.deletionTimestamp);
    },
    allPvc() {
      return this.$store.getters['cluster/all'](PVC).filter((pod) => pod?.isXApp);
    },
    cloudDesktopList() {
      const arr = [];

      try {
        const data = this.$store.state['pai-common'].cloudDesktopList.filter((v) => v?.config && v?.config?.Labels && JSON.parse(v?.config?.Labels).name );

        for (const key in data) {
          const configLabels = JSON.parse(data[key]?.config?.Labels);

          arr.push({
            id:          configLabels?.name,
            desktopName: configLabels?.name,
            repoPath:    data[key]['repo_path'],
            tag:         data[key].tag,
            devShm:      !!configLabels?.shm,
            icon:        svgIcon[configLabels?.name] ? svgIcon[configLabels?.name] : svgIcon['default']
          });
        }

        return arr;
      } catch (e) {
        console.log(e);

        return [];
      }
    },
    containerQuickFormVisible() {
      return this.$store.state['pai-common'].currentModal === 'ContainerCloudDesktopInstall';
    },
    filters() {
      // 用户选择的项目/命名空间
      if (this.currentCluster && this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster]) {
        if (this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster].join(',')?.includes('all://')) {
          return [];
        } else {
          return this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster];
        }
      } else {
        return [];
      }
    },
    filterProjects() {
      return this.filters.filter((v) => v?.includes('project://')).map((v) => v.split('//')[1]);
    },
    filterNamespaces() {
      return this.filters.filter((v) => v?.includes('ns://')).map((v) => v.split('//')[1]);
    },
    installedCloudDeskApp() {
      // 是否安装了云桌面应用(clouddesk)
      if (this.isRancher) {
        return !!this.$store.getters['cluster/all'](CATALOG.APP).find((i) => i.name === 'clouddesk');
      } else {
        return !!this.$store.getters['cluster/all'](PAI_CATALOG.HELM_CHART).find((i) => i.name === 'clouddesk');
      }
    },
    isSharedStorage() {
      // 是否安装了存储(paistore)
      return !!this.$store.getters['cluster/all'](CATALOG.APP).find((i) => i.name === 'paistores');
    },
  },
  watch: {
    podList: {
      handler(nue) {
        this.initCloudDesktopListInstalled(nue);
      },
      deep:      true,
      immediate: true,
    }
  },
  methods: {
    initCloudDesktopListInstalled(podList) {
      for (const item of podList) {
        const find = this.cloudDesktopListInstalled?.find((installed) => installed.alias === item.alias);

        if (find) {
          find.phase = item?.status.phase;
        } else {
          const cloudDeskApp = {
            id:          item.metadata.name,
            desktopName: item.metadata.name,
            name:        item.metadata.name,
            markRemove:  false,
            alias:       item.metadata.annotations['com.tdology/alias'],
            namespace:   item.metadata.namespace,
            phase:       item.status.phase,
            icon:        svgIcon['default'],
            createTime:  item.metadata?.creationTimestamp
          };

          const cloudDeskImage = this.cloudDesktopList.find((cloudDesk) => {
            const container = item.spec?.containers[0];

            return container && container.name === cloudDesk.desktopName && container.image === `${ cloudDesk.repoPath }:${ cloudDesk.tag }`;
          });

          if (cloudDeskImage) {
            cloudDeskApp.desktopName = cloudDeskImage.desktopName;
            cloudDeskApp.tag = `${ cloudDeskImage.repoPath }:${ cloudDeskImage.tag }`;
            if (svgIcon[cloudDeskImage.desktopName]) {
              cloudDeskApp.icon = svgIcon[cloudDeskImage.desktopName];
            }
          }
          this.cloudDesktopListInstalled.push(cloudDeskApp);
        }
      }
      this.cloudDesktopListInstalled = this.cloudDesktopListInstalled.sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime());
    },
    showDesktopInstall(item) {
      this.currentApp = item;
      this.$store.dispatch('pai-common/updateState', { currentModal: 'ContainerCloudDesktopInstall' });
    },
    handelCloseCreate() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    showDelete() {
      if (this.deleteAppVisible === true) {
        this.deleteAppVisible = false;
      } else {
        this.deleteAppVisible = true;
      }
    },
    handleDeleteApp(value) {
      this.$confirm(this.t('pai.detail.vmset.desktopDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(async() => {
        const removePod = this.podList?.find((i) => i.metadata.annotations && i.metadata.annotations['com.tdology/alias'] === value.alias);

        this.cloudDesktopListInstalled.forEach((d) => {
          if (removePod?.metadata.annotations['com.tdology/alias'] === d.alias) {
            d.markRemove = true;
          }
        });
        await removePod.remove();
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteSuccess')
        });
        this.deleteAppVisible = false;
      }).catch((error) => {
        console.error(error);
        this.deleteAppVisible = false;
      });
    },
    async linkToDesk(xapp) {
      const pod = this.podList.find((pod) => pod.namespace === xapp.namespace && pod.name === xapp.name);

      if (!pod) {
        this.$message({
          type:    'error',
          message: this.t('pai.clouddesktop.noPod')
        });

        return;
      }
      this.loadingRustDesk = true;
      try {
        await pod.openXApp(this.currentCluster, () => {
          this.loadingRustDesk = false;
        });
      } catch (e) {
        console.error(e);
        this.loadingRustDesk = false;
      }
    }
  }
};
</script>

<template>
  <div class="cloud">
    <Loading
      v-if="loadingRustDesk"
      mode="main"
    />
    <div class="installedApp">
      <div class="top">
        <span>{{ t('pai.apps.container.install') }}</span>
        <el-input
          v-model="filterInstalledValue"
          size="mini"
          prefix-icon="el-icon-search"
          :placeholder="t('pai.detail.vmset.filter')"
          style="width: 194px"
        />
      </div>
      <div
        class="content"
        style="position: relative"
      >
        <div
          v-for="i in cloudDesktopListInstalled.filter(v=>(!filters.length || filterNamespaces?.includes(v.namespace)) && (v.alias?.includes(filterInstalledValue)))"
          :key="i.id"
        >
          <img
            v-if="deleteAppVisible && i.markRemove === false && ( i.markRemove === false && i.phase==='Running' )"
            src="../../../../assets/images/cloudDesktop/editDelete.svg"
            class="editDelete"
            @click="handleDeleteApp(i)"
          >

          <div
            :class="[i.markRemove === false && i.phase==='Running' ? 'contentItem' : 'contentItemDisable']"
            @click="linkToDesk(i)"
          >
            <div
              v-if="i.markRemove === false && i.phase==='Running'"
              class="appIcon"
              v-html="i.icon"
            >
              {{ i.icon }} {{ i.markRemove }}{{ i.phase }}
            </div>
            <div
              v-else
            >
              <div
                class="el-loading-mask"
                style="background-color: rgba(0, 0, 0, 0.5);"
              >
                <div
                  v-if="loading"
                  class="el-loading-spinner"
                >
                  <i
                    class="el-icon-loading"
                    :style="{color: i.markRemove === true? '#fff': '#BEE380'}"
                  />
                  <p
                    class="el-loading-text"
                    :style="{color: i.markRemove === true? '#fff': '#BEE380'}"
                  >
                    {{ i.markRemove === true? t('apps.installed.status.deleting'): t('apps.installed.status.creating') }}
                  </p>
                </div>
              </div>
              <div
                class="appIcon"
                v-html="i.icon"
              >
                {{ i.icon }}
              </div>
            </div>
            <el-tooltip
              :content="`${i.namespace} / ${i.alias} / ${i.name}`"
              effect="light"
            >
              <span>{{ i.alias }}</span>
            </el-tooltip>
          </div>
        </div>
        <div
          v-if="cloudDesktopListInstalled.length !== 0"
          style="cursor: pointer; color: #0C5489; position: absolute; right: 10px; top: 5px"
          @click="showDelete"
        >
          <img src="../../../../assets/images/cloudDesktop/edit.svg">{{ t('pai.detail.vmset.edit') }}
        </div>
      </div>
    </div>
    <div class="appStore">
      <div class="top">
        <span>{{ t('catalog.charts.header') }}</span>
        <el-input
          v-model="filterStoreValue"
          size="mini"
          prefix-icon="el-icon-search"
          :placeholder="t('pai.detail.vmset.filter')"
          style="width: 194px"
        />
      </div>
      <div class="content">
        <div
          v-for="item in filterStoreValue !== ''? cloudDesktopList.filter(value => value.desktopName?.includes(filterStoreValue)):cloudDesktopList"
          :key="`${item.repoPath}:${item.tag}`"
          class="contentItem"
          @mouseenter="desktopRepoPath = `${item.repoPath}:${item.tag}`"
          @click="showDesktopInstall(item)"
        >
          <div
            v-if="desktopRepoPath === `${item.repoPath}:${item.tag}`"
            class="modalBox"
            @mouseleave="desktopRepoPath = ''"
          >
            <img src="../../../../assets/images/cloudDesktop/install.svg">
            {{ t('pai.apps.vm.install') }}
          </div>
          <div
            class="appIcon"
            v-html="item.icon"
          >
            {{ item.icon }}
          </div>
          <span>{{ item.desktopName }}</span>
        </div>
      </div>
    </div>
    <container-cloud-desktop-install
      v-if="containerQuickFormVisible"
      :on-close="handelCloseCreate"
      :desktop-id="desktopRepoPath"
      :current-app="currentApp"
      :installedCloudDeskApp="installedCloudDeskApp"
      :isSharedStorage="isSharedStorage"
    />
  </div>
</template>

<style lang="scss" scoped>
  .cloud{
    width: 100%;
    background-image: url('../../../../assets/images/cloudDesktop/bg.png');
    background-repeat: no-repeat;
    background-size: cover;
    background-position: center;
    display: flex;
    flex-direction: column;
    padding: 44px 115px;
    .installedApp{
      .top{
        display: flex;
        justify-content: space-between;
        color: #185C8E;
        span{
          font-size: 18px;
        }
      }
      .content{
        mix-width: 1360px;
        min-height: 115px;
        display: flex;
        flex-wrap: wrap;
        margin-top: 15px;
        padding-bottom: 15px;
        background: #FAFAFA;
        border-radius: 10px;
        .contentItem{
          display: flex;
          flex-direction: column;
          margin-left: 26px;
          margin-top: 25px;
          cursor: pointer;
          .appIcon{
            position: relative;
          }
          span{
            width: 71px;
            text-align: center;
            margin-top: 5px;
            color: #4095E5;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
        }
        .contentItemDisable{
          display: flex;
          flex-direction: column;
          margin-left: 26px;
          margin-top: 25px;
          cursor: not-allowed;
          pointer-events: none;
          cursor: pointer;
          position: relative;
          .appIcon{
            margin-left: 6px;
            position: relative;
          }
          span{
            width: 76px;
            text-align: center;
            margin-top: 6px;
            color: #ccc;
            overflow: hidden;
            text-overflow: ellipsis;
            white-space: nowrap;
          }
          .el-loading-mask{
            height: 68px;
            z-index: 2;
          }
          .el-loading-text {
            margin: 7px 0;
            font-size: 13px;
          }
        }
        .editDelete{
          width: 20px;
          height: 20px;
          position: absolute;
          margin-top: 19px;
          margin-left: 78px;
          z-index: 3;
        }
      }
    }
    .appStore{
      margin-top: 200px;
      .top{
        display: flex;
        justify-content: space-between;
        color: #185C8E;
        span{
          font-size: 18px;
        }
      }
      .content{
        display: flex;
        flex-wrap: wrap;
        min-height: 115px;
        margin-left: -11px;
        .contentItem{
          margin-top: 15px;
          width: 259px;
          height: 109px;
          background: #FAFAFA;
          border-radius: 5px;
          display: flex;
          align-items: center;
          margin-left: 11px;
          position: relative;
          .appIcon{
            margin: 1px 18px 0 15px;
          }
          span{
            width: 100px;
            font-size: 16px;
            display: block;
          }
          .modalBox {
            position: absolute;
            width: 100%;
            height: 109px;
            display: flex;
            justify-content: center;
            align-items: center;
            cursor: pointer;
            background: #3D7EAD;
            opacity: 0.9;
            color: #fff;
            z-index: 99;
          }
        }
      }
    }

  }
</style>
