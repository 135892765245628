import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import SteveModel from '@shell/plugins/steve/steve-class';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { MANAGEMENT, NAMESPACE } from '@shell/config/types';

export default class extends PaiLocationMixin(SteveModel) {
  async getBackup() {
    const backupName = this.spec.backupName;

    if (backupName) {
      const backupId = `${ this.namespace }/${ backupName }`;
      let backup = await this.$getters['byId'](backupId );

      if (!backup) {
        backup = await this.$dispatch('find', {
          type: PAI_RESOURCES.VM_BACKUP,
          id:   backupId,
        });
      }

      return backup;
    }

    return null;
  }

  get name() {
    return this.metadata?.labels?.displayName || this.metadata?.name;
  }

  get projectId() {
    const inStore = this.$rootGetters['currentProduct'].inStore;
    const all = this.$rootGetters[`${ inStore }/all`](NAMESPACE);

    if (this.metadata?.namespace && this.metadata?.namespace.length > 0) {
      const namespace = all.find((namespace) => namespace.id === this.metadata?.namespace);

      if (namespace) {
        return namespace.projectId;
      }
    }

    return null;
  }

  get projectDisplayName() {
    const clusterId = this.$rootGetters['currentCluster']?.id;

    if (this.projectId) {
      const project = this.$rootGetters['management/byId'](MANAGEMENT.PROJECT, `${ clusterId }/${ this.projectId }`);

      return project?.spec?.displayName;
    }

    return null;
  }
}
