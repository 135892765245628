import { PRODUCT_NAME as PAI } from '../config/pai';
import dayjs from 'dayjs';

const PAI_PRODUCTS = ['pai', 'platform', 'deploy'];

export const RUST_DESK_URL = '/api/v1/namespaces/clouddesk/services/http:clouddesk-web:80/proxy/#/connect';
export const WINDOW_OPEN_PARAMS = `top=0,left=0,width=${ screen.width },height=${ screen.height },fullscreen=1,directories=no,toolbar=no,menubar=no,scrollbars=no, resizable=no,location=no, status=no`;

// 将pai.resource type转换为原生resource type
export function convertResource(type) {
  if (!type) {
    return type;
  }

  return type.includes('pai.') ? type.split('pai.')[1] : type;
}

export function getCustomDetailLink(row) {
  const {
    product,
    cluster,
    resource,
  } = this.$route.params;

  if (row.metadata.namespace) {
    return {
      name:   `${ product }-c-cluster-resource-namespace-id`,
      params: {
        product,
        cluster,
        resource,
        namespace: row.metadata.namespace,
        id:        row.id.split('/')[1],
      },
    };
  } else {
    return {
      name:   `${ product }-c-cluster-resource-id`,
      params: {
        product,
        cluster,
        resource,
        id: row.id,
      },
    };
  }
}

export function getDetailLocation(model, productName = PAI) {
  const product = model.$rootGetters['productId'];

  if (product === productName) {
    const schema = model.$getters['schemaFor'](model.type);
    const id = model.id?.replace(/.*\//, '');

    return {
      name:   `${ productName }-c-cluster-resource${ schema?.attributes?.namespaced ? '-namespace' : '' }-id`,
      params: {
        product:   productName,
        cluster:   model.$rootGetters['clusterId'],
        resource:  convertType(model.type),
        namespace: model.metadata?.namespace,
        id,
      },
    };
  }

  return model._detailLocation;
}

export function getListLocation(model, productName = PAI) {
  const product = model.$rootGetters['productId'];

  if (product === productName) {
    return {
      name:   `${ productName }-c-cluster-resource`,
      params: {
        product:  productName,
        cluster:  model.$rootGetters['clusterId'],
        resource: convertType(model.type),
      },
    };
  }

  return model.listLocation;
}

export function getDoneRoute(model, productName = PAI) {
  const product = model.$rootGetters['productId'];

  if (product === productName) {
    return `${ productName }-c-cluster-resource`;
  }

  return model.listLocation.name;
}

const PAI_TYPES = ['rbac.authorization.k8s.io.role', 'rbac.authorization.k8s.io.clusterrole', 'rbac.authorization.k8s.io.rolebinding', 'rbac.authorization.k8s.io.clusterrolebinding', 'serviceaccount', 'event', 'node', 'storage.k8s.io.storageclass', 'autoscaling.horizontalpodautoscaler', 'networking.k8s.io.networkpolicy', 'networking.k8s.io.ingress', 'apps.deployment', 'namespace', 'apps.replicaset', 'batch.cronjob', 'apps.daemonset', 'batch.job', 'apps.statefulset', 'pod', 'service', 'configmap', 'persistentvolumn', 'secret', 'catalog.cattle.io.clusterrepo', 'management.cattle.io.clusterroletemplatebinding', 'persistentvolume', 'persistentvolumeclaim'];

export function convertType(type) {
  if (!type) {
    return type;
  }

  return PAI_TYPES.includes(type) ? `pai.${ type }` : type;
}

export const formatTime = (str) => {
  return dayjs(str)
    .format('YYYY-MM-DD HH:mm:ss') === 'Invalid Date' ? '-' : dayjs(str)
      .format('YYYY-MM-DD HH:mm:ss');
};

export function deepClone(data) {
  // object,array,date
  if (data && typeof data === 'object') {
    if (typeof data === 'function') {
      const tempFunc = data.bind(null);

      tempFunc.prototype = deepClone(data.prototype);

      return tempFunc;
    }
    switch (Object.prototype.toString.call(data)) {
    case '[object String]':
      return data.toString();
    case '[object Number]':
      return Number(data.toString());
    case '[object Boolean]':
      return Boolean(data.toString());
    case '[object Date]':
      return new Date(data.getTime());
    case '[object Array]':
      // eslint-disable-next-line no-case-declarations
      const arr = [];

      for (let i = 0; i < data.length; i++) {
        arr[i] = deepClone(data[i]);
      }

      return arr;
    case '[object Object]':
      // eslint-disable-next-line no-case-declarations
      const obj = {};

      for (const key in data) {
        obj[key] = deepClone(data[key]);
      }

      return obj;
    }
  } else {
    return data;
  }
}

export function keyboardToRancher() {
  document.onkeydown = function() {
    if (window.event.keyCode === 82 && event.ctrlKey && event.altKey) {
      window.event.preventDefault();
      if (document.location.href.includes('dashboard')) {
        document.location.href = '/dashboard/home';
      } else {
        document.location.href = '/home';
      }
    }
  };
}

export function isPaiProduct(product) {
  return PAI_PRODUCTS.includes(product);
}

export function isPaiType(type) {
  return type.includes('pai.');
}

export function delay(t, v) {
  return new Promise((resolve) => {
    setTimeout(resolve.bind(null, v), t);
  });
}
