<script>
import ResourceTable from '@/pkg/pai/components/ResourceTable';
import Loading from '@shell/components/Loading';
import EditLbModal from '@/pkg/pai/components/EditLbModal';
import { SERVICE } from '@shell/config/types';
import { mapGetters } from 'vuex';

export default {
  name:       'ListMachine',
  components: {
    ResourceTable, Loading, EditLbModal
  },
  props: {
    resource: {
      type:     String,
      required: true,
    },
    schema: {
      type:     Object,
      required: true,
    },
    useQueryParamsForSimpleFiltering: {
      type:    Boolean,
      default: false
    }
  },
  async fetch() {
    await this.initData();
    this.allServices = await this.$store.dispatch('cluster/findAll', { type: SERVICE });
  },
  data() {
    return {
      rows: [], editLbModal: false, allServices: [], loading: true
    };
  },
  computed: { ...mapGetters(['currentCluster']) },
  methods:  {
    async initData() {
      const services = [];
      const servicesResult = await this.$store.dispatch('pai-common/getLbServices', { cluster: `${ this.currentCluster.id }` });

      for (const key in servicesResult.data) {
        const namespace = key.split('/')[0];
        const name = key.split('/')[1].split('#')[0];
        const proto = key.split('/')[1].split('#')[1];
        const port = key.split('/')[2];
        const backendPort = servicesResult.data[key];

        services.push({
          namespace, name, proto, port, backendPort
        });
      }
      this.rows = services;
      this.loading = false;
    },
    handleActionButtonClick(row) {
      this.editLbModal = true;
      this.$store.dispatch('pai-common/updateState', { currentModal: 'editLbModal', currentItem: row });
    },
    detailLink(row) {
      const service = this.allServices.find((v) => {
        return v.metadata.namespace === row.namespace && v.metadata.name === row.name && v.spec.ports && v.spec.ports.map((v) => `${ v.port }`)
          .includes(row.port);
      });

      this.$router.push(service.detailLocation);
    }
  },
  $loadingResources() {
    return { loadIndeterminate: true };
  },
};
</script>

<template>
  <div>
    <Loading
      v-if="loading"
    />
    <ResourceTable
      v-else
      :schema="schema"
      :rows="rows"
      :tableActions="false"
      :rowActions="false"
      :use-query-params-for-simple-filtering="useQueryParamsForSimpleFiltering"
    >
      <template
        slot="cell:name"
        slot-scope="scope"
      >
        <a
          style="cursor: pointer"
          @click="detailLink(scope.row)"
        >{{ scope.row.name }}</a>
      </template>
      <template
        slot="cell:action"
        slot-scope="scope"
      >
        <button
          class="btn role-primary"
          @click="handleActionButtonClick(scope.row)"
        >
          {{ t('action.edit') }}
        </button>
      </template>
    </ResourceTable>
    <EditLbModal
      v-if="editLbModal"
      @initData="initData"
    />
  </div>
</template>
