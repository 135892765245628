<template>
  <div
    class="template"
  >
    <el-collapse-transition>
      <div
        class="ZPOOLSettings"
      >
        <el-row>
          <el-col :span="4">
            <div
              class="disk-item"
            >
              <div class="disk-flag">
                <div>
                  {{ t('pai.edit.machine.disks') }}
                  <el-tooltip
                    :content="t('pai.edit.machine.diskTip')"
                    effect="light"
                  >
                    <i class="icon icon-info" />
                  </el-tooltip>
                </div>
              </div>
              <div>
                <draggable
                  v-model="devs"
                  group="list"
                  class="draggable-bg-disk"
                  :disabled="networkZPOOLDisabled"
                >
                  <div
                    v-for="(d,index) in devs"
                    :key="index"
                    class="disk-content"
                  >
                    <div
                      v-if="d"
                      class="disk-row"
                    >
                      <el-tooltip
                        :content="d.name"
                        effect="light"
                      >
                        <span class="disk-path"><img src="../components/open-local/img/drag.svg"> {{ d.name }}</span>
                      </el-tooltip>
                      <span>{{ d.size }}</span>
                    </div>
                  </div>
                </draggable>
              </div>
            </div>
          </el-col>
          <div
            class="scroll-box"
            :style="{'height': maxHeight ? maxHeight : 'auto'}"
          >
            <el-col :span="5">
              <div
                v-for="(vdevs,index) in dataVdevs"
                :key="index"
                class="disk-item"
              >
                <div class="disk-flag">
                  <span>{{ t('pai.edit.machine.device') }}{{ index +1 }}</span>
                  <a
                    v-if="index === 0 || networkZPOOLDisabled"
                    href="#"
                    class="noDel"
                  >{{ t('pai.edit.machine.remove') }}</a>
                  <a
                    v-else
                    href="#"
                    @click="removeDataList('vdevs',index)"
                  >{{ t('pai.edit.machine.remove') }}</a>
                </div>
                <draggable
                  v-model="vdevs.list"
                  group="list"
                  class="draggable-bg"
                  :disabled="networkZPOOLDisabled"
                >
                  <div
                    v-for="(d,i) in vdevs.list"
                    :key="i"
                    class="disk-content"
                  >
                    <div
                      v-if="d"
                      class="disk-row"
                    >
                      <el-tooltip
                        :content="d.name"
                        effect="light"
                      >
                        <span class="disk-path"><img src="../components/open-local/img/drag.svg"> {{ d.name }}</span>
                      </el-tooltip>
                      <span>{{ d.size }}</span>
                    </div>
                  </div>
                </draggable>
                <div class="dropdown-box">
                  <el-dropdown
                    :disabled="networkZPOOLDisabled"
                  >
                    <span class="el-dropdown-link">
                      {{ vdevs.type }}<i class="el-icon-arrow-down el-icon--right" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(type, i) in vdevsType"
                        :key="i"
                        @click.native="handleDropdown(type,index,'vdevs')"
                      >
                        {{ type }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div
                v-if="!networkZPOOLDisabled"
                class="addBtn"
                @click="addDataList(1)"
              >
                <i class="el-icon-circle-plus-outline" />
                {{ t('generic.add')+t('pai.edit.machine.device') }}
              </div>
            </el-col>
            <el-col :span="5">
              <div
                class="disk-item"
              >
                <div class="disk-flag">
                  {{ t('pai.edit.machine.cacheDisk') }}
                </div>
                <draggable
                  v-model="cacheVdevs"
                  group="list"
                  class="draggable-bg"
                  :disabled="networkZPOOLDisabled"
                >
                  <div
                    v-for="(d,index) in cacheVdevs"
                    :key="index"
                    class="disk-content"
                  >
                    <div
                      v-if="d"
                      class="disk-row"
                    >
                      <el-tooltip
                        :content="d.name"
                        effect="light"
                      >
                        <span class="disk-path"><img src="../components/open-local/img/drag.svg"> {{ d.name }}</span>
                      </el-tooltip>
                      <span>{{ d.size }}</span>
                    </div>
                  </div>
                </draggable>
              </div>
            </el-col>
            <el-col :span="5">
              <div
                v-for="(vdevs,index) in logVdevs"
                :key="index"
                class="disk-item"
              >
                <div class="disk-flag">
                  <span>{{ t('pai.edit.machine.logDisk') }}{{ index+1 }}</span>
                  <div>
                    <a
                      v-if=" networkZPOOLDisabled"
                      href="#"
                      class="noDel"
                    >{{ t('pai.edit.machine.remove') }}</a>
                    <a
                      v-else
                      href="#"
                      @click="removeDataList('log',index)"
                    >{{ t('pai.edit.machine.remove') }}</a>
                  </div>
                </div>
                <draggable
                  v-model="vdevs.list"
                  group="list"
                  class="draggable-bg"
                  :disabled="networkZPOOLDisabled"
                >
                  <div
                    v-for="(d,i) in vdevs.list"
                    :key="i"
                    class="disk-content"
                  >
                    <div
                      v-if="d"
                      class="disk-row"
                    >
                      <el-tooltip
                        :content="d.name"
                        effect="light"
                      >
                        <span class="disk-path"><img src="../components/open-local/img/drag.svg"> {{ d.name }}</span>
                      </el-tooltip>
                      <span>{{ d.size }}</span>
                    </div>
                  </div>
                </draggable>
                <div class="dropdown-box">
                  <el-dropdown
                    :disabled="networkZPOOLDisabled"
                  >
                    <span class="el-dropdown-link">
                      {{ vdevs.type }}<i class="el-icon-arrow-down el-icon--right" />
                    </span>
                    <el-dropdown-menu slot="dropdown">
                      <el-dropdown-item
                        v-for="(type,i) in vdevsType.slice(0,2)"
                        :key="i"
                        @click.native="handleDropdown(type,index,'log')"
                      >
                        {{ type }}
                      </el-dropdown-item>
                    </el-dropdown-menu>
                  </el-dropdown>
                </div>
              </div>
              <div
                v-if="!networkZPOOLDisabled"
                class="addBtn"
                @click="addDataList(4)"
              >
                <i class="el-icon-circle-plus-outline" />
                {{ t('generic.add')+t('pai.edit.machine.logDisk') }}
              </div>
            </el-col>
            <el-col :span="5">
              <div
                class="disk-item"
              >
                <div class="disk-flag">
                  {{ t('pai.edit.machine.hotSpare') }}
                </div>
                <draggable
                  v-model="hotSpareVdevs"
                  group="list"
                  class="draggable-bg"
                  :disabled="networkZPOOLDisabled"
                >
                  <div
                    v-for="(d,index) in hotSpareVdevs"
                    :key="index"
                    class="disk-content"
                  >
                    <div
                      v-if="d"
                      class="disk-row"
                    >
                      <el-tooltip
                        :content="d.name"
                        effect="light"
                      >
                        <span class="disk-path"><img src="../components/open-local/img/drag.svg"> {{ d.name }}</span>
                      </el-tooltip>
                      <span>{{ d.size }}</span>
                    </div>
                  </div>
                </draggable>
              </div>
            </el-col>
          </div>
        </el-row>
      </div>
    </el-collapse-transition>
  </div>
</template>

<script>
import draggable from 'vuedraggable';

export default {
  name:       'ZPOOLSettings',
  components: { draggable },
  props:      {
    valueConfig: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
    maxHeight: {
      type:    String,
      default: ''
    },
    devsData: {
      type:    Array,
      default: () => {
        return [];
      }
    },
    device: {
      type:    String,
      default: ''
    },
    networkZPOOLDisabled: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return {
      devs:          [],
      dataVdevs:     [],
      cacheVdevs:    [],
      logVdevs:      [],
      hotSpareVdevs: [],
      vdevsType:     ['Stripe', 'Mirror', 'Raidz', 'Raidz2', 'Raidz3'],
    };
  },
  async fetch() {
    this.initDevsData();
  },
  methods: {
    initDevsData() {
      this.dataVdevs = this.valueConfig.dataVdevs;
      this.cacheVdevs = this.valueConfig.cacheVdevs;
      this.logVdevs = this.valueConfig.logVdevs;
      this.hotSpareVdevs = this.valueConfig.hotSpareVdevs;
      const newArr = (this.dataVdevs?.map((item) => item.list).concat(this.logVdevs?.map((item) => item.list)).concat(this.cacheVdevs).concat(this.hotSpareVdevs))
        .flat();

      const devsData = this.devsData?.filter((item) => !newArr.some((n) => n.name === item.name) && this.device !== item.name);

      this.devs = devsData || [];
    },
    addDataList(vdevs) {
      switch (vdevs) {
      case 1:
        this.dataVdevs.push({ list: [], type: this.vdevsType[0] });
        break;
      case 4:
        this.logVdevs.push({ list: [], type: this.vdevsType[0] });
        break;
      }
    },
    removeDataList(vdevs, index) {
      switch (vdevs) {
      case 'vdevs':
        for (const numberKey in this.dataVdevs[index].list) {
          this.devs.push(this.dataVdevs[index].list[numberKey]);
        }
        this.dataVdevs.splice(index, 1);
        break;
      case 'log':
        for (const numberKey in this.logVdevs[index].list) {
          this.devs.push(this.logVdevs[index].list[numberKey]);
        }
        this.logVdevs.splice(index, 1);
        break;
      }
    },
    handleDropdown(item, index, dataType) {
      switch (dataType) {
      case 'vdevs':
        this.dataVdevs[index].type = item;
        break;
      case 'log':
        this.logVdevs[index].type = item;
        break;
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.title-box{
  position: relative;
  .banner-bg{
    background: #FAFAFA;
    padding: 10px 0px;
    cursor: pointer;
  }
  .showBtn{
    position: absolute;
    right: 10px;
    top: 0px;
    color: #246FA5;
    border: 1px solid #246FA5;
  }
}
.ZPOOLSettings{
  padding-bottom: 10px;
  .noDel{
    color: #cccccc;
    cursor: default
  }
  .disk-type {
    min-height: 50px;
    padding: 2px 4px;
    margin: 4px;
    background: #DCDEE7;
  }
  .disk-item {
    border: 1px solid rgba(239, 239, 239, 1);
    background-color: rgba(250, 250, 250, 1);
    margin: 10px 0px;
    padding: 0px 8px;
    .disk-type {
      min-height: 50px;
      padding: 2px 4px;
      margin: 4px;
      background: #DCDEE7;
    }
    .disk-flag {
      display: flex;
      justify-content: space-between;
      padding: 8px;
      .h3-title {
        flex-grow: 1;
        font-weight: 500;
        padding: 8px;
        font-size: 14px;
        border-bottom: 1px solid rgba(239, 239, 239, 1);
      }
    }
  }
  .disk-content{
    .disk-row {
      height: 30px;
      line-height: 30px;
      display: flex;
      justify-content: space-between;
      background: #FFFFFF;
      padding: 0px 8px;
      margin: 10px 0px;
      border-radius: 3px;
      .disk-path{
        width: 150px;
        overflow:hidden;
        white-space: nowrap;
        text-overflow: ellipsis;
        -o-text-overflow:ellipsis;
      }
      img {
        position: relative;
        top: 2px;
      }
    }
  }
  .draggable-bg,.draggable-bg-disk{
    min-height: 130px;
    background: #DCDEE7;
    padding: 8px;
    margin-bottom: 10px;
  }
  .draggable-bg-disk{
    max-height: 360px;
    overflow-y: auto;
    .no-data {
      text-align: center;
      line-height: 100px;
    }
  }
  .el-dropdown-link {
    cursor: pointer;
    color: #246FA5;
  }
  .addBtn{
    width: 100%;
    padding: 10px 0px;
    border-radius: 4px;
    cursor: pointer;
    margin-top: 10px;
    color: #246FA5;
  }
  .el-col{
    padding: 10px;
  }
  .dropdown-box{
    width: 100%;
    margin: 15px 0px;
    text-align: right;
  }
  .scroll-box{
    overflow-x: hidden;
    overflow-y: auto;
  }
}
</style>
