import { importTypes } from '@rancher/auto-import';
import { IPlugin, PanelLocation } from '@shell/core/types';
import routing from './routing/pai-routing';
import customValidators from './validators';
import './assets/styles/app.scss';
import paiStore from './store/pai-common';
import paiGrafana from './store/pai-grafana';
import { registerLayout } from '@shell/initialize/layouts';
import paiHome from './layouts/pai/home.vue';
import paiDefault from './layouts/pai/default.vue';

// Init the package
export default function(plugin: IPlugin) {
  // Auto-import model, detail, edit from the folders
  importTypes(plugin);

  // Provide plugin metadata from package.json
  plugin.metadata = require('./package.json');

  // Load a product
  plugin.addProduct(require('./config/pai'));
  plugin.addProduct(require('./config/platform'));
  plugin.addProduct(require('./config/deploy'));
  plugin.addProduct(require('./config/monitor'));

  plugin.addRoutes(routing);

  registerLayout('pai/home', paiHome);
  registerLayout('pai/default', paiDefault);

  plugin.validators = customValidators;

  plugin.addDashboardStore(paiStore.config.namespace, paiStore.specifics, paiStore.config);
  plugin.addDashboardStore(paiGrafana.config.namespace, paiGrafana.specifics, paiGrafana.config);
  // 添加list页面扩展点
  // plugin.addPanel(
  //   PanelLocation.RESOURCE_LIST,
  //   { resource: ['monitoring.coreos.com.alertmanagerconfig', 'monitoring.coreos.com.prometheusrule', 'monitoring.coreos.com.prometheus'] },
  //   { component: () => import('') }
  // );
}
