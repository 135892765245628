<script>
import { SERVICE } from '@shell/config/types';
import { PAI_RESOURCES } from '../config/types';
import { PRODUCT_NAME } from '../config/pai';
export default {
  name: 'EditLbModal',
  data() {
    return {
      allServices: [],
      loading:     false,
      rules:       {
        backendPort: [{
          required: true,
          message:  this.t('pai.edit.placeholder') + this.t('pai.lb.outerPort'),
          trigger:  'blur'
        }],
      }
    };
  },
  async fetch() {
    this.allServices = await this.$store.dispatch('cluster/findAll', { type: SERVICE });
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'editLbModal';
    },
    form() {
      return this.$store.state['pai-common'].currentItem;
    }
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    onConfirm() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          const service = this.allServices.find((v) => {
            return v.metadata.namespace === this.form.namespace && v.metadata.name === this.form.name && v.spec.ports && v.spec.ports.map((v) => `${ v.port }`)
              .includes(this.form.port);
          });

          if (service) {
            const ports = service.spec.ports[service.spec.ports.map((v) => `${ v.port }`).indexOf(this.form.port)];
            const extIP = ports.port;
            const backend = this.form.backendPort;

            if (!this.form.backendPort) {
              this.$message.error(this.t('pai.edit.tips.required'));

              return;
            }
            if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.form.backendPort)) {
              this.$message.error(this.t('pai.edit.tips.specialCharacters'));

              return;
            }
            this.$set(service.metadata, 'annotations', { [`ext.ipvs/${ this.form.proto }${ extIP }`]: `${ backend }` });
            this.$message({
              type:    'success',
              message: this.t('pai.lb.tip.success')
            });
          } else {
            this.$message({
              type:    'warning',
              message: this.t('pai.lb.tip.warning')
            });
          }
          await service.save();
          this.handleClose();
          window.location.reload();
        } else {
          return false;
        }
      });
    },
  },
};
</script>
<template>
  <el-dialog
    :title="t('action.edit')"
    :visible.sync="dialogVisible"
    width="35%"
    :before-close="handleClose"
    :inline-message="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item
        :label="t('namespace.label')"
        prop="namespace"
      >
        <el-input
          v-model="form.namespace"
          disabled
        />
      </el-form-item>
      <el-form-item
        :label="t('workload.detail.services')"
        prop="namespace"
      >
        <el-input
          v-model="form.namespace"
          disabled
        />
      </el-form-item>
      <el-form-item
        :label="t('pai.lb.proto')"
        prop="proto"
      >
        <el-input
          v-model="form.proto"
          disabled
        />
      </el-form-item>
      <el-form-item
        :label="t('servicesPage.ips.define')"
        prop="port"
      >
        <el-input
          v-model="form.port"
          disabled
        />
      </el-form-item><el-form-item
        :label="t('pai.lb.outerPort')"
        prop="backendPort"
      >
        <el-input v-model="form.backendPort" />
      </el-form-item>
    </el-form>

    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >{{ t('pai.detail.vmset.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.el-input, .el-select{
  width: 100%;
}
::v-deep .el-form-item__label {
  line-height: unset;
}
</style>
