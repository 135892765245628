<script>
import ClusterEditView from '@/pkg/pai/mixins/clusterEditView';

import CruResource from '@shell/components/CruResource';
import Loading from '@shell/components/Loading';
import AliasNsDescription from '../../components/form/AliasNsDescription.vue';
import NameNsDescription from '@shell/components/form/NameNsDescription';
import Tab from '@shell/components/Tabbed/Tab';
import Tabbed from '@shell/components/Tabbed';
import { CAPI, HCI, SCHEMA } from '@shell/config/types';
import ClusterMembershipEditor from '@shell/components/form/Members/ClusterMembershipEditor';
import { Banner } from '@components/Banner';
import { canViewClusterMembershipEditor } from '@shell/components/form/Members/ClusterMembershipEditor.vue';
import { NAME as HARVESTER_MANAGER } from '@shell/config/harvester-manager-types';
import { HARVESTER as HARVESTER_FEATURE, mapFeature } from '@shell/store/features';
import { HIDE_DESC, mapPref } from '@shell/store/prefs';
import Labels from '@shell/edit/provisioning.cattle.io.cluster/Labels';
import AgentEnv from '@shell/edit/provisioning.cattle.io.cluster/AgentEnv';
import { PRODUCT_NAME } from '../../config/pai';
import { clone } from '@shell/utils/object';
import { createYaml } from '@shell/utils/create-yaml';
import { PAI_CAPI } from '~/pkg/pai/config/types';
import { _CREATE } from '@shell/config/query-params';
import { LabeledInput } from '@components/Form/LabeledInput';
import { ALIAS } from '@/pkg/pai/config/labels-annotations';
const HARVESTER_HIDE_KEY = 'cm-harvester-import';

export default {
  components: {
    Banner,
    ClusterMembershipEditor,
    Loading,
    NameNsDescription,
    AliasNsDescription,
    LabeledInput,
    CruResource,
    Tab,
    Tabbed,
    Labels,
    AgentEnv,
  },

  mixins: [ClusterEditView],

  props: {
    mode: {
      type:     String,
      required: true,
    },

    value: {
      type:     Object,
      required: true,
    },

    provider: {
      type:     String,
      required: true,
    },
  },

  fetch() {
    // if ( !this.value.spec.rkeConfig ) {
    // set(this.value.spec, 'rkeConfig', {});
    // }
  },

  data() {
    return { membershipUpdate: {} };
  },

  computed: {
    canManageMembers() {
      return canViewClusterMembershipEditor(this.$store);
    },

    hideDescriptions: mapPref(HIDE_DESC),

    harvesterEnabled: mapFeature(HARVESTER_FEATURE),

    harvesterLocation() {
      return this.isCreate && !this.hideDescriptions.includes(HARVESTER_HIDE_KEY) && this.harvesterEnabled ? {
        name:   `c-cluster-product-resource`,
        params: {
          product:  HARVESTER_MANAGER,
          resource: HCI.CLUSTER,
        },
      } : null;
    },
  },

  created() {
    this.registerAfterHook(this.saveRoleBindings, 'save-role-bindings');
  },

  methods: {
    done() {
      let routeName = 'pai-overview';

      if (this.mode === _CREATE && (this.provider === 'import' || this.provider === 'custom')) {
        // Go show the registration command
        routeName = 'pai-cluster-resource-namespace-id';
      }

      this.$router.push({
        name:   routeName,
        params: {
          cluster:   this.$route.params.cluster,
          resource:  PAI_CAPI.RANCHER_CLUSTER,
          namespace: this.value.metadata.namespace,
          id:        this.value.metadata.name,
          product:   PRODUCT_NAME
        },
      });
    },

    async saveRoleBindings() {
      await this.value.waitForMgmt();

      if (this.membershipUpdate.save) {
        await this.membershipUpdate.save(this.value.mgmt.id);
      }
    },

    async saveOverride(btnCb) {
      if (!this.value.metadata.name) {
        this.$message({
          type:    'warning',
          message: this.t('pai.detail.vmset.enterName')
        });
        btnCb(false);
      } else if (!this.value.metadata.annotations[ALIAS]) {
        this.$message({
          type:    'warning',
          message: this.t('pai.edit.placeholder') + this.t('pai.edit.alias')
        });
        btnCb(false);
      } else {
        await this.save(btnCb);
      }
    },

    onMembershipUpdate(update) {
      this.$set(this, 'membershipUpdate', update);
    },
    generateYaml() {
      const resource = this.value;
      const schemas = this.$store.getters[`management/all`](SCHEMA);
      const clonedResource = clone(resource);

      delete clonedResource.isSpoofed;

      const out = createYaml(schemas, CAPI.RANCHER_CLUSTER, clonedResource);

      return out;
    },
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <CruResource
    v-else
    :mode="mode"
    :resource="value"
    :errors="errors"
    component-testid="cluster-manager-import"
    :generate-yaml="generateYaml"
    @finish="saveOverride"
    @error="e=>errors = e"
    style="overflow: hidden"
  >
    <div class="mt-20 row">
      <NameNsDescription
        v-if="!isView"
        v-model="value"
        :mode="mode"
        :namespaced="false"
        name-label="cluster.name.label"
        name-placeholder="cluster.name.placeholder"
        :descriptionHidden="true"
        style="flex: 0.3 0 100%;"
      />
      <AliasNsDescription
        v-if="!isView"
        v-model="value"
        :mode="mode"
        :namespaced="false"
        name-label="tableHeaders.alias"
        name-placeholder="cluster.name.placeholder"
        description-label="cluster.description.label"
        description-placeholder="cluster.description.placeholder"
        style="flex: 0.5 0 100%; margin-left: -74.6%;"
      />
    </div>

    <Tabbed :side-tabs="true">
      <Tab
        v-if="canManageMembers"
        name="memberRoles"
        label-key="cluster.tabs.memberRoles"
        :weight="3"
      >
        <Banner
          v-if="isEdit"
          color="info"
        >
          {{ t('cluster.memberRoles.removeMessage') }}
        </Banner>
        <ClusterMembershipEditor
          :mode="mode"
          :parent-id="value.mgmt ? value.mgmt.id : null"
          @membership-update="onMembershipUpdate"
        />
      </Tab>
      <AgentEnv
        v-model="value"
        :mode="mode"
      />
      <Labels
        v-model="value"
        :mode="mode"
      />
    </Tabbed>
  </CruResource>
</template>
