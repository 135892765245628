<template>
  <div class="monitorManagement">
    <div class="selectCard">
      <el-select
        v-model="clustersVal"
        class="mySelect"
        :placeholder="t('pai.detail.vmset.allCluster')"
        filterable
        style="width: 260px"
      >
        <el-option
          v-for="(item, index) in kubeClusters"
          :key="index"
          :label="item.nameDisplay"
          :value="item.id"
          :disabled="item.metadata.state.name !== 'active'"
        />
      </el-select>
    </div>
    <div class="managementCard flex">
      <div class="monitorCard emergentBorder">
        <img
          src="./images/emergent.svg"
        >
        <span>{{ t('pai.detail.vmset.tab.monitor.emergent') }}</span>
        <div class="statistics emergent">
          {{ resEmergentNum + nodesEmergentNum }}
        </div>
      </div>
      <div class="monitorCard warningBorder">
        <img
          src="./images/warning.svg"
        >
        <span>{{ t('pai.detail.vmset.tab.monitor.warning') }}</span>
        <div class="statistics warning">
          {{ resWarningNum }}
        </div>
      </div>
      <div class="monitorCard promptBorder">
        <img
          src="./images/prompt.svg"
        >
        <span>{{ t('pai.detail.vmset.tab.monitor.prompt') }}</span>
        <div class="statistics prompt">
          {{ resTipsNum + nodesTipsNum }}
        </div>
      </div>
    </div>
    <div class="managementCard tableCard">
      <div class="flex">
        <div class="title">
          {{ t('pai.detail.vmset.tab.monitor.alarmTable') }}
        </div>
        <div class="search">
          <div v-if="activeTab === 'res'">
            <el-input
              v-model="inputValueRes"
              style="width: 200px;margin-right: 30px"
              suffix-icon="el-icon-search"
              :placeholder="t('pai.detail.vmset.filter')"
            />
            <el-select
              v-model="alarmLevelValRes"
              class="mySelect"
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in alarmOptions"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
          <div v-else>
            <el-input
              v-model="inputValue"
              style="width: 200px;margin-right: 30px"
              :placeholder="t('pai.detail.vmset.filter')"
              suffix-icon="el-icon-search"
            />
            <el-select
              v-model="alarmLevelVal"
              class="mySelect"
              :placeholder="t('pai.detail.vmset.tab.monitor.alarmLevel')"
              filterable
              clearable
            >
              <el-option
                v-for="(item, index) in alarmOptions"
                :key="index"
                :label="item.name"
                :value="item.value"
              />
            </el-select>
          </div>
        </div>
      </div>
      <Tabbed
        ref="containersTabbed"
        class="deployment-tabs"
        :show-tabs-add-remove="true"
        :flat="true"
        @changed="handleTab"
      >
        <Tab
          :label="t('pai.detail.vmset.tab.monitor.resAlarm')"
          name="res"
          :weight="99"
        >
          <div class="tableList">
            <el-table
              :data="resTables"
              :header-cell-style="{background:'#f4f5fa', color: '#000000', height: '20px', lingHeight: '20px',fontWeight: 'normal'}"
              style="width: 100%"
            >
              <el-table-column
                prop="clusterName"
                :label="t('pai.detail.vmset.tab.monitor.clusterName')"
                width="140"
              />
              <el-table-column
                prop="alarmLevel"
                :label="t('pai.detail.vmset.tab.monitor.alarmLevel')"
                width="110"
              >
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.alarmLevel === 'emergent'"
                    class="alarmLevel emergentLevel"
                  >
                    {{ t('pai.detail.vmset.tab.monitor.emergent') }}
                  </div>
                  <div
                    v-if="scope.row.alarmLevel === 'warning'"
                    class="alarmLevel warningLevel"
                  >
                    {{ t('pai.detail.vmset.tab.monitor.warning') }}
                  </div>
                  <div
                    v-if="scope.row.alarmLevel === 'prompt'"
                    class="alarmLevel promptLevel"
                  >
                    {{ t('pai.detail.vmset.tab.monitor.prompt') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="name"
                :label="t('pai.detail.vmset.tab.monitor.alertName')"
                width="210"
              />
              <el-table-column
                prop="message"
                :label="t('pai.detail.vmset.tab.monitor.alarmMessage')"
              />
            </el-table>
          </div>
        </Tab>
        <Tab
          :label="t('pai.detail.vmset.tab.monitor.nodeAlarm')"
          name="node"
          :weight="98"
        >
          <div class="tableList">
            <el-table
              :data="tables"
              :header-cell-style="{background:'#f4f5fa', color: '#000000', height: '20px', lingHeight: '20px',fontWeight: 'normal'}"
              style="width: 100%"
            >
              <el-table-column
                prop="clusterName"
                :label="t('pai.detail.vmset.tab.monitor.clusterName')"
                width="140"
              />
              <el-table-column
                prop="metadata.name"
                :label="t('pai.detail.vmset.tab.monitor.clusterNode')"
                width="140"
              />
              <el-table-column
                prop="alarmLevel"
                :label="t('pai.detail.vmset.tab.monitor.alarmLevel')"
                width="140"
              >
                <template slot-scope="scope">
                  <div
                    v-if="scope.row.alarmLevel === 'emergent'"
                    class="alarmLevel emergentLevel"
                  >
                    {{ t('pai.detail.vmset.tab.monitor.emergent') }}
                  </div>
                  <div
                    v-if="scope.row.alarmLevel === 'warning'"
                    class="alarmLevel warningLevel"
                  >
                    {{ t('pai.detail.vmset.tab.monitor.warning') }}
                  </div>
                  <div
                    v-if="scope.row.alarmLevel === 'prompt'"
                    class="alarmLevel promptLevel"
                  >
                    {{ t('pai.detail.vmset.tab.monitor.prompt') }}
                  </div>
                </template>
              </el-table-column>
              <el-table-column
                prop="alarmType"
                :label="t('pai.detail.vmset.tab.monitor.alarmType')"
              />
              <el-table-column
                prop="message"
                :label="t('pai.detail.vmset.tab.monitor.alarmMessage')"
              />
            </el-table>
          </div>
        </Tab>
      </Tabbed>
    </div>
  </div>
</template>

<script>
import monitorMixins from '../../../../mixins/monitor';
import metricPoller from '@shell/mixins/metric-poller';
import Tabbed from '../../../../components/Tabbed/index.vue';
import Tab from '../../../../components/Tabbed/Tab.vue';

export default {
  layout:     'pai/default',
  name:       'Index',
  mixins:     [metricPoller, monitorMixins],
  components: {
    Tabbed,
    Tab,
  },
  fetch() {
    // 集群
    this.clusterData();
    this.getNodesData();
    this.clustersVal = this.activeCluster[0].id;
  },
  data() {
    return {
      clustersVal:   '',
      alarmLevelVal: '',
      inputValue:    '',
      alarmOptions:  [{
        name:  this.t('pai.detail.vmset.tab.monitor.emergent'),
        value: 'emergent',
      }, {
        name:  this.t('pai.detail.vmset.tab.monitor.warning'),
        value: 'warning',
      }, {
        name:  this.t('pai.detail.vmset.tab.monitor.prompt'),
        value: 'prompt',
      }],
      activeTab:        'res',
      inputValueRes:    '',
      alarmLevelValRes: '',
    };
  },
  computed: {
    // 模糊搜索
    resTables() {
      const tableList = this.resErrorList;

      if (this.inputValueRes && this.alarmLevelValRes) {
        return tableList.filter((data) => {
          return Object.keys(data).some((key) => {
            return data.alarmLevel === this.alarmLevelValRes && String(data[key]).toLowerCase().includes(this.inputValueRes.toLowerCase());
          });
        });
      } else if (this.inputValueRes || this.alarmLevelValRes) {
        return tableList.filter((data) => {
          return Object.keys(data).some((key) => {
            if (!this.alarmLevelValRes) {
              return String(data[key]).toLowerCase().includes(this.inputValueRes.toLowerCase());
            } else {
              return data.alarmLevel === this.alarmLevelValRes;
            }
          });
        });
      } else {
        return tableList;
      }
    },
    tables() {
      const tableList = this.nodesErrorArr.flat();

      if (this.inputValue && this.alarmLevelVal) {
        return tableList.filter((data) => {
          return Object.keys(data).some((key) => {
            return data.alarmLevel === this.alarmLevelVal && String(data[key]).toLowerCase().includes(this.inputValue.toLowerCase());
          });
        });
      } else if (this.inputValue || this.alarmLevelVal) {
        return tableList.filter((data) => {
          return Object.keys(data).some((key) => {
            if (!this.alarmLevelVal) {
              return String(data[key]).toLowerCase().includes(this.inputValue.toLowerCase());
            } else {
              return data.alarmLevel === this.alarmLevelVal;
            }
          });
        });
      } else {
        return tableList;
      }
    }
  },
  watch: {
    async clustersVal(val) {
      await this.getAlarmNodes([this.kubeClusters.find((i) => i.id === this.clustersVal)]);
      await this.getResMonitor([this.kubeClusters.find((i) => i.id === this.clustersVal)]);
    }
  },
  methods: {
    handleTab(e) {
      this.activeTab = e.selectedName;
    },
    async getNodesData() {
      // 节点数据
      try {
        await this.getAlarmNodes(this.activeCluster);
      } catch (e) {
        console.log(e);
      }
    },
  }
};
</script>

<style scoped lang="scss">
.monitorManagement{
  background: #F5F4F6;
  padding: 20px 10px;
  margin-top: 16px;
  .selectCard {
    width: 100%;
    background: #FFFFFF;
    margin-bottom: 16px;
    padding: 10px;
  }
  .managementCard{
    .monitorCard{
      width: 48%;
      padding: 16px;
      background: #FFFFFF;
      margin-right: 14px;
      border-radius: 3px;
      .statistics{
        font-size: 48px;
        font-weight: 500;
        text-align: center;
      }
      .emergent {
        color: #C85352
      }
      .warning {
        color: #FCCA00;
      }
      .prompt{
        color: #4095E5;
      }
      span {
        position: relative;
        top: -6px;
        left: 8px;
      }
    }
    .emergentBorder{
      border-top: 10px solid #C85352;
    }
    .warningBorder{
      border-top: 10px solid #FCCA00;
    }
    .promptBorder{
      border-top: 10px solid #4095E5;
    }
    .monitorCard:last-child{
      margin: 0;
    }
    .search {
      margin-bottom: 16px;
    }
  }
  .tableList {
    margin-top: 10px;
  }
  .tableCard{
    margin: 16px 0px;
    padding: 16px;
    background: #FFFFFF;
    border-radius: 3px;
    .title{
      margin-top: 10px;
    }
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .alarmLevel{
    width: 47px;
    height: 23px;
    line-height: 20px;
    border-radius: 3px;
    text-align: center;
  }
  .emergentLevel{
    background-color: rgba(222, 134, 143, 0.25);
    border: 1px solid #C85352;
    color: #C85352;
  }
  .warningLevel{
    border: 1px solid #FCCA00;
    background-color: rgba(252, 202, 0, 0.13);
    color: #FCCA00;
  }
  .promptLevel{
    border: 1px solid #4095E5;
    background-color: rgba(180, 253, 255, 0.34);
    color: #4095E5;
  }
}
</style>
