import { MONITORING } from '@shell/config/types';
import { _YAML, _CREATE, _VIEW, _CONFIG } from '@shell/config/query-params';
import AlertmanagerConfig from '@shell/models/monitoring.coreos.com.alertmanagerconfig';
import MonitorLocationMixin from '~/pkg/pai/mixins/monitorLocation';
import { PRODUCT_NAME as MONITOR } from '../config/monitor';

export default class extends MonitorLocationMixin(AlertmanagerConfig) {
  get alertmanagerConfigDoneRouteName() {
    return `${ MONITOR }-c-cluster-resource-namespace-id`;
  }

  get _detailLocation() {
    return {
      name:   this.alertmanagerConfigDoneRouteName,
      params: {
        cluster:   this.$rootGetters['clusterId'],
        product:   MONITOR,
        resource:  MONITORING.ALERTMANAGERCONFIG,
        namespace: this.metadata?.namespace,
        id:        this.name,
      },
      hash:  '#receivers',
      query: { as: 'config' }
    };
  }

  getCreateReceiverRoute() {
    return {
      name:   `${ MONITOR }-c-cluster-alertmanagerconfig-alertmanagerconfigid-receiver`,
      params: {
        cluster:              this.$rootGetters['clusterId'],
        alertmanagerconfigid: this.id
      },
      query: { mode: _CREATE, currentView: _CONFIG }
    };
  }

  getReceiverDetailLink(receiverName) {
    return {
      name:   `${ MONITOR }-c-cluster-alertmanagerconfig-alertmanagerconfigid-receiver`,
      params: {
        cluster:              this.$rootGetters['clusterId'],
        alertmanagerconfigid: this.id,
        receiverName
      },
      query: {
        mode: _VIEW, receiverName, currentView: _CONFIG
      }
    };
  }

  getEditReceiverYamlRoute(receiverName, queryMode) {
    return {
      name:   `${ MONITOR }-c-cluster-alertmanagerconfig-alertmanagerconfigid-receiver`,
      params: {
        cluster:              this.$rootGetters['clusterId'],
        alertmanagerconfigid: this.id
      },
      query: {
        mode:        queryMode || _VIEW,
        receiverName,
        currentView: _YAML
      }
    };
  }

  getEditReceiverConfigRoute(receiverName, queryMode) {
    return {
      name:   `${ MONITOR }-c-cluster-alertmanagerconfig-alertmanagerconfigid-receiver`,
      params: {
        cluster:              this.$rootGetters['clusterId'],
        alertmanagerconfigid: this.id
      },
      query: {
        mode:        queryMode || _VIEW,
        receiverName,
        currentView: _CONFIG
      }
    };
  }
}
