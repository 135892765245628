<script>
import { NAMESPACE, PVC, PV } from '@/shell/config/types';
import { PAI_RESOURCES } from '@/pkg/pai/config/types';
import { ALIAS } from '@/pkg/pai/config/labels-annotations';

import { PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
import { waitFor } from '@shell/utils/async';

export default {
  name: 'CloneVmModal',
  data() {
    return {
      namespaces: [],
      pvcs:       [],
      loading:    false,
      form:       {
        name:      '',
        namespace: ''
      },
      rules: {
        name: [{
          required: true,
          message:  this.t('pai.edit.placeholder') + this.t('pai.vmset.name'),
          trigger:  'blur'
        }],
        namespace: [{
          required: true,
          message:  this.t('pai.edit.SelectPlaceholder') + this.t('namespace.label'),
          trigger:  'blur'
        }],
      },
      cloneMessage: null,
    };
  },
  async fetch() {
    this.namespaces = await this.$store.dispatch('cluster/findAll', { type: NAMESPACE });
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'cloneVmModal';
    },
    value() {
      return this.$store.state['pai-common'].currentItem;
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else {
        return 'local';
      }
    },
  },
  methods: {
    handleClose() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
    onConfirm() {
      this.$refs.form.validate(async(valid) => {
        if (valid) {
          // 判断同命名空间是否有同样的name存在
          const sameNameVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });

          const sameNameVm = sameNameVms.find((vm) => vm.annotations[ALIAS] === this.form.name && vm.metadata.namespace === this.form.namespace);

          if (sameNameVm) {
            await this.$message({
              type:    'error',
              message: this.t('pai.vmset.tips.namespaceRepeat')
            });

            return;
          }
          this.loading = true;
          try {
            const newName = `${ this.value.metadata.name }-clone-${ new Date().getTime() }`;
            const initialModel = await this.$store.dispatch(`cluster/create`, {
              type:     PAI_RESOURCES.VM_CLONE,
              metadata: {
                namespace: this.value.metadata.namespace,
                name:      newName
              },
              spec: {
                vm:            this.value.metadata.name,
                destNamespace: this.form.namespace,
                destVM:        newName,
                alias:         this.form.name
              }
            });

            await initialModel.save();
            await this.$message({
              type:    'success',
              message: this.t('pai.vmset.tips.clone.waiting')
            });
            const clone = await this.$store.dispatch(`cluster/find`, {
              type: PAI_RESOURCES.VM_CLONE,
              id:   `${ this.value.metadata.namespace }/${ newName }`,
              opt:  { force: true }
            });

            await waitFor(() => {
              return clone.status.phase === 'Succeeded' || clone.status.phase === 'Failed';
            });
            const checkClone = await this.$store.dispatch(`cluster/find`, {
              type: PAI_RESOURCES.VM_CLONE,
              id:   `${ this.value.metadata.namespace }/${ newName }`,
              opt:  { force: true }
            });

            if (checkClone.status.phase === 'Failed') {
              await this.$message({
                type:    'error',
                message: `${ this.t('pai.vmset.tips.clone.failed') }：${ checkClone.status.message }`
              });
              this.loading = false;
            }
            if (checkClone.status.phase === 'Succeeded') {
              await this.$message({
                type:    'success',
                message: this.t('pai.vmset.tips.clone.succeeded')
              });
              await this.handleClose();
              this.loading = false;
              await this.$router.push({
                name:   `${ PAI }-c-cluster-resource`,
                params: {
                  product:  PAI,
                  resource: PAI_RESOURCES.VMSET,
                  cluster:  this.currentCluster,
                },
              });
            }
            // 强制刷新缓存
            this.$parent.initVms();
          } catch (e) {
            await this.$message({
              type:    'warning',
              message: e.message
            });
            this.loading = false;
          }
        }
      });
    },
  },
};
</script>
<template>
  <el-dialog
    :title="t('dialog.cloneDialog.title')"
    :visible.sync="dialogVisible"
    width="30%"
    :before-close="handleClose"
    :inline-message="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <el-form
      ref="form"
      :model="form"
      :rules="rules"
      label-width="auto"
    >
      <el-form-item
        :label="t('pai.vmset.name')"
        prop="name"
      >
        <el-input v-model="form.name" />
      </el-form-item>
      <el-form-item
        :label="t('namespace.selectNamespace')"
        prop="namespace"
      >
        <el-select
          v-model="form.namespace"
          filterable
        >
          <el-option
            v-for="(item) in namespaces.map(v=>v.metadata.name)"
            :key="item"
            :label="item"
            :value="item"
          />
        </el-select>
      </el-form-item>
    </el-form>
    <span
      slot="footer"
      class="dialog-footer"
    >
      <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        @click="onConfirm"
      >{{ t('pai.detail.vmset.confirm') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
.el-input, .el-select{
  width: 100%;
}
::v-deep .el-form-item__label {
  line-height: unset;
}
::v-deep .el-dialog__body {
  overflow-y: hidden!important;
}
</style>
