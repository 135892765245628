<template>
  <div class="alarmRules">
    <div class="box-title flex">
      <div>{{ t('pai.detail.vmset.tab.monitor.alarmNodes') }}</div>
      <div>
        <el-select
          v-model="clustersVal"
          class="mySelect"
          :placeholder="t('pai.detail.vmset.allCluster')"
          filterable
          @change="clusterValChange"
        >
          <el-option
            v-for="(item, index) in kubeClusters"
            :key="index"
            :label="item.nameDisplay"
            :value="item.id"
            :disabled="item.metadata.state.name !== 'active'"
          />
        </el-select>
      </div>
    </div>
    <div
      v-if="alarmRulesData.length !== 0"
      ref="legend"
      style="width: 100%;height: 20px"
    />
    <div
      ref="alarmRules"
      style="width: 100%;height: 200px"
    />
  </div>
</template>

<script>
import * as echarts from 'echarts';
import monitorMixins from '../../../../../mixins/monitor';
import metricPoller from '@shell/mixins/metric-poller';

export default {
  name:   'AlarmRules',
  mixins: [monitorMixins, metricPoller],
  data() {
    return { clustersVal: '', switchAlarmData: [] };
  },
  fetch() {
    // 集群
    this.clusterData();
    this.clustersVal = this.activeCluster[0].id;
  },
  watch: {
    alarmRulesData: {
      handler(val, oldval) {
        this.$nextTick(() => {
          this.getAlarmRules();
        });

        this.switchAlarmData = val;
      },
      deep: true,
    },
    switchAlarmData: {
      handler(val, oldval) {
        this.$nextTick(() => {
          this.getAlarmRules();
        });
      },
      deep: true,
    }
  },
  methods: {
    getAlarmRules() {
      const t = this.$store.getters['i18n/t'];
      const myChart = echarts.init(this.$refs.alarmRules);
      const data = this.switchAlarmData;
      const Xdata = [];

      data.forEach((item) => {
        Xdata.push(item?.name);
      });

      let option = {};

      myChart.clear();
      if (data && data.length !== 0) {
        this.getLegend();
        option = {
          animation: false,
          grid:      {
            left:         '2%',
            right:        '2%',
            bottom:       '1%',
            top:          '11%',
            containLabel: true
          },
          tooltip: {
            trigger:     'axis',
            axisPointer: { type: 'shadow' }
          },
          xAxis: [
            { type: 'category', data: Xdata }
          ],
          yAxis: [
            {
              type:      'value',
              axisLabel: { show: false }
            }
          ],
          series: [
            {
              name:        data.map((item) => item?.name),
              type:        'bar',
              barGap:      0,
              barMaxWidth: '20px',
              emphasis:    { focus: 'series' },
              data:        data.map((item) => item?.value),
              itemStyle:   {
                color(params) {
                  let color = '';

                  data.forEach((item) => {
                    if (params.name === item.name) {
                      color = item.status == t('pai.detail.vmset.tab.monitor.emergent') ? '#C85352' : item.status == t('pai.detail.vmset.tab.monitor.warning') ? '#FCCA00' : '#4095E5';
                    }
                  });

                  return color;
                }
              }
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      t('pai.detail.vmset.tab.monitor.noMonitor'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getLegend() {
      const t = this.$store.getters['i18n/t'];
      const myChart = echarts.init(this.$refs.legend);

      const option = {
        legend: {},
        color:  ['#C85352', '#4095E5'],
        grid:   {
          containLabel: true,
          data:         [t('pai.detail.vmset.tab.monitor.emergent'), t('pai.detail.vmset.tab.monitor.prompt')]
        },
        xAxis: { type: 'value', show: false },
        yAxis: {
          type: 'category',
          show: false,
        },
        series: [
          {
            name:  t('pai.detail.vmset.tab.monitor.emergent'),
            type:  'bar',
            label: { show: false },
            show:  false
          },
          {
            name:  t('pai.detail.vmset.tab.monitor.prompt'),
            type:  'bar',
            label: { show: false },
            show:  false
          },
        ]
      };

      myChart.on('legendselectchanged', (e) => {
        let newArr = [];

        if (e.selected[t('pai.detail.vmset.tab.monitor.prompt')] === true && e.selected[t('pai.detail.vmset.tab.monitor.emergent')] === true) { // 全部展示
          newArr = [...this.alarmRulesData];
        } else if (e.name === t('pai.detail.vmset.tab.monitor.prompt') && e.selected[t('pai.detail.vmset.tab.monitor.prompt')] === false) { // 展示紧急数据
          newArr = this.switchAlarmData.filter((item) => item.status !== t('pai.detail.vmset.tab.monitor.prompt'));
        } else if (e.name === t('pai.detail.vmset.tab.monitor.prompt') && e.selected[t('pai.detail.vmset.tab.monitor.prompt')] === true) { // 展示提示数据
          newArr = this.alarmRulesData.filter((item) => item.status === t('pai.detail.vmset.tab.monitor.prompt'));
        } else if (e.name === t('pai.detail.vmset.tab.monitor.emergent') && e.selected[t('pai.detail.vmset.tab.monitor.emergent')] === false) { // 展示提示数据
          newArr = this.switchAlarmData.filter((item) => item.status !== t('pai.detail.vmset.tab.monitor.emergent'));
        } else if (e.name === t('pai.detail.vmset.tab.monitor.emergent') && e.selected[t('pai.detail.vmset.tab.monitor.emergent')] === true) { // 展示紧急数据
          newArr = this.alarmRulesData.filter((item) => item.status === t('pai.detail.vmset.tab.monitor.emergent'));
        }
        this.switchAlarmData = newArr;
      });
      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    async clusterValChange() {
      try {
        await this.getAlarmNodes([this.kubeClusters.find((i) => i.id === this.clustersVal)]);
        this.getRulesData();
      } catch (e) {
        console.log(e);
      }
    },
    async loadMetrics() {
      this.clusterValChange();
    }
  }
};
</script>

<style scoped lang="scss">
.alarmRules{
  .box-title {
    margin: 6px 0px;
    font-weight: bold;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
}
</style>
