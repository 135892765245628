<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@shell/components/form/LabeledSelect.vue';
import SectionTitle from '~/pkg/pai/components/form/SectionTitle.vue';
import UnitInput from '@shell/components/form/UnitInput';
export default {
  components: {
    LabeledInput, LabeledSelect, SectionTitle, UnitInput
  },
  props: {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    return {
      storageOptions: [{
        label: this.t('pai.detail.vmset.localStorage'),
        value: 'local-path'
      }, {
        label: this.t('pai.detail.vmset.distStorage'),
        value: 'longhorn'
      }]
    };
  },
  created() {
    this.$emit('register-before-hook', this.willSave, 'willSave');
  },
  methods: {
    willSave() {
      const errors = [];

      if (!this.value.guardConfig.configYaml.license) {
        errors.push(this.t('validation.required', { key: `license` }, true));
      }
      if (!this.value.guardConfig.configYaml.wsUri) {
        errors.push(this.t('validation.required', { key: `wsUri` }, true));
      }

      if (!this.value.images.storage) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.videoReview.images') }storage` }, true));
      }
      if (!this.value.images.redis) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.videoReview.images') }redis` }, true));
      }
      if (!this.value.images.sophon) {
        errors.push(this.t('validation.required', { key: `${ this.t('catalog.charts.videoReview.images') }sophon` }, true));
      }

      if (errors.length > 0) {
        return Promise.reject(errors);
      } else {
        return Promise.resolve();
      }
    },
  }
};
</script>

<template>
  <div style="margin-bottom: 20px">
    <div class="row mt-20">
      <div class="col span-5">
        <LabeledInput
          v-model="value.guardConfig.configYaml.license"
          label="license"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <LabeledInput
          v-model="value.guardConfig.configYaml.wsUri"
          label="wsUri"
          :required="true"
        />
      </div>
    </div>

    <!--  PVC 配置  -->
    <div class="row mt-20">
      <SectionTitle :value="t('catalog.charts.videoReview.pvc')" />
    </div>
    <div class="row mt-20">
      <div class="col span-5">
        <LabeledSelect
          v-model="value.pvc.pvcGuard.storageClass"
          :label="'pvcGuard '+t('catalog.charts.videoReview.storage')"
          :options="storageOptions"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <UnitInput
          v-model="value.pvc.pvcGuard.storageRequest"
          :label="'pvcGuard '+t('catalog.charts.videoReview.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-5">
        <LabeledSelect
          v-model="value.pvc.pvcFace.storageClass"
          :label="'pvcFace '+t('catalog.charts.videoReview.storage')"
          :options="storageOptions"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <UnitInput
          v-model="value.pvc.pvcFace.storageRequest"
          :label="'pvcFace '+t('catalog.charts.videoReview.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-5">
        <LabeledSelect
          v-model="value.pvc.pvcSys.storageClass"
          :label="'pvcSys '+t('catalog.charts.videoReview.storage')"
          :options="storageOptions"
          :required="true"
        />
      </div>
      <div class="col span-5">
        <UnitInput
          v-model="value.pvc.pvcSys.storageRequest"
          :label="'pvcSys '+t('catalog.charts.videoReview.storageSize')"
          :input-exponent="3"
          :increment="1024"
          :output-modifier="true"
          :required="true"
        />
      </div>
    </div>

    <!--镜像配置-->
    <div class="row mt-10">
      <SectionTitle :value="t('catalog.charts.videoReview.images')" />
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.storage"
          label="storage"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.redis"
          label="redis"
          :required="true"
        />
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="value.images.sophon"
          label="sophon"
          :required="true"
        />
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">

</style>
