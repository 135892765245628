export const ALIAS = 'com.tdology/alias';
export const COLLECTION = 'com.tdology/collection';
export const BUILT_IN = 'com.tdology/builtin';
export const VIRT = 'com.tdology.virt';
export const REALTIME = 'tdology.com/realtime';

export const IMAGE = {
  SIZE:       'tdology.com/os-size',
  ARCH:       'kubernetes.io/arch',
  AGENT:      'io.katacontainers.config.agent.name',
  OS:         'io.katacontainers.config.hypervisor.os',
  NAME:       'com.tdology.virt.vmimages',
  DEPEND_VM:  'depend_vm',
  DEPEND_POD: 'depend_pod',
  DEPEND_PVC: 'depend_pvc',
  LOGO:       'com.tdology.virt.vmimages/logo',
  ISO:        'com.tdology.pvc.iso',
  TOOL:       'com.tdology/toolkit',
  MONITORING: 'com.tdology/minitoring'
};

export const PVC_LABELS = {
  OS:        'com.tdology.pvc.vmos',
  MOUNT_POD: 'com.tdology.pvc.mounto',
  MOUNT_VM:  'com.tdology.virt.vmsets',
};

export const PVC_ANNOTATIONS = { TYPE: 'com.tdology.pvc.type' };

export const VMSET_ANNOTATIONS = {
  IMAGE_ALIAS:      'com.tdology.virt.vmimages/alias',
  TEMPLATE:         'com.tdology.virt.vmsets/tmpl',
  TMPLVOLUMES_TMPL: 'tmplVolumes.tmpl',
  VOLUMES_TMPL:     'volumes.tmpl',
  NETWORK_TMPL:     'network.tmpl',
  NOMANAGENIC_TMPL: 'nomanagenic.tmpl'
};

export const NAD_ANNOTATIONS = {
  TRUNKS:  'com.tdology.nad.trunks',
  VLANS:   'com.tdology.nad.vlans',
  MASTERS: 'com.tdology.nad.masters',
  BRIDGES: 'com.tdology.nad.bridges',
};
export const CLUSTER_ANNOTATIONS = { COLLECTION: 'com.kubernetes.pods/collection' };

export const MACHINE_ANNOTATIONS = {
  ACTION:       'picloud/action',
  ROLE:         'picloud/tmpl-node',
  MAIN_NIC:     'picloud/nic',
  TMPL_NODE:    'picloud/tmpl-node',
  MODE:         'picloud/mode',
  NET_CONFIG:   'picloud/netConfig',
  ZPOOL_CONFIG: 'picloud/zpoolConfig'
};

export const POD_LABELS = { JOB_NAME: 'job-name' };

export const COOKIES_LABELS = { XDESK: 'XDESK_CONNECT' };

export const GUEST_AGENT_ANNOTATIONS = { INSTALL: 'com.tdology.ga/install' };

export const RUST_DESK_ANNOTATIONS = {
  ID: 'com.tdology.desk/id', PW: 'com.tdology.desk/pw', RESTART_COUNT: 'com.tdology.desk/restartCount'
};
