import { render, staticRenderFns } from "./Jump.vue?vue&type=template&id=19b51dd3&scoped=true"
import script from "./Jump.vue?vue&type=script&lang=js"
export * from "./Jump.vue?vue&type=script&lang=js"
import style0 from "./Jump.vue?vue&type=style&index=0&id=19b51dd3&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "19b51dd3",
  null
  
)

export default component.exports