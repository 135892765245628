<script>
import { LabeledInput } from '@components/Form/LabeledInput';
import { CATALOG } from '@shell/config/types';
import { PAI_CATALOG } from '../config/types';

export default {
  components: { LabeledInput },
  props:      {
    value: {
      type:     Object,
      required: true,
    }
  },
  data() {
    if (!this.value.globalConfig) {
      this.$set(this.value, 'globalConfig', {
        devices: { include: [] },
        vgs:     { include: [] },
        zpools:  { include: [] }
      });
    }

    return {
      monitorDisabled: true,
      devices:         this.value.globalConfig?.devices?.include?.length ? this.value.globalConfig?.devices?.include?.[0] : '',
      vgs:             this.value.globalConfig?.vgs?.include?.length ? this.value.globalConfig?.vgs?.include?.[0] : '',
      zpools:          this.value.globalConfig?.zpools?.include?.length ? this.value.globalConfig?.zpools?.include?.[0] : '',
    };
  },
  async fetch() {
    const isRancher = await this.$store.getters['isRancher'];
    const agentName = 'rancher-monitoring';

    // 判断是否安装了rancher-monitoring
    if (isRancher) {
      const charts = await this.$store.dispatch('cluster/findAll', { type: CATALOG.APP });

      if (!!charts.find((v) => v.name.includes(agentName))) {
        this.monitorDisabled = false;
      }
    } else {
      const charts = await this.$store.dispatch('cluster/findAll', { type: PAI_CATALOG.HELM_CHART });

      if (!!charts.find((v) => v.name.includes(agentName))) {
        this.monitorDisabled = false;
      }
    }
  },
  methods: {
    setVgs(e) {
      this.$set(this.value.globalConfig, 'vgs', { include: [e] });
    },
    setDevices(e) {
      this.$set(this.value.globalConfig, 'devices', { include: [e] });
    },
    setZpools(e) {
      this.$set(this.value.globalConfig, 'zpools', { include: [e] });
    },
  }
};
</script>

<template>
  <div class="open-local">
    <div class="row mt-10">
      <div class="col span-6">
        <el-checkbox
          v-model="value.monitor.enabled"
          :disabled="monitorDisabled"
        >
          {{ t('catalog.charts.openLocal.monitorInstall') }}
        </el-checkbox>
      </div>
      <div class="col span-6">
        <div
          class="tips-content"
          style="padding-top: 0px"
        >
          {{ t('catalog.charts.openLocal.monitorTip') }}
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="vgs"
          :label="t('catalog.charts.openLocal.lvmGroup')"
          @input="setVgs"
        />
      </div>
      <div class="col span-6">
        <div class="tips-content">
          {{ t('catalog.charts.openLocal.lvmTip') }}
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="devices"
          :label="t('catalog.charts.openLocal.devices')"
          @input="setDevices"
        />
      </div>
      <div class="col span-6">
        <div class="tips-content">
          {{ t('catalog.charts.openLocal.devicesTip') }}
        </div>
      </div>
    </div>
    <div class="row mt-10">
      <div class="col span-6">
        <LabeledInput
          v-model="zpools"
          :label="t('catalog.charts.openLocal.zpools')"
          @input="setZpools"
        />
      </div>
      <div class="col span-6">
        <div class="tips-content">
          {{ t('catalog.charts.openLocal.zpoolsTip') }}
        </div>
      </div>
    </div>
  </div>
</template>
<style scoped lang="scss">
.open-local{
  .tips-content{
    padding-top: 14px;
    color: #6C6C76;
  }
}

</style>
