<script>
import { SERVICE_ACCOUNT } from '@shell/config/types';
import * as XLSX from 'xlsx';
import { PAI } from '~/pkg/pai/config/types';
import { SHA256 } from '~/pkg/pai/utils/sh256';
import { randomStr } from '@shell/utils/string';

export default {
  name: 'ImportUsersModal',
  async fetch() {
    await this.$store.dispatch('cluster/findAll', { type: PAI.SPOOFED.USER_MANAGE, opt: { force: true } });
  },
  data() {
    const column = {
      userName: `${ this.t('login.label.user') }*`, displayName: this.t('pai.edit.user.name'), telPhone: this.t('pai.edit.user.telPhone'), description: this.t('user.edit.credentials.userDescription.label'), password: `${ this.t('login.label.password') }*`
    };
    const excelHeaders = [`${ this.t('login.label.user') }*`, this.t('pai.edit.user.name'), this.t('pai.edit.user.telPhone'), this.t('user.edit.credentials.userDescription.label'), `${ this.t('login.label.password') }*`];

    return {
      loading:     false,
      column,
      excelHeaders,
      fileList:    [],
      excelData:   [],
      successData: [],
      uploadError: false,
    };
  },
  computed: {
    dialogVisible() {
      return this.$store.state['pai-common'].currentModal === 'ImportUsersModal';
    },
    exitUserList() {
      return this.$store.getters['cluster/all'](PAI.SPOOFED.USER_MANAGE);
    },
  },
  methods: {
    handleClose() {
      this.fileList = [];
      this.excelData = [];
      this.successData = [];
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      this.loading = false;
    },
    async batchCreatUser() {
      const DEFAULT_GROUP = 'system:authenticated';

      this.loading = true;
      for (let i = 0; i < this.excelData.length; i++) {
        const item = this.excelData[i];
        const salt = randomStr(8);
        const password = `${ salt }:${ SHA256(`${ item[this.column.password] }:${ salt }`) }`;
        const user = await this.$store.dispatch('cluster/create', {
          type:        SERVICE_ACCOUNT,
          description: item[this.column.description],
          metadata:    {
            annotations: {
              groups:   [DEFAULT_GROUP, item[this.column.userName]].join(','),
              password,
              userInfo: JSON.stringify({
                displayName: item[this.column.displayName],
                description: item[this.column.description],
                createTime:  new Date().getTime(),
                telPhone:    item[this.column.telPhone]
              }),
            },
            labels:    { 'users.tdology.com': 'true' },
            name:      String(item[this.column.userName]),
            namespace: 'kube-system',
          },
        });

        await user.save();
        this.successData.push(user);
      }
      this.loading = false;
      await this.$store.dispatch('pai-common/updateState', { currentModal: '' });
      this.$message.success(this.t('nav.usermanage.batchCreate'));
      await this.$store.dispatch('cluster/findAll', {
        type: PAI.SPOOFED.USER_MANAGE,
        opt:  { force: true }
      });
      this.fileList = [];
      this.excelData = [];
      this.successData = [];
    },
    handleChange(file, fileList) {
      this.fileList = fileList.map((file) => {
        if (file.response) {
          return file.response.data;
        }

        return file;
      });
      if (this.uploadError === true) {
        if (fileList.length === 1) {
          this.fileList = fileList;
        } else {
          this.fileList.push(file);
        }
      }
      this.readExcelFile(file.raw);
    },
    readExcelFile(file) {
      const fileReader = new FileReader();

      fileReader.readAsBinaryString(file); // 二进制
      fileReader.onload = async(event) => {
        try {
          const { result } = event.target;
          // 以二进制流方式读取得到整份excel表格对象
          const workbook = XLSX.read(result, { type: 'binary' });

          // 获取工作表
          const worksheetName = workbook.SheetNames[0];
          const worksheet = workbook.Sheets[worksheetName];

          const jsonHeaders = XLSX.utils.sheet_to_json(worksheet, { header: 1 })[0];

          workbook.SheetNames.forEach((sheetName) => {
            const sheetData = XLSX.utils.sheet_to_json(workbook.Sheets[sheetName]);

            // 将工作表转换为JSON对象数组
            this.excelData.push(...sheetData); // 将每个Sheet的数据添加到allSheetsData数组中
          });

          if (this.excelData.length <= 0) {
            this.$message.error(this.t('nav.usermanage.tips.emptyExcel'));
            this.fileList = [];

            return false;
          }

          for (let i = 0; i < this.excelHeaders.length; i++) {
            if (this.excelHeaders.length !== jsonHeaders.length || this.excelHeaders[i] !== jsonHeaders[i]) {
              this.$message.error(this.t('nav.usermanage.tips.fileTemplate'));
              this.fileList = [];
              this.excelData = [];

              return false;
            }
          }
          const userNameReg = /^[^\u4e00-\u9fa5 ]{3,15}$/;
          const pwdReg = /^[^\u4e00-\u9fa5 ]{6,18}$/;

          // 校验excel表格用户名重复
          const newExcelData = this.excelData.map((item) => item[this.column.userName]);

          if (newExcelData.some((val, index) => newExcelData.indexOf(val) !== index) === true) {
            this.$message.error(this.t('nav.usermanage.tips.excelSameName'));
            this.fileList = [];
            this.excelData = [];

            return false;
          }

          for (let i = 0; i < this.exitUserList.length; i++) {
            for (let j = 0; j < this.excelData.length; j++) {
              const excelDataItem = this.excelData[j];

              if (this.exitUserList[i].metadata.name === String(excelDataItem[this.column.userName])) {
                this.$message.error(this.t('nav.usermanage.tips.excelExistsName'));
                this.fileList = [];
                this.excelData = [];

                return false;
              }
            }
          }
          for (let j = 0; j < this.excelData.length; j++) {
            const excelDataItem = this.excelData[j];

            if (!excelDataItem[this.column.userName]) {
              this.$message.error(this.t('login.label.user') + this.t('pai.verify.required'));
              this.fileList = [];
              this.excelData = [];

              return false;
            } else if (!userNameReg.test(excelDataItem[this.column.userName])) {
              this.$message.error(this.t('nav.usermanage.tips.userNameReg'));
              this.fileList = [];
              this.excelData = [];

              return false;
            } else if (!excelDataItem[this.column.password]) {
              this.$message.error(this.t('login.label.password') + this.t('pai.verify.required'));
              this.fileList = [];
              this.excelData = [];

              return false;
            } else if (!pwdReg.test(excelDataItem[this.column.password])) {
              this.$message.error(this.t('nav.usermanage.tips.pwdReg'));
              this.fileList = [];
              this.excelData = [];

              return false;
            }
          }
        } catch (e) {
          if (e) {
            this.$message.error(this.t('nav.usermanage.tips.FileCorruption'));
            this.fileList = [];
            this.excelData = [];

            return false;
          }
        }
      };
    },
    handleImport() {
      this.batchCreatUser();
    },
    handleError(err) {
      // 处理文件上传失败
      if (err) {
        this.uploadError = true;
      }
    },
    handleRemove() {
      this.fileList = [];
      this.excelData = [];
    }
  },
};
</script>
<template>
  <el-dialog
    :title="t('nav.usermanage.batchImport')+t('pai.edit.gateway.user')"
    :visible.sync="dialogVisible"
    width="40%"
    :before-close="handleClose"
    :inline-message="true"
    :close-on-click-modal="false"
    :modal-append-to-body="false"
  >
    <span v-if="fileList.length < 1">{{ t('nav.usermanage.tips.fileTemplate') +'，'+ t('nav.usermanage.tips.fileSupport') }}</span>
    <el-upload
      action=""
      :file-list="fileList"
      :on-change="handleChange"
      :on-error="handleError"
      :on-remove="handleRemove"
      :limit="1"
      accept=".xls, .xlsx"
      :auto-upload="false"
      class="custom-upload"
    >
      <button
        type="button"
        class="btn role-primary"
        :disabled="fileList.length>=1"
        :style="{margin: fileList.length>=1? '50px 0 0 -180px': '50px 0 0 -10px'}"
      >
        {{ t('nav.usermanage.clickToUpload') }}
      </button>
      <div
        v-if="fileList.length>=1"
        slot="tip"
        class="el-upload__tip"
        style="margin: -25px 0 0 90px; font-size: 14px; color: #e99d42;"
      >
        {{ t('nav.usermanage.all') + excelData.length + t('nav.usermanage.strip') }}，{{ t('nav.usermanage.successfullyUploaded') + successData.length + t('nav.usermanage.strip') }}
      </div>
    </el-upload>
    <span
      slot="footer"
      class="dialog-footer"
      style="display: block; margin: 40px 0 20px 0;"
    >
      <el-button @click="handleClose">{{ t('nav.usermanage.cancelImport') }}</el-button>
      <el-button
        type="primary"
        :loading="loading"
        :disabled="fileList.length < 1"
        @click="handleImport"
      >{{ t('nav.usermanage.startImport') }}</el-button>
    </span>
  </el-dialog>
</template>
<style lang="scss" scoped>
::v-deep .el-dialog__body {
  padding: 85px !important;
}
::v-deep .el-upload-list {
  margin: -85px 0 0 -90px !important;
}
</style>
