<script>
import CreateEditView from '@shell/mixins/create-edit-view';
import SectionTitle from '../components/form/SectionTitle';
import { LabeledInput } from '@components/Form/LabeledInput';
import LabeledSelect from '@/shell/components/form/LabeledSelect';
import CruResource from '@shell/components/CruResource';
import LabeledRadioGroup from '../components/form/LabeledRadioGroup';
import { _CLONE, _CREATE, _EDIT } from '@shell/config/query-params';
import { ALIAS, MACHINE_ANNOTATIONS, VMSET_ANNOTATIONS } from '../config/labels-annotations';
import { kparamsToArr } from '../utils/string';
import { set } from '@shell/utils/object';
import { PAI_NODE, PAI_PLATFORM_SETTING_TYPES, PAI_RESOURCES } from '../config/types';
import ZPOOLSettings from '../components/ZPOOLSettings';
import Tabbed from '../components/Tabbed/index.vue';
import Tab from '../components/Tabbed/Tab.vue';
import ResourceTable from '@/pkg/pai/components/ResourceTable';
import EditableCell from '~/pkg/pai/components/EditableCell.vue';
import { Checkbox } from '@components/Form/Checkbox';
import { PRODUCT_NAME as PAI, PRODUCT_NAME } from '../config/pai';

export default {
  components: {
    LabeledRadioGroup,
    CruResource,
    LabeledInput,
    LabeledSelect,
    SectionTitle,
    ZPOOLSettings,
    Tabbed,
    Tab,
    ResourceTable,
    EditableCell,
    Checkbox,
  },

  mixins: [CreateEditView],
  props:  {
    value: {
      type:     Object,
      required: true,
      default:  () => {
        return {};
      }
    },
    mode: {
      type:    String,
      default: 'edit'
    },
    realMode: {
      type:    String,
      default: 'edit'
    }
  },
  async fetch() {
    this.gatewayData = await this.$store.dispatch('management/findAll', { type: PAI_PLATFORM_SETTING_TYPES.GATEWAY });
    this.nodeDatas = await this.$store.dispatch('cluster/findAll', { type: 'node' });

    if (this.value?.status?.mode === 'mode=live' || !this.value?.status?.mode) {
      this.isLiveMode = true;
    }
    if (this.realMode === _EDIT) {
      this.initData();
    }
    await this.getDiskData();
    await this.getDevsData();
  },
  data() {
    let kparams = [];

    if (this.value?.spec?.kparams) {
      kparams = kparamsToArr(this.value.spec.kparams);
    }

    let sshAuthorizedKeys = [];

    if (this.value?.spec?.cloudconfig?.sshAuthorizedKeys) {
      sshAuthorizedKeys = this.value?.spec?.cloudconfig?.sshAuthorizedKeys;
    }

    if (this.value?.status?.mode) {
      this.value.setAnnotation(MACHINE_ANNOTATIONS.MODE, this.value?.status?.mode);
    } else {
      this.value.setAnnotation(MACHINE_ANNOTATIONS.MODE, 'mode=live');
    }
    if (!!this.value.metadata?.annotations['picloud/newname'] || !!this.value.metadata?.annotations['picloud/tmpl']) {
      this.value.setAnnotation(MACHINE_ANNOTATIONS.ACTION, 'provision');
    }

    let netConfig = {};

    if (this.value.metadata?.annotations[MACHINE_ANNOTATIONS.NET_CONFIG]) {
      netConfig = JSON.parse(this.value.metadata.annotations[MACHINE_ANNOTATIONS.NET_CONFIG]);
    }

    let ZPOOLConfig = {};

    if (this.value.metadata?.annotations[MACHINE_ANNOTATIONS.ZPOOL_CONFIG]) {
      ZPOOLConfig = JSON.parse(this.value.metadata.annotations[MACHINE_ANNOTATIONS.ZPOOL_CONFIG]);
    }

    // default setting
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/role']) {
      this.$set(this.value.metadata.annotations, 'picloud/role', 'agent');
    }
    if (!this.value.metadata.annotations || !this.value.metadata.annotations['picloud/seednode']) {
      this.$set(this.value.metadata.annotations, 'picloud/seednode', 'false');
    }

    return {
      kparams,
      sshAuthorizedKeys,
      tip:                '',
      gatewayData:        [],
      MACHINE_ANNOTATIONS,
      nodeDatas:          [],
      closeIP:            [],
      bondModual:         false,
      bondTable:          [],
      networkOption:      [],
      bondingModeItems:   ['balance-rr', 'active-backup', 'balance-xor', 'broadcast', '802.3ad', 'balance-tlb', 'balance-alb'],
      bondForm:           {},
      dialogVisible:      false,
      dialogTitle:        '',
      diskDialogVisible:  false,
      bondType:           'add',
      ExistsServerDir:    false,
      ExistsLonghornDisk: false,
      isLiveMode:         false,
      devsDataFlag:       0,
      editBondNics:       [],
      bondEditIndex:      0,
      disksData:          [],
      devsData:           [],
      bondConfig:         { // 网络配置
        Bonds:        [],
        IPInfo:       {},
        WANInfo:      {},
        VLANProtocol: '802.1q',
      },
      zpoolConfig: {
        zpoolName: '',
        isMigrate: false,
        dataVdevs: [{
          list: [],
          type: 'Stripe'
        }],
        cacheVdevs: [],
        logVdevs:   [{
          list: [],
          type: 'Stripe'
        }],
        hotSpareVdevs: [],
        forceCreate:   false,
        longhorn:      {
          enable: false,
          size:   0,
          unit:   'GB'
        }
      },
      oldBasic: {
        newname:           this.value.metadata?.annotations['picloud/newname'],
        tmpl:              this.value.metadata?.annotations['picloud/tmpl'],
        os:                this.value.spec?.os,
        device:            this.value.spec?.install?.device,
        role:              this.value.metadata?.annotations['picloud/role'],
        seednode:          this.value.metadata?.annotations['picloud/seednode'],
        kparams:           kparamsToArr(this.value.spec?.kparams),
        sshAuthorizedKeys: this.value.spec?.cloudconfig?.sshAuthorizedKeys
      },
      oldBondBasic: {
        Bonds:        netConfig?.Bonds,
        IPInfo:       netConfig?.IPInfo,
        WANInfo:      netConfig?.WANInfo,
        VLANProtocol: netConfig?.VLANProtocol,
      },
      oldZPOOLBasic: {
        zpoolName:     ZPOOLConfig?.zpoolName,
        isMigrate:     ZPOOLConfig?.isMigrate,
        dataVdevs:     ZPOOLConfig?.dataVdevs,
        cacheVdevs:    ZPOOLConfig?.cacheVdevs,
        logVdevs:      ZPOOLConfig?.logVdevs,
        hotSpareVdevs: ZPOOLConfig?.hotSpareVdevs,
        forceCreate:   ZPOOLConfig?.forceCreate,
        longhorn:      ZPOOLConfig?.longhorn
      },
      oldMode:        this.value?.metadata?.annotations[MACHINE_ANNOTATIONS.MODE],
      modeType:       this.value?.metadata?.annotations[MACHINE_ANNOTATIONS.MODE],
      modeStatusCode: ''
    };
  },
  computed: {
    _CREATE() {
      return _CREATE;
    },
    _CLONE() {
      return _CLONE;
    },
    _EDIT() {
      return _EDIT;
    },
    nodeNameList() {
      const nodeNameList = this.nodes?.map((steveModel) => steveModel.metadata.name);

      nodeNameList?.splice(nodeNameList?.indexOf(this.value.metadata.name), 1);

      return nodeNameList;
    },
    clusterList() {
      return this.gatewayData.map((item) => item.metadata.name);
    },
    osList() {
      return this.$store.state['pai-common'].osList?.map((v) => {
        return ({
          label: v.split('/')[v.split('/').length - 1],
          value: v
        });
      });
    },
    cluster() {
      return this.value.metadata.labels['com.tdology.gateway'];
    },
    isEditable() {
      return this.realMode === _CREATE || this.realMode === _CLONE || this.realMode === _EDIT;
    },
    nodes() {
      return this.$store.getters['management/all'](PAI_PLATFORM_SETTING_TYPES.MACHINE);
    },
    newNameDisable() {
      return !!this.nodeDatas.find((node) => this.value.metadata.name === node.metadata?.name);
    },
    networkTable() {
      return [
        {
          mode:        'wan',
          VLANID:      '',
          NIC:         '',
          IP:          '',
          NetMask:     '',
          Nameservers: '',
          Gateway:     ''
        },
        {
          mode:        'lan',
          VLANID:      '',
          NIC:         '',
          IP:          '',
          NetMask:     '',
          Nameservers: '',
          Gateway:     ''
        },
      ];
    },
    bondNetworkOption() {
      const newArr = [];

      this.bondTable.forEach((item) => {
        newArr.push(item.Nics);
      });

      const data = this.networkOption.filter((item) => ![...new Set(newArr.flat())].some((d) => d === item));

      return this.bondType === 'add' ? data : data.concat(this.editBondNics);
    },
    networkZPOOLDisabled() {
      return !this.isEditable || !this.isLiveMode;
    },
    basicChanged() {
      if (
        this.oldBasic.newname !== this.value.metadata?.annotations['picloud/newname'] ||
          this.oldBasic.tmpl !== this.value.metadata?.annotations['picloud/tmpl'] ||
          this.oldBasic.os !== this.value.spec?.os ||
          this.oldBasic.device !== this.value.spec?.install?.device ||
          this.oldBasic.role !== this.value.metadata?.annotations['picloud/role'] ||
          this.oldBasic.seednode !== this.value.metadata?.annotations['picloud/seednode'] ||
          JSON.stringify(this.oldBasic.kparams) !== JSON.stringify(this.value.spec?.kparams ? this.value.spec?.kparams : []) ||
          JSON.stringify(this.oldBasic.sshAuthorizedKeys) !== JSON.stringify(this.value.spec?.cloudconfig?.sshAuthorizedKeys)) {
        return true;
      } else {
        return false;
      }
    },
    netBasicChange() {
      if (
        JSON.stringify(this.oldBondBasic?.Bonds) !== JSON.stringify(this.bondTable) ||
          JSON.stringify(this.oldBondBasic?.IPInfo) !== JSON.stringify(this.networkTable[1]) ||
          JSON.stringify(this.oldBondBasic?.WANInfo) !== JSON.stringify(this.networkTable[0]) ||
          JSON.stringify(this.oldBondBasic?.VLANProtocol) !== JSON.stringify(this.bondConfig?.VLANProtocol)
      ) {
        return true;
      } else {
        return false;
      }
    },
    zpoolBasicChange() {
      if (
        !!this.zpoolConfig.zpoolName ||
          this.zpoolConfig?.isMigrate === true ||
          this.zpoolConfig?.dataVdevs?.find((item) => item.name !== '' && item.name !== undefined) ||
          this.zpoolConfig?.cacheVdevs?.find((item) => item.name !== '' && item.name !== undefined) ||
          this.zpoolConfig?.logVdevs?.find((item) => item.name !== '' && item.name !== undefined) ||
          this.zpoolConfig?.hotSpareVdevs?.find((item) => item.name !== '' && item.name !== undefined) ||
          this.zpoolConfig?.forceCreate === true ||
          JSON.stringify(this.oldZPOOLBasic?.longhorn) !== JSON.stringify(this.zpoolConfig?.longhorn)
      ) {
        return true;
      } else {
        return false;
      }
    },
    modeOptios() {
      return [
        { label: this.t('pai.edit.machine.liveMode'), value: 'mode=live' },
        { label: this.t('pai.edit.machine.installationSystem'), value: 'mode=install' },
        { label: this.t('pai.edit.machine.diskBoot'), value: 'mode=disk' },
        { label: this.t('pai.edit.machine.emergencyRepair'), value: 'fallback_mode=live' }
      ];
    }
  },
  methods: {
    done() {
      this.$router.go(-1);
    },
    async getDiskData() {
      try {
        // 系统盘数据
        const disksResult = await this.$store.dispatch('pai-common/getDisks', { ip: `${ this.value.spec.ip }:61280` });

        this.disksData = disksResult.data?.Data?.data?.map((item) => item.name);
      } catch (e) {}
    },
    async getDevsData() {
      try {
        // 硬盘池数据
        const devsResult = await this.$store.dispatch('pai-common/getDevs', { ip: `${ this.value.spec.ip }:61280` });

        this.devsData = devsResult?.data?.Data?.data;
        this.devsDataFlag += 1;
      } catch (e) {

      }
    },
    async selectionChanged(e) {
      if ((this.basicChanged === true || this.netBasicChange === true || this.zpoolBasicChange === true) && e !== 'mode=live' && this.isLiveMode) {
        this.$confirm(this.t('pai.edit.machine.modeSwitchTip'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.detail.vmset.confirm'),
          cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        }).then(async() => {
          this.modeType = e;
          this.isLiveMode = e === 'mode=live';
          await this.initData();
          if (e === 'mode=install' && this.value.status.mode === 'mode=disk') {
            this.tip = this.t('pai.edit.machine.installTip');
          } else {
            this.tip = null;
          }
        }).catch( () => {
          this.oldMode = e;
        });
      } else {
        this.modeType = e;
        this.isLiveMode = e === 'mode=live';
        await this.initData();
        if (e === 'mode=install' && this.value.status.mode === 'mode=disk') {
          this.tip = this.t('pai.edit.machine.installTip');
        } else {
          this.tip = null;
        }
      }
    },
    addBond() {
      this.bondForm = {
        BondName: '',
        Nics:     '',
        Mode:     ''
      };
      this.dialogTitle = this.t('generic.add') + this.t('pai.edit.machine.bond');
      this.bondType = 'add';
      this.dialogVisible = true;
    },
    editBond(row, index) {
      this.bondForm = {
        ...row,
        Nics: row.Nics,
      };
      this.bondEditIndex = index;
      this.editBondNics = row.Nics;
      this.dialogTitle = this.t('pai.detail.vmset.edit') + this.t('pai.edit.machine.bond');
      this.bondType = 'edit';
      this.dialogVisible = true;
    },
    delBond(row, index) {
      const type = row.BondName;

      this.$confirm(this.t('promptRemove.attemptingToRemove', { type }), this.t('pai.labels.tip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(() => {
        this.bondTable.splice(index, 1);
        this.networkTable[0].NIC = '';
        this.networkTable[1].NIC = '';
      });
    },
    networkChange(row, index) {
      this.networkTable[index].NIC = row.NIC;
      if (index === 1) {
        this.value.setAnnotation(MACHINE_ANNOTATIONS.MAIN_NIC, row.NIC);
        this.networkTable[index].IP = this.value.spec.ip;
      }
    },
    storageSizeBlur(e) {
      this.zpoolConfig.longhorn.size = e.target.value >= 0 ? e.target.value : 0 ;
    },
    vlanIDChange(row, index) {
      this.networkTable[index].VLANID = row.VLANID >= 0 ? row.VLANID : 0;
    },
    beforeClose() {
      this.dialogVisible = false;
      this.editBondNics = [];
    },
    handleNodeDetail() {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-resource`,
        params: {
          product:  PRODUCT_NAME,
          resource: PAI_NODE,
        },
      });
    },
    handleTab(e) {
      if (this.isLiveMode === true) {
        this.zpoolConfig.dataVdevs = this.$refs.zpoolSettings.dataVdevs;
        this.zpoolConfig.cacheVdevs = this.$refs.zpoolSettings.cacheVdevs;
        this.zpoolConfig.logVdevs = this.$refs.zpoolSettings.logVdevs;
        this.zpoolConfig.hotSpareVdevs = this.$refs.zpoolSettings.hotSpareVdevs;
        if (e.selectedName === 'ZPOOLBasic') {
          this.$refs.zpoolSettings.initDevsData();
        } else if (e.selectedName === 'basic') {
          const dataVdevs = this.$refs.zpoolSettings.dataVdevs;
          const cacheVdevs = this.$refs.zpoolSettings.cacheVdevs;
          const logVdevs = this.$refs.zpoolSettings.logVdevs;
          const hotSpareVdevs = this.$refs.zpoolSettings.hotSpareVdevs;

          const newArr = (dataVdevs?.map((item) => item.list).concat(logVdevs?.map((item) => item.list)).concat(cacheVdevs).concat(hotSpareVdevs))
            .flat();

          this.disksData = this.disksData?.filter((item) => !newArr.some((n) => n.name === item));
        }
      }
    },
    async initData() {
      if (this.value.metadata?.annotations[MACHINE_ANNOTATIONS.NET_CONFIG]) { // 网络配置
        const bondConfig = JSON.parse(this.value.metadata.annotations[MACHINE_ANNOTATIONS.NET_CONFIG]);

        this.bondConfig = bondConfig;
        if (bondConfig.Bonds?.length !== 0) {
          this.bondTable = bondConfig.Bonds;
          this.bondModual = true;
        }
        const wanInfo = bondConfig.WANInfo;
        const lanInfo = bondConfig.IPInfo;

        this.networkTable[0].Gateway = wanInfo.Gateway;
        this.networkTable[0].IP = wanInfo.IP;
        this.networkTable[0].NIC = wanInfo.NIC;
        this.networkTable[0].Nameservers = wanInfo.Nameservers;
        this.networkTable[0].NetMask = wanInfo.NetMask;
        this.networkTable[0].VLANID = wanInfo.VLANID;

        this.networkTable[1].Gateway = lanInfo.Gateway;
        this.networkTable[1].IP = lanInfo.IP;
        this.networkTable[1].NIC = lanInfo.NIC;
        this.networkTable[1].Nameservers = lanInfo.Nameservers;
        this.networkTable[1].NetMask = lanInfo.NetMask;
        this.networkTable[1].VLANID = lanInfo.VLANID;
      } else {
        //  获取内\外网默认数据
        try {
          if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.NAD) && !this.value.metadata.annotations[MACHINE_ANNOTATIONS.NET_CONFIG]) {
            const nadResult = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.NAD });
            const wanData = nadResult.find((item) => item.metadata.name === 'cluster-wan');
            const lanData = nadResult.find((item) => item.metadata.name === 'cluster-lan');

            if (wanData) {
              const wan = JSON.parse(wanData.spec.config);
              const address = wan.ipam.addresses[0];

              this.networkTable[0].VLANID = wan.vlan || 0;
              this.networkTable[0].IP = address.address.split('/')[0];
              this.networkTable[0].NetMask = address.address.split('/')[1];
              this.networkTable[0].Gateway = address.gateway;
              this.networkTable[0].Nameservers = wan.dns.nameservers;
            }
            if (lanData) {
              const lan = JSON.parse(lanData.spec.config);
              const address = lan.ipam.addresses[0];

              this.networkTable[1].VLANID = lan.vlan || 0;
              this.networkTable[1].IP = this.value.spec.ip;
              this.networkTable[1].NetMask = address.address.split('/')[1];
              this.networkTable[1].Gateway = address.gateway;
              this.networkTable[1].Nameservers = lan.dns.nameservers;
            }
          }
        } catch (e) {}

        this.oldBondBasic = {
          Bonds:  this.bondTable,
          IPInfo: {
            ...this.networkTable[1],
            Nameservers: this.networkTable[1]?.Nameservers,
            NetMask:     this.networkTable[1]?.NetMask,
            VLANID:      this.networkTable[1]?.VLANID
          },
          WANInfo: {
            ...this.networkTable[0],
            Nameservers: this.networkTable[0]?.Nameservers,
            NetMask:     this.networkTable[0]?.NetMask,
            VLANID:      this.networkTable[0]?.VLANID,
          },
          VLANProtocol: this.bondConfig?.VLANProtocol,
        };
      }
      if (!this.networkZPOOLDisabled) {
        this.zpoolConfig = {
          zpoolName: '',
          isMigrate: false,
          dataVdevs: [{
            list: [],
            type: 'Stripe'
          }],
          cacheVdevs: [],
          logVdevs:   [{
            list: [],
            type: 'Stripe'
          }],
          hotSpareVdevs: [],
          forceCreate:   false,
          longhorn:      {
            enable: false,
            size:   0,
            unit:   'GB'
          }
        };
        this.oldZPOOLBasic.longhorn = {
          enable: false,
          size:   0,
          unit:   'GB'
        };
        this.devsDataFlag += 1;
      } else {
        this.value.setAnnotation('picloud/newname', this.oldBasic.newname);
        this.value.setAnnotation('picloud/tmpl', this.oldBasic.tmpl);
        this.$set(this.value.spec, 'os', this.oldBasic.os);
        this.$set(this.value.spec, 'install.device', this.oldBasic.device);
        this.value.setAnnotation('picloud/role', this.oldBasic.role);
        this.value.setAnnotation('picloud/seednode', this.oldBasic.seednode);
        this.kparams = this.oldBasic.kparams ? this.oldBasic.kparams : [];
        this.sshAuthorizedKeys = this.oldBasic.sshAuthorizedKeys ? this.oldBasic.sshAuthorizedKeys : [];

        if (this.value.metadata?.annotations[MACHINE_ANNOTATIONS.ZPOOL_CONFIG]) { // ZPOOL数据
          this.zpoolConfig.zpoolName = this.oldZPOOLBasic?.zpoolName;
          this.zpoolConfig.isMigrate = this.oldZPOOLBasic?.isMigrate;
          this.zpoolConfig.dataVdevs = this.oldZPOOLBasic?.dataVdevs;
          this.zpoolConfig.cacheVdevs = this.oldZPOOLBasic?.cacheVdevs;
          this.zpoolConfig.logVdevs = this.oldZPOOLBasic?.logVdevs;
          this.zpoolConfig.hotSpareVdevs = this.oldZPOOLBasic?.hotSpareVdevs;
          this.zpoolConfig.forceCreate = this.oldZPOOLBasic?.forceCreate;
          this.zpoolConfig.longhorn = this.oldZPOOLBasic?.longhorn;
          this.devsDataFlag += 1;
        }
      }

      // 根据返回状态判断 ‘当前ZPOOL保存集群管理数据’、‘创建分布式块存储’是否可以勾选
      try {
        const checkResult = await this.$store.dispatch('pai-common/getCheckDisable', { ip: `${ this.value.spec.ip }:61280` });
        const data = checkResult.data.Data;

        this.ExistsServerDir = data.ExistsServerDir;
        this.ExistsLonghornDisk = data.ExistsLonghornDisk;
      } catch (e) {}
      // 获取网卡数据
      try {
        const networkResult = await this.$store.dispatch('pai-common/getNetwork', { ip: `${ this.value.spec.ip }:61280` });

        this.networkOption = networkResult.data.Data.data?.map((item) => {
          return item.NIC;
        });
      } catch (e) {}
    },
    onConfirm() {
      const bondRow = this.bondTable.find((item) => item.BondName === this.bondForm.BondName);

      if (!this.bondForm.BondName) {
        this.$message.warning(this.t('pai.edit.machine.name') + this.t('pai.verify.required'));

        return false;
      } else {
        if (this.bondForm.BondName === 'bond0') {
          this.$message.warning('为避免名称冲突，名称不可使用“bond0”');

          return false;
        }
        if (!/^[a-zA-Z0-9]+$/.test(this.bondForm.BondName)) {
          this.$message.warning('名称仅支持数字字母');

          return false;
        }
        if (!/^.{1,13}$/.test(this.bondForm.BondName)) {
          this.$message.warning('名称长度不能超过 13 位');

          return false;
        }
        if (this.bondType === 'add' && bondRow) {
          this.$message.warning(this.t('pai.edit.machine.nameRepeat'));

          return false;
        }
      }

      if (!this.bondForm.Nics) {
        this.$message.warning(this.t('pai.edit.machine.network') + this.t('pai.verify.required'));

        return false;
      }
      if (this.bondForm.Nics && this.bondForm.Nics.length < 2) {
        this.$message.warning('至少需要两张网卡');

        return false;
      }
      if (!this.bondForm.Mode) {
        this.$message.warning(this.t('pai.edit.machine.mode') + this.t('pai.verify.required'));

        return false;
      }

      if (this.bondType === 'add') {
        this.bondTable.push({ ...this.bondForm, Nics: this.bondForm.Nics });
      } else {
        this.bondTable[this.bondEditIndex].BondName = this.bondForm.BondName;
        this.bondTable[this.bondEditIndex].Mode = this.bondForm.Mode;
        this.bondTable[this.bondEditIndex].Nics = this.bondForm.Nics;
        this.networkTable[0].NIC = '';
        this.networkTable[1].NIC = '';
      }
      this.beforeClose();
    },
    async saveScan(buttonCb) {
      for (let i = 0; i < this.nodes.length; i++) {
        if (this.realMode === _CREATE) {
          if (this.nodes[i].metadata.name === this.value.metadata.name) {
            this.$message.error(this.t('pai.detail.vmset.duplicateNames'));
            buttonCb(false);

            return;
          }
        } else if (this.nodes[i].metadata.name === this.value.metadata.annotations['picloud/newname']) {
          this.$message.error(this.t('pai.detail.vmset.duplicateNames'));
          buttonCb(false);

          return;
        }
      }
      if (this.isEditable) {
        if (!this.value.metadata.name || !this.value.spec.ip || !this.value.metadata?.labels['com.tdology.gateway'] || !this.value.spec.os || !this.value.spec.arch) {
          this.$message.error(this.t('pai.edit.tips.required'));
          buttonCb(false);

          return;
        } else if (!/^(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9])\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[1-9]|0)\.(25[0-5]|2[0-4][0-9]|[0-1]{1}[0-9]{2}|[1-9]{1}[0-9]{1}|[0-9])$/.test(this.value.spec.ip)) {
          this.$message.error(this.t('pai.edit.tips.ipError'));
          buttonCb(false);

          return;
        } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.name) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.metadata.annotations['picloud/alias']) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.install.device) || (/[!@#$%^&*()>?<";~`|+={}]/).test(this.value.spec.arch)) {
          this.$message.error(this.t('pai.edit.tips.specialCharacters'));
          buttonCb(false);

          return;
        }
        if (this.sshAuthorizedKeys?.length !== 0) {
          const reg = /^(?=.*[a-zA-Z])(?=.*\d)(?!.*[\u4e00-\u9fa5]).+$/;

          for (let i = 0;i < this.sshAuthorizedKeys.length ; i++) {
            if (!!this.sshAuthorizedKeys[i] && (this.sshAuthorizedKeys[i].slice(0, 11) !== 'ssh-ed25519' || !reg.test(this.sshAuthorizedKeys[i].slice(11)))) {
              this.$message.error( `第${ i + 1 }${ this.t('pai.edit.machine.strip') }${ this.t('pai.edit.machine.SSHKey') }${ this.t('pai.edit.machine.SSHRule') }`);

              buttonCb(false);

              return;
            }
          }
        }
      }

      if (this.realMode === _EDIT) {
        if (this.isLiveMode) {
          const dataVdevs = this.$refs.zpoolSettings.dataVdevs;
          const cacheVdevs = this.$refs.zpoolSettings.cacheVdevs;
          const logVdevs = this.$refs.zpoolSettings.logVdevs;
          const hotSpareVdevs = this.$refs.zpoolSettings.hotSpareVdevs;

          const isDataVdevs = dataVdevs?.filter((item) => item?.list?.find((l) => !!l.name));
          const isCacheVdevs = cacheVdevs?.length !== 0;
          const isLogVdevs = logVdevs?.filter((item) => item?.list?.find((l) => !!l.name));
          const isHotSpareVdevs = hotSpareVdevs?.length !== 0;

          if ((isDataVdevs?.length !== 0 || isCacheVdevs || isLogVdevs?.length !== 0 || isHotSpareVdevs) && !this.zpoolConfig.zpoolName) { // 分配硬盘信息有数据时校验名称为必填项
            this.$message.error(this.t('pai.edit.machine.ZPOOLBasic') + this.t('pai.edit.machine.name') + this.t('pai.verify.required'));
            buttonCb(false);

            return;
          }
          const bondConfig = {
            ...this.bondConfig,
            Bonds:   this.bondTable,
            WANInfo: { // 外网
              ...this.networkTable[0],
              Nameservers: Array.isArray(this.networkTable[0]?.Nameservers) ? this.networkTable[0]?.Nameservers.flat() : [this.networkTable[0]?.Nameservers],
              NetMask:     Number(this.networkTable[0]?.NetMask),
              VLANID:      Number(this.networkTable[0]?.VLANID),
            },
            IPInfo: { // 内网
              ...this.networkTable[1],
              Nameservers: Array.isArray(this.networkTable[1]?.Nameservers) ? this.networkTable[1]?.Nameservers.flat() : [this.networkTable[1]?.Nameservers],
              NetMask:     Number(this.networkTable[1]?.NetMask),
              VLANID:      Number(this.networkTable[1]?.VLANID)
            },
          };
          const zpoolConfig = {
            ...this.zpoolConfig,
            dataVdevs,
            cacheVdevs,
            logVdevs,
            hotSpareVdevs,
            longhorn: { ...this.zpoolConfig?.longhorn, size: Number(this.zpoolConfig?.longhorn?.size) }
          };

          this.$set(this.value.metadata.annotations, MACHINE_ANNOTATIONS.NET_CONFIG, JSON.stringify(bondConfig));
          this.$set(this.value.metadata.annotations, MACHINE_ANNOTATIONS.ZPOOL_CONFIG, JSON.stringify(zpoolConfig));
          // 获取网络配置信息
          try {
            const param = {
              data:    bondConfig,
              headers: { headers: { 'mini-proxy': `${ this.value.spec.ip }:61280` } }
            };

            const configResult = await this.$store.dispatch('pai-common/getNetworkConfig', param);

            if (!this.value.spec.cloudconfig.runCmd) {
              this.$set(this.value.spec.cloudconfig, 'runCmd', [configResult.data.Data]);
            } else {
              this.value.spec.cloudconfig.runCmd[0] = configResult.data.Data;
            }
          } catch (e) {}

          if (this.zpoolConfig?.zpoolName) { // 硬盘配置了数据就创建ZPOOL
            // 创建ZPOOL
            try {
              const param = {
                data:    zpoolConfig,
                headers: { headers: { 'mini-proxy': `${ this.value.spec.ip }:61280` } }
              };

              await this.$store.dispatch('pai-common/createPool', param);
            } catch (e) {
              this.$message({
                type:    'error',
                message: e.response.data.Data.Msg,
              });
              buttonCb(false);

              return;
            }
          }
        }

        try {
          await this.save(buttonCb);
          setTimeout(async() => {
            // 检查启动模式是否修改成功
            await this.modeStatus(this.value);
          }, 2000);
          if ((this.basicChanged === true || this.netBasicChange === true) && this.isLiveMode == true) { // 需要重新安装系统
            const installMessage = await this.$message({
              dangerouslyUseHTMLString: true,
              showClose:                true,
              type:                     'success',
              duration:                 0,
              message:                  `${ this.t('pai.edit.machine.saveSuccess') }<br>
                                   <div style="color: #000000;margin: 8px 0px">${ this.t('pai.edit.machine.reassemblyTip') } </div>
                                   <a style="display: block; cursor: pointer;" id="installSave">${ this.t('pai.edit.machine.installationSystem') }</a >`
            });

            this.$nextTick(() => {
              const link = document.getElementById('installSave');

              if (link) {
                link.addEventListener('click', async() => {
                  setTimeout(async() => {
                    try {
                      const name = this.value.metadata.annotations['picloud/newname'] ? this.value.metadata.annotations['picloud/newname'] : this.value.metadata.name;
                      const id = `${ this.value.metadata.namespace }/${ name }`;
                      const machine = await this.$store.dispatch('management/find', {
                        id, type: PAI_PLATFORM_SETTING_TYPES.MACHINE, opt: { force: true },
                      });

                      machine.metadata.annotations[MACHINE_ANNOTATIONS.MODE] = 'mode=install';
                      await machine.save();

                      await installMessage.close();
                      setTimeout(async() => {
                        // 检查启动模式是否修改成功
                        await this.modeStatus(machine);
                      }, 2000);

                      if (this.modeStatusCode !== 'fail') { // 启动模式修改成功后重启节点
                        if (machine?.metadata?.annotations[MACHINE_ANNOTATIONS.MODE] === 'mode=install') {
                          try {
                            await this.$store.dispatch('pai-common/getReboot', { ip: `${ machine?.spec?.ip }:61280` });
                            await this.$message({
                              dangerouslyUseHTMLString: true,
                              duration:                 3000,
                              message:                  `${ this.t('pai.edit.machine.linkTips') } <br><a style="display: block; cursor: pointer;margin-top: 10px" id="nodeDetailLink">${ this.t('pai.edit.machine.openLink') }</a >`
                            });

                            this.$nextTick(() => {
                              const link = document.getElementById('nodeDetailLink');

                              if (link) {
                                link.addEventListener('click', this.handleNodeDetail);
                              }
                            });
                          } catch (e) {
                            this.$message({
                              type:    'warning',
                              message: this.t('pai.edit.machine.rebootErr')
                            });
                          }
                        }
                      }
                    } catch (e) {
                      this.$message({
                        type:    'error',
                        message: this.t('pai.edit.machine.installErr')
                      });
                    }
                  }, 2000);
                });
              }
            });
          }
        } catch (e) {
          console.log(e);
          buttonCb(false);
        }
      } else {
        await this.save(buttonCb);
        setTimeout(async() => {
          // 检查启动模式是否修改成功
          await this.modeStatus(this.value);
        }, 2000);
      }
    },
    async modeStatus(value) {
      const name = value.metadata.annotations['picloud/newname'] ? value.metadata.annotations['picloud/newname'] : value.metadata.name;
      const id = `${ value.metadata.namespace }/${ name }`;
      const machine = await this.$store.dispatch('management/find', {
        id, type: PAI_PLATFORM_SETTING_TYPES.MACHINE, opt: { force: true },
      });

      if (this.tip) {
        this.$message({
          type:    'warning',
          message: this.tip
        });
      } else if (this.realMode === _EDIT && value?.metadata?.annotations[MACHINE_ANNOTATIONS.MODE] && machine?.status?.mode !== value?.metadata?.annotations[MACHINE_ANNOTATIONS.MODE]) {
        this.$message({
          type:    'warning',
          message: this.t('pai.edit.machine.bootMode') + this.t('pai.lb.tip.fail')
        });
        this.modeStatusCode = 'fail';
      } else if (this.basicChanged !== true && this.netBasicChange !== true) {
        this.$message.success(this.t('pai.edit.machine.saveSuccess'));
      }
    }
  },
  watch: {
    cluster() {
      this.$store.dispatch('pai-common/getOs', this.value.metadata.labels['com.tdology.gateway']);
    },
    kparams: {
      handler(nue) {
        if (nue.length) {
          const kparams = nue.filter((v) => !!v.trim()).map((v) => v.trim()).join(' ');

          set(this.value, 'spec.kparams', kparams);
        } else if (this.value?.spec?.kparams) {
          this.$delete(this.value.spec, 'kparams');
        }
      },
      deep:      true,
      immediate: true,
    },
    osList(val) {
      if (val) {
        this.$set(this.value.spec, 'os', val.length !== 0 ? this.value?.spec?.os : '');
      }
    },
    sshAuthorizedKeys: {
      handler(nue) {
        if (nue.length) {
          set(this.value, 'spec.cloudconfig.sshAuthorizedKeys', nue);
        } else if (this.value?.spec?.sshAuthorizedKeys) {
          this.$delete(this.value.spec, 'sshAuthorizedKeys');
        }
      },
      deep:      true,
      immediate: true,
    },
    basicChanged(val) {
      if (val === true) {
        // 基础配置有变动则传入picloud/action
        this.value.setAnnotation(MACHINE_ANNOTATIONS.ACTION, 'provision');
      }
    },
    modeType(val) {
      if (val !== this.oldMode) {
        this.modeType = val;
      } else {
        this.modeType = this.oldMode;
      }
      this.value.setAnnotation(MACHINE_ANNOTATIONS.MODE, this.modeType);
    }
  },
  created() {
    // Add fields missing when create or clone
    const spec = {
      ip: '', os: '', install: { device: '' }, cloudconfig: { k3os: {} }
    };

    if (!this.value.spec && this.realMode === _CREATE) {
      this.$set(this.value, 'spec', spec);
      this.$set(this.value.metadata.labels, 'com.tdology.gateway', '');
    }
    if (!this.value.status) {
      this.$set(this.value, 'status', '');
    }
    for (const key in spec) {
      if (!this.value.spec[key]) {
        this.value.spec[key] = spec[key];
      }
    }
    // query os list
    if (this.realMode !== _CREATE) {
      if (this.value.metadata.labels['com.tdology.gateway']) {
        this.$store.dispatch('pai-common/getOs', this.value.metadata.labels['com.tdology.gateway']);
      }
    }
    if (this.value.metadata.namespace) {
      this.$set(this.value.metadata, 'namespace', 'picloud-system');
    }
  },
  beforeDestroy() {
    this.$store.commit('pai-common/getOs', []);
  },
};
</script>

<template>
  <CruResource
    :cancel-event="true"
    :mode="mode"
    :resource="value"
    :subtypes="[]"
    :validation-passed="true"
    :errors="errors"
    :apply-hooks="applyHooks"
    @error="e=>errors = e"
    @finish="saveScan"
    @cancel="done"
  >
    <div v-if="realMode===_CLONE">
      <div class="mb-20 row">
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            :mode="mode"
          />
        </div>
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <div class="tab-main">
        <SectionTitle
          :value="t('pai.edit.machine.basic')"
          class="sectionTitle"
          style="margin-bottom: 20px"
        />
        <div class="mb-20 row">
          <div class="col span-3">
            <LabeledSelect
              v-model.trim="value.metadata.annotations['picloud/tmpl']"
              :label="t('pai.edit.machine.templateNode')"
              :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
              :tooltip="t('pai.edit.machine.templateNodeTooltip')"
              :append-to-body="false"
              :options="nodeNameList"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <LabeledSelect
              v-model.trim="value.spec.os"
              :required="true"
              :label="t('pai.edit.machine.operatingSystem')"
              :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
              :append-to-body="false"
              :options="osList"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <LabeledSelect
              v-if="isLiveMode"
              v-model.trim="value.spec.install.device"
              :label="t('pai.edit.machine.systemDisk')"
              :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
              :append-to-body="false"
              :options="disksData"
              :mode="mode"
            />
            <LabeledInput
              v-else
              v-model.trim="value.spec.install.device"
              type="text"
              :label="t('pai.edit.machine.systemDisk')"
              :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
              :mode="mode"
            />
          </div>
        </div>
      </div>
      <div class="mb-20 row">
        <div class="col span-3">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/role']"
            name="picloud/role"
            :label="t('pai.edit.machine.nodeRole')"
            :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
            :row="true"
            :required="true"
            :mode="mode"
          />
        </div>
        <div class="col span-3">
          <LabeledRadioGroup
            v-model.trim="value.metadata.annotations['picloud/seednode']"
            name="picloud/seednode"
            :label="t('pai.edit.machine.seedNode')"
            :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
            :required="true"
            :row="true"
            :tooltip="t('pai.edit.machine.firstNode')"
            :mode="mode"
          />
        </div>
      </div>
    </div>
    <div v-else-if="realMode===_CREATE">
      <div class="mb-20 row">
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            :append-to-body="false"
            :mode="mode"
          />
        </div>
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            :mode="mode"
          />
        </div>
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <hr class="mt-20 mb-20">
      <div class="tab-main">
        <SectionTitle
          :value="t('pai.edit.machine.basic')"
          class="sectionTitle"
          style="margin-bottom: 20px"
        />
        <div class="mb-20 row">
          <div class="col span-3">
            <LabeledSelect
              v-model.trim="value.metadata.labels['com.tdology.gateway']"
              :label="t('pai.edit.gateway.name')"
              :placeholder="t('pai.edit.placeholder') + t('pai.edit.gateway.name')"
              :append-to-body="false"
              :options="clusterList"
              :required="true"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <LabeledSelect
              v-model.trim="value.metadata.annotations['picloud/tmpl']"
              :label="t('pai.edit.machine.templateNode')"
              :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
              :tooltip="t('pai.edit.machine.templateNodeTooltip')"
              :append-to-body="false"
              :options="nodeNameList"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <LabeledSelect
              v-model.trim="value.spec.os"
              :required="true"
              :label="t('pai.edit.machine.operatingSystem')+t('pai.edit.machine.byClusterShow')"
              :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
              :append-to-body="false"
              :options="osList"
              :mode="mode"
            />
          </div>

          <div class="col span-3">
            <LabeledInput
              v-model.trim="value.spec.arch"
              type="text"
              :required="true"
              :label="t('pai.edit.machine.arch')"
              :mode="mode"
            />
          </div>
        </div>
        <div class="mb-20 row">
          <div class="col span-3">
            <LabeledSelect
              v-if="isLiveMode"
              v-model.trim="value.spec.install.device"
              :label="t('pai.edit.machine.systemDisk')"
              :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
              :append-to-body="false"
              :options="disksData"
              :mode="mode"
            />
            <LabeledInput
              v-else
              v-model.trim="value.spec.install.device"
              type="text"
              :label="t('pai.edit.machine.systemDisk')"
              :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/role']"
              name="picloud/role"
              :label="t('pai.edit.machine.nodeRole')"
              :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
              :row="true"
              :required="true"
              :mode="mode"
            />
          </div>
          <div class="col span-3">
            <LabeledRadioGroup
              v-model.trim="value.metadata.annotations['picloud/seednode']"
              name="picloud/seednode"
              :label="t('pai.edit.machine.seedNode')"
              :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
              :required="true"
              :row="true"
              :tooltip="t('pai.edit.machine.firstNode')"
              :mode="mode"
            />
          </div>
        </div>
      </div>
    </div>
    <div v-else>
      <div class="mb-20 row">
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.metadata.name"
            type="text"
            :required="true"
            :label="t('pai.edit.machine.hostName')"
            mode="view"
          />
        </div>
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.spec.ip"
            type="text"
            :required="true"
            label="IP"
            mode="view"
          />
        </div>
        <div class="col span-3">
          <LabeledInput
            v-model.trim="value.metadata.annotations['picloud/alias']"
            type="text"
            :label="t('pai.edit.alias')"
            :placeholder="
              t('pai.edit.placeholder') +
                t('pai.edit.alias')
            "
            :mode="mode"
          />
        </div>
      </div>
      <div
        class="mb-20"
        style="padding-left: 10px"
      >
        <div style="font-size: 12px; color: #6C6C76">
          {{ t('pai.edit.machine.bootMode') }}
        </div>
        <div style="display: flex; align-items: baseline">
          <el-radio-group
            :value="modeType"
            :disabled="!isEditable"
            @input="selectionChanged"
          >
            <div
              v-for="item in modeOptios"
              :key="item.value"
            >
              <el-radio
                :label="item.value"
              >
                {{ item.label }}
              </el-radio>
            </div>
          </el-radio-group>
          <el-tooltip
            class="item"
            effect="light"
            :content="t('pai.edit.machine.emergencyRepairTip')"
          >
            <i
              class="icon icon-info"
              style="margin-top: 10px;margin-left: -16px"
            />
          </el-tooltip>
        </div>
      </div>
      <Tabbed
        ref="containersTabbed"
        class="deployment-tabs"
        :show-tabs-add-remove="true"
        :flat="true"
        @changed="handleTab"
      >
        <Tab
          label-key="pai.edit.machine.basic"
          name="basic"
          :weight="99"
        >
          <div
            class="tipBox mg-20"
          >
            <i
              class="icon el-icon-info"
              style="color: rgba(154, 154, 154, 1); margin-right: 5px;"
            />
            <span v-html="t('pai.edit.machine.basicTip', {}, true)" />
          </div>
          <div
            class="mg-20"
          >
            <div class="row">
              <div class="col span-3">
                <LabeledInput
                  v-model.trim="value.metadata.annotations['picloud/newname']"
                  type="text"
                  :label="t('pai.edit.machine.newName')"
                  :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.newName')"
                  :mode="mode"
                  :disabled="newNameDisable || networkZPOOLDisabled"
                />
              </div>
              <div class="col span-3">
                <LabeledSelect
                  v-model.trim="value.metadata.annotations['picloud/tmpl']"
                  :label="t('pai.edit.machine.templateNode')"
                  :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.templateNode')"
                  :tooltip="t('pai.edit.machine.templateNodeTooltip')"
                  :append-to-body="false"
                  :options="nodeNameList"
                  :mode="mode"
                  :disabled="networkZPOOLDisabled"
                />
              </div>
              <div class="col span-3">
                <div class="mb-20">
                  <LabeledSelect
                    v-model.trim="value.spec.os"
                    :required="true"
                    :label="t('pai.edit.machine.operatingSystem')"
                    :placeholder="t('pai.edit.SelectPlaceholder')+t('pai.edit.machine.operatingSystem')"
                    :append-to-body="false"
                    :options="osList"
                    :mode="mode"
                    :disabled="networkZPOOLDisabled"
                  />
                </div>
              </div>
              <div class="col span-3">
                <div class="mb-20">
                  <LabeledSelect
                    v-if="isLiveMode"
                    v-model.trim="value.spec.install.device"
                    :label="t('pai.edit.machine.systemDisk')"
                    :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
                    :append-to-body="false"
                    :options="disksData"
                    :mode="mode"
                    :disabled="networkZPOOLDisabled"
                  />
                  <LabeledInput
                    v-else
                    v-model.trim="value.spec.install.device"
                    type="text"
                    :label="t('pai.edit.machine.systemDisk')"
                    :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.systemDisk')"
                    :mode="mode"
                    :disabled="networkZPOOLDisabled"
                  />
                </div>
              </div>
            </div>
            <div class="mb-20 row">
              <div class="col span-3">
                <LabeledRadioGroup
                  v-model.trim="value.metadata.annotations['picloud/role']"
                  name="picloud/role"
                  :label="t('pai.edit.machine.nodeRole')"
                  :options="[{label:t('pai.edit.machine.masterNode'),value:'server'},{label:t('pai.edit.machine.secondaryNode'),value:'agent'}]"
                  :row="true"
                  :required="true"
                  :mode="mode"
                  :disabled="networkZPOOLDisabled"
                />
              </div>
              <div class="col span-3">
                <LabeledRadioGroup
                  v-model.trim="value.metadata.annotations['picloud/seednode']"
                  name="picloud/seednode"
                  :label="t('pai.edit.machine.seedNode')"
                  :options="[{label:t('pai.edit.machine.yes'),value:'true'},{label:t('pai.edit.machine.no'),value:'false'}]"
                  :required="true"
                  :row="true"
                  :tooltip="t('pai.edit.machine.firstNode')"
                  :mode="mode"
                  :disabled="networkZPOOLDisabled"
                />
              </div>
            </div>
            <hr class="mt-20 mb-20">
            <template>
              <div>
                <SectionTitle
                  :value="t('pai.edit.machine.kparams')"
                  class="row mt-10 mb-10"
                >
                  <el-tooltip
                    :content="t('pai.edit.machine.kparamsTip')"
                    effect="light"
                  >
                    <i class="icon icon-info" />
                  </el-tooltip>
                </SectionTitle>
              </div>
              <div class="row mt-10 mb-10">
                <button
                  type="button"
                  class="btn role-primary"
                  style="margin-right: 10px"
                  :disabled="!isEditable || networkZPOOLDisabled"
                  @click="kparams.push('')"
                >
                  <i class="el-icon-plus" />
                  {{ t('generic.add') }}
                </button>
                <button
                  type="button"
                  class="btn bg-error"
                  :disabled="!kparams.length || !isEditable || networkZPOOLDisabled"
                  @click="kparams=[]"
                >
                  <i class="el-icon-delete" />
                  {{ t('pai.labels.removeAll') }}
                </button>
              </div>
              <div
                v-for="(item,i) in kparams"
                :key="item+i"
                class="mb-20 row"
              >
                <div class="col span-6">
                  <LabeledInput
                    :value="item"
                    type="text"
                    placeholder="isolcpus=1,2,10-20"
                    :mode="mode"
                    :disabled="networkZPOOLDisabled"
                    @input="kparams[i]=$event"
                    @blur="$set(kparams,i,kparams[i])"
                  />
                </div>
                <el-button
                  type="text"
                  :disabled="!isEditable || networkZPOOLDisabled"
                  @click="kparams.splice(i,1)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </template>
            <template>
              <div>
                <SectionTitle
                  :value="t('pai.edit.machine.SSHKey')"
                  class="row mt-10 mb-10"
                >
                  <el-tooltip
                    effect="light"
                  >
                    <i class="icon icon-info" />
                    <div
                      slot="content"
                      v-html="t('pai.edit.machine.SSHTip', {}, true)"
                    />
                  </el-tooltip>
                </SectionTitle>
              </div>
              <div class="row mt-10 mb-10">
                <button
                  type="button"
                  class="btn role-primary"
                  style="margin-right: 10px"
                  :disabled="!isEditable || networkZPOOLDisabled"
                  @click="sshAuthorizedKeys.push('')"
                >
                  <i class="el-icon-plus" />
                  {{ t('generic.add') }}
                </button>
                <button
                  type="button"
                  class="btn bg-error"
                  :disabled="!sshAuthorizedKeys.length || !isEditable || networkZPOOLDisabled"
                  @click="sshAuthorizedKeys=[]"
                >
                  <i class="el-icon-delete" />
                  {{ t('pai.labels.removeAll') }}
                </button>
              </div>
              <div
                v-for="(item,i) in sshAuthorizedKeys"
                :key="i"
                class="mb-20 row"
              >
                <div class="col span-6">
                  <LabeledInput
                    :value="item"
                    type="text"
                    placeholder="请以ssh-ed25519开头"
                    :mode="mode"
                    :disabled="networkZPOOLDisabled"
                    @input="sshAuthorizedKeys[i]=$event"
                    @blur="$set(sshAuthorizedKeys,i,sshAuthorizedKeys[i])"
                  />
                </div>
                <el-button
                  type="text"
                  :disabled="!isEditable || networkZPOOLDisabled"
                  @click="sshAuthorizedKeys.splice(i,1)"
                >
                  {{ t('pai.labels.remove') }}
                </el-button>
              </div>
            </template>
          </div>
        </Tab>
        <Tab

          label-key="pai.edit.machine.networkBasic"
          name="networkBasic"
          :weight="99"
        >
          <div
            v-if="!isLiveMode && !value.metadata?.annotations[MACHINE_ANNOTATIONS.NET_CONFIG]"
            style="display: flex; justify-content: center;align-items: center; min-height: 300px"
          >
            <img
              src="../assets/images/machine/no-live.png"
              alt=""
            >
          </div>
          <div v-else>
            <div class="tipBox mg-20">
              <i
                class="icon el-icon-info"
                style="color: rgba(154, 154, 154, 1); margin-right: 5px;"
              />
              <span v-html="t('pai.edit.machine.networkTip', {}, true)" />
            </div>

            <!-- 链路聚合 -->
            <div class="mg-20">
              <div
                class="title-box"
              >
                <SectionTitle
                  :value="t('pai.edit.machine.bond')"
                  class="row banner-bg"
                >
                  <el-tooltip
                    effect="light"
                  >
                    <i class="icon icon-info" />
                    <div
                      slot="content"
                      v-html="t('pai.edit.machine.bondTip', {}, true)"
                    />
                  </el-tooltip>
                  <span
                    class="showBtn"
                    style="margin-left: 20px"
                    @click="bondModual = !bondModual"
                  >
                    {{ bondModual === false ? t('pai.edit.machine.show'): t('pai.edit.machine.hide') }}
                    <i :class="[bondModual === false ? 'el-icon-caret-bottom' : 'el-icon-caret-top']" />
                  </span>
                </SectionTitle>
                <button
                  v-if="bondModual"
                  type="button"
                  class="btn role-primary addNetworkBtn"
                  :disabled="networkZPOOLDisabled"
                  @click="addBond"
                >
                  <i class="el-icon-plus" />
                  {{ t('generic.add') }}
                </button>
              </div>
              <el-table
                v-if="bondModual"
                :data="bondTable"
                style="margin-top: 30px; border: 1px solid #DCDEE7; border-radius:4px"
                :header-cell-style="{background:'#F4F5FA', color: '#000000',fontWeight: 400}"
                align="center"
              >
                <el-table-column
                  prop="BondName"
                >
                  <template
                    slot="header"
                    slot-scope="scope"
                  >
                    {{ t('pai.edit.machine.name') }}<span style="color: red">*</span>
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Nics"
                >
                  <template
                    slot="header"
                    slot-scope="scope"
                  >
                    {{ t('pai.edit.machine.network') }}<span style="color: red">*</span>
                  </template>
                  <template
                    slot-scope="scope"
                  >
                    {{ scope.row.Nics.join(',') }}
                  </template>
                </el-table-column>
                <el-table-column
                  prop="Mode"
                >
                  <template
                    slot="header"
                    slot-scope="scope"
                  >
                    模式<span style="color: red">*</span>
                    <el-tooltip
                      effect="light"
                    >
                      <i class="icon icon-info" />
                      <div
                        slot="content"
                        v-html="t('pai.edit.machine.bondModeTip', {}, true)"
                      />
                    </el-tooltip>
                  </template>
                </el-table-column>
                <el-table-column
                  v-if="!networkZPOOLDisabled"
                  label="操作"
                >
                  <template
                    slot-scope="scope"
                  >
                    <el-button
                      type="text"
                      @click="editBond(scope.row, scope.$index)"
                    >
                      {{ t('pai.detail.vmset.edit') }}
                    </el-button>
                    <el-button
                      type="text"
                      @click="delBond(scope.row,scope.$index)"
                    >
                      {{ t('pai.labels.remove') }}
                    </el-button>
                  </template>
                </el-table-column>
              </el-table>
              <el-dialog
                :title="dialogTitle"
                :visible.sync="dialogVisible"
                width="35%"
                :before-close="beforeClose"
                :close-on-click-modal="false"
                :modal-append-to-body="false"
              >
                <div style="padding-bottom: 80px">
                  <LabeledInput
                    v-model="bondForm.BondName"
                    style="text-align: left"
                    :required="true"
                    type="text"
                    :label="t('pai.edit.machine.name')"
                    :placeholder="'请输入名称，支持数字字母'"
                    :mode="mode"
                  />
                  <LabeledSelect
                    v-model="bondForm.Nics"
                    style="text-align: left; margin: 20px 0px"
                    :required="true"
                    :multiple="true"
                    :label="t('pai.edit.machine.network')"
                    :placeholder="'至少需要两张网卡'"
                    :append-to-body="false"
                    :options="bondNetworkOption"
                    :mode="mode"
                  />
                  <LabeledSelect
                    v-model="bondForm.Mode"
                    style="text-align: left"
                    :required="true"
                    :label="t('pai.edit.machine.mode')"
                    :placeholder="'请选择模式'"
                    :append-to-body="false"
                    :options="bondingModeItems"
                    :mode="mode"
                  >
                    <el-tooltip
                      effect="light"
                    >
                      <i class="icon icon-info" />
                      <div
                        slot="content"
                        v-html="t('pai.edit.machine.bondModeTip', {}, true)"
                      />
                    </el-tooltip>
                  </LabeledSelect>
                </div>
                <span
                  slot="footer"
                  class="dialog-footer"
                >
                  <el-button @click="beforeClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
                  <el-button
                    type="primary"
                    @click="onConfirm"
                  >{{ t('pai.detail.vmset.confirm') }}</el-button>
                </span>
              </el-dialog>
            </div>

            <!-- 网络配置 -->
            <div class="mg-20">
              <div
                class="title-box"
              >
                <SectionTitle
                  :value="t('pai.edit.machine.networkBasic')"
                  class="row banner-bg"
                />
              </div>
              <LabeledRadioGroup
                v-model.trim="bondConfig.VLANProtocol"
                name="VLANProtocol"
                :label="t('pai.edit.machine.vlan')"
                :options="[{label:'802.1q',value:'802.1q'},{label:'802.1ad',value:'802.1ad'}]"
                :row="true"
                :tooltip="t('pai.edit.machine.vlanTip', {}, true)"
                :mode="mode"
                style="margin: 20px 0px"
                :disabled="networkZPOOLDisabled"
              />
              <el-table
                :data="networkTable"
                style="margin-top: 30px; border: 1px solid #DCDEE7; border-radius:4px"
                :header-cell-style="{background:'#F4F5FA', color: '#000000',fontWeight: 400}"
                align="center"
              >
                <el-table-column
                  label="配置模式"
                  width="100px"
                >
                  <template
                    slot-scope="scope"
                  >
                    <span>{{ scope.row.mode === 'wan' ? t('pai.edit.machine.wanNetwork') : t('pai.edit.machine.lanNetwork') }}</span>
                  </template>
                </el-table-column>
                <el-table-column
                  width="220px"
                >
                  <template
                    slot="header"
                    slot-scope="scope"
                  >
                    {{ t('pai.edit.machine.network') }}
                    <!--                    <span style="color: red">*</span>-->
                    <el-tooltip
                      effect="light"
                      :content="t('pai.edit.machine.tableNetworkTip')"
                    >
                      <i class="icon icon-info" />
                    </el-tooltip>
                  </template>
                  <template
                    slot-scope="scope"
                  >
                    <el-select
                      v-model="scope.row.NIC"
                      :placeholder="'请选择网卡'"
                      :disabled="networkZPOOLDisabled"
                      @change="networkChange(scope.row, scope.$index)"
                    >
                      <el-option
                        v-for="(item,index) in bondNetworkOption.concat(bondTable?.map((item)=> item.BondName))"
                        :key="index"
                        :label="item"
                        :value="item"
                      />
                    </el-select>
                  </template>
                </el-table-column>
                <el-table-column
                  width="220px"
                >
                  <template
                    slot="header"
                    slot-scope="scope"
                  >
                    VLAN ID
                    <!--                    <span style="color: red">*</span>-->
                    <el-tooltip
                      effect="light"
                      :content="t('pai.edit.machine.vlanIDTip')"
                    >
                      <i class="icon icon-info" />
                    </el-tooltip>
                  </template>
                  <template
                    slot-scope="scope"
                  >
                    <el-input
                      v-model="scope.row.VLANID"
                      type="number"
                      :placeholder="'请输入VLAN ID'"
                      :disabled="networkZPOOLDisabled"
                      @blur="vlanIDChange(scope.row, scope.$index)"
                    />
                  </template>
                </el-table-column>
                <el-table-column
                  label="IP"
                  prop="IP"
                />
                <el-table-column
                  :label="t('pai.edit.machine.mask')"
                  prop="NetMask"
                />
                <el-table-column
                  :label="t('pai.edit.machine.gateway')"
                  prop="Gateway"
                />
                <el-table-column
                  label="DNS"
                  prop="Nameservers"
                />
              </el-table>
            </div>
          </div>
        </Tab>
        <Tab
          label-key="pai.edit.machine.ZPOOLBasic"
          name="ZPOOLBasic"
          :weight="99"
        >
          <div
            v-if="!isLiveMode && !value.metadata?.annotations[MACHINE_ANNOTATIONS.ZPOOL_CONFIG]"
            style="display: flex; justify-content: center;align-items: center; min-height: 300px"
          >
            <img
              src="../assets/images/machine/no-live.png"
              alt=""
            >
          </div>
          <div
            v-else
            class="mg-20"
          >
            <SectionTitle
              :value="t('pai.edit.machine.basicInfo')"
              class="row banner-bg"
            />
            <div
              class="row"
              style="margin: 20px 0px"
            >
              <div class="col span-3">
                <LabeledInput
                  v-model.trim="zpoolConfig.zpoolName"
                  type="text"
                  :label="t('pai.edit.machine.name')"
                  :placeholder="t('pai.edit.placeholder')+t('pai.edit.machine.name')"
                  :mode="mode"
                  :disabled="networkZPOOLDisabled"
                />
              </div>
              <div class="col span-3">
                <Checkbox
                  v-model="zpoolConfig.isMigrate"
                  class="mt-20 mb-10"
                  :mode="mode"
                  :label="t('pai.edit.machine.checkZPOOL')"
                  :tooltip="t('pai.edit.machine.checkZPOOLTip')"
                  :disabled="ExistsServerDir || networkZPOOLDisabled"
                />
              </div>
              <div class="col span-3">
                <Checkbox
                  v-model="zpoolConfig.forceCreate"
                  class="mt-20 mb-10"
                  :mode="mode"
                  :label="t('pai.edit.machine.creatZPOOL')"
                  :disabled="networkZPOOLDisabled"
                />
              </div>
              <div class="col span-3">
                <Checkbox
                  v-model="zpoolConfig.longhorn.enable"
                  class="mt-20 mb-10"
                  :mode="mode"
                  :label="t('pai.edit.machine.creatStorage')"
                  :tooltip="t('pai.edit.machine.creatStorageTip')"
                  :disabled="ExistsLonghornDisk || networkZPOOLDisabled"
                />
              </div>
            </div>
            <div
              v-if="zpoolConfig.longhorn.enable"
              class="block"
            >
              <div class="row">
                <div class="col span-4">
                  <span>
                    {{ t('pai.edit.machine.storageSize') }}:&nbsp;
                  </span>
                  <el-input
                    v-model="zpoolConfig.longhorn.size"
                    type="number"
                    :placeholder="'请输入'"
                    required="true"
                    style="width: 250px"
                    :disabled="ExistsLonghornDisk || networkZPOOLDisabled"
                    @blur="storageSizeBlur"
                  />
                </div>
                <div
                  class="col span-3"
                  style="display: flex;justify-content: start;align-items: center;"
                >
                  <span>
                    {{ t('pai.edit.machine.unit') }}:&nbsp;
                  </span>
                  <LabeledRadioGroup
                    v-model.trim="zpoolConfig.longhorn.unit"
                    name="unit"
                    :options="[{label: 'GB',value:'GB'},{label:'TB',value:'TB'},{label: 'MB',value:'MB'}]"
                    :row="true"
                    :mode="mode"
                    :disabled="ExistsLonghornDisk || networkZPOOLDisabled"
                  />
                </div>
              </div>
            </div>
            <hr class="mt-20 mb-20">
            <SectionTitle
              :value="t('pai.edit.machine.allocateDisk')"
              class="row banner-bg"
            />
            <div style="text-align: right">
              <img
                src="../assets/images/machine/show-big.png"
                alt=""
                style="cursor: pointer"
                @click="diskDialogVisible=true"
              >
            </div>
            <template>
              <ZPOOLSettings
                :key="devsDataFlag"
                ref="zpoolSettings"
                :mode="mode"
                :devsData="devsData"
                :valueConfig="zpoolConfig"
                :device="value.spec.install.device"
                :max-height="'420px'"
                :networkZPOOLDisabled="networkZPOOLDisabled"
              />
            </template>
            <el-dialog
              :title="t('pai.edit.machine.allocateDisk')"
              :visible.sync="diskDialogVisible"
              width="80%"
              :close-on-click-modal="false"
              :modal-append-to-body="false"
            >
              <ZPOOLSettings
                :mode="mode"
                :devsData="devsData"
                :valueConfig="zpoolConfig"
                :device="value.spec.install.device"
                :networkZPOOLDisabled="networkZPOOLDisabled"
              />
            </el-dialog>
          </div>
        </Tab>
      </Tabbed>
    </div>
    <template v-if="isEditable && realMode !== _EDIT">
      <div>
        <SectionTitle
          :value="t('pai.edit.machine.kparams')"
          class="row mt-10 mb-10"
        >
          <el-tooltip
            :content="t('pai.edit.machine.kparamsTip')"
            effect="light"
          >
            <i class="icon icon-info" />
          </el-tooltip>
        </SectionTitle>
      </div>
      <div class="row mt-10 mb-10">
        <button
          type="button"
          class="btn role-primary"
          style="margin-right: 10px"
          :disabled="!isEditable"
          @click="kparams.push('')"
        >
          <i class="el-icon-plus" />
          {{ t('generic.add') }}
        </button>
        <button
          type="button"
          class="btn bg-error"
          :disabled="!kparams.length || !isEditable"
          @click="kparams=[]"
        >
          <i class="el-icon-delete" />
          {{ t('pai.labels.removeAll') }}
        </button>
      </div>
      <div
        v-for="(item,i) in kparams"
        :key="item+i"
        class="mb-20 row"
      >
        <div class="col span-6">
          <LabeledInput
            :value="item"
            type="text"
            placeholder="isolcpus=1,2,10-20"
            :mode="mode"
            @input="kparams[i]=$event"
            @blur="$set(kparams,i,kparams[i])"
          />
        </div>
        <el-button
          type="text"
          :disabled="!isEditable"
          @click="kparams.splice(i,1)"
        >
          {{ t('pai.labels.remove') }}
        </el-button>
      </div>
    </template>
    <template v-if="isEditable && realMode !== _EDIT">
      <div>
        <SectionTitle
          :value="t('pai.edit.machine.SSHKey')"
          class="row mt-10 mb-10"
        >
          <el-tooltip
            effect="light"
          >
            <i class="icon icon-info" />
            <div
              slot="content"
              v-html="t('pai.edit.machine.SSHTip', {}, true)"
            />
          </el-tooltip>
        </SectionTitle>
      </div>
      <div class="row mt-10 mb-10">
        <button
          type="button"
          class="btn role-primary"
          style="margin-right: 10px"
          :disabled="!isEditable"
          @click="sshAuthorizedKeys.push('')"
        >
          <i class="el-icon-plus" />
          {{ t('generic.add') }}
        </button>
        <button
          type="button"
          class="btn bg-error"
          :disabled="!sshAuthorizedKeys.length || !isEditable"
          @click="sshAuthorizedKeys=[]"
        >
          <i class="el-icon-delete" />
          {{ t('pai.labels.removeAll') }}
        </button>
      </div>
      <div
        v-for="(item,i) in sshAuthorizedKeys"
        :key="i"
        class="mb-20 row"
      >
        <div class="col span-6">
          <LabeledInput
            :value="item"
            type="text"
            placeholder="请以ssh-ed25519开头"
            :mode="mode"
            @input="sshAuthorizedKeys[i]=$event"
            @blur="$set(sshAuthorizedKeys,i,sshAuthorizedKeys[i])"
          />
        </div>
        <el-button
          type="text"
          :disabled="!isEditable"
          @click="sshAuthorizedKeys.splice(i,1)"
        >
          {{ t('pai.labels.remove') }}
        </el-button>
      </div>
    </template>
  </CruResource>
</template>

<style lang="scss" scoped>
.v-select, .v-select *{
  width: 100% !important;
}
.el-button--text,.el-button--text:focus{
  border: unset;
  color: var(--primary);
}
BUTTON:focus{
  box-shadow:unset;
}
.tipBox {
  border-radius: 5px;
  background-color: rgba(228, 233, 245, 1);
  font-size: 12px;
  text-align: left;
  border: 1px solid rgba(52, 124, 175, 1);
  padding: 8px;
}
.mg-20 {
  margin: 20px;
}
.title-box{
  position: relative;
  .showBtn{
    cursor: pointer;
    color: #246FA5;
  }
  .addNetworkBtn{
    position: absolute;
    right: 10px;
    top: -4px;
  }
}
.block {
  border-radius: 5px;
  background-color: rgba(250, 250, 250, 1);
  border: 1px dashed rgba(187, 187, 187, 1);
  padding: 12px;
}
::v-deep .el-radio-group {
  display: flex;
}
::v-deep .el-radio__label {
  padding-left: 2px;
  margin-right: 24px;
}
::v-deep .el-radio__input.is-checked .el-radio__inner {
  border-color: #246FA5;
  background: #246FA5;
}
::v-deep .el-radio__input.is-checked+.el-radio__label {
  color: #6C6C76;
}
::v-deep .el-radio__input.is-checked .el-radio__inner::after {
  display: none;
}
</style>
