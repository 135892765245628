import SteveModel from '@shell/plugins/steve/steve-class';
import PlatformLocationMixin from '~/pkg/pai/mixins/platformLocation';
import { PRODUCT_NAME } from '~/pkg/pai/config/platform';
import { _EDIT, _UNFLAG, AS, MODE } from '@shell/config/query-params';
import { MACHINE_ANNOTATIONS } from '~/pkg/pai/config/labels-annotations';
import { Message } from 'element-ui';
import { PAI_PLATFORM_SETTING_TYPES } from '~/pkg/pai/config/types';
import {
  SCHEMA, SERVICE, ENDPOINTS, NODE, POD
} from '@/shell/config/types';
import { clone } from '@/shell/utils/object';
import { createYaml } from '@/shell/utils/create-yaml';

let serviceObj = {};

export default class extends PlatformLocationMixin(SteveModel) {
  get details() {
    const product = this.$rootGetters['productId'];

    if (product === PRODUCT_NAME) {
      return [
        {
          label:   this.t('tableHeaders.clusters'),
          content: this.metadata.labels['com.tdology.gateway'],
        },
        {
          label:   'IP',
          content: this.spec.ip,
        },
        {
          label:   this.t('pai.detail.vmset.system'),
          content: this.spec.os,
        },
      ];
    }

    return super.details;
  }

  goToEdit(moreQuery = {}) {
    if (this.annotations && this.annotations[MACHINE_ANNOTATIONS.MAIN_NIC]) {
      const location = this.detailLocation;

      location.query = {
        ...location.query,
        [MODE]: _EDIT,
        [AS]:   _UNFLAG,
        ...moreQuery
      };

      this.currentRouter().push(location);
    } else {
      Message.warning(this.t('pai.edit.machine.noEdit'));
    }
  }

  get availableActions() {
    const out = super._availableActions;

    return [
      {
        action:  'openSSH',
        icon:    'icon icon-service',
        label:   '打开SSH连接',
        enabled: !this.getSSH,
      },
      {
        action:  'closeSSH',
        icon:    'icon icon-close',
        label:   '关闭SSH连接',
        enabled: this.getSSH,
      },
      ...out,
    ];
  }

  get getSSH() {
    return this.metadata?.annotations['isSSH'] === 'true';
  }

  toYaml(yaml, apiVersion) {
    const inStore = this.$rootGetters['currentStore'](yaml);
    const schemas = this.$rootGetters[`${ inStore }/all`](SCHEMA);
    const resource = clone(yaml);

    return createYaml(schemas, apiVersion, resource);
  }

  async createEndpoints(resource) {
    const inStore = this.$rootGetters['currentStore'](resource);
    const schemas = this.$rootGetters[`${ inStore }/all`](SCHEMA);
    const endpointsSchema = schemas.filter((v) => v.id?.toLowerCase() === ENDPOINTS)[0];
    const opt = {
      method:  'post',
      headers: {
        'content-type': 'application/yaml',
        accept:         'application/json',
      },
      data: {},
      url:  `v1/${ ENDPOINTS }`,
    };
    const endpointsYaml = {
      apiVersion: 'v1',
      kind:       'Endpoints',
      metadata:   {
        name:      'master1-ssh',
        namespace: 'kube-system' // 命名空间固定
      },
      subsets: [
        {
          addresses: [
            {
              ip:       '',
              nodeName: ''
            }
          ],
          ports: [
            {
              name:     'ssh',
              port:     22,
              protocol: 'TCP'
            }
          ]
        }
      ]
    };

    endpointsYaml.metadata.name = `${ resource.name }-ssh`;
    endpointsYaml.subsets[0].addresses[0].ip = resource.spec?.ip;
    endpointsYaml.subsets[0].addresses[0].nodeName = resource.name;

    try {
      opt.data = this.toYaml(endpointsYaml, ENDPOINTS);
      await endpointsSchema.$ctx.dispatch('request', opt);
    } catch (e) {
      Message.error(e.message);
    }
  }

  async createService(resource) {
    const min = 30000;
    const max = 32767;
    const randomNum = Math.floor(Math.random() * (max - min + 1)) + min;

    const inStore = this.$rootGetters['currentStore'](resource);
    const schemas = this.$rootGetters[`${ inStore }/all`](SCHEMA);
    const serviceSchema = schemas.filter((v) => v.id?.toLowerCase() === SERVICE)[0];
    const opt = {
      method:  'post',
      headers: {
        'content-type': 'application/yaml',
        accept:         'application/json',
      },
      data: {},
      url:  `v1/${ SERVICE }`,
    };
    const serviceYaml = {
      apiVersion: 'v1',
      kind:       'Service',
      metadata:   {
        annotations: { sshPort: '' },
        name:        '', // 实际名称
        namespace:   'kube-system' // 命名空间固定
      },
      spec: {
        ports: [
          {
            name:       '',
            port:       22,
            protocol:   'TCP',
            targetPort: 22,
            nodePort:   '' // 接口改成正确的接口
          }
        ],
        sessionAffinity: 'None',
        type:            'NodePort'
      }
    };
    const lbData = resource?.metadata?.annotations['lbData'] ? JSON.parse(resource?.metadata?.annotations['lbData']) : [];

    serviceYaml.metadata.name = `${ resource.name }-ssh`;
    serviceYaml.spec.ports[0].name = resource.name;
    if (lbData.length !== 0 && !lbData.includes(randomNum)) {
      serviceYaml.metadata.annotations.sshPort = randomNum.toString();
      serviceYaml.spec.ports[0].nodePort = randomNum;
    }

    try {
      opt.data = this.toYaml(serviceYaml, SERVICE);
      await serviceSchema.$ctx.dispatch('request', opt);
    } catch (e) {
      Message.error(e.message);
    }
  }

  openSSH(resource = this) {
    if (resource?.metadata?.annotations['isCluster']) {
      this.$dispatch('promptModal', {
        component:      'ConfirmDialog',
        componentProps: {
          resource,
          confirm: (resource) => {
            try {
              this.createEndpoints(resource).then((res) => {
                this.createService(resource);
              });
              Message.success(this.t('pai.edit.machine.sshTips.actionSuccess'));
              setTimeout(async() => {
                await this.$dispatch('findAll', {
                  type: PAI_PLATFORM_SETTING_TYPES.MACHINE,
                  opt:  { force: true, watch: true },
                } );
                await this.$dispatch('findAll', {
                  type: SERVICE,
                  opt:  { force: true, watch: true },
                } );
              });
            } catch (e) {
              Message.error(this.t('pai.edit.machine.sshTips.actionError'));
            }
          },
        },
      });
    } else {
      Message.warning(this.t('pai.edit.machine.sshTips.noSSH'));
    }
  }

  async getServiceData(resource) {
    const serviceData = await this.$dispatch('findAll', { type: SERVICE });

    serviceObj = serviceData?.find((service) => service?.spec?.ports !== undefined && service?.spec?.ports[0]?.name === resource.name);
  }

  closeSSH(resource = this) {
    if (resource?.metadata?.annotations['isCluster'] === 'true') {
      this.$dispatch('promptModal', {
        component:      'ConfirmDialog',
        componentProps: {
          resource,
          confirm: (resource) => {
            if (serviceObj) {
              try {
                this.getServiceData(resource).then(() => {
                  serviceObj.remove();
                  Message.success(this.t('pai.edit.machine.sshTips.actionSuccess'));
                });
                setTimeout(async() => {
                  await this.$dispatch('findAll', {
                    type: PAI_PLATFORM_SETTING_TYPES.MACHINE,
                    opt:  { force: true, watch: true },
                  } );
                  await this.$dispatch('findAll', {
                    type: SERVICE,
                    opt:  { force: true, watch: true },
                  } );
                });
              } catch (e) {
                Message.error(this.t('pai.edit.machine.sshTips.actionError'));
              }
            }
          },
        },
      });
    } else {
      Message.warning(this.t('pai.edit.machine.sshTips.noSSH'));
    }
  }
}
