<script>
import LiveDate from '@/shell/components/formatter/LiveDate';
import { mapGetters } from 'vuex';
import { PVC, STORAGE_CLASS } from '@/shell/config/types';
import { PVC_LABELS, IMAGE } from '@/pkg/pai/config/labels-annotations';
import { VM_POWER_STATES_ENUM } from '@/pkg/pai/plugins/pai-resource-class';
import { convertUnitToG } from '@/pkg/pai/utils/units';
import { Banner } from '@components/Banner';
import { PVC_ANNOTATIONS } from '../../../config/labels-annotations';
export default {
  components: { LiveDate, Banner },

  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    },
    podName: {
      type:    String,
      default: () => {
        return '';
      }
    },
  },
  data() {
    if (!this.value.spec.volumes) {
      this.value.spec.volumes = [];
    }
    if (!this.value.spec.volumeClaimTemplates) {
      this.value.spec.volumeClaimTemplates = [];
    }

    return {
      dialogVisible: false,
      volumeName:    [],
      selectWay:     'new',
      form:          {
        name: '',
        size: '',
        sc:   '',
        pvc:  '',
      },
      disks:             [],
      filterValue:       '',
      scs:               [],
      deleteDiskVisible: false, // 彻底删除pvc
      deleteCurrentDisk: '',
      completelyDelete:  false // 是否彻底删除
    };
  },
  async fetch() {
    await this.$store.dispatch('cluster/findAll', { type: PVC });
    this.scs = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    pvcs() {
      return this.$store.getters['cluster/all'](PVC).filter((v) => !v.labels[IMAGE.ISO] && !v.labels[IMAGE.TOOL]);
    },
    data() {
      let tableData = [];
      const templatesVolumes = this.value?.spec?.volumeClaimTemplates?.filter((v) => v.metadata?.labels ? !v.metadata?.labels[IMAGE.TOOL] : v).map((item) => item?.metadata?.name);
      const volumes = this.value.spec?.volumes?.map((v) => v.name);
      const templatesPodName = this.podName.slice(0, this.podName.indexOf('.'));
      const names = [];

      templatesVolumes?.forEach((item) => { // 创建的新磁盘
        if (item !== 'vmoscdrom') {
          names.push(`pvc-${ templatesPodName }-${ item }`);
        }
      });
      volumes?.forEach((item) => { // 已有磁盘
        names.push(item);
      });

      let existsDisk = [];
      let labeledPvcs = [];

      if (this.value?.status?.instances && Object.keys(this.value.status.instances).length === 1) { // 只有一个pod时展示挂载的已有磁盘数据
        // 已存在
        existsDisk = this.pvcs.filter((pvc) => (pvc.metadata.namespace === this.value.metadata.namespace && pvc.status.phase === 'Bound' && names.includes(pvc.metadata.name)) || (pvc.metadata.labels && pvc.metadata?.labels[PVC_LABELS.MOUNT_VM] === this.value.metadata.name));
        // 已挂载
        labeledPvcs = this.pvcs.filter((pvc) => (pvc.metadata.namespace === this.value.metadata.namespace && pvc.metadata.labels && pvc.metadata.labels[PVC_LABELS.MOUNT_VM] && this.value.metadata.name === pvc.metadata.labels[PVC_LABELS.MOUNT_VM] && pvc.status.phase === 'Bound') || (pvc.metadata.labels && pvc.metadata?.labels[PVC_LABELS.MOUNT_VM] === this.value.metadata.name));
      } else {
        // 已存在
        existsDisk = this.pvcs.filter((pvc) => pvc.metadata.namespace === this.value.metadata.namespace && pvc.status.phase === 'Bound' && names.includes(pvc.metadata.name));
        // 已挂载
        labeledPvcs = this.pvcs.filter((pvc) => pvc.metadata.namespace === this.value.metadata.namespace && pvc.metadata.labels && pvc.metadata.labels[PVC_LABELS.MOUNT_VM] && this.value.metadata.name === pvc.metadata.labels[PVC_LABELS.MOUNT_VM] && pvc.status.phase === 'Bound');
      }
      // 挂载中
      const bounding = this.pvcs.filter((pvc) => pvc.metadata.namespace === this.value.metadata.namespace && pvc.metadata.labels && pvc.metadata.labels[PVC_LABELS.MOUNT_VM] && this.value.metadata.name === pvc.metadata.labels[PVC_LABELS.MOUNT_VM] && pvc.status.phase === 'Pending');
      // 重启生效
      const pvcs = names.filter((name) => this.pvcs.map((v) => v.metadata.namespace === this.value.metadata.namespace && !names.includes(v.metadata.name)));

      tableData = [
        ...labeledPvcs.map((pvc) => {
          return {
            name:         pvc.metadata.name,
            type:         this.getPvcType(pvc.annotations[PVC_ANNOTATIONS.TYPE]),
            status:       pvc?.metadata?.deletionTimestamp ? 'Terminating' : pvc?.stateDisplay === 'Bound' ? this.t('pai.detail.vmset.tab.diskManagement.mounted') : pvc?.stateDisplay,
            volume:       pvc.spec.volumeName,
            mode:         pvc.spec.accessModes[0],
            storageClass: pvc.spec.storageClassName,
            volumeType:   pvc.spec.volumeMode,
            time:         pvc.metadata.creationTimestamp,
            size:         convertUnitToG(pvc.spec.resources?.requests?.storage),
          };
        }),
        ...bounding.map((pvc) => {
          return {
            name:         pvc.metadata?.name,
            type:         this.getPvcType(pvc.annotations[PVC_ANNOTATIONS.TYPE]),
            status:       this.t('pai.detail.vmset.tab.diskManagement.mounting'),
            volume:       pvc.spec?.volumeName,
            mode:         pvc.spec?.accessModes[0],
            storageClass: pvc.spec?.storageClassName,
            volumeType:   pvc.spec?.volumeMode,
            time:         pvc.metadata?.creationTimestamp,
            size:         convertUnitToG(pvc.spec.resources?.requests?.storage),
          };
        }),
        ...pvcs.map((pvc) => {
          return {
            name:   pvc,
            status: this.t('pai.detail.vmset.tab.diskManagement.restartEffect'),
          };
        })];
      const newExistsDisk = existsDisk.map((pvc) => {
        return {
          name:         pvc.metadata.name,
          type:         this.getPvcType(pvc.annotations[PVC_ANNOTATIONS.TYPE]),
          status:       pvc?.metadata?.deletionTimestamp ? 'Terminating' : pvc?.stateDisplay === 'Bound' ? this.t('pai.detail.vmset.tab.diskManagement.mounted') : pvc?.stateDisplay,
          volume:       pvc.spec.volumeName,
          mode:         pvc.spec.accessModes[0],
          storageClass: pvc.spec.storageClassName,
          volumeType:   pvc.spec.volumeMode,
          time:         pvc.metadata.creationTimestamp,
          size:         convertUnitToG(pvc.spec.resources?.requests?.storage),
        };
      });

      if (this.value?.status?.instances && Object.keys(this.value.status.instances).length > 1) { // 如果有多个pod的时候点击pod展示对应的磁盘数据
        tableData = tableData.filter((item) => item.name.includes(templatesPodName));
      }
      const newArr = tableData.map((item) => {
        if (item.name.includes('-0-0')) {
          return tableData.concat(newExistsDisk);
        } else {
          return tableData;
        }
      });

      const list = newArr && newArr[0]?.filter((obj, index) => newArr[0].findIndex((item) => item.name === obj.name) === index);

      return list;
    },
  },
  methods: {
    handleCheckedChange(value) {
      this.completelyDelete = value;
    },
    handleUnload(name) {
      this.deleteDiskVisible = true;
      this.deleteCurrentDisk = name;
      this.completelyDelete = false;
    },
    getPvcType(status) {
      let type = '';

      switch (status) {
      case 'vmosdisk':
        type = this.t('pai.vmset.storage.types.os');
        break;
      case 'toolkitdisk':
        type = this.t('pai.vmset.image.toolkit');
        break;
      case 'cdromdisk':
        type = this.t('pai.vmset.storage.types.iso');
        break;
      case 'datadisk':
        type = this.t('pai.vmset.storage.data');
        break;
      default:
        type = '';
      }

      return type;
    },
    async onDelete() {
      this.deleteDiskVisible = false;
      if (this.completelyDelete === true) { // 彻底删除
        try {
          // 当前要强制卸载磁盘的pvc信息
          const result = this.pvcs.find((i) => i.metadata.name === this.deleteCurrentDisk);

          await result.remove();
          await this.$message({
            type:    'success',
            message: this.t('pai.labels.success')
          });
        } catch (e) {
          console.log(e);
        }
      }
      const name = this.deleteCurrentDisk;
      const diskName = name.includes(this.podName.replace('.', '-')) ? name.split(`${ this.podName.replace('.', '-') }-`)[1] : name;

      if (this.value.spec.volumeClaimTemplates.find((v) => v.metadata.name === diskName)) {
        this.value.spec.volumeClaimTemplates.splice(this.value.spec.volumeClaimTemplates.indexOf((v) => v.metadata.name === diskName), 1);
      }
      if (this.value.spec.volumes?.find((v) => v.name === diskName)) {
        this.value.spec.volumes.splice(this.value.spec.volumes.indexOf((v) => v.name === diskName), 1);
      }
      const pvc = this.pvcs.find((v) => v.metadata.name === name);

      try {
        if (pvc && this.completelyDelete === false) {
          pvc.setLabel(PVC_LABELS.MOUNT_VM, '');
          pvc.setLabel(PVC_LABELS.MOUNT_POD, '');
          await pvc.save();
        }
        await this.value.save();

        this.$confirm(this.t('pai.vmset.tips.deleteDiskRestart'), this.t('pai.labels.tip'), {
          confirmButtonText: this.t('pai.edit.machine.yes'),
          cancelButtonText:  this.t('pai.edit.machine.no'),
          type:              'warning'
        }).then(async() => {
          try {
            await this.value.powerOff();
            this.powerState = VM_POWER_STATES_ENUM.Off;
            setTimeout(async() => {
              await this.value.powerOn();
              this.powerState = VM_POWER_STATES_ENUM.On;
            }, 1000 * 10);
            this.$message({
              type:    'success',
              message: `${ this.t('pai.labels.success') }`,
            });
          } catch (e) {
            this.$message({
              type:    'warning',
              message: e.message
            });
          }
        });
      } catch (e) {
        this.$message({
          type:    'warning',
          message: e.message
        });
      }
    },
  },
};
</script>

<template>
  <el-card>
    <el-row>
      <el-col :span="24">
        <el-input
          v-model="filterValue"
          :placeholder="t('pai.detail.vmset.filter')"
          prefix-icon="el-icon-search"
          style="float: right"
          size="small"
        />
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? data.filter(item => item.name.includes(filterValue)): data"
          class="diskTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
          />
          <el-table-column
            prop="name"
            :label="t('pai.detail.vmset.tab.diskManagement.name')"
            align="center"
            width="280"
          />
          <el-table-column
            prop="type"
            :label="t('pai.detail.vmset.tab.diskManagement.type')"
            align="center"
          />
          <el-table-column
            prop="size"
            :label="t('pai.detail.vmset.tab.diskManagement.size')"
            align="center"
          />
          <el-table-column
            prop="status"
            :label="t('pai.detail.vmset.status')"
            align="center"
            width="130"
          />
          <el-table-column
            prop="volume"
            :label="t('pai.detail.vmset.tab.diskManagement.volume')"
            align="center"
            width="250"
          />
          <el-table-column
            prop="mode"
            :label="t('pai.detail.vmset.tab.diskManagement.accessMode')"
            align="center"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.diskManagement.storageClass')"
            align="center"
          >
            <template slot-scope="scope">
              {{ scope.row.storageClass ? scs.find(v=>v.name === scope.row.storageClass) && scs.find(v=>v.name === scope.row.storageClass).alias ? scs.find(v=>v.name === scope.row.storageClass).alias : scope.row.storageClass : '-' }}
            </template>
          </el-table-column>
          <el-table-column
            prop="volumeType"
            :label="t('pai.detail.vmset.tab.diskManagement.volumeType')"
            align="center"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.diskManagement.survivalTime')"
            align="center"
          >
            <template slot-scope="scope">
              <div>
                <LiveDate :value="scope.row.time" />
              </div>
            </template>
          </el-table-column>
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="80"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                :disabled="(scope.row.type === '系统盘' || scope.row.type === 'OS Disk') || scope.row.status === '重启生效' || scope.row.status === '挂载中' || scope.row.status === 'Terminating' || data.length === 1"
                @click="handleUnload(scope.row.name)"
              >
                <i class="el-icon-refresh-left" /> {{ t('pai.detail.vmset.tab.diskManagement.unmountVolume') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="t('pai.detail.vmset.confirmUnload')"
          :visible.sync="deleteDiskVisible"
          width="500px"
        >
          <div style="display: flex; flexDirection: column; alignItems: flex-start; margin-left: 15px">
            <b>{{ t('pai.vmset.tips.diskUnloadFromVm') }}</b>
            <el-checkbox
              v-model="completelyDelete"
              style="margin: 20px 0 0 0"
              @change="handleCheckedChange"
            >
              {{ t('pai.vmset.tips.completelyDelete') }}
            </el-checkbox>
            <Banner
              color="warning"
              label-key="pai.vmset.tips.diskCompletelyDelete"
            />
          </div>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button @click="deleteDiskVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
            <el-button
              type="primary"
              @click="onDelete"
            >{{ t('pai.detail.vmset.confirm') }}</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </el-card>
</template>
<style lang="scss" scoped>
.diskTable{
  padding: 0 20px;
}
.el-input, .el-select, .el-input-number{
  width: 300px;
}
::v-deep .el-descriptions-item__label {
  width: 100px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
