<script>
import day from 'dayjs';
import { NORMAN } from '@/shell/config/types';
import { mapGetters } from 'vuex';
import { PAI_RESOURCES } from '../../../config/types';
export default {
  props: {
    value: {
      type:    Object,
      default: () => {
        return {};
      }
    }
  },
  data() {
    const params = this.$route.params;
    // 当时时间戳
    const time = new Date().valueOf();

    return {
      params,
      restoreDialogVisible: false,
      restoreForm:          { name: `restore-ns-${ this.value.metadata.namespace }-${ time }` },
      backUpForm:           { name: `backup-vm-${ this.value.metadata.name }-${ time }` },
      restoreData:          [],
      backUps:              [],
      filterValue:          '',
      backUpDialogVisible:  false,
      backupBy:             '',
      currentBackupName:    ''
    };
  },
  async fetch() {
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VM_BACKUP)) {
      this.backUps = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_BACKUP });
    }
    if (this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VM_RESTORE)) {
      this.restoreData = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_RESTORE });
    }
  },
  computed: {
    ...mapGetters([{ t: 'i18n/t' }, 'isRancher', 'currentCluster']),
    newTableData() {
      const dateFormat = 'YYYY-MM-DD';
      const timeFormat = 'HH:mm:ss';
      const tableData = [];

      this.backUps.map((item) => {
        if (item.spec?.vm === this.value.metadata.name ) {
          tableData.push({
            name:               item.metadata?.name,
            projectDisplayName: item.projectDisplayName,
            displayName:        item.metadata?.annotations['com.tdology/alias'],
            namespace:          item.metadata?.namespace,
            status:             item.status?.phase,
            creator:            item.spec?.creator,
            startTime:          day(item.status?.startTimestamp).format(`${ dateFormat } ${ timeFormat }`),
            endTime:            item.status?.completionTimestamp ? day(item.status?.completionTimestamp).format(`${ dateFormat } ${ timeFormat }`) : '-',
            deadTime:           day(item.status?.expiration).format(`${ dateFormat } ${ timeFormat }`),
          });
        }
      });

      return tableData;
    },
    backupStatus() {
      return [
        {
          name: 'Active', label: this.t('pai.detail.vmset.tab.backUpManagement.label.newCreate'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.activeTip')
        },
        {
          name: 'New', label: this.t('pai.detail.vmset.tab.backUpManagement.label.newCreate'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.newTip')
        },
        {
          name: 'FailedValidation', label: this.t('pai.detail.vmset.tab.backUpManagement.label.validationFailed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.validationFailedTip', { key: this.t('pai.detail.vmset.backUp') })
        },
        {
          name: 'InProgress', label: this.t('pai.detail.vmset.tab.backUpManagement.label.inProgress'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.inProgressTip', { key: this.t('pai.detail.vmset.backUp') })
        },
        {
          name: 'WaitingForPluginOperations', label: this.t('pai.detail.vmset.tab.backUpManagement.label.waitingForPluginOperations'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.waitingForPluginOperationsTip')
        },
        {
          name: 'WaitingForPluginOperationsPartiallyFailed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.waitingForPluginOperationsPartiallyFailed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.waitingForPluginOperationsPartiallyFailedTip')
        },
        {
          name: 'Finalizing', label: this.t('pai.detail.vmset.tab.backUpManagement.label.finalizing'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.finalizingTip')
        },
        {
          name: 'FinalizingPartiallyFailed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.finalizingPartiallyFailed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.finalizingPartiallyFailedTip')
        },
        {
          name: 'Completed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.completed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.completedTip', { key: this.t('pai.detail.vmset.backUp') })
        },
        {
          name: 'PartiallyFailed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.partiallyFailed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.partiallyFailedTip', { key: this.t('pai.detail.vmset.backUp') })
        },
        {
          name: 'Failed', label: this.t('pai.detail.vmset.tab.backUpManagement.label.failed'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.failedTip', { key: this.t('pai.detail.vmset.backUp') })
        },
        {
          name: 'Deleting', label: this.t('pai.detail.vmset.tab.backUpManagement.label.deleting'), tip: this.t('pai.detail.vmset.tab.backUpManagement.tips.deletingTip')
        },
      ];
    },
    principal() {
      if (this.isRancher) {
        return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
      } else {
        const user = this.$store.getters['auth/v3User'];

        if (user) {
          return { loginName: user.name };
        }

        return { loginName: 'admin' };
      }
    },
  },
  methods: {
    restoreVm(value) {
      const time = new Date().valueOf();

      this.backupBy = value.creator;
      this.currentBackupName = value.name;
      this.restoreDialogVisible = true;
      this.restoreForm = { name: `restore-ns-${ this.value.metadata.namespace }-${ time }` };
    },
    async onRestore() {
      if (this.restoreForm.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      } else if (/[\u4E00-\u9FA5]/g.test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.verifyChinese'));

        return;
      } else if ((/[A-Z]/g).test(this.restoreForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.notUppercase'));

        return;
      }
      if (this.restoreData.length > 0) {
        for (const item in this.restoreData) {
          if (this.restoreData[item]?.metadata?.annotations?.vm === this.value?.metadata?.name) {
            if (this.restoreData[item]?.metadata?.annotations['com.tdology/alias'] === this.restoreForm?.name) {
              this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

              return;
            }
          }
        }
      }
      const time = new Date().valueOf();

      try {
        const restore = await this.$store.dispatch(`cluster/create`, {
          type:     PAI_RESOURCES.VM_RESTORE,
          metadata: {
            annotations: { 'com.tdology/alias': this.restoreForm.name, vm: this.value.metadata.name },
            name:        `restore-ns-${ this.restoreForm.name }-${ time }`,
            namespace:   this.value.metadata.namespace,
            vm:          this.value.metadata.name
          },
          spec: {
            creator:    this.backupBy,
            backupName: this.currentBackupName
          }
        });

        await restore.save({ url: `/k8s/clusters/${ this.currentCluster.id }/v1/${ PAI_RESOURCES.VM_RESTORE }` });

        this.$message({
          type:    'success',
          message: `${ this.t('pai.labels.success') }`,
        });
      } catch (e) {
        this.$message({
          type:    'warning',
          message: e.message
        });
      }
      this.restoreDialogVisible = false;
      // 更新恢复记录
      await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_RESTORE, opt: { force: true } });
    },
    deleteBackup(value) {
      this.$confirm(this.t('pai.detail.vmset.confirmDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(async() => {
        const index = this.backUps.findIndex((i) => value.name === i.metadata.name);

        await this.backUps[index].remove();
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteSuccess')
        });
      }).catch((error) => {
        console.log(error);
        this.$message({
          type:    'info',
          message: this.t('pai.detail.vmset.cancelDelete')
        });
      });
    },
    backupShow() {
      const time = new Date().valueOf();

      this.backUpForm = { name: `backup-vm-${ this.value.metadata.name }-${ time }` };
      this.backUpDialogVisible = true;
    },
    async onBackUp() { // 备份
      if (this.backUpForm.name === '') {
        this.$message.error(this.t('pai.detail.vmset.enterName'));

        return;
      } else if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.backUpForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

        return;
      } else if (/[\u4E00-\u9FA5]/g.test(this.backUpForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.verifyChinese'));

        return;
      } else if ((/[A-Z]/g).test(this.backUpForm.name)) {
        this.$message.error(this.t('pai.detail.vmset.notUppercase'));

        return;
      }
      for (const item in this.backUps) {
        if (this.backUps[item].spec?.vm === this.value.metadata.name) {
          if (this.backUps[item]?.metadata?.annotations['com.tdology/alias'] === this.backUpForm.name) {
            this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

            return;
          }
        }
      }

      const time = new Date().valueOf();

      try {
        const backUp = await this.$store.dispatch(`cluster/create`, {
          type:     PAI_RESOURCES.VM_BACKUP,
          metadata: {
            annotations: { 'com.tdology/alias': this.backUpForm.name },
            name:        `backup-vm-${ this.backUpForm.name }-${ time }`,
            namespace:   this.value.metadata.namespace,
          },
          spec: {
            vm:      this.value.metadata.name,
            creator: this.principal.loginName
          },
        });

        await backUp.save({ url: `/k8s/clusters/${ this.currentCluster.id }/v1/${ PAI_RESOURCES.VM_BACKUP }` });
        this.$message.success(this.t('pai.detail.vmset.backuping'));
      } catch (e) {
        console.log(e);
        this.$message.error(this.t('pai.detail.vmset.backupError'));
      }
      this.backUpDialogVisible = false;
      // 刷新数据
      this.backUps = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_BACKUP, opt: { force: true } });
    }
  }
};

</script>

<template>
  <el-card style="margin:10px">
    <el-row>
      <el-col :span="24">
        <div style="display: flex; justify-content: space-between;">
          <div>
            <el-button
              class="btn role-primary"
              @click="backupShow"
            >
              <i class="el-icon-plus" /> {{ t('pai.detail.vmset.tab.backUpManagement.createBackUp') }}
            </el-button>
          </div>
          <el-input
            v-model="filterValue"
            clearable
            :placeholder="t('pai.detail.vmset.filter')"
            prefix-icon="el-icon-search"
            size="small"
          />
          <el-dialog
            :title="t('pai.detail.vmset.tab.backUpManagement.backUpVm')"
            :visible.sync="backUpDialogVisible"
            width="500px"
          >
            <el-descriptions
              :column="1"
              :colon="false"
            >
              <el-descriptions-item>
                <template slot="label">
                  <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.backUpManagement.name') }}
                </template>
                <el-input
                  v-model="backUpForm.name"
                />
              </el-descriptions-item>
            </el-descriptions>
            <span
              slot="footer"
              class="dialog-footer"
            >
              <el-button @click="backUpDialogVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
              <el-button
                type="primary"
                @click="onBackUp"
              >{{ t('pai.detail.vmset.confirm') }}</el-button>
            </span>
          </el-dialog>
        </div>
      </el-col>
      <el-col :span="24">
        <el-table
          :data="filterValue !== ''? newTableData.filter(item => item.displayName.includes(filterValue)): newTableData"
          class="backUpTable"
        >
          <el-table-column
            :label="t('pai.detail.vmset.index')"
            type="index"
            width="50"
          />
          <el-table-column
            :label="t('pai.detail.vmset.tab.backUpManagement.name')"
            width="170"
          >
            <template slot-scope="scope">
              <el-tooltip
                :content="scope.row.displayName"
                effect="light"
              >
                <span
                  style="width: 180px; overflow:hidden; white-space: nowrap; text-overflow: ellipsis;"
                >{{ scope.row.displayName }}</span>
              </el-tooltip>
            </template>
          </el-table-column>
          <el-table-column
            v-if="isRancher"
            prop="projectDisplayName"
            :label="t('pai.detail.vmset.tab.backUpManagement.projectName')"
          />
          <el-table-column
            prop="namespace"
            :label="t('pai.detail.vmset.tab.backUpManagement.namespaceName')"
          />
          <el-table-column
            :label="t('pai.detail.vmset.status')"
          >
            <template slot-scope="scope">
              <div
                v-for="(item, index) in backupStatus"
                :key="index"
              >
                <span v-if="item.name?.toLowerCase() === scope.row?.status?.toLowerCase()">
                  {{ item.label }}
                  <el-tooltip
                    :content="item.tip"
                    placement="top"
                    effect="light"
                  >
                    <img src="@pkg/pai/assets/images/overview/tooltips.svg">
                  </el-tooltip>
                </span>
              </div>
            </template>
          </el-table-column>
          <el-table-column
            prop="creator"
            :label="t('pai.detail.vmset.tab.backUpManagement.creator')"
          />
          <el-table-column
            prop="startTime"
            :label="t('pai.detail.vmset.startTime')"
            width="180"
          />
          <el-table-column
            prop="endTime"
            :label="t('pai.detail.vmset.tab.backUpManagement.endTime')"
            width="180"
          />
          <el-table-column
            prop="deadTime"
            :label="t('pai.detail.vmset.tab.backUpManagement.deadTime')"
            width="180"
          />
          <el-table-column
            fixed="right"
            :label="t('pai.detail.vmset.operate')"
            width="200"
          >
            <template slot-scope="scope">
              <el-button
                type="text"
                size="small"
                :disabled="scope.row.status !== 'Completed'"
                @click="restoreVm(scope.row)"
              >
                <i class="el-icon-refresh-left" /> {{ t('pai.detail.vmset.tab.backUpManagement.resume') }}
              </el-button>
              <el-button
                type="text"
                size="small"
                :disabled="!scope.row.status || scope.row.status === 'InProgress' || scope.row.status === 'Deleting' || scope.row.status === 'WaitingForPluginOperations' || scope.row.status === 'WaitingForPluginOperationsPartiallyFailed' || scope.row.status === 'Finalizing' || scope.row.status === 'FinalizingPartiallyFailed'"
                @click="deleteBackup(scope.row)"
              >
                <i class="el-icon-delete-solid" /> {{ t('pai.detail.vmset.delete') }}
              </el-button>
            </template>
          </el-table-column>
        </el-table>
        <el-dialog
          :title="t('pai.detail.vmset.tab.backUpManagement.restoreVm')"
          :visible.sync="restoreDialogVisible"
          width="500px"
        >
          <el-descriptions
            :column="1"
            :colon="false"
          >
            <el-descriptions-item>
              <template slot="label">
                <span style="color: red">*</span>{{ t('pai.detail.vmset.tab.backUpManagement.restoreName') }}
              </template>
              <el-input
                v-model="restoreForm.name"
              />
            </el-descriptions-item>
          </el-descriptions>
          <span
            slot="footer"
            class="dialog-footer"
          >
            <el-button @click="restoreDialogVisible = false">{{ t('pai.detail.vmset.cancel') }}</el-button>
            <el-button
              type="primary"
              @click="onRestore"
            >{{ t('pai.detail.vmset.confirm') }}</el-button>
          </span>
        </el-dialog>
      </el-col>
    </el-row>
  </el-card>
</template>

<style lang="scss" scoped>
.backUpTable{
  padding: 0 20px;
}
.el-input {
  border: none;
  border: 2px solid #5084AA;
  border-radius: 5px;
  width: 215px;
}
.el-input{
  width: 300px;
}
::v-deep .el-descriptions-item__label {
  width: 100px;
}
.el-button--text:focus, .el-button--text:hover {
  box-shadow: none !important;
}
</style>
