import { PAI_RESOURCES } from './types';

export const NETWORK_MODES = {
  BRIDGE:      'bridge',
  IP_VLAN:     'ipvlan',
  MAC_VLAN:    'macvlan',
  HOST_DEVICE: 'host-device' // 网卡直通
};
export const MAC_VLAN_MODES = {
  BRIDGE: 'bridge', PRIVATE: 'private', VEPA: 'vepa', PASSTHRU: 'passthru'
};

export const ADDRESS_ASSIGNMENT_TYPE = {
  DHCP: 'dhcp', HOST_LOCAL: 'host-local', STATIC: 'static'
};

export const COMMAND_HASH_MPA = {
  copy:      '#overview',
  backup:    '#backUpManagement',
  image:     '#backUpManagement',
  log:       '#eventLog',
  monitor:   '#monitor',
  snapshot:  '#snapshotManagement',
  detail:    '#overview',
  network:   '#network',
  storage:   '#storage',
  container: '#container',
  vm:        '#vm',
};

export const HIDDEN_MASTHEAD_TYPES = [PAI_RESOURCES.VMSET];

export const IMAGE = {
  OS:          ['linux', 'windows'],
  FRAMEWORK:   ['amd64', 'arm64'],
  AGENT:       ['guest', 'noop'],
  DRIVER:      ['driver.longhorn.io', 'local.csi.aliyun.com'],
  EXPORT_TYPE: ['qcow2', 'raw'],
};

export const NAME_REGEX = /^[a-z][a-z0-9]+$|^[a-z]+([a-z0-9]*-[a-z0-9]+)+/;

export const FORCE_VALUES = {
  loki: {
    FORCE_NAME:      'loki',
    FORCE_NAMESPACE: 'loki',
  }
};

export const RESTART_POLICY_TYPES = ['Always', 'OnFailure', 'Never'];

export const PVC_TYPES = {
  DATA: 'datadisk', ISO: 'cdromdisk', OS: 'vmosdisk', TOOL: 'toolkitdisk'
};
export const REG_BASE_URL = '/api/v1/namespaces/kube-public/services/http:regui:80/proxy';
export const XAPP_URL = `${ REG_BASE_URL }/images/xapps`;
export const REG_URL = `${ REG_BASE_URL }/list`;
