<script>
import * as echarts from 'echarts';
import { MANAGEMENT } from '@/shell/config/types';
import day from 'dayjs';
import { PAI_NODE, PAI_RESOURCES, PAI_STORAGE } from '../../../../config/types';
import metricPoller from '@shell/mixins/metric-poller';
import { PVC } from '@shell/config/types';
import { PRODUCT_NAME } from '../../../../config/pai';
import { mapGetters } from 'vuex';
import ClusterResource from './components/clusterResource';
import AlarmRules from './components/alarmRules';
import AlarmStatistics from './components/alarmStatistics';
import monitorMixins from '../../../../mixins/monitor';

export default {
  layout:     'pai/default',
  components: {
    AlarmRules, ClusterResource, AlarmStatistics
  },
  fetch() {
    // 集群
    this.clusterData();
    if (this.netLine) {
      this.getNetLineRate();
    }
    if (this.diskIOPSData) {
      this.getDiskIOPSRate();
    }
  },
  computed: {
    ...mapGetters(['isRancher']),
    netLine() {
      return this.$store.state['pai-grafana'].netLineForm;
    },
    diskIOPSData() {
      return this.$store.state['pai-grafana'].diskIOPSDataForm;
    },
    clusters() {
      return this.$store.getters['management/all'](MANAGEMENT.CLUSTER);
    },
    vmSchema() {
      return this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VMSET);
    }
  },
  mixins: [metricPoller, monitorMixins],
  watch:  {
    netLine() {
      this.getNetLineRate();
    },
    diskIOPSData() {
      this.getDiskIOPSRate();
    }
  },

  data() {
    const dateFormat = 'YYYY-MM-DD HH:mm:ss';
    const useGrid = {
      top:          '30px',
      left:         '2.5%',
      right:        '3.5%',
      bottom:       '3%',
      containLabel: true
    };
    const colors = ['#0076F7', '#F29B69', '#D95040', '#F2BD42', '#76C1C9', '#E99D42', '#DC6153'];
    const options = [
      {
        name:  this.t(`pai.detail.vmset.cpuUtilization`),
        value: 'cpu'
      },
      {
        name:  this.t('pai.detail.vmset.ramUtilization'),
        value: 'ram'
      },
      {
        name:  this.t('pai.detail.vmset.tab.overview.diskPercentage'),
        value: 'disk'
      },
    ];
    const resourdeData = [
      {
        title:  this.t('pai.overview.cluster'),
        active: 0,
        all:    0,
        path:   require('./images/cluster.png'),
        res:    'cluster',
      }, {
        title:  this.t('pai.menu.cluster.node'),
        active: 0,
        all:    0,
        path:   require( './images/node.png'),
        res:    PAI_NODE,
      }, {
        title:  this.t('pai.menu.k8s.cni.cncf.io.networkattachmentdefinition'),
        active: 0,
        all:    0,
        path:   require('./images/internet.png'),
        res:    PAI_RESOURCES.NAD,
      }, {
        title:  this.t('pai.menu.k8s.cni.cncf.io.storageattachmentdefinition'),
        active: 0,
        all:    0,
        path:   require('./images/storage.png'),
        res:    PAI_STORAGE.PVC,
      },
    ];

    return {
      timeFormat:     'HH:MM',
      startTimeDay:   Math.round((new Date().setDate(new Date().getDate() - 15)) / 1000).toString(), // 15天前
      startTime:      Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(), // 1小时前的10位时间戳
      endTime:        new Date(String(day().format(dateFormat))).getTime() / 1000, // 系统时间10位时间戳
      clusterValue:   'cpu',
      nodeValue:      'cpu',
      useGrid,
      colors,
      resourdeData,
      options,
      timeData:       [],
      timeDataNode:   [],
      clustersVal:    '',
      nodeCluster:    '',
      netRateCluster: '',
      diskCluster:    '',
      fiveMinutes:    300, // 5分钟间隔
      clusterMonitor: false,
      nodeMonitor:    false,
      netMonitor:     false,
      iopsMonitor:    false,
      cloudData:      {
        active: 0,
        all:    0
      },
      podData: {
        active: 0,
        all:    0
      },
      statefulsetData: {
        active: 0,
        all:    0
      },
      deploymentData: {
        active: 0,
        all:    0
      },
      jobData: {
        active: 0,
        all:    0
      },
      cronjobData: {
        active: 0,
        all:    0
      },
      daemonsetData: {
        active: 0,
        all:    0
      },
    };
  },
  methods: {
    async loadMetrics() { // 数据定时刷新
      //  网络资源数据
      this.netWorkData();
      //  存储资源数据
      this.storageData();
      this.getResData();

      if (!this.clustersVal) {
        this.clustersVal = this.activeCluster[0]?.id;
        this.nodeCluster = this.activeCluster[0]?.id;
        this.netRateCluster = this.activeCluster[0]?.id;
        this.diskCluster = this.activeCluster[0]?.id;
      }
      try {
        if (this.clusterValue === 'cpu') {
          await this.getCPUUtil();
        } else if (this.clusterValue === 'ram') {
          await this.getMemoryUtil();
        } else if (this.clusterValue === 'disk') {
          await this.getDiskUtil();
        }
      } catch (e) {
        console.log(e);
      }

      try {
        if (this.nodeValue === 'cpu') {
          await this.getCPUNode();
        } else if (this.nodeValue === 'ram') {
          await this.getMemoryNode();
        } else if (this.nodeValue === 'disk') {
          await this.getDiskNode();
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (this.netRateCluster) {
          await this.getNetRateWork();
        }
      } catch (e) {
        console.log(e);
      }
      try {
        if (this.diskCluster) {
          await this.getDiskWork();
        }
      } catch (e) {
        console.log(e);
      }
    },
    // 获取集群名称用于未安装监控告警提示
    getCLusterName(id) {
      let clusterName = '';

      this.activeCluster.filter((item) => {
        if (item.id === id) {
          clusterName = item.nameDisplay;
        }
      });

      return clusterName;
    },
    // 集群利用率切换
    clusterValChange(val) {
      this.clustersVal = val;
      this.$nextTick(() => {
        this.getClusterData(this.clusterValue);
      });
    },
    //  集群select切换
    clusterChange(val) {
      this.clusterValue = val;
      this.$nextTick(() => {
        this.getClusterData(val);
      });
    },
    // 集群切换根据类型分别调用不同接口
    getClusterData(val) {
      if (val === 'cpu') {
        this.getCPUUtil();
      } else if (val === 'ram') {
        this.getMemoryUtil();
      } else if (val === 'disk') {
        this.getDiskUtil();
      }
    },

    // 节点集群切换
    nodeClusterChange(val) {
      this.nodeCluster = val;
      this.$nextTick(() => {
        this.getNodeData(this.nodeValue);
      });
    },
    // 节点
    nodeChange(val) {
      this.nodeValue = val;
      this.$nextTick(() => {
        this.getNodeData(val);
      });
    },
    // 节点集群切换根据类型分别调用不同接口
    getNodeData(val) {
      if (val === 'cpu') {
        this.getCPUNode();
      } else if (val === 'ram') {
        this.getMemoryNode();
      } else if (val === 'disk') {
        this.getDiskNode();
      }
    },

    // 网络吞吐量集群切换
    netRateClusterChange(val) {
      this.netRateCluster = val;
      this.$nextTick(() => {
        this.getNetRateWork();
      });
    },
    // 磁盘集群切换
    diskClusterChange(val) {
      this.diskCluster = val;
      this.$nextTick(() => {
        this.getDiskWork();
      });
    },
    // 集群使用率
    getClusterUseChart(data) {
      if (!this.$refs.clusterUseChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.clusterUseChart);
      const seriesArr = [];
      const colors = [];
      const Xdata = this.timeData;
      let option = {};

      myChart.clear();

      if (data?.length !== 0) {
        data.forEach((item, index) => {
          colors[index] = this.colors[index];
          seriesArr.push({
            name: item.name,
            type: 'line',
            data: item.num,
          });
        });

        option = {
          animation: false,
          color:     colors,
          grid:      this.useGrid,
          tooltip:   {
            trigger: 'axis',
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `
               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        Xdata
          },
          yAxis: {
            type:    'value',
            name:    `${ this.t('pai.detail.vmset.unit') }：%`,
            nameGap: '10',
            max:     100,
          },
          series: seriesArr
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // 节点使用率
    getNodeUseChart(data) {
      if (!this.$refs.nodeUseChart) {
        return;
      }
      const myChart = echarts.init(this.$refs.nodeUseChart);
      const seriesArr = [];
      const colors = [];
      const Xdata = this.timeDataNode;
      let option = {};

      myChart.clear();

      if (data?.length !== 0) {
        data.forEach((item, index) => {
          colors[index] = this.colors[index];
          seriesArr.push({
            name: item.name,
            type: 'line',
            data: item.num,
          });
        });

        option = {
          animation: false,
          color:     colors,
          grid:      this.useGrid,
          tooltip:   {
            trigger: 'axis',
            formatter(params) {
              let html = `<div>${ params[0].axisValue }</div>`;

              params.forEach((v) => {
                html += `

               <div class='hang'>
                <div style="display:flex;align-items: center;">
                  <div style="margin-right:5px;border-radius:10px;width:10px;height:10px;background-color:${ v.color };"></div>
                  <div class="small" style='background-color:${ v.color };'></div>
                  <div style:"float:left">${ v.seriesName }</div>
                  <div style='font-weight:700;margin-left: 60px'>${ v.value }%</div>
                </div>
               </div>`;
              });

              return html;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        Xdata
          },
          yAxis: {
            type:    'value',
            name:    `${ this.t('pai.detail.vmset.unit') }：%`,
            nameGap: '10',
            max:     100,
          },
          series: seriesArr
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
      }

      myChart.setOption(option, true);
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },

    // CPU利用率数据
    async getCPUUtil() {
      this.clusterMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];

      const params = {
        clusterName: this.getCLusterName(this.clustersVal),
        url:         `/k8s/clusters/${ this.clustersVal }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate({__name__=~"node_cpu_seconds_total",mode="idle"}[240s])))*100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const result = await this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404) {
          this.clusterMonitor = true;
        }
      });

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: this.getCLusterName(this.clustersVal),
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeData = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getClusterUseChart(newArr);
    },

    // 内存利用率数据
    async getMemoryUtil() {
      this.clusterMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const params = {
        clusterName: this.getCLusterName(this.clustersVal),
        url:         `/k8s/clusters/${ this.clustersVal }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - sum(node_memory_MemAvailable_bytes OR windows_os_physical_memory_free_bytes) / sum(node_memory_MemTotal_bytes OR windows_cs_physical_memory_bytes))*100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const result = await this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404) {
          this.clusterMonitor = true;
        }
      });

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: this.getCLusterName(this.clustersVal),
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeData = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getClusterUseChart(newArr);
    },

    // 磁盘利用率数据
    async getDiskUtil() {
      this.clusterMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      const params = {
        clusterName: this.getCLusterName(this.clustersVal),
        url:         `/k8s/clusters/${ this.clustersVal }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes) by (device, instance)))) / ceil((sum(avg(node_filesystem_size_bytes) by (device, instance)) - sum(avg(node_filesystem_avail_bytes{device=~"data/.*|longhorn/.*"}) by (device, instance) or vector(0)))) * 100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const result = await this.$store.dispatch('pai-common/getUse', params).catch((err) => {
        if (err.response.status === 404) {
          this.clusterMonitor = true;
        }
      });

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: this.getCLusterName(this.clustersVal),
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeData = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getClusterUseChart(newArr);
    },

    //  节点CPU使用率
    async getCPUNode() {
      this.nodeMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      let ipsResult = {};
      let ips = {};
      const newArr = [];

      const paramNode = {
        showGrowl:   false,
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=kube_node_info&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const arrNode = await this.$store.dispatch('pai-common/getUse', paramNode).catch((err) => {
        if (err.response.status === 404) {
          this.nodeMonitor = true;
          this.getNodeUseChart(newArr);
        } else {
          this.getNodeUseChart(newArr);
        }
      });

      ipsResult = arrNode?.data;
      if (ipsResult?.data?.result?.length !== 0) {
        ipsResult?.data?.result.forEach((v) => {
          const ip = v.metric.internal_ip;
          const node = v.metric.node;

          ips = { ...ips, [ip]: node };
        });
      }
      const param = {
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(1 - avg(irate(node_cpu_seconds_total{mode="idle",job="node-exporter"}[5m])) by (instance)) * 100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const result = await this.$store.dispatch('pai-common/getUse', param);

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: ips[item.metric.instance.split(':')[0]],
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeDataNode = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getNodeUseChart(newArr);
    },

    //  节点内存使用率
    async getMemoryNode() {
      this.nodeMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      const newArr = [];
      let ipsResult = {};
      let ips = {};

      const paramNode = {
        showGrowl:   false,
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=kube_node_info&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arrNode = await this.$store.dispatch('pai-common/getUse', paramNode).catch((err) => {
        if (err.response.status === 404) {
          this.nodeMonitor = true;
          this.getNodeUseChart(newArr);
        } else {
          this.getNodeUseChart(newArr);
        }
      });

      ipsResult = arrNode?.data;
      if (ipsResult?.data?.result?.length !== 0) {
        ipsResult?.data?.result.forEach((v) => {
          const ip = v.metric.internal_ip;
          const node = v.metric.node;

          ips = { ...ips, [ip]: node };
        });
      }
      const param = {
        showGrowl:   false,
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=(sum(node_memory_MemTotal_bytes{job="node-exporter"})  by (instance) - sum(node_memory_MemAvailable_bytes)  by (instance)) / sum(node_memory_MemTotal_bytes)  by (instance) * 100&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };
      const result = await this.$store.dispatch('pai-common/getUse', param);

      const datas = result?.data?.data?.result;

      datas?.map((item) => {
        newArr.push(
          {
            name: ips[item.metric.instance.split(':')[0]],
            num:  item.values?.map((val) => {
              return (parseFloat(val[1])).toFixed(2);
            }),
          });
        if (item?.values !== undefined) {
          this.timeDataNode = item.values?.map((val) => {
            const now = new Date(val[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
        }
      });
      this.getNodeUseChart(newArr);
    },

    //  节点磁盘使用率
    async getDiskNode() {
      this.nodeMonitor = false;
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳
      let ipsResult = {};
      let ips = {};
      let usedList = [];
      let list = [];

      // 获取节点
      const param1 = {
        showGrowl:   false,
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=kube_node_info&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arr1 = await this.$store.dispatch('pai-common/getUse', param1).catch((err) => {
        if (err.response.status === 404) {
          this.nodeMonitor = true;
          this.getNodeUseChart(list);
        } else {
          this.getNodeUseChart(list);
        }
      });

      ipsResult = arr1?.data;
      if (ipsResult?.data?.result?.length !== 0) {
        ipsResult?.data?.result.forEach((v) => {
          const ip = v.metric.internal_ip;
          const node = v.metric.node;

          ips = { ...ips, [ip]: node };
        });
      }

      const param2 = {
        showGrowl:   false,
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes{job="node-exporter"}) by (device, instance)) by (instance) - sum(avg(node_filesystem_avail_bytes{fstype="zfs",device=~"data/.*|longhorn/.*"}) by (device, instance) or up * 0) by (instance))/1024/1024/1024)&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arr2 = await this.$store.dispatch('pai-common/getUse', param2);
      const totalData = arr2?.data;

      const param3 = {
        clusterName: this.getCLusterName(this.nodeCluster),
        url:         `/k8s/clusters/${ this.nodeCluster }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/query_range?query=ceil((sum(avg(node_filesystem_size_bytes{job="node-exporter"}) by (device, instance)) by (instance) - sum(avg(node_filesystem_avail_bytes) by (device, instance)) by (instance))/1024/1024/1024)&start=${ startTime }&end=${ endTime }&step=${ this.fiveMinutes }`
      };

      const arr3 = await this.$store.dispatch('pai-common/getUse', param3);
      const usedData = arr3?.data;

      if (usedData?.data?.result?.length !== 0) {
        usedList = usedData?.data?.result?.map((v) => {
          const name = ips[v.metric.instance.split(':')[0]];

          return ({
            name,
            nodeIP: v.metric.instance.split(':')[0],
            value:  v.values
          });
        });
      }

      if (totalData?.data?.result?.length !== 0) {
        const storageNode = totalData?.data?.result?.filter((v, i) => v?.metric?.instance)?.map((v, i) => {
          const nodeName = ips[v.metric.instance.split(':')[0]];
          const used = usedList.filter((v) => v.name === nodeName);

          const timeArr = used[0]?.value.map((time, index) => {
            const now = new Date(time[0] * 1000);

            return `${ [now.getHours() < 10 ? `0${ now.getHours() }` : now.getHours(), now.getMinutes() < 10 ? `0${ now.getMinutes() }` : now.getMinutes()].join(':') }`;
          });
          const percent = used[0]?.value.map((use, index) => {
            return parseFloat((use[1] / v.values[index][1] * 100).toFixed(2));
          });

          return {
            name: nodeName,
            time: timeArr,
            num:  percent,
          };
        });

        list = storageNode?.map((v, i) => {
          return { ...v };
        });
      }
      this.getNodeUseChart(list);
    },

    // 模块对应路由跳转
    handleRoute(val) {
      if (val === 'cluster') {
        this.$router.push({ name: 'pai-overview' });
      }
    },
    // 网络吞吐量/磁盘请求次数
    getNetRateWork() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳

      this.$store.dispatch('pai-grafana/getNetLinePlatForm', {
        cluster: this.netRateCluster,
        step:    15,
        startTime,
        endTime
      });
    },
    getDiskWork() {
      const startTime = Math.round((new Date().setHours(new Date().getHours() - 1)) / 1000).toString(); // 1小时前的10位时间戳
      const endTime = new Date(String(day().format(this.dateFormat))).getTime() / 1000; // 系统时间10位时间戳

      this.$store.dispatch('pai-grafana/getDiskIOPSPlatForm', {
        cluster: this.diskCluster,
        step:    15,
        startTime,
        endTime
      });
    },
    getNetLineRate() {
      if (!this.$refs.netRate) {
        return;
      }
      const myChart = echarts.init(this.$refs.netRate);
      let option = {};

      this.netMonitor = false;

      myChart.clear();

      if (this.netLine.length !== 0 && this.netLine !== 404) {
        option = {
          animation: false,
          grid:      this.useGrid,
          legend:    {
            type:              'scroll',
            pageButtonItemGap: 10,
            data:              [],
            itemWidth:         15,
            top:               10,
            left:              'center'
          },
          tooltip: {
            trigger:  'axis',
            position: 'left',
            formatter(params) {
              return `${ params[0].axisValueLabel }<br>${
                params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }M</span>` + `<br>${
                params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }M</span>`;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        this.netLine[0]?.nodeValuesDate,
          },
          yAxis: {
            type:    'value',
            name:    `${ this.t('pai.detail.vmset.unit') }：M`,
            nameGap: '10'
          },
          series: [
            {
              type:      'line',
              name:      this.netLine[0]?.node,
              smooth:    true,
              symbol:    this.netLine[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.netLine[0]?.nodeValuesData,
              itemStyle: { color: '#4097D5' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  '#4097D5'
                  },
                  {
                    offset: 1,
                    color:  '#DBE4EF'
                  }
                ])
              },
            },
            {
              type:      'line',
              name:      this.netLine[1]?.node,
              smooth:    true,
              symbol:    this.netLine[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.netLine[1]?.nodeValuesData,
              itemStyle: { color: '#C75250' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  '#C75250'
                  },
                  {
                    offset: 1,
                    color:  '#F7ECEC'
                  }
                ])
              },
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
        if (this.netLine === 404) {
          this.netMonitor = true;
        }
      }

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    getDiskIOPSRate() {
      if (!this.$refs.diskIOPS) {
        return;
      }
      const myChart = echarts.init(this.$refs.diskIOPS);
      let option = {};
      const unit = `${ this.t('pai.detail.vmset.times') }/s`;

      this.iopsMonitor = false;

      myChart.clear();

      if (this.diskIOPSData.length !== 0 && this.diskIOPSData !== 404) {
        option = {
          animation: false,
          grid:      this.useGrid,
          legend:    {
            type:              'scroll',
            pageButtonItemGap: 10,
            data:              [],
            itemWidth:         15,
            top:               10,
            left:              'center'
          },
          tooltip: {
            trigger:  'axis',
            position: 'left',
            formatter(params) {
              return `${ params[0].axisValueLabel }<br>${
                params[0].marker }&nbsp;${ params[0].seriesName }&emsp;` + `<span style="float: right">${ params[0].value }${ unit }</span>` + `<br>${
                params[1].marker }&nbsp;${ params[1].seriesName }&emsp;` + `<span style="float: right">${ params[1].value }${ unit }</span>`;
            }
          },
          xAxis: {
            type:        'category',
            boundaryGap: false,
            data:        this.diskIOPSData[0]?.nodeValuesDate,
          },
          yAxis: {
            type:    'value',
            name:    `${ this.t('pai.detail.vmset.unit') }：${ unit }`,
            nameGap: '10'
          },
          series: [
            {
              type:      'line',
              name:      this.diskIOPSData[0]?.node,
              smooth:    true,
              symbol:    this.diskIOPSData[0]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.diskIOPSData[0]?.nodeValuesData,
              itemStyle: { color: 'rgba(189,183,107,0.7)' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  'rgba(189,183,107,0.7)'
                  },
                  {
                    offset: 1,
                    color:  'rgba(189,183,107,0.1)'
                  }
                ])
              },
            },
            {
              type:      'line',
              name:      this.diskIOPSData[1]?.node,
              smooth:    true,
              symbol:    this.diskIOPSData[1]?.nodeValuesData?.length === 1 ? 'circle' : 'none',
              sampling:  'average',
              data:      this.diskIOPSData[1]?.nodeValuesData,
              itemStyle: { color: 'rgba(32,178,170,0.7)' },
              areaStyle: {
                color: new echarts.graphic.LinearGradient(0, 0, 0, 1, [
                  {
                    offset: 0,
                    color:  'rgba(32,178,170,0.7)'
                  },
                  {
                    offset: 1,
                    color:  'rgba(32,178,170,0.1)'
                  }
                ])
              },
            }
          ]
        };
      } else {
        option = {
          title: {
            text:      this.t('pai.detail.vmset.noData'),
            x:         'center',
            y:         'center',
            textStyle: {
              fontSize:   16,
              fontWeight: 'normal',
            }
          }
        };
        if (this.diskIOPSData === 404) {
          this.iopsMonitor = true;
        }
      }

      myChart.setOption(option);
      myChart.resize();
      window.addEventListener('resize', () => {
        myChart.resize();
      });
    },
    monitorLink(cluster) {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-chart`,
        params: {
          cluster,
          product: PRODUCT_NAME,
        },
        query: {
          'repo-type': 'cluster', repo: this.isRancher ? 'rancher-charts' : 'mini', chart: 'rancher-monitoring'
        }
      });
    },
    //  网络资源数据
    async netWorkData() {
      try {
        const internetData = await this.$store.dispatch('management/findAll', { type: PAI_RESOURCES.NAD });
        const activeInternet = [];

        internetData.forEach((item) => {
          if (item.metadata.state.name === 'active') {
            activeInternet.push(item);
          }
        });
        this.$set(this.resourdeData[2], 'all', internetData.length);
        this.$set(this.resourdeData[2], 'active', activeInternet.length);
      } catch (e) {
        console.log(e);
      }
    },
    //  存储资源数据
    async storageData() {
      try {
        const pvcData = await this.$store.dispatch('cluster/findAll', { type: PVC });
        const activeDisk = [];

        pvcData.forEach((item) => {
          if (item.metadata.state.name === 'bound') {
            activeDisk.push(item);
          }
        });

        this.$set(this.resourdeData[3], 'all', pvcData.length);
        this.$set(this.resourdeData[3], 'active', activeDisk.length);
      } catch (e) {
        console.log(e);
      }
    },
    async getResData() {
      let cloudData = 0;
      const activeCloud = [];
      let podData = 0;
      let noActivePod = 0;
      let statefulsetData = 0;
      let noActiveStatefulset = 0;
      let deploymentData = 0;
      let noActiveDeployment = 0;
      let jobData = 0;
      let noActiveJob = 0;
      let cronjobData = 0;
      let noActiveCronjob = 0;
      let daemonsetData = 0;
      let noActiveDaemonset = 0;

      for (const item of this.activeCluster) {
        const countsReq = await this.$store.dispatch('management/request', { url: `/k8s/clusters/${ item.id }/v1/counts` });
        const countsResult = countsReq.data[0].counts;

        // 云主机组
        try {
          const cloudReq = await this.$store.dispatch('management/request', { url: `/k8s/clusters/${ item.id }/v1/virt.liveit100.com.vmset` });

          if (cloudReq !== undefined) {
            cloudData += cloudReq.count !== undefined ? cloudReq.count : 0;
            cloudReq?.data?.forEach((item) => {
              if (item.spec.power === 'On') {
                activeCloud.push(item);
              }
            });
          }
        } catch (e) {
          console.log(e);
        }

        // 容器组
        podData += countsResult['pod'] !== undefined ? countsResult['pod'].summary?.count : 0;
        if (countsResult['pod']?.summary?.states !== undefined) {
          const statesData = countsResult['pod'].summary?.states;

          for (const key in statesData) {
            noActivePod += statesData[key];
          }
        }

        // 有状态负载
        statefulsetData += countsResult['apps.statefulset'].summary?.count !== undefined ? countsResult['apps.statefulset'].summary?.count : 0;
        if (countsResult['apps.statefulset'].summary?.states !== undefined) {
          const statesData = countsResult['apps.statefulset'].summary?.states;

          for (const key in statesData) {
            noActiveStatefulset += statesData[key];
          }
        }

        // 无状态负载
        deploymentData += countsResult['apps.deployment'].summary?.count !== undefined ? countsResult['apps.deployment'].summary?.count : 0;
        if (countsResult['apps.deployment'].summary?.states !== undefined) {
          const statesData = countsResult['apps.deployment'].summary?.states;

          for (const key in statesData) {
            noActiveDeployment += statesData[key];
          }
        }

        // 普通任务
        jobData += countsResult['batch.job'].summary?.count !== undefined ? countsResult['batch.job'].summary?.count : 0;
        if (countsResult['batch.job'].summary?.states !== undefined) {
          const statesData = countsResult['batch.job'].summary?.states;

          for (const key in statesData) {
            noActiveJob += statesData[key];
          }
        }

        // 定时任务
        cronjobData += countsResult['batch.cronjob'].summary?.count !== undefined ? countsResult['batch.cronjob'].summary?.count : 0;
        if (countsResult['batch.cronjob'].summary?.states !== undefined) {
          const statesData = countsResult['batch.cronjob'].summary?.states;

          for (const key in statesData) {
            noActiveCronjob += statesData[key];
          }
        }

        //  守护进程集
        daemonsetData += countsResult['apps.daemonset'].summary?.count !== undefined ? countsResult['apps.daemonset'].summary?.count : 0;
        if (countsResult['apps.daemonset'].summary?.states !== undefined) {
          const statesData = countsResult['apps.daemonset'].summary?.states;

          for (const key in statesData) {
            noActiveDaemonset += statesData[key];
          }
        }
      }

      this.resourdeData[0].all = this.kubeClusters?.length;
      this.resourdeData[0].active = this.activeCluster?.length;
      // 告警数据
      this.getAlarmNodes(this.activeCluster).then(() => {
        this.getRulesData();
        this.getStatisticsData();
        this.activeNode = this.nodeData - (this.nodesEmergentNum + this.nodesTipsNum);
        this.$set(this.resourdeData[1], 'all', this.nodeData);
        this.$set(this.resourdeData[1], 'active', this.activeNode);
      });

      this.$set(this.cloudData, 'all', cloudData);
      this.$set(this.cloudData, 'active', activeCloud.length);
      this.$set(this.podData, 'all', podData);
      this.$set(this.podData, 'active', noActivePod !== 0 ? podData - noActivePod : podData);
      this.$set(this.statefulsetData, 'all', statefulsetData);
      this.$set(this.statefulsetData, 'active', noActiveStatefulset !== 0 ? statefulsetData - noActiveStatefulset : statefulsetData);
      this.$set(this.deploymentData, 'all', deploymentData);
      this.$set(this.deploymentData, 'active', noActiveDeployment !== 0 ? deploymentData - noActiveDeployment : deploymentData);
      this.$set(this.jobData, 'all', jobData);
      this.$set(this.jobData, 'active', noActiveJob !== 0 ? jobData - noActiveJob : jobData);
      this.$set(this.cronjobData, 'all', cronjobData);
      this.$set(this.cronjobData, 'active', noActiveCronjob !== 0 ? cronjobData - noActiveCronjob : cronjobData);
      this.$set(this.daemonsetData, 'all', daemonsetData);
      this.$set(this.daemonsetData, 'active', noActiveDaemonset !== 0 ? daemonsetData - noActiveDaemonset : daemonsetData);
    },
  }
};
</script>
<template>
  <div class="monitor-overview">
    <div class="monitor-overview-main">
      <ClusterResource
        :resourdeData="resourdeData"
        :cloudData="cloudData"
        :podData="podData"
        :statefulsetData="statefulsetData"
        :deploymentData="deploymentData"
        :jobData="jobData"
        :cronjobData="cronjobData"
        :daemonsetData="daemonsetData"
      />
      <div class="main-alarmChart flex">
        <div class="main-alarm-pie">
          <AlarmStatistics
            ref="alarmStatistics"
          />
        </div>
        <div class="main-alarm-line">
          <AlarmRules
            ref="alarmRules"
          />
        </div>
      </div>
      <div class="main-chart flex">
        <div class="main-chart-box">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.clusterUsage') +'('+ t( 'pai.detail.vmset.tab.monitor.anHour' )+')' }}</div>
            <div>
              <el-select
                v-model="clustersVal"
                class="mySelect"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                filterable
                @change="clusterValChange"
              >
                <el-option
                  v-for="(item, index) in kubeClusters"
                  :key="index"
                  :label="item.nameDisplay"
                  :value="item.id"
                  :disabled="item.metadata.state.name !== 'active'"
                />
              </el-select>
              <el-select
                v-model="clusterValue"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                filterable
                @change="clusterChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div
            ref="clusterUseChart"
            style="width: 100%;height: 220px"
          />
          <div
            v-if="clusterMonitor === true"
            class="chartMonitor"
            @click="monitorLink(clustersVal)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
        <div class="main-chart-box">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.nodeUsage') +'('+ t( 'pai.detail.vmset.tab.monitor.anHour' )+')' }}</div>
            <div class="select-box">
              <el-select
                v-model="nodeCluster"
                class="mySelect"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                filterable
                @change="nodeClusterChange"
              >
                <el-option
                  v-for="(item, index) in kubeClusters"
                  :key="index"
                  :label="item.nameDisplay"
                  :value="item.id"
                  :disabled="item.metadata.state.name !== 'active'"
                />
              </el-select>
              <el-select
                v-model="nodeValue"
                :placeholder="t('pai.edit.SelectPlaceholder')"
                filterable
                @change="nodeChange"
              >
                <el-option
                  v-for="item in options"
                  :key="item.value"
                  :label="item.name"
                  :value="item.value"
                />
              </el-select>
            </div>
          </div>
          <div
            ref="nodeUseChart"
            style="width: 100%;height: 220px"
          />
          <div
            v-if="nodeMonitor === true"
            class="chartMonitor"
            @click="monitorLink(nodeCluster)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
      </div>
      <div
        class="main-chart flex"
        style="margin-top: 16px"
      >
        <div class="main-chart-box">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.tab.monitor.netRate') +'('+ t( 'pai.detail.vmset.tab.monitor.anHour' )+')' }}</div>
            <el-select
              v-model="netRateCluster"
              class="mySelect"
              :placeholder="t('pai.edit.SelectPlaceholder')"
              filterable
              @change="netRateClusterChange"
            >
              <el-option
                v-for="(item, index) in kubeClusters"
                :key="index"
                :label="item.nameDisplay"
                :value="item.id"
                :disabled="item.metadata.state.name !== 'active'"
              />
            </el-select>
          </div>
          <div
            ref="netRate"
            style="width: 100%;height: 220px"
          />
          <div
            v-if="netMonitor"
            class="chartMonitor"
            @click="monitorLink(netRateCluster)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
        <div class="main-chart-box">
          <div
            class="box-title flex"
            style="margin-bottom: 0px;"
          >
            <div>{{ t('pai.detail.vmset.tab.overview.diskRequest') + 'IOPS' +'（'+ t( 'pai.detail.vmset.tab.monitor.anHour' )+'）' }}</div>
            <el-select
              v-model="diskCluster"
              class="mySelect"
              :placeholder="t('pai.edit.SelectPlaceholder')"
              filterable
              @change="diskClusterChange"
            >
              <el-option
                v-for="(item, index) in kubeClusters"
                :key="index"
                :label="item.nameDisplay"
                :value="item.id"
                :disabled="item.metadata.state.name !== 'active'"
              />
            </el-select>
          </div>
          <div
            ref="diskIOPS"
            style="width: 100%;height: 220px"
          />
          <div
            v-if="iopsMonitor"
            class="chartMonitor"
            @click="monitorLink(diskCluster)"
          >
            <a>{{ t('pai.detail.vmset.unMonitorInstall') }}</a>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss">
.monitor-overview {
  .el-input__inner {
    height: 26px;
    line-height: 26px;
  }
  .el-input__icon {
    line-height: 26px;
  }
}
</style>

<style lang="scss" scoped>
.monitor-overview{
  .monitor-overview-title{
    margin: 10px 0px;
  }
  .monitor-overview-main{
    padding: 1px 10px 10px 10px;
    background-color: #F5F4F6;
    .main-chart{
      .main-chart-box {
        width: 50%;
        background-color: #FFFFFF;
        padding: 12px 16px;
      }
    }
    .main-alarmChart{
      margin-bottom: 12px;
      .main-alarm-pie, .main-alarm-line{
        background: #FFFFFF;
        width: 50%;
        padding: 12px 16px;
      }
      .main-alarm-pie{
        margin: 0px 10px 0px 0px;
      }
    }
  }
  .box-title {
    margin-bottom: 16px;
    margin-top: 6px;
    font-weight: bold;
  }
  .flex {
    display: flex;
    justify-content: space-between;
  }
  .chartMonitor {
    font-size: 16px;
    position: relative;
    top: -44%;
    left: 57%;
    a{
      cursor: pointer;
    }
  }
}
</style>
