<script>
import VmCard from '../../../components/VmCard';
import { PAI_RESOURCES } from '../../../config/types';
import { compare } from '@shell/utils/sort';
import dayjs from 'dayjs';
import { mapState } from 'vuex';
import { PRODUCT_NAME as PAI } from '../../../config/pai';
import { VMSET_ANNOTATIONS } from '~/pkg/pai/config/labels-annotations';

export default {
  components: { VmCard },
  props:      {
    cluster: {
      type:     String,
      required: true,
    }
  },
  async fetch() {
    this.installedVms = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VMSET });
    await this.setData();
  },
  data() {
    return {
      installedVms:      [],
      installedVmResult: [],
      filterValue:       '',
      cardCount:         7,
    };
  },
  computed: {
    ...mapState('pai-common', ['currentVmRow']),
    filterNamespaces() {
      return this.$parent.getFilterNamespaces();
    },
    getLength() {
      return (arr) => {
        return arr ? arr.length : 0;
      };
    },
    getArr() {
      return (arr) => {
        return arr;
      };
    },
  },
  methods: {
    setData() {
      const filterNamespaces = this.filterNamespaces;

      // 已安装应用-云主机
      this.installedVms = this.installedVms.sort((a, b) => {
        return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
      }).sort((a, b) => {
        return compare(b.starTimeStamp, a.starTimeStamp);
      });
      if (filterNamespaces.length > 0) {
        this.installedVmResult = this.installedVms.filter((v) => filterNamespaces.includes(v.metadata.namespace) && !v?.metadata?.annotations[VMSET_ANNOTATIONS.TEMPLATE] );
      } else {
        this.installedVmResult = this.installedVms.filter((v) => !v?.metadata?.annotations[VMSET_ANNOTATIONS.TEMPLATE] );
      }
    },
    changeCollection() {
      this.installedVms.sort((a, b) => {
        return compare(dayjs(b.creationTimestamp).unix(), dayjs(a.creationTimestamp).unix());
      }).sort((a, b) => {
        return compare(b.starTimeStamp, a.starTimeStamp);
      });
    },
    goList() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource`,
        params: {
          product:  PAI,
          cluster:  this.cluster,
          resource: PAI_RESOURCES.VMSET,
        },
      });
    },
    onWindowResize() {
      if (this.$refs.vmCardContainer && this.$refs.vmCardContainer.clientWidth) {
        this.cardCount = parseInt(this.$refs.vmCardContainer.clientWidth / 255);
      }
    },
  },
  watch: {
    '$route.query.q'(val) {
      this.filterValue = val || '';
    },
    filterNamespaces: {
      deep:      true,
      immediate: true,
      handler() {
        this.setData();
      }
    }
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
    this.$nextTick(() => {
      if (this.$refs.vmCardContainer && this.$refs.vmCardContainer.clientWidth) {
        this.cardCount = this.$refs.vmCardContainer.clientWidth / 255;
      }
    });
  },
};
</script>
<template>
  <div>
    <div class="title">
      <div class="line" />
      {{
        t('pai.vmset.label')
      }}（{{
        getLength(installedVmResult.filter(v => v.metadata.name.includes(filterValue) || v.alias.includes(filterValue)))
      }}）
      <div class="line" />
    </div>
    <div
      ref="vmCardContainer"
      v-loading="$fetchState.pending"
      class="listVm"
    >
      <VmCard
        v-for="vm in getArr(installedVmResult).filter(v=>v.metadata.name.includes(filterValue)||v.alias.includes(filterValue)).slice(0,currentVmRow*cardCount)"
        :key="vm.metadata.uid"
        :value="vm"
        @changeCollection="changeCollection"
      />
    </div>
    <div class="more">
      <el-button @click="goList">
        {{ t('pai.labels.viewMore') }}>>
      </el-button>
      <el-empty
        v-show="getLength(getArr(installedVmResult).filter(v=>v.metadata.name.includes(filterValue)||v.alias.includes(filterValue))) === 0"
        :image-size="60"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.title {
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #0C5489;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin: 10px 0;

  .line {
    height: 50%;
    width: 45%;
    border-bottom: 1px dashed #cccccc;
  }
}
.listVm{
  display:grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 255px);
  grid-gap: 5px;
}
.more {
  text-align: center;

  .el-button {
    background: #0C5489;
    color: #fff;
  }
}
</style>
