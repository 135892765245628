<script>
import { PAI_CATALOG } from '../../../config/types';
import { PRODUCT_NAME as PAI, PRODUCT_NAME } from '../../../config/pai';
import { MANAGEMENT, POD, PVC } from '@shell/config/types';
import { NAMESPACE_FILTERS } from '@shell/store/prefs';
import { mapState } from 'vuex';
import svgIcon from '~/pkg/pai/assets/images/cloudDesktop/svgIcon';
import Loading from '@shell/components/Loading.vue';
export default {
  components: { Loading },
  props:      {
    cluster: {
      type:     String,
      required: true,
    }
  },
  async fetch() {
    // 已安装应用-容器云桌面
    await this.$store.dispatch('pai-common/getCloudDesktop');
    await this.$store.dispatch('cluster/findAll', { type: PVC });
    await this.$store.dispatch('cluster/findAll', { type: POD });
  },
  data() {
    return {
      deleteAppVisible:          false,
      filterValue:               '',
      cardCount:                 7,
      PRODUCT_NAME,
      PAI_CATALOG,
      cloudDesktopListInstalled: [],
      loading:                   true,
      loadingRustDesk:           false,
    };
  },
  beforeDestroy() {
    window.removeEventListener('resize', this.onWindowResize);
  },
  mounted() {
    window.addEventListener('resize', this.onWindowResize);
    this.$nextTick(() => {
      if (this.$refs.cardContainer && this.$refs.cardContainer.clientWidth) {
        this.cardCount = this.$refs.cardContainer.clientWidth / 255;
      }
    });
  },
  computed: {
    ...mapState('pai-common', ['currentDesktopRow']),
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    filters() {
      // 用户选择的项目/命名空间
      if (this.currentCluster && this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster]) {
        if (this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster].join(',')?.includes('all://')) {
          return [];
        } else {
          return this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster];
        }
      } else {
        return [];
      }
    },
    filterProjects() {
      return this.filters.filter((v) => v?.includes('project://')).map((v) => v.split('//')[1]);
    },
    filterNamespaces() {
      return this.filters.filter((v) => v?.includes('ns://')).map((v) => v.split('//')[1]);
    },
    podList() {
      return this.$store.getters['cluster/all'](POD).filter((pod) => pod?.isXApp && pod.status?.phase !== 'Terminating' && !pod.metadata.deletionTimestamp);
    },
    allPvc() {
      return this.$store.getters['cluster/all'](PVC).filter((pod) => pod?.isXApp);
    },
    getLength() {
      return (arr) => {
        return arr ? arr.length : 0;
      };
    },
    getArr() {
      return (arr) => {
        return arr;
      };
    },
    cloudDesktopList() {
      const arr = [];

      try {
        const data = this.$store.state['pai-common'].cloudDesktopList.filter((v) => v?.config && v?.config?.Labels && JSON.parse(v?.config?.Labels).name );

        for (const key in data) {
          const configLabels = JSON.parse(data[key]?.config?.Labels);

          arr.push({
            id:          configLabels?.name,
            desktopName: configLabels?.name,
            repoPath:    data[key]['repo_path'],
            tag:         data[key].tag,
            devShm:      !!configLabels?.shm,
            icon:        svgIcon[configLabels?.name] ? svgIcon[configLabels?.name] : svgIcon['default']
          });
        }

        return arr;
      } catch (e) {
        console.log(e);

        return [];
      }
    },
  },
  watch: {
    '$route.query.q'(val) {
      this.filterValue = val || '';
    },
    podList: {
      handler(nue) {
        this.initCloudDesktopListInstalled(nue);
      },
      deep:      true,
      immediate: true,
    }
  },
  methods: {
    initCloudDesktopListInstalled(podList) {
      for (const item of podList) {
        const find = this.cloudDesktopListInstalled?.find((installed) => installed.alias === item.alias);

        if (find) {
          find.phase = item?.status.phase;
        } else {
          const cloudDeskApp = {
            id:          item.metadata.name,
            desktopName: item.metadata.name,
            name:        item.metadata.name,
            markRemove:  false,
            alias:       item.metadata.annotations['com.tdology/alias'],
            namespace:   item.metadata.namespace,
            phase:       item.status.phase,
            icon:        svgIcon['default'],
            createTime:  item.metadata?.creationTimestamp
          };

          const cloudDeskImage = this.cloudDesktopList.find((cloudDesk) => {
            const container = item.spec?.containers[0];

            return container && container.name === cloudDesk.desktopName && container.image === `${ cloudDesk.repoPath }:${ cloudDesk.tag }`;
          });

          if (cloudDeskImage) {
            cloudDeskApp.desktopName = cloudDeskImage.desktopName;
            cloudDeskApp.tag = `${ cloudDeskImage.repoPath }:${ cloudDeskImage.tag }`;
            if (svgIcon[cloudDeskImage.desktopName]) {
              cloudDeskApp.icon = svgIcon[cloudDeskImage.desktopName];
            }
          }
          this.cloudDesktopListInstalled.push(cloudDeskApp);
        }
      }
      this.cloudDesktopListInstalled = this.cloudDesktopListInstalled.sort((a, b) => new Date(b.createTime).getTime() - new Date(a.createTime).getTime());
    },
    onWindowResize() {
      if (this.$refs.cardContainer && this.$refs.cardContainer.clientWidth) {
        this.cardCount = parseInt(this.$refs.cardContainer.clientWidth / 255);
      }
    },
    goList() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-clouddesktop`,
        params: {
          product:  PAI,
          cluster:  this.cluster,
        },
      });
    },
    showDelete() {
      if (this.deleteAppVisible === true) {
        this.deleteAppVisible = false;
      } else {
        this.deleteAppVisible = true;
      }
    },
    handleDeleteApp(value) {
      this.$confirm(this.t('pai.detail.vmset.desktopDelete'), this.t('pai.detail.vmset.tooltip'), {
        confirmButtonText: this.t('pai.detail.vmset.confirm'),
        cancelButtonText:  this.t('pai.detail.vmset.cancel'),
        type:              'warning'
      }).then(async() => {
        const removePod = this.podList?.find((i) => i.metadata.annotations && i.metadata.annotations['com.tdology/alias'] === value.alias);

        this.cloudDesktopListInstalled.forEach((d) => {
          if (removePod?.metadata.annotations['com.tdology/alias'] === d.alias) {
            d.markRemove = true;
          }
        });
        await removePod.remove();
        this.$message({
          type:    'success',
          message: this.t('pai.detail.vmset.deleteSuccess')
        });
        this.deleteAppVisible = false;
      }).catch((error) => {
        this.deleteAppVisible = false;
        console.log(error);
      });
    },
    async linkToDesk(xapp) {
      const pod = this.podList.find((pod) => pod.namespace === xapp.namespace && pod.name === xapp.name);

      if (!pod) {
        this.$message({
          type:    'error',
          message: this.t('pai.clouddesktop.noPod')
        });

        return;
      }
      this.loadingRustDesk = true;
      try {
        await pod.openXApp(this.currentCluster, () => {
          this.loadingRustDesk = false;
        });
      } catch (e) {
        console.error(e);
        this.loadingRustDesk = false;
      }
    }
  },
};
</script>
<template>
  <div>
    <Loading mode="main" v-if="loadingRustDesk" />
    <div class="title">
      <div class="line" />
      {{ t('pai.apps.container.cloudDesktop') }}（{{ getLength(cloudDesktopListInstalled.filter(v=>(!filters.length || filterNamespaces?.includes(v.namespace)) && (v.alias?.includes(filterValue)))) }}）
      <div class="line" />
    </div>
    <div
      v-if="cloudDesktopListInstalled.length !== 0"
      style="float: right; margin-top: -15px; cursor: pointer;"
      @click="showDelete"
    >
      <img src="../../../assets/images/cloudDesktop/edit.svg">{{ t('pai.detail.vmset.edit') }}
    </div>
    <div
      ref="cardContainer"
      v-loading="$fetchState.pending"
      class="listContainer"
    >
      <div
        v-for="i in getArr(cloudDesktopListInstalled).filter(v=>(!filters.length || filterNamespaces?.includes(v.namespace)) && (v.alias?.includes(filterValue))).slice(0,currentDesktopRow*cardCount)"
        :key="i.id"
      >
        <div class="card">
          <div
            v-if="i.markRemove === false && i.phase==='Running'? '': loading"
            class="el-loading-mask"
            style="background-color: rgba(0, 0, 0, 0.5);z-index:2"
          >
            <div class="el-loading-spinner">
              <i
                class="el-icon-loading"
                :style="{color: i.markRemove === true? '#fff': '#BEE380'}"
              />
              <p
                class="el-loading-text"
                :style="{color: i.markRemove === true? '#fff': '#BEE380'}"
              >
                {{ i.markRemove === true? t('apps.installed.status.deleting'): t('apps.installed.status.creating') }}
              </p>
            </div>
          </div>
          <div>
            <img
              v-if="deleteAppVisible && i.markRemove === false && ( i.markRemove === false && i.phase==='Running' )"
              src="../../../assets/images/cloudDesktop/editDelete.svg"
              class="editDelete"
              @click="handleDeleteApp(i)"
            >
            <div
              :class="[i.markRemove === false && i.phase==='Running' ? 'contentItem' : 'contentItemDisable']"
              @click="linkToDesk(i)"
            >
              <div
                class="appIcon"
                v-html="i.icon"
              >
                {{ i.icon }}
              </div>
              <el-tooltip
                :content="`${i.namespace} / ${i.alias} / ${i.name}`"
                effect="light"
              >
                <span>{{ i.alias }}</span>
              </el-tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="more">
      <el-button
        v-if="cloudDesktopListInstalled.length !== 0"
        @click="goList"
      >
        {{ t('pai.labels.viewMore') }}>>
      </el-button>
      <el-empty
        v-show="getLength(getArr(cloudDesktopListInstalled).filter(v=>(!filters.length || filterNamespaces?.includes(v.namespace)) && (v.alias?.includes(filterValue)))) === 0"
        :image-size="60"
      />
    </div>
  </div>
</template>
<style lang="scss" scoped>
.title {
  height: 25px;
  line-height: 25px;
  text-align: center;
  color: #0C5489;
  font-size: 18px;
  display: flex;
  justify-content: space-between;
  white-space: nowrap;
  margin: 10px 0;
  .line {
    height: 50%;
    width: 45%;
    border-bottom: 1px dashed #cccccc;
  }
}
.listContainer{
  display:grid;
  justify-content: space-between;
  grid-template-columns: repeat(auto-fill, 255px);
  grid-gap: 5px;
  position: relative;
  margin-top: 20px;
}
.card {
  box-shadow:
      0 1px 1px rgb(0 0 0 / 0.25),
      0 2px 2px rgb(0 0 0 / 0.20),
      0 4px 4px rgb(0 0 0 / 0.15),
      0 8px 8px rgb(0 0 0 / 0.10),
      0 16px 16px rgb(0 0 0 / 0.05);
  max-width: 260px;
  width: 255px;
  margin-right: 10px;
  height: 109px;
  line-height: 20px;
  border-radius: 3px;
  background-color: rgba(239, 239, 239, 1);
  color: rgba(16, 16, 16, 1);
  font-size: 14px;
  text-align: center;
  border: 1px solid rgba(206, 206, 206, 1);
  border-bottom: none;
  padding: 0 10px;
  margin-bottom: 10px;
  position: relative;
  cursor: pointer;
  .contentItem{
    display: flex;
    margin-top: 21px;
    span{
      margin-top: 23px;
      margin-left: 20px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
    .appIcon{
      margin-left: 7px;
    }
  }
  .contentItemDisable{
    display: flex;
    margin-top: 21px;
    cursor: not-allowed;
    pointer-events: none;
    .appIcon{
      margin-left: 7px;
    }
    span{
      width: 100px;
      margin-top: 23px;
      margin-left: 26px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
    }
  }
  .editDelete{
    position: absolute;
    right: -10px;
    top: -10px;
  }
  .el-loading-text {
    margin: -22px 0 0 40px;
    font-size: 16px;
  }
  .el-icon-loading{
    font-size: 20px;
    margin-left: -40px;
    margin-top: 12px;
  }
}
.more {
  text-align: center;
  .el-button {
    background: #0C5489;
    color: #fff;
  }
}
</style>
