import { MANAGEMENT, NAMESPACE } from '@/shell/config/types';
import { filterHiddenLocalCluster, filterOnlyKubernetesClusters } from '@shell/utils/cluster';
import { PRODUCT_NAME } from '~/pkg/pai/config/monitor';
import { PAI_NODE } from '~/pkg/pai/config/types';

export default {
  data() {
    return {
      kubeClusters:        [],
      activeCluster:       [],
      alarmStatisticsData: [],
      alarmRulesData:      [],
      nodeData:            0,
      activeNode:          0,
      nodesEmergentNum:    0,
      nodesAlarmNum:       0,
      nodesTipsNum:        0,
      nodesErrorArr:       [],
      notReady:            'node.kubernetes.io/not-ready',
      unreachable:         'node.kubernetes.io/unreachable',
      unschedulable:       'node.kubernetes.io/unschedulable',
      resErrorList:        [],
      resEmergentNum:      0,
      resWarningNum:       0,
      resTipsNum:          0
    };
  },
  computed: {
    clusters() {
      return this.$store.getters['management/all'](MANAGEMENT.CLUSTER);
    },
    kubeClustersCustom() {
      return [{
        id: 'allCluster', nameDisplay: this.t('pai.detail.vmset.allCluster'), metadata: { state: { name: 'active' } }
      }];
    }
  },

  methods: {
    // 集群资源数据
    clusterData() {
      const kubeClusters = filterHiddenLocalCluster(filterOnlyKubernetesClusters(this.clusters || []), this.$store);
      const activeCluster = [];

      kubeClusters?.forEach((item) => {
        if (item?.state === 'active') {
          activeCluster.push(item);
        }
      });
      this.kubeClusters = kubeClusters.reverse();
      this.activeCluster = activeCluster.reverse();
      // this.kubeClusters.forEach((item) => {
      //   this.kubeClustersCustom.push((item));
      // });
    },
    async getAlarmNodes(cluster) {
      const clusterData = cluster;

      this.nodesErrorArr = [];
      this.nodesEmergentNum = 0;
      this.nodesTipsNum = 0;
      this.nodeData = 0;
      let nodesAll = 0;
      const emergentData = [];
      const tipsData = [];

      for (const row of clusterData) {
        const nodeUrl = { url: `/k8s/clusters/${ row.id }/v1/nodes` };
        const nodeResult = await this.$store.dispatch('pai-common/getUse', nodeUrl);
        const nodes = nodeResult.data.data;

        nodes?.forEach((item) => {
          if (item?.spec?.taints) {
            if ( item?.spec?.taints.find((k) => k.key === this.notReady || k.key === this.unreachable)) { // 紧急
              emergentData.push(
                {
                  ...item,
                  alarmLevel:  'emergent',
                  clusterName: row?.nameDisplay,
                  alarmType:   item?.spec?.taints.find((k) => k.key === this.notReady || k.key === this.unreachable)?.key,
                  message:     `${ item?.spec?.taints.find((k) => k.key === this.notReady || k.key === this.unreachable)?.key }=${ item?.spec?.taints.find((k) => k.key === this.notReady || k.key === this.unreachable)?.effect }`
                }
              );
            } else if (item?.spec?.taints.find((k) => k.key === this.unschedulable)) { // 提示
              tipsData.push({
                ...item,
                alarmLevel:  'prompt',
                clusterName: row?.nameDisplay,
                alarmType:   item?.spec?.taints.find((k) => k.key === this.unschedulable)?.key,
                message:     `${ item?.spec?.taints.find((k) => k.key === this.unschedulable)?.key }=${ item?.spec?.taints.find((k) => k.key === this.unschedulable)?.effect }`
              });
            }
          }
        });
        nodesAll += nodes?.length;
      }
      this.nodeData = nodesAll;
      this.nodesEmergentNum = emergentData?.length;
      this.nodesTipsNum = tipsData.length;
      this.nodesErrorArr = emergentData.concat(tipsData);
    },
    getRulesData() { // 告警节点
      this.alarmRulesData = [];
      const newNodesErrorArr = this.nodesErrorArr.flat();

      newNodesErrorArr.forEach((item) => {
        if (item?.spec?.taints) {
          if (item?.spec?.taints.find((k) => k.key === this.notReady || k.key === this.unreachable)) {
            this.alarmRulesData.push({
              name:   item?.metadata?.name,
              value:  1,
              status: this.t('pai.detail.vmset.tab.monitor.emergent'),
            });
          } else if (item?.spec?.taints.find((k) => k.key === this.unschedulable)) {
            this.alarmRulesData.push({
              name:   item?.metadata?.name,
              value:  1,
              status: this.t('pai.detail.vmset.tab.monitor.prompt')
            });
          }
        }
      });
    },
    getStatisticsData() { // 告警统计
      this.alarmStatisticsData = [
        { value: this.nodesEmergentNum + this.resEmergentNum !== 0 ? this.nodesEmergentNum + this.resEmergentNum : null, name: this.t('pai.detail.vmset.tab.monitor.emergent') },
        { value: this.nodesAlarmNum + this.resWarningNum !== 0 ? this.nodesAlarmNum + this.resWarningNum : null, name: this.t('pai.detail.vmset.tab.monitor.warning') },
        { value: this.nodesTipsNum + this.resTipsNum !== 0 ? this.nodesTipsNum + this.resTipsNum : null, name: this.t('pai.detail.vmset.tab.monitor.prompt') },
      ];
    },

    // 资源告警
    async getResMonitor(cluster, type) {
      const clusterData = cluster || this.activeCluster;

      this.resEmergentNum = 0;
      this.resWarningNum = 0;
      this.resTipsNum = 0;
      const emergentData = [];
      const waringData = [];
      const tipsData = [];
      const rules = [];

      this.$store.dispatch('growl/clear');
      try {
        if (this.$store.getters['management/byId'](NAMESPACE, 'cattle-monitoring-system')) {
          for (const cluster of clusterData) {
            const param = { url: `/k8s/clusters/${ cluster.id }/api/v1/namespaces/cattle-monitoring-system/services/http:rancher-monitoring-prometheus:9090/proxy/api/v1/rules?type=alert` };
            const resourceMonitor = await this.$store.dispatch('pai-common/getUse', param);

            resourceMonitor?.data?.data?.groups?.forEach((group) => {
              group?.rules.map((rule) => {
                if (rule.state === 'firing') {
                  rule.alerts.forEach((a) => {
                    rules.push({
                      ...a,
                      clusterName: cluster.nameDisplay,
                      name:        a.labels?.alertname,
                      alarmLevel:  a.labels?.severity === 'critical' ? 'emergent' : a.labels?.severity === 'warning' ? 'warning' : 'prompt',
                      message:     a.annotations?.message ? a.annotations?.message : a.annotations?.description
                    });
                  });
                }
              });
            });
          }
        }
      } catch (e) {
        console.log(e);
      }
      const rulesSort = rules?.sort((a, b) => a.alarmLevel > b.alarmLevel ? 1 : -1);

      rulesSort.forEach((item, index) => {
        if (item.labels?.severity === 'critical') {
          emergentData.push(item);
        } else if (item.labels?.severity === 'warning') {
          waringData.push(item);
        } else {
          tipsData.push(item);
        }
      });
      if ((waringData.length !== 0 || emergentData.length !== 0) && this.$route.fullPath === '/pai/home') {
        this.$notify({
          title:                    this.t('generic.notification.title.warning'),
          dangerouslyUseHTMLString: true,
          message:                  `${ this.t('pai.detail.vmset.tab.monitor.all') }： <span style="color: #E6A23C; font-size: 18px;font-weight: bold;margin-right: 5px">${ waringData.length + emergentData.length }</span> 
                                    ${ this.t('nav.usermanage.strip') + this.t('pai.detail.vmset.tab.monitor.alarm') } <br/>
                                     <a style="display: block; cursor: pointer;margin-top: 10px" id="installSave">${ this.t('pai.detail.vmset.tab.monitor.details') }</a >`,
          type:     'warning',
          duration: 5000
        });
        this.$nextTick(() => {
          const link = document.getElementById('installSave');

          if (link) {
            link.addEventListener('click', async() => {
              this.$router.push({
                name:   `${ PRODUCT_NAME }-c-cluster-monitor-management`,
                params: {
                  product: PRODUCT_NAME,
                  cluster: 'local',
                }
              });
            });
          }
        });
      }
      this.resErrorList = rules;
      this.resEmergentNum = emergentData?.length;
      this.resWarningNum = waringData?.length;
      this.resTipsNum = tipsData.length;
    },
  },
};
