<script>
import { mapGetters } from 'vuex';
import { PRODUCT_NAME } from '../../../config/pai';
import { MANAGEMENT } from '@shell/config/types';
import { COMMAND_HASH_MPA } from '../../../config/settings';
import { SHOW_PRE_RELEASE } from '@shell/store/prefs';
import { compatibleVersionsFor } from '@shell/store/catalog';
import { CHART, REPO, REPO_TYPE, VERSION } from '@shell/config/query-params';
import { compare } from '@shell/utils/sort';
import ContainerQuickForm from '../../../components/ContainerQuickForm';

export default {
  components: { ContainerQuickForm },
  async fetch() {
    await this.$store.dispatch('catalog/load', { force: true, reset: true });
  },
  data() {
    return {
      loadImgSrc: `this.src = "${ require('../../../assets/images/home/empty.svg') }"`,
      containers: [],
      PRODUCT_NAME,
      COMMAND_HASH_MPA,
    };
  },
  computed: {
    ...mapGetters({ t: 'i18n/t' }),
    ...mapGetters(['currentCluster']),
    ...mapGetters({ allCharts: 'catalog/charts' }),
    filterNamespaces() {
      return this.$parent.getFilterNamespaces();
    },
    containersWithIcon() {
      return this.allCharts.filter(container => !!container.icon).sort((a, b) => {
        return compare(b.starTimeStamp, a.starTimeStamp);
      });
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    containerQuickFormVisible() {
      return this.$store.state['pai-common'].currentModal === 'ContainerQuickForm';
    },
  },
  methods: {
    compare,
    onClickApp(app) {
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-app`,
        params: {
          cluster: this.currentCluster,
          product: PRODUCT_NAME,
        },
        query: { image: app.id },
      });
    },
    onClickChart(chart) {
      let version;
      const OSs = this.currentCluster.workerOSs;
      const showPrerelease = this.$store.getters['prefs/get'](SHOW_PRE_RELEASE);
      const compatibleVersions = compatibleVersionsFor(chart, OSs, showPrerelease);
      const versions = chart.versions;

      if (compatibleVersions.length > 0) {
        version = compatibleVersions[0].version;
      } else {
        version = versions[0].version;
      }
      this.$router.push({
        name:   `${ PRODUCT_NAME }-c-cluster-apps-charts-chart`,
        params: {
          cluster: this.currentCluster,
          product: PRODUCT_NAME,
        },
        query: {
          [REPO_TYPE]: chart.repoType,
          [REPO]:      chart.repoName,
          [CHART]:     chart.chartName,
          [VERSION]:   version,
        },
      });
    },
    showContainerQuickForm() {
      this.$store.dispatch('pai-common/updateState', { currentModal: 'ContainerQuickForm' });
    },
    handelCloseCreate() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
  },
};
</script>
<template>
  <div>
    <div class="title">
      {{ t('pai.apps.container.store') }}
    </div>
    <div
      v-loading="$fetchState.pending"
      class="list"
    >
      <div
        v-for="(item) in containersWithIcon.slice(0, 3)"
        :key="item.id"
        class="item"
        @click="onClickChart(item)"
      >
        <div class="box">
          <img
            :src="item.icon"
            alt=""
            :onerror="loadImgSrc"
          >
        </div>
        <a class="name">
          {{ item.chartNameDisplay }}
        </a>
      </div>
      <!--      容器快捷安装-->
      <!--      <div-->
      <!--        class="item"-->
      <!--        @click="showContainerQuickForm"-->
      <!--      >-->
      <!--        <div class="box">-->
      <!--          <img-->
      <!--            style="width: 48px"-->
      <!--            src="../../../assets/images/home/install.svg"-->
      <!--            alt=""-->
      <!--          >-->
      <!--        </div>-->
      <!--        <div class="name">-->
      <!--          {{ t('pai.apps.container.quickContainerCreation') }}-->
      <!--        </div>-->
      <!--      </div>-->
      <div class="item">
        <div
          v-if="currentCluster"
          class="box"
          @click="$router.push({name:'pai-c-cluster-apps-charts',params:{product:PRODUCT_NAME,cluster:currentCluster},hash:COMMAND_HASH_MPA.container})"
        >
          {{ t('pai.labels.moer') }}>>
        </div>
      </div>
    </div>
    <container-quick-form
      v-if="containerQuickFormVisible"
      :on-close="handelCloseCreate"
    />
  </div>
</template>
<style lang="scss" scoped>
.title {
    color: #0C5489;
    font-size: 18px;
    margin-bottom: 10px;
}

.list {
    display: flex;
    flex-wrap: wrap;

    .item {
        margin-right: 10px;
        margin-bottom: 10px;

        .box {
            width: 80px;
            height: 80px;
            display: flex;
            justify-content: center;
            align-items: center;
            background: #EFEFEF;
            border: 1px solid #CECECE;
            cursor: pointer;

            img {
                width: 50px;
            }
        }

        .name {
            display: block;
            text-align: center;
            margin-top: 10px;
            font-size: 12px;
            max-width: 80px;
        }
    }
}
</style>
