import Service from '@shell/models/service';
import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';

export default class extends PaiLocationMixin(Service) {
  get _availableActions() {
    const out = super._availableActions;

    const clone = out.find((action) => action.action === 'goToClone');

    if (clone) {
      clone.enabled = !!this.spec?.template?.spec;
    }

    return out;
  }
}
