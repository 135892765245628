<script>
import { BOTTOM } from '@shell/utils/position';
import { mapState } from 'vuex';

export default {
  props: {
    visible: {
      type:     Boolean,
      required: true
    },
  },
  data() {
    return {
      hover:  false,
      toggle: window.localStorage.getItem('menu-toggle') === 'true',
    };
  },

  computed: {
    ...mapState('wm', ['tabs', 'userPin']),
    wmShowingAtBottom() {
      return this.tabs.length > 0 && this.userPin === BOTTOM;
    },
    show() {
      return this.visible || this.hover || this.toggle;
    },
  },

  methods: {
    handleHover(hover) {
      this.hover = hover;
    },
    handleClick() {
      this.$emit('toggle');
      this.$nextTick(() => {
        this.toggle = window.localStorage.getItem('menu-toggle') === 'true';
      });
    }
  },
};
</script>

<template>
  <div
    v-if="show"
    class="toggle-minimized-button"
    :class="{['is-minimized']: toggle}"
    :style="{top: wmShowingAtBottom?'30%':'50%'}"
    @mouseover="handleHover(true)"
    @mouseleave="handleHover(false)"
    @click="handleClick"
  >
    <div class="position" /><svg
      xmlns="http://www.w3.org/2000/svg"
      class="svg-icon icon panel-icon"
      viewBox="0 0 16 86"
      aria-hidden="true"
      width="16"
      height="86"
    ><g
      fill="none"
      fill-rule="evenodd"
    ><path
      class="path-wapper"
      d="M0 0l14.12 8.825A4 4 0 0116 12.217v61.566a4 4 0 01-1.88 3.392L0 86V0z"
      fill="#F4F5FA"
    /><path
      class="path-arrow"
      d="M10.758 48.766a.778.778 0 000-1.127L6.996 43l3.762-4.639a.778.778 0 000-1.127.85.85 0 00-1.172 0l-4.344 5.202a.78.78 0 000 1.128l4.344 5.202a.85.85 0 001.172 0z"
      fill="#222"
      fill-rule="nonzero"
    /></g></svg>
  </div>
</template>

<style lang="scss" scoped>
.toggle-minimized-button {
  position: absolute;
  display: flex;
  -webkit-box-align: center;
  align-items: center;
  z-index: 4;
  cursor: pointer;
  top: 50%;
  margin-top: 0px;
  width: 40px;
  height: 0px;
  opacity: 100;
  transition: opacity 0.2s ease-in-out 0s;
  background: red;
  margin-left: -11px;

   .panel-icon {
    font-size: 16px;
  }
}
.is-minimized {
  opacity: 1;
  pointer-events: auto;
  .svg-icon{
    .path-arrow {
      transform-origin: center center;
      transform: rotate(180deg);
    }
  }
}
.position {
  width: 10px;
  height: 86px;
  z-index: 4;
}
</style>
