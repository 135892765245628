import PaiLocationMixin from '~/pkg/pai/mixins/paiLocation';
import SteveModel from '@shell/plugins/steve/steve-class';
import { MANAGEMENT, NAMESPACE, SCHEMA } from '@shell/config/types';
import { clone } from '@shell/utils/object';
import { createYaml } from '@shell/utils/create-yaml';
import { PAI_RESOURCES } from '~/pkg/pai/config/types';
import { PVC_LABELS } from '~/pkg/pai/config/labels-annotations';

export default class extends PaiLocationMixin(SteveModel) {
  get _availableActions() {
    const out = super._availableActions;

    out.unshift({
      action:  'restore',
      enabled: this.status?.phase === 'Completed',
      icon:    'icon icon-copy',
      label:   this.t('pai.detail.vmset.restore'),
    });

    return out;
  }

  restore(resource = this) {
    this.$dispatch('promptModal', {
      component:      'RestoreDialog',
      componentProps: { value: resource },
    });
  }

  get name() {
    return this.metadata.labels?.displayName || this.metadata?.name;
  }

  get projectId() {
    const inStore = this.$rootGetters['currentProduct'].inStore;
    const all = this.$rootGetters[`${ inStore }/all`](NAMESPACE);

    if (this.metadata?.namespace && this.metadata?.namespace.length > 0) {
      const namespace = all.find((namespace) => namespace.id === this.metadata?.namespace);

      if (namespace) {
        return namespace.projectId;
      }
    }

    return null;
  }

  get projectDisplayName() {
    const clusterId = this.$rootGetters['currentCluster']?.id;

    if (this.projectId) {
      const project = this.$rootGetters['management/byId'](MANAGEMENT.PROJECT, `${ clusterId }/${ this.projectId }`);

      return project?.spec?.displayName;
    }

    return null;
  }
}
