import { addParam } from '@shell/utils/url';
import Socket, { EVENT_MESSAGE } from '@shell/utils/socket';
import { base64Decode } from '@shell/utils/crypto';

export const GUEST_COMMANDS = {
  shutdown: ['qga', 'execute', 'guest-shutdown'],
  ping:     ['qga', 'execute', 'guest-ping'],
  info:     ['qga', 'execute', 'guest-info'],
  rustDesk: {
    getId:       ['qga', 'exec', 'rustdesk', '--get-id'],
    setPassword: ['qga', 'exec', 'rustdesk', '--password'],
  },
};

const CONTAINER_NOT_FOUND = 'container_not_found';

export function sendCommandToContainer(cluster, namespace, podName, containerName, commands, maxTries = 3) {
  return new Promise((resolve, reject) => {
    let url = `/k8s/clusters/${ cluster }/api/v1/namespaces/${ namespace }/pods/${ podName }/exec?container=${ containerName }&stderr=true&stdout=true`;

    url = addParam(url, 'command', commands);
    const socket = new Socket(url, false, 0, 'base64.channel.k8s.io', maxTries);

    socket.addEventListener(EVENT_MESSAGE, (e) => {
      const result = parseGuestResult(e.detail.data);

      if (result.success) {
        resolve(result);
      } else {
        reject(result);
      }
    });
    socket.connect();
  });
}

export function sendCommandToContainerWithResult(cluster, namespace, podName, containerName, commands, maxTries = 3) {
  return new Promise((resolve, reject) => {
    let url = `/k8s/clusters/${ cluster }/api/v1/namespaces/${ namespace }/pods/${ podName }/exec?container=${ containerName }&stderr=true&stdout=true`;

    url = addParam(url, 'command', commands);
    const socket = new Socket(url, false, 0, 'base64.channel.k8s.io', maxTries);

    socket.addEventListener(EVENT_MESSAGE, (e) => {
      const result = parseGuestResult(e.detail.data);

      if (result.success && result.data) {
        resolve(result);
      }
      if (!result.success) {
        reject(result);
      }
    });
    socket.connect();
  });
}

export function sendCommandToContainerWithCallback(cluster, namespace, podName, containerName, commands, callback, maxTries = 3) {
  let url = `/k8s/clusters/${ cluster }/api/v1/namespaces/${ namespace }/pods/${ podName }/exec?container=${ containerName }&stderr=true&stdout=true`;

  url = addParam(url, 'command', commands);
  const socket = new Socket(url, false, 0, 'base64.channel.k8s.io', maxTries);

  socket.addEventListener(EVENT_MESSAGE, (e) => {
    if (callback) {
      callback(parseGuestResult(e.detail.data));
    }
  });
  socket.connect();
}

export function shutdownPod(cluster, pod) {
  return sendCommandsToPodFirstContainer(cluster, pod, GUEST_COMMANDS.shutdown);
}

export function sendCommandsToPodFirstContainer(cluster, pod, commands) {
  const {
    containers = [],
    namespace,
    name,
  } = pod;

  if (containers.length > 0) {
    return sendCommandToContainer(cluster, namespace, name, containers[0].name, commands);
  }

  return new Promise((resolve, reject) => {
    reject(CONTAINER_NOT_FOUND);
  });
}

export function sendCommandsToPodFirstContainerWithResult(cluster, pod, commands) {
  const {
    containers = [],
    namespace,
    name,
  } = pod;

  if (containers.length > 0) {
    return sendCommandToContainerWithResult(cluster, namespace, name, containers[0].name, commands);
  }

  return new Promise((resolve, reject) => {
    reject(CONTAINER_NOT_FOUND);
  });
}

export function sendCommandsToPodFirstContainerWithCallback(cluster, pod, commands, callback) {
  const {
    containers = [],
    namespace,
    name,
  } = pod;

  if (containers.length > 0) {
    sendCommandToContainerWithCallback(cluster, namespace, name, containers[0].name, commands, callback);
  }
}

export function parseGuestResult(msg) {
  if (!msg || msg.length < 1) {
    return {
      success: false,
      data:    null,
    };
  }
  const type = msg.substr(0, 1);
  const msgData = base64Decode(msg.substr(1));
  // console.log(type)
  // console.log(msgData)
  let data = null;

  if (msgData) {
    try {
      data = JSON.parse(msgData);
    } catch (e) {
      data = msgData;
    }
  }

  return {
    success: `${ type }` === '1',
    data,
  };
}
