<script>
import { CATALOG, MANAGEMENT, NAMESPACE, NORMAN } from '@shell/config/types';
import { mapGetters, mapState } from 'vuex';
import { NAMESPACE_FILTERS } from '@shell/store/prefs';
import ChartMixin from '@shell/mixins/chart';
import DeleteVmModal from '../../components/DeleteVmModal.vue';
import CloneVmModal from '../../components/CloneVmModal.vue';
import VmStore from './components/VmStore.vue';
import Loading from '../../components/Loading.vue';
import ContainerStore from './components/ContainerStore.vue';
import ContainerCloudDesktop from './components/ContainerCloudDesktop.vue';
import VmInstalled from './components/VmInstalled.vue';
import ContainerInstalled from './components/ContainerInstalled.vue';
import ContainerCloudDesktopInstalled from './components/ContainerCloudDesktopInstalled.vue';
import { sortBy } from '@shell/utils/sort';
import NoCluster from '~/pkg/pai/pages/home/components/NoCluster.vue';
import { PAI_CATALOG, PAI_PLATFORM_SETTING_TYPES, PAI_RESOURCES } from '../../config/types';
import monitorMixins from '~/pkg/pai/mixins/monitor';
import LicenseDialog from './components/LicenseDialog';
export default {
  layout:     'pai/home',
  components: {
    NoCluster,
    ContainerInstalled,
    VmInstalled,
    ContainerStore,
    ContainerCloudDesktop,
    Loading,
    VmStore,
    DeleteVmModal,
    CloneVmModal,
    ContainerCloudDesktopInstalled,
    LicenseDialog
  },
  mixins: [ChartMixin, monitorMixins],
  async fetch() {
    try { // 判断license授权是否过期
      const licenseData = await this.$store.dispatch('pai-common/getLicense');

      if (licenseData?.status?.valid === false) {
        await this.getUserRole();
      } else { // 已经被授权
        await this.goFresh();
      }
    } catch (e) {
      if (e.response.status !== 200) {
        this.licenseDialogVisible = true;
        this.licenseErr = true;
      }
    }
  },
  data() {
    return {
      filterValue:          this.$route.query?.q ? this.$route.query?.q : '',
      isAdmin:              false,
      licenseDialogVisible: false,
      licenseErr:           false
    };
  },
  computed: {
    ...mapState(['clusterReady']),
    ...mapGetters([{ t: 'i18n/t' }, 'isRancher']),
    enableCloudDesk() {
      return process.env.VUE_APP_CLOUD_DESK_ENABLE === 'true';
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    filter() {
      // 用户选择的项目/命名空间
      if (this.currentCluster && this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster]) {
        if (this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster].join(',').includes('all://')) {
          return [];
        } else {
          return this.$store.getters['prefs/get'](NAMESPACE_FILTERS)[this.currentCluster];
        }
      } else {
        return [];
      }
    },
    deleteVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'deleteVmModal';
    },
    cloneVmModalVisible() {
      return this.$store.state['pai-common'].currentModal === 'cloneVmModal';
    },
    vmSchema() {
      return this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VMSET);
    },
    appSchema() {
      return this.$store.getters['cluster/schemaFor'](PAI_CATALOG.HELM_CHART);
    },
    installedCloudDeskApp() {
      if (!this.enableCloudDesk) {
        return false;
      }
      if (this.isRancher) {
        return !!this.$store.getters['cluster/all'](CATALOG.APP).find((i) => i.name === 'clouddesk');
      } else {
        return !!this.$store.getters['cluster/all'](PAI_CATALOG.HELM_CHART).find((i) => i.name === 'clouddesk');
      }
    },
  },
  methods: {
    async goFresh() {
      await this.$store.dispatch('cleanNamespaces');
      await this.$store.dispatch('management/findAll', { type: NAMESPACE, opt: { force: true } });
      if (this.enableCloudDesk) {
        const isRancher = await this.$store.getters['isRancher'];

        if (isRancher) {
          await this.$store.dispatch('cluster/findAll', { type: CATALOG.APP });
        } else {
          await this.$store.dispatch('cluster/findAll', { type: PAI_CATALOG.HELM_CHART });
        }
      }
      await this.clusterData();
      await this.getResMonitor();
    },
    async getUserRole() {
      this.licenseDialogVisible = true;
      if (this.isRancher) {
        const globalRoleBindings = await this.$store.dispatch('management/findAll', { type: MANAGEMENT.GLOBAL_ROLE_BINDING });
        const roles = await this.$store.dispatch('management/findAll', { type: MANAGEMENT.GLOBAL_ROLE });
        const userData = await this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
        const userId = userData?.id?.split('//')[1];

        globalRoleBindings
          .filter((binding) => binding.userName === userId)
          .forEach((binding) => {
            const globalRole = roles.find((r) => r.id === binding.globalRoleName);

            if (globalRole.id === 'admin') {
              this.isAdmin = true;
            }
          });
      } else {
        const user = this.$store.getters['auth/v3User'];

        if (user.groups === 'system:masters') {
          this.isAdmin = true;
        }
      }
    },
    getFilterNamespaces() {
      // 全部项目下的命名空间
      const projectsById = {};
      const namespaces = sortBy(
        this.$store.getters[`cluster/all`](NAMESPACE),
        ['nameDisplay'],
      );
      const projects = this.$store.getters['management/all'](
        MANAGEMENT.PROJECT,
      );

      const namespacesByProject = {};

      namespacesByProject[null] = []; // For namespaces not in a project
      for (const project of projects) {
        projectsById[project.metadata.name] = project;
      }
      for (const namespace of namespaces) {
        let projectId = namespace.projectId;

        if (!projectId || !projectsById[projectId]) {
          // If there's a projectId but that project doesn't exist, treat it like no project
          projectId = null;
        }

        let entry = namespacesByProject[projectId];

        if (!entry) {
          entry = [];
          namespacesByProject[namespace.projectId] = entry;
        }
        entry.push(namespace);
      }
      // 用户选择的命名空间列表
      const filters = [];

      for (const value of this.filter) {
        if (value.includes('ns://')) {
          filters.push(value.split('//')[1]);
        } else if (value.includes('project://')) {
          const namespaces = namespacesByProject[value.split('//')[1]]?.map((v) => v.metadata.name);

          namespaces?.forEach((v) => filters.push(v));
        }
      }

      return filters;
    },
    onSearch(e) {
      const route = {
        name:   this.$route.name,
        params: { ...this.$route.params },
        query:  { ...this.$route.query, q: e }
      };

      if (!e && this.$route.query?.q) {
        route.query = {};
      }

      this.$router.replace(route);
    },
  },
};
</script>

<template>
  <div>
    <div v-if="clusterReady">
      <div class="stores">
        <div
          v-if="vmSchema"
          style="flex: 0.8"
        >
          <vm-store />
        </div>
        <div
          style="flex: 0.8"
        >
          <container-store />
        </div>
        <div
          v-if="installedCloudDeskApp"
          style="flex: 0.8"
        >
          <container-cloud-desktop />
        </div>
      </div>
      <div class="apps">
        <div class="header">
          <div>{{ t('pai.apps.container.install') }}</div>
          <el-input
            v-model="filterValue"
            prefix-icon="el-icon-search"
            :placeholder="t('sortableTable.search')"
            @input="onSearch"
          />
        </div>
        <div
          v-if="vmSchema"
          class="content"
        >
          <vm-installed :cluster="currentCluster" />
        </div>
        <div
          v-if="appSchema"
          class="content"
        >
          <container-installed :cluster="currentCluster" />
        </div>
        <div
          v-if="installedCloudDeskApp"
          class="content"
        >
          <container-cloud-desktop-installed :cluster="currentCluster" />
        </div>
      </div>
    </div>
    <Loading
      v-else-if="currentCluster"
      :top="50"
    />
    <NoCluster v-else />
    <DeleteVmModal v-if="deleteVmModalVisible" />
    <CloneVmModal v-if="cloneVmModalVisible" />
    <LicenseDialog
      :license-dialog-visible="licenseDialogVisible"
      :is-admin="isAdmin"
      :license-err="licenseErr"
      :goFresh="goFresh"
    />
  </div>
</template>
<style lang="scss" scoped>
.explain {
  padding: 35px 50px;
  line-height: 35px;
  background: #0C5489;
  color: #FFFFFF;
  font-size: 18px;
  text-align: left;
  font-family: SourceHanSansSC-regular, serif;
}

.selector {
  margin: 20px;
  width: 20%;
}

.stores{
  display: flex;
  padding: 10px 180px 0 25px;
}

.apps {
  padding: 10px 25px 10px 25px;

  .header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    color: #0C5489;
    font-size: 18px;

    ::v-deep .el-input {
      width: 230px;
    }
  }

  .content {
    margin-bottom: 10px;
  }
}
</style>
