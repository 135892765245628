import Workload from './workload';

export default class DaemonSet extends Workload {
  //https://git.tdology.com/qa/Bug/issues/46 无法对守护进程暂停编排,隐藏此按钮
  get _availableActions() {
    const out = super._availableActions;

    const pause = out.find(action => action.action === 'pause');

    if (pause) {
      pause.enabled = false;
    }

    return out;
  }
  async rollBack(cluster, daemonSet, revision) {
    const body = [
      {
        op:    'replace',
        path:  '/spec/template',
        value: {
          metadata: revision.data.spec.template.metadata,
          spec:     revision.data.spec.template.spec
        }
      }, {
        op:    'replace',
        path:  '/metadata/generation',
        value: revision.revision,
      }
    ];

    await this.rollBackWorkload(cluster, daemonSet, 'daemonsets', body);
  }
}
