import { render, staticRenderFns } from "./DiskManagement.vue?vue&type=template&id=787e73a8&scoped=true"
import script from "./DiskManagement.vue?vue&type=script&lang=js"
export * from "./DiskManagement.vue?vue&type=script&lang=js"
import style0 from "./DiskManagement.vue?vue&type=style&index=0&id=787e73a8&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "787e73a8",
  null
  
)

export default component.exports