import { render, staticRenderFns } from "./VmQuickForm.vue?vue&type=template&id=40d926af&scoped=true"
import script from "./VmQuickForm.vue?vue&type=script&lang=js"
export * from "./VmQuickForm.vue?vue&type=script&lang=js"
import style0 from "./VmQuickForm.vue?vue&type=style&index=0&id=40d926af&prod&lang=scss&scoped=true"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "40d926af",
  null
  
)

export default component.exports