<script>
import { Card } from '@components/Card';
import { MANAGEMENT } from '@shell/config/types';
import AsyncButton from '@shell/components/AsyncButton.vue';
import { SETTING } from '@shell/config/settings';
import { LabeledInput } from '@components/Form/LabeledInput';

export default {
  name:       'ServerURLDialog',
  components: { AsyncButton, LabeledInput, Card },

  props: {
    confirm: {
      type:     Function,
      required: true,
    },
  },
  fetch() {
    if (this.$store.getters['cluster/schemaFor'](MANAGEMENT.SETTING)) {
      this.serverUrlSetting = this.$store.getters['management/byId'](MANAGEMENT.SETTING, SETTING.SERVER_URL);
    }
  },
  data() {
    return {
      serverUrlSetting: {},
      serverUrl:        window.location.protocol + '//' + window.location.host,
    };
  },
  methods: {
    close() {
      this.$emit('close');
    },
    async handleConfirm() {
      try {
        this.serverUrlSetting.value = this.serverUrl;
        await this.serverUrlSetting.save();
        this.confirm();
      } catch (err) {
        this.$message.error(err);
      }
    },
  },
};
</script>

<template>
  <Card
    class="prompt-rotate"
    :show-highlight-border="false"
  >
    <h4
      slot="title"
      class="text-default-text"
      v-html="t('dialog.serverURLDialog.title')"
    />
    <template #body>
      <div
        class="mt-10"
      >
        <span> {{ t('dialog.serverURLDialog.tip') }}</span>
        <div
          :style="{'align-items':'center'}"
          class="row mt-10"
        >
          <LabeledInput
            v-model="serverUrl"
            :label="t('setup.serverUrl.label')"
          />
        </div>
      </div>
    </template>
    <div
      slot="actions"
      class="buttons"
    >
      <button
        class="btn role-secondary mr-10"
        @click="close"
      >
        {{ t('generic.cancel') }}
      </button>

      <button
        class="btn role-primary"
        @click="handleConfirm"
      >
        {{ t('generic.confirm') }}
      </button>
    </div>
  </Card>
</template>
<style lang="scss" scoped>
.prompt-rotate {
  margin: 0;
}
.buttons {
  display: flex;
  justify-content: flex-end;
  width: 100%;
}
.el-input {
  border: none;
  border: 2px solid #5084AA;
  border-radius: 5px;
  width: 400px;
  margin-top: 10px;
}
</style>
