<script>
import { SET_LOGIN_ACTION } from '@shell/config/page-actions';
import { AFTER_LOGIN_ROUTE, mapPref } from '@shell/store/prefs';

export default {

  computed: {
    afterLoginRoute: mapPref(AFTER_LOGIN_ROUTE),

    pageActions() {
      const pageActions = [];
      const product = this.$store.getters['currentProduct'];

      if (!product) {
        return [];
      }

      // Only show for Cluster Explorer or Global Apps (not configuration)
      const canSetAsHome = product.inStore === 'cluster' || (product.inStore === 'management' && product.category !== 'configuration');

      if (canSetAsHome) {
        pageActions.push({
          labelKey: 'nav.header.setLoginPage',
          action:   SET_LOGIN_ACTION,
        });
      }

      return pageActions;
    },
    showPageActions() {
      return this.pageActions?.length;
    },
  },
  methods: {
    showPageActionsMenu(show) {
      if (this.$refs.pageActions) {
        if (show) {
          this.$refs.pageActions.show();
        } else {
          this.$refs.pageActions.hide();
        }
      }
    },
    getLoginRoute() {
      return {
        name:   this.$route.name,
        params: this.$route.params,
        query:  this.$route.query,
      };
    },
    pageAction(action) {
      if (action.action === SET_LOGIN_ACTION) {
        this.afterLoginRoute = this.getLoginRoute();
        this.$message({
          type:    'success',
          message: this.t('pai.messages.setLoginPageSuccess')
        });
      }
    },
  },
};
</script>

<template>
  <div
    v-if="showPageActions"
    id="page-actions"
    class="actions"
  >
    <i
      data-testid="page-actions-menu"
      class="icon icon-actions"
      @blur="showPageActionsMenu(false)"
      @click="showPageActionsMenu(true)"
      @focus.capture="showPageActionsMenu(true)"
    />
    <v-popover
      ref="pageActions"
      placement="bottom-end"
      offset="12"
      trigger="manual"
      :delay="{show: 0, hide: 0}"
      :popper-options="{modifiers: { flip: { enabled: false } } }"
      :container="false"
    >
      <template
        slot="popover"
        class="user-menu"
      >
        <ul
          data-testid="page-actions-dropdown"
          class="list-unstyled dropdown"
          @click.stop="showPageActionsMenu(false)"
        >
          <li
            v-for="a in pageActions"
            :key="a.label"
            class="user-menu-item"
          >
            <a
              v-if="!a.separator"
              @click="pageAction(a)"
            >{{ a.labelKey ? t(a.labelKey) : a.label }}</a>
            <div
              v-else
              class="menu-separator"
            >
              <div class="menu-separator-line" />
            </div>
          </li>
        </ul>
      </template>
    </v-popover>
  </div>
</template>
<style lang="scss" scoped>
.actions {
  color:white;
  cursor:pointer;
  a{
    cursor:pointer;
    text-decoration: none;
  }
}
</style>
