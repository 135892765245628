import { MONITORING } from '@shell/config/types';
import {
  PAI_MONITOR_MANAGEMENT,
  PAI_MONITOR_OVERVIEW,
  PAI_MONITOR,
  PAI_ADVANCED, PAI_MONITORING
} from '~/pkg/pai/config/types';
import { IF_HAVE } from '@shell/store/type-map';

export const PRODUCT_NAME = 'monitor';
export const MONITOR_PRODUCT_TYPES = [
  MONITORING.ALERTMANAGER,
  MONITORING.ALERTMANAGERCONFIG,
  MONITORING.PODMONITOR,
  MONITORING.PROMETHEUS,
  MONITORING.PROMETHEUSRULE,
  MONITORING.SERVICEMONITOR,
  MONITORING.THANOSRULER,
  MONITORING.SPOOFED.RECEIVER,
  MONITORING.SPOOFED.RECEIVER_SPEC,
  MONITORING.SPOOFED.RECEIVER_EMAIL,
  MONITORING.SPOOFED.RECEIVER_SLACK,
  MONITORING.SPOOFED.RECEIVER_WEBHOOK,
  MONITORING.SPOOFED.RECEIVER_PAGERDUTY,
  MONITORING.SPOOFED.RECEIVER_OPSGENIE,
  MONITORING.SPOOFED.RECEIVER_HTTP_CONFIG,
  MONITORING.SPOOFED.RESPONDER,
  MONITORING.SPOOFED.ROUTE,
  MONITORING.SPOOFED.ROUTE_SPEC,
  MONITORING.SPOOFED.ALERTMANAGERCONFIG_RECEIVER_SPEC,
  MONITORING.SPOOFED.ALERTMANAGERCONFIG_ROUTE_SPEC,
];

export function init($plugin, store) {
  const {
    product,
    basicType,
    virtualType,
    weightIconGroup,
    configureType,
    headers
  } = $plugin.DSL(store, PRODUCT_NAME);

  const typeStoreMap = {};

  MONITOR_PRODUCT_TYPES.forEach((item) => {
    typeStoreMap[item] = 'cluster';
  });
  product({
    inStore:               'management',
    removable:             false,
    showNamespaceFilter:   true,
    hideKubeShell:         true,
    hideKubeConfig:        true,
    showClusterSwitcher:   false,
    hideCopyConfig:        true,
    hideNamespaceLocation: true,
    to:                    {
      name:   `${ PRODUCT_NAME }-c-cluster-monitor-overview`,
      params: {
        product: PRODUCT_NAME,
        cluster: 'local',
      },
    },
    typeStoreMap
  });
  basicType([
    PAI_MONITOR_OVERVIEW,
    PAI_MONITOR_MANAGEMENT,
    PAI_MONITOR,
    MONITORING.ALERTMANAGERCONFIG,
    MONITORING.PROMETHEUSRULE,
  ]);
  virtualType({
    group:               'Root',
    name:                PAI_MONITOR_OVERVIEW,
    labelKey:            'pai.menu.monitor.overview',
    showNamespaceFilter: false,
    namespaced:          true,
    weight:              101,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-monitor-overview`,
      params: {
        product: PRODUCT_NAME,
        cluster: 'local',
      },
    },
    icon: 'dot-half',
  });
  virtualType({
    group:               'Root',
    name:                PAI_MONITOR_MANAGEMENT,
    labelKey:            'pai.menu.monitor.alert',
    showNamespaceFilter: false,
    namespaced:          true,
    weight:              100,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-monitor-management`,
      params: {
        product: PRODUCT_NAME,
        cluster: 'local',
      },
    },
    icon: 'alert',
  });
  virtualType({
    ifHave:              IF_HAVE.V2_MONITORING,
    group:               'Root',
    ifHaveType:          MONITORING.ALERTMANAGERCONFIG,
    name:                MONITORING.ALERTMANAGERCONFIG,
    labelKey:            'pai.menu.monitor.alertManagerConfig',
    showNamespaceFilter: true,
    namespaced:          true,
    weight:              97,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        cluster:  'local',
        resource: MONITORING.ALERTMANAGERCONFIG,
      },
    },
    icon: 'gear'
  });
  virtualType({
    ifHave:              IF_HAVE.V2_MONITORING,
    group:               'root',
    name:                MONITORING.PROMETHEUSRULE,
    ifHaveType:          MONITORING.PROMETHEUSRULE,
    labelKey:            'pai.menu.monitor.prometheusRule',
    showNamespaceFilter: true,
    namespaced:          true,
    weight:              96,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        cluster:  'local',
        resource: MONITORING.PROMETHEUSRULE,
      },
    },
    icon: 'cis',
  });

  basicType([
    PAI_MONITORING,
    PAI_MONITOR,
    MONITORING.PROMETHEUS
  ], PAI_ADVANCED);
  configureType(MONITORING.PROMETHEUS, {
    isCreatable: true,
    isEditable:  true,
    canYaml:     true
  });

  weightIconGroup(PAI_ADVANCED, 97, true, 'list-flat');

  virtualType({
    group:               PAI_ADVANCED,
    name:                PAI_MONITORING,
    ifHaveType:          MONITORING.ALERTMANAGER,
    labelKey:            'pai.menu.monitor.monitoring',
    showNamespaceFilter: false,
    namespaced:          true,
    weight:              99,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-monitor-monitoring`,
      params: {
        product: PRODUCT_NAME,
        cluster: 'local',
      },
    },
  });
  virtualType({
    group:               PAI_ADVANCED,
    name:                PAI_MONITOR,
    labelKey:            'pai.menu.monitor.monitors',
    showNamespaceFilter: false,
    namespaced:          true,
    weight:              98,
    ifHaveType:          MONITORING.PODMONITOR,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-monitor-monitor`,
      params: {
        product: PRODUCT_NAME,
        cluster: 'local',
      },
    }
  });
  virtualType({
    group:               PAI_ADVANCED,
    name:                MONITORING.PROMETHEUS,
    ifHaveType:          MONITORING.PROMETHEUS,
    labelKey:            'pai.menu.monitor.prometheus',
    showNamespaceFilter: true,
    namespaced:          true,
    weight:              95,
    route:               {
      name:   `${ PRODUCT_NAME }-c-cluster-resource`,
      params: {
        product:  PRODUCT_NAME,
        cluster:  'local',
        resource: MONITORING.PROMETHEUS,
      },
    }
  });
}
