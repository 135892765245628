<script>
export default {
  props: {
    value: {
      type:    String,
      default: '',
    },
  }
};
</script>
<template>
  <div>
    <div class="icon" />{{ value }}
    <div class="after">
      <slot />
    </div>
  </div>
</template>
<style scoped>
.icon {
  width: 3px;
  height: 15px;
  background: var(--primary);
  margin-right: 10px;
}
.after {
  margin-left: 10px;
  display: inline-block;
}
</style>
