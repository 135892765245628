<script>
import Loading from '@shell/components/Loading';
import ChartMixin from '@shell/mixins/chart';
import LazyImage from '~/pkg/pai/components/LazyImage';
import isEqual from 'lodash/isEqual';
import { mapGetters } from 'vuex';
import { PRODUCT_NAME as PAI } from '../../../../../config/pai';
import { PAI_RESOURCES, PAI_STORAGE } from '~/pkg/pai/config/types';
import windowsIcon from '../../../../../assets/vmImage/windows.png';
import linuxIcon from '../../../../../assets/vmImage/linux.png';
import { PAI_IMAGE_STATES, PAI_IMAGE_STATES_ENUM } from '~/pkg/pai/plugins/pai-resource-class';
import { STORAGE_CLASS } from '@shell/config/types';
import { IMAGE, PVC_LABELS } from '~/pkg/pai/config/labels-annotations';
import { COMMAND_HASH_MPA } from '~/pkg/pai/config/settings';
import VmQuickForm from '../../../../../components/VmQuickForm';
export default {
  layout:     'pai/default',
  components: {
    LazyImage,
    Loading,
    VmQuickForm
  },

  mixins: [
    ChartMixin
  ],

  async fetch() {
    const image = await this.$route.query.image;

    this.imageId = image;
    this.scs = await this.$store.dispatch('cluster/findAll', { type: STORAGE_CLASS });
    if (image) {
      const images = await this.$store.dispatch('cluster/findAll', { type: PAI_RESOURCES.VM_IMAGE });

      const target = images.find(v => v.id === image);

      if (target) {
        this.image = target;
      }
    }
  },

  data() {
    return {
      scs:   [],
      image: {
        spec: {
          arch: '-', agent: '-', size: '-'
        }
      },
      COMMAND_HASH_MPA,
      vmForm:  false,
      imageId: '',
    };
  },

  computed: {
    ...mapGetters(['currentCluster']),
    name() {
      return this.image?.metadata?.name;
    },
    alias() {
      return this.image?.spec?.alias ? this.image.spec.alias : '';
    },
    description() {
      return this.image?.spec?.desc ? this.image.spec.desc : this.t('pai.apps.vm.nodesc');
    },
    namespaces() {
      if (!this.image.spec || !this.image.spec.share_to) {
        return this.t('nav.ns.all');
      } else {
        const namespaces = this.image.spec.share_to;
        const filterNs = new Set(namespaces);

        return filterNs.join('、');
      }
    },
    icon() {
      if (this.image?.spec?.logo) {
        const logoName = this.image?.spec?.logo;

        try {
          const logo = require(`../../../../../assets/images/imageLogo/${ logoName }`);

          if (logo) {
            return logo;
          }
        } catch (e) {}
      }

      return this.image?.spec?.os?.includes('windows') ? windowsIcon : linuxIcon;
    },
    isDownload() {
      return this.image?.spec?.source_type === 'download';
    },
    url() {
      return this.image.spec && this.image.spec.source_parameters && this.image.spec.source_parameters.url ? this.image.spec.source_parameters.url : '';
    },
    volumeName() {
      return this.image.spec && this.image.spec.source_parameters && this.image.spec.source_parameters.volumeName ? this.image.spec.source_parameters.volumeName : '';
    },
    stateLabel() {
      const sc = this.scs.find(v => v.name === `${ this.image.namespace }-${ this.image.name }`);

      if (sc && this.image.status && this.image.status.message === 'Ready') {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Normal].labelKey;
      }
      if (this.isError) {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Abnormal].labelKey;
      } else {
        return PAI_IMAGE_STATES[PAI_IMAGE_STATES_ENUM.Pending].labelKey;
      }
    },
    isError() {
      const sc = this.scs.find(v => v.name === `${ this.image.namespace }-${ this.image.name }`);

      return (!sc && this.image?.status?.message) || (sc && this.image.status?.message && this.image.status?.message !== 'Ready');
    },
    isReady() {
      const sc = this.scs.find(v => v.name === `${ this.image.namespace }-${ this.image.name }`);

      return sc && this.image.status && this.image.status.message === 'Ready';
    },
    tableData() {
      const tableData = [];

      if (this.image.usedVms?.length) {
        this.image.usedVms.forEach((item) => {
          tableData.push({
            namespace: item.metadata.namespace,
            vmName:    item.metadata.name,
            vmAlias:   item.alias,
          });
        });
      }
      if (this.image.usedPvcs?.length) {
        const vms = this.image.usedVms?.map(v => v.name) || [];

        this.image.usedPvcs.forEach((v) => {
          if (v.labels && vms.includes(v.labels[PVC_LABELS.MOUNT_VM])) {
            const index = vms.indexOf(v.labels[PVC_LABELS.MOUNT_VM]);

            tableData[index].pvc = v.id;
          } else {
            tableData.push({ pvc: v.id });
          }
        });
      }

      return tableData;
    },
  },

  watch: {
    '$route.query'(neu, old) {
      if ( !isEqual(neu, old) ) {
        this.$fetch();
      }
    },
  },

  methods: {
    install() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-create`,
        params: {
          cluster:  this.$route.params.cluster,
          product:  this.$store.getters['productId'],
          resource: PAI_RESOURCES.VMSET,
        },
        query: { image: `${ this.image.metadata.namespace }-${ this.image.metadata.name }` }
      });
    },
    handleOpenVM() {
      this.vmForm = true;
    },
    hanleCloseVM() {
      this.vmForm = false;
    },
    toVmDetail(value) {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-namespace-id`,
        params: {
          product:   PAI,
          cluster:   this.$route.params.cluster,
          resource:  PAI_RESOURCES.VMSET,
          namespace: value.namespace,
          id:        value.vmName,
        },
      });
    },
    toPvcDetail(pvc) {
      this.$router.push({
        name:   `${ PAI }-c-cluster-resource-id`,
        params: {
          product:  PAI,
          cluster:  this.$route.params.cluster,
          resource: PAI_STORAGE.PVC,
          id:       pvc,
        },
      });
    }
  },
};
</script>

<template>
  <Loading v-if="$fetchState.pending" />
  <div
    v-else
    style="background: #F5F4F6"
  >
    <div class="chart-header">
      <div class="name-logo-install">
        <div class="name-logo">
          <div class="logo-bg">
            <LazyImage
              :src="icon"
              class="logo"
            />
          </div>
          <div class="header-name">
            <nuxt-link :to="{ name: 'pai-c-cluster-apps-charts', hash: COMMAND_HASH_MPA.vm}">
              {{ t('catalog.chart.header.charts') }}:
            </nuxt-link>
            {{ alias }}{{ name ? '('+name+')' : '' }}
          </div>
        </div>
        <div>
          <button
            type="button"
            class="btn role-primary"
            :disabled="!isReady"
            style="margin-right: 5px"
            @click="handleOpenVM"
          >
            {{ t('pai.apps.vm.quickInstallation') }}
          </button>
          <button
            type="button"
            class="btn role-primary"
            :disabled="!isReady"
            @click.prevent="install"
          >
            {{ t('pai.apps.vm.ordinaryInstallation') }}
          </button>
          <vm-quick-form
            v-if="vmForm"
            :on-close="hanleCloseVM"
            :vm-id="imageId"
            :image="image"
          />
        </div>
      </div>
    </div>

    <div class="spacer" />

    <div class="content">
      <div style="padding: 36px 0 0 58px;">
        <el-descriptions
          :title="t('pai.vmset.info')"
          direction="horizontal"
          :column="1"
        >
          <el-descriptions-item :label="t('pai.apps.vm.desc')">
            {{ description }}
          </el-descriptions-item>
          <el-descriptions-item :label="t('tableHeaders.state')">
            {{ t(stateLabel) }}
          </el-descriptions-item>

          <el-descriptions-item :label="t('pai.apps.vm.auth')">
            {{ namespaces }}
          </el-descriptions-item>
        </el-descriptions>

        <el-descriptions
          :title="t('pai.vmset.moreInfo')"
          direction="horizontal"
          :column="1"
        >
          <el-descriptions-item :label="t('pai.vmset.image.framework')">
            {{ image.spec.arch }}
          </el-descriptions-item>
          <el-descriptions-item :label="t('pai.vmset.agent.label')">
            {{ image.spec.agent === 'guest' ? t('generic.yes') : t('generic.no') }}
          </el-descriptions-item>
          <el-descriptions-item :label="t('pai.vmset.image.size')">
            {{ image.spec.size }}
          </el-descriptions-item>
        </el-descriptions>
        <el-descriptions
          :title="t('pai.apps.vm.vmReference')"
          direction="horizontal"
          :column="1"
          style="padding-bottom: 35px"
        >
          <el-descriptions-item label-style="display: none">
            <el-table
              :data="tableData"
              border
              size="mini"
              :header-cell-style="{background: '#FAFAFA'}"
              :class="{'table': tableData.length>0}"
            >
              <el-table-column
                prop="namespace"
                :label="t('pai.vmset.nameSpace')"
                width="180"
                align="center"
              />
              <el-table-column
                :label="t('pai.vmset.vm')"
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <a
                    v-if="scope.row.vmAlias"
                    style="cursor: pointer"
                    @click="toVmDetail(scope.row)"
                  >{{ scope.row.vmAlias }}</a>
                  <span v-else>-</span>
                </template>
              </el-table-column>
              <el-table-column
                :label="t('persistentVolumeClaim.volumeClaim.label')"
                width="180"
                align="center"
              >
                <template slot-scope="scope">
                  <a
                    v-if="scope.row.pvc"
                    style="cursor: pointer"
                    @click="toPvcDetail(scope.row.pvc)"
                  >{{ scope.row.pvc }}</a>
                  <span v-else>-</span>
                </template>
              </el-table-column>
            </el-table>
          </el-descriptions-item>
        </el-descriptions>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
  $name-height: 50px;
  $padding: 5px;
  ::v-deep .el-descriptions__body .el-descriptions__table{
    width: unset;
  }
  .chart-header {
    background: #fff;
    height: 130px;
    margin: -13px;
    .name-logo-install {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: $name-height;
      padding: 61px 24px 0 20px;
    }

    .name-logo {
      display: flex;
      align-items: center;

      .logo-bg {
        width: 90px;
        height: 90px;
        background: #FAFAFA;
        border-radius: 50px;
        position: relative;
      }

      .logo {
        max-height: $name-height - 2 * $padding;
        max-width: $name-height - 2 * $padding;
        position: absolute;
        width: auto;
        height: auto;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        margin: auto;
      }

      .header-name {
        margin: 0 20px;
      }
    }

    .os-label{
      background-color: var(--warning-banner-bg);
      color: var(--warning);
    }

    .btn {
      height: 30px;
    }

    .description {
      margin-right: 80px;
    }
  }
  .content{
    background: #fff;
    margin: -20px -13px 0 -13px;
  }
  /* table 列数据为空自动显示 - */
  .table :empty::before{
    content:'-';
    color:gray;
  }
  .table {
    span{
      cursor: default;
      -webkit-user-select: none;
      -moz-user-select: none;
      -ms-user-select: none;
      user-select: none;
    }
  }
</style>
