<script>
export default {
  name:         'EditableCell',
  inheritAttrs: false,
  props:        {
    value: {
      type:    [String, Number],
      default: ''
    },
    toolTipContent: {
      type:    String,
      default: ''
    },
    toolTipDelay: {
      type:    Number,
      default: 500
    },
    toolTipPlacement: {
      type:    String,
      default: 'top-start'
    },
    showInput: {
      type:    Boolean,
      default: true
    },
    editableComponent: {
      type:    String,
      default: 'el-input'
    },
    closeEvent: {
      type:    String,
      default: 'blur'
    },
    canEdit: {
      type:    Boolean,
      default: false
    },
    inputDisabled: {
      type:    Boolean,
      default: false
    }
  },
  data() {
    return { editMode: false };
  },
  computed: {
    model: {
      get() {
        return this.value;
      },
      set(val) {
        this.$emit('input', val);
      }
    },
    listeners() {
      return {
        [this.closeEvent]: this.onInputExit,
        ...this.$listeners
      };
    },
    tooltip() {
      if (this.toolTipContent) {
        return this.toolTipContent;
      } else {
        return this.t('pai.vmset.tips.editable');
      }
    },
  },
  methods: {
    onFieldClick() {
      if (this.canEdit) {
        this.editMode = true;
        this.$nextTick(() => {
          const inputRef = this.$refs.input;

          if (inputRef) {
            inputRef.focus();
          }
        });
      }
    },
    onInputExit(e) {
      if (this.closeEvent === 'blur') {
        this.editMode = false;
      } else if (this.closeEvent === 'visible-change' && !e) {
        this.editMode = false;
      }
    },
    onInputChange(val) {
      this.$emit('input', val);
    }
  },
};
</script>
<template>
  <div
    class="edit-cell"
    @click="onFieldClick"
  >
    <el-tooltip
      v-if="!editMode && !showInput && canEdit"
      :placement="toolTipPlacement"
      :open-delay="toolTipDelay"
      :content="tooltip"
    >
      <div
        tabindex="0"
        class="cell-content edit-enabled-cell"
        @keyup.enter="onFieldClick"
      >
        <slot name="content" />
      </div>
    </el-tooltip>
    <div v-else-if="!editMode && !showInput && !canEdit">
      <slot name="content" />
    </div>
    <component
      :is="editableComponent"
      v-if="editMode || showInput"
      ref="input"
      v-bind="$attrs"
      v-model="model"
      :disabled="inputDisabled"
      @focus="onFieldClick"
      @keyup.enter.native="onInputExit"
      v-on="listeners"
    >
      <slot name="edit-component-slot" />
    </component>
  </div>
</template>
<style scoped>
.cell-content {
  min-height: 40px;
  padding-left: 5px;
  padding-top: 5px;
  border: 1px solid transparent;
}
.edit-enabled-cell {
  border: 1px dashed #409eff;
}
</style>
