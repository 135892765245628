<template>
  <div class="clusterResource">
    <div class="main-resource flex">
      <div
        v-for="(item,index) in resourdeData"
        :key="index"
        class="main-resourceCard flex"
        @click="toLink(item.res)"
      >
        <div class="main-resourceCard-left">
          <div class="img-bg">
            <img
              :src="item.path"
              :alt="item.title"
            >
          </div>
        </div>

        <div class="main-resourceCard-right">
          <div class="box-title">
            {{ item.title }}: <span class="box-numBig">&nbsp;{{ item.all }}</span>
          </div>
          <div class="flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ item.all - item.active }}</span> / <span class="box-numBig activeColor">{{ item.active }}</span>
            </div>
            <div class="box-text">
              {{ t('pai.vmset.image.state.abnormal') + '/' + t('pai.detail.vmset.normal') }}
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="cluster-module bgCard cluster-flex">
      <!--云主机组-->
      <div
        v-if="vmSchema"
        class="module-main"
      >
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.vmset') }}:  <span class="box-numBig">{{ cloudData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ cloudData.all - cloudData.active }}</span> / <span class="box-numBig activeColor">{{ cloudData.active }}</span>
            </div>
            <div class="box-text">
              {{ t('pai.detail.vmset.shutdown') + '/' + t('pai.detail.vmset.On') }}
            </div>
          </div>
        </div>
      </div>
      <!--容器组-->
      <div class="module-main">
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.pod') }}: <span class="box-numBig">{{ podData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ podData.all - podData.active }}</span> / <span class="box-numBig activeColor">{{ podData.active }}</span>
            </div>
            <div class="box-text">
              {{ resStatus }}
            </div>
          </div>
        </div>
      </div>
      <!--有状态负载-->
      <div class="module-main">
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.apps.statefulLoad') }}: <span class="box-numBig">{{ statefulsetData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ statefulsetData.all - statefulsetData.active }}</span> / <span class="box-numBig activeColor">{{ statefulsetData.active }}</span>
            </div>
            <div class="box-text">
              {{ resStatus }}
            </div>
          </div>
        </div>
      </div>
      <!--无状态负载-->
      <div class="module-main">
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.apps.statelessLoad') }}: <span class="box-numBig">{{ deploymentData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ deploymentData.all - deploymentData.active }}</span> / <span class="box-numBig activeColor">{{ deploymentData.active }}</span>
            </div>
            <div class="box-text">
              {{ resStatus }}
            </div>
          </div>
        </div>
      </div>
      <!--普通任务-->
      <div class="module-main">
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.batch.job') }}: <span class="box-numBig">{{ jobData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ jobData.all - jobData.active }}</span> / <span class="box-numBig activeColor">{{ jobData.active }}</span>
            </div>
            <div class="box-text">
              {{ resStatus }}
            </div>
          </div>
        </div>
      </div>
      <!--定时任务-->
      <div class="module-main">
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.batch.cronjob') }}: <span class="box-numBig">{{ cronjobData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ cronjobData.all - cronjobData.active }}</span> / <span class="box-numBig activeColor">{{ cronjobData.active }}</span>
            </div>
            <div class="box-text">
              {{ resStatus }}
            </div>
          </div>
        </div>
      </div>
      <!--守护进程集-->
      <div class="module-main">
        <div class="module-main-top">
          <span class="img-bg">
            <img :src="`${imgUrl}`">
          </span>
          <span
            class="box-title"
            style="margin-left: 6px"
          >
            {{ t('pai.menu.apps.daemonset') }}: <span class="box-numBig">{{ daemonsetData.all }}</span>
          </span>
        </div>

        <div class="module-main-bottom">
          <div class="cluster-flex">
            <div class="box-num">
              <span class="box-numBig errColor">{{ daemonsetData.all - daemonsetData.active }}</span> / <span class="box-numBig activeColor">{{ daemonsetData.active }}</span>
            </div>
            <div class="box-text">
              {{ resStatus }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { PAI_POD, PAI_RESOURCES, PAI_WORKLOAD_TYPES } from '../../../../../config/types';
import { PRODUCT_NAME } from '../../../../../config/pai';

export default {
  name:  'ClusterResource',
  props: {
    resourdeData: { type: Array },
    cloudData:    {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    podData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    statefulsetData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    deploymentData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    jobData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    cronjobData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    },
    daemonsetData: {
      type:    Object,
      default: () => {
        return {
          active: 0,
          all:    0
        };
      }
    }
  },
  computed: {
    vmSchema() {
      return this.$store.getters['cluster/schemaFor'](PAI_RESOURCES.VMSET);
    }
  },
  data() {
    return {
      imgUrl:    require('../../overview/images/module-icon.png'),
      resStatus: `${ this.t('pai.vmset.image.state.abnormal') }/${ this.t('pai.detail.vmset.normal') }`
    };
  },
  methods: {
    toLink(res) {
      if (res === 'cluster') {
        this.$router.push({ name: 'pai-overview' });
      } else {
        this.$router.push({
          name:   `${ PRODUCT_NAME }-c-cluster-resource`,
          params: {
            product:  PRODUCT_NAME,
            resource: res,
          }
        });
      }
    }
  }
};
</script>

<style lang="scss" scoped>
.main-resource{
  margin-top: 14px;
  .main-resourceCard{
    width: 25%;
    background-color: #FFFFFF;
    padding: 20px;
    margin-right: 16px;
    cursor: pointer;
    .main-resourceCard-left{
      width: 30%;
      .img-bg{
        width: 63px;
        height: 60px;
        line-height: 84px;
        text-align: center;
        border-radius: 50%;
        background-color: #F0F2F5;
      }
      img{
        width: 35px;
        height: 35px;
      }
    }
    .main-resourceCard-right {
      width: 65%;
    }
  }
  .main-resourceCard:last-child{
    margin-right: 0px;
  }
}
.cluster-module{
  background-color: #FFFFFF;
  .module-main{
    width: 14%;
    padding: 10px;
    border-right: 1px solid #BBBBBB;
    .module-main-top {
      .img-bg{
        display: inline-block;
        width: 26px;
        height: 26px;
        line-height: 30px;
        text-align: center;
        border-radius: 50%;
        background-color: #F0F2F5;
      }
      img{
        width: 16px;
        height: 16px;
      }
      .box-title {
        margin-bottom: 16px;
        margin-top: 6px;
      }
    }
    .module-main-bottom{
      margin-top: 16px;
    }
  }
  .module-main:last-child{
    border-right: 0px;
  }

}
.bgCard {
  padding: 10px;
  margin: 12px 0px;
  background-color: #fff;
  border-radius: 5px;
}
.cluster-flex {
  display: flex;
  justify-content: space-between;
}
.flex {
  display: flex;
  justify-content: space-between;
}
.box-title {
  margin-bottom: 16px;
  margin-top: 6px;
}
.box-numBig{
  font-size: 18px;
  font-weight: 600;
}
.activeColor{
  color: #4AC58F;
}
.errColor{
  color: #C85352;
}
.box-text {
  margin-top: 2px;
}
</style>
