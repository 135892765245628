<script>
import UnitInput from '@/shell/components/form/UnitInput';
import {
  MANAGEMENT, NAMESPACE, NORMAN, POD, PVC
} from '@shell/config/types';
import { mapGetters } from 'vuex';
import { PRODUCT_NAME as PAI } from '~/pkg/pai/config/pai';
import {
  calBestEffortRequestCpu,
  calBestEffortRequestMemory,
} from '@/pkg/pai/utils/units';
import { CHART, REPO, REPO_TYPE, VERSION } from '@shell/config/query-params';

export default {
  name:       'ContainerCloudDesktopInstall',
  components: { UnitInput },
  props:      {
    onClose:   { type: Function, default: null },
    desktopId: {
      type:     String,
      required: true,
    },
    currentApp: {
      type:     Object,
      required: true,
    },
    installedCloudDeskApp: {
      type:     Boolean,
      required: true,
    },
    isSharedStorage: {
      type:     Boolean,
      required: true
    }
  },
  async fetch() {
    this.namespaceOptions = await this.$store.getters[`cluster/all`](NAMESPACE).map((item) => {
      return item.metadata.name;
    });
    this.form.namespace = this.namespaceOptions.find((item) => {
      return item === 'default';
    }) || this.namespaceOptions[0];
    await this.$store.dispatch('cluster/findMatching', {
      type:     POD,
      selector: `type=containerdesk`,
    });
    await this.$store.dispatch('cluster/findMatching', {
      type:     PVC,
      selector: `type=containerdesk`,
    });
  },
  data() {
    return {
      dialogVisible: true,
      form:          {
        namespace: '',
        alias:     '',
        cpu:       '1',
        ram:       '2Gi',
        radio:     this.isSharedStorage ? 'true' : 'false'
      },
      namespaceOptions: [],
      qosRadio:         'bestEffort'
    };
  },
  watch: {
    'form.cpu': {
      deep: true,
      handler() {
        if (this.form.cpu && parseInt(this.form.cpu) < 1) {
          this.form.cpu = 1;
        }
      }
    },
    'form.ram': {
      deep: true,
      handler() {
        if (this.form.ram && parseInt(this.form.ram) < 1) {
          this.form.ram = '1Gi';
        }
      }
    }
  },
  computed: {
    ...mapGetters(['isRancher']),
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    principal() {
      if (this.isRancher) {
        return this.$store.getters['rancher/byId'](NORMAN.PRINCIPAL, this.$store.getters['auth/principalId']) || {};
      } else {
        const user = this.$store.getters['auth/v3User'];
        if(user) {
          return { loginName: user.name};
        }
        return { loginName: 'admin' };
      }
    },
    podList() {
      return this.$store.getters['cluster/all'](POD).filter((pod) => pod?.isXApp && pod.status?.phase !== 'Terminating' && !pod.metadata.deletionTimestamp);
    },
    allPvc() {
      return this.$store.getters['cluster/all'](PVC).filter((pod) => pod?.isXApp);
    },
  },
  methods: {
    handleClose() {
      this.dialogVisible = false;
      if (this.onClose) {
        this.onClose();
      }
    },
    randNum() {
      let rand = '';

      for (let i = 0; i < 8; i++) {
        rand += Math.floor(Math.random() * 10);
      }

      return rand;
    },
    async handleCreate() {
      const pvcYamlData = {
        // 这个pvc :<appname>-<namespace>-<username>-xapps 在这个<namespace>空间是唯一的
        apiVersion: 'v1',
        kind:       'PersistentVolumeClaim',
        metadata:   {
          name:        `pvc-${ this.principal.loginName }-xapps`, // <namespace>-<username>
          namespace:   this.form.namespace, // <namespace>
          annotations: { userName: this.principal.loginName }, // 用户名
          labels:      { type: 'containerdesk' }, // 检索用的label
        },
        spec: {
          accessModes:      ['ReadWriteMany'],
          resources:        { requests: { storage: '10Gi' } },
          storageClassName: 'longhorn',
          volumeMode:       'Filesystem'
        }
      };
      const pvcVolume = { volumeMounts: [], volumes: [] };
      // Labels下面存在shm
      const devShmvolumeMounts = {
        mountPath: '/dev/shm',
        name:      'dshm'
      };
      const devShmVolumes = {
        name:     'dshm',
        emptyDir: { medium: 'Memory' }
      };

      if (this.currentApp.devShm === true) { // 根据镜像config 中的Labels下面的shm 选择来确定是否添加(同时添加上面的volumeMounts：dshm)
        pvcVolume.volumeMounts.push(devShmvolumeMounts);
        pvcVolume.volumes.push(devShmVolumes);
      }
      const exitVolumes = [];

      if (this.form.radio === 'true') {
        // 当前应用已存在的pvc（<namespace>-<username>）直接添加无需创建
        this.allPvc.forEach((pvc) => {
          if (this.form.namespace === pvc.metadata.namespace && this.principal.loginName === pvc.metadata?.annotations?.userName && !pvc.metadata?.deletionTimestamp) {
            exitVolumes.push({
              name:                  'data',
              persistentVolumeClaim: { claimName: pvc.metadata.name },
            });
          }
        });
        pvcVolume.volumeMounts.push({ // 需要根据用户选择来确定是否添加
          name:      'data',
          mountPath: `/home/${ this.principal.loginName }`
        });
        pvcVolume.volumes.push(exitVolumes.length > 0 ? exitVolumes[0] : {
          name:                  'data',
          persistentVolumeClaim: { claimName: `pvc-${ this.principal.loginName }-xapps` }, }
        );
      }
      let resource = {};

      if (this.qosRadio === 'bestEffort') {
        resource = {
          limits: {
            cpu:    this.form.cpu,
            memory: this.form.ram,
          },
          requests: {
            cpu:    `${ calBestEffortRequestCpu(this.form.cpu) }`,
            memory: `${ calBestEffortRequestMemory(this.form.ram) }`,
          },
        };
      } else {
        resource = {
          limits: {
            cpu:    this.form.cpu,
            memory: this.form.ram,
          },
          requests: {
            cpu:    this.form.cpu,
            memory: this.form.ram,
          },
        };
      }
      const randNumber = this.randNum();

      const podYamlData = {
        apiVersion: 'v1',
        kind:       'Pod',
        spec:       {
          template: {
            metadata: {
              name:        `${ this.currentApp.desktopName }-${ randNumber }`, // 随便写的名字
              namespace:   this.form.namespace, // 用户填写<namespace>
              annotations: { 'com.tdology/alias': this.form.alias, // 用于显示 <用户填写的名称>
              },
              labels: {
                app:  `${ this.currentApp.desktopName }-${ randNumber }`, // 和上面的metadata.name保持一致
                type: 'containerdesk', // 检索用的label
              }
            },
            spec: {
              containers: [{
                name:            this.currentApp.desktopName,
                image:           `${ this.currentApp.repoPath }:${ this.currentApp.tag }`,
                imagePullPolicy: 'IfNotPresent',
                resources:       resource,
                env:             [
                  { name: 'RELAYSERVER', value: 'clouddesk.tdology' },
                  { name: 'RSERVER', value: 'clouddesk.tdology' },
                  { name: 'INCONTAINER', value: 'pai' },
                ],
                // 需要根据用户选择来确定是否添加
                volumeMounts: pvcVolume.volumeMounts,
              }],
              restartPolicy: 'Always',
              volumes:       pvcVolume.volumes
            }
          }
        }
      };

      // USER环境变量只有加存储的时候才需要添加
      if (this.form.radio === 'true') {
        podYamlData?.spec?.template?.spec?.containers[0]?.env?.push({ name: 'USER', value: this.principal.loginName });
      }
      try {
        if(this.installedCloudDeskApp) {
          const numRegex = /^[1-9]\d*$/;

          if (!this.form.alias || !this.form.cpu || !this.form.ram) {
            this.$message.error(this.t('pai.edit.tips.required'));

            return;
          }

          if (!numRegex.test(this.form.cpu)) {
            this.$message.error(this.t('pai.vmset.tips.parseIntCpu'));

            return;
          }
          if (!numRegex.test(parseFloat(this.form.ram))) {
            this.$message.error(this.t('pai.vmset.tips.parseIntRAM'));

            return;
          }

          if ((/[!@#$%^&*()>?<";~`|+={}]/).test(this.form.alias)) {
            this.$message.error(this.t('pai.detail.vmset.specialCharacters'));

            return;
          }
          for (const item in this.podList) {
            if (this.podList[item].metadata.namespace === this.form.namespace && this.podList[item]?.metadata?.annotations && this.podList[item]?.metadata?.annotations['com.tdology/alias'] === this.form.alias) {
              this.$message.error(this.t('pai.detail.vmset.duplicateNames'));

              return;
            }
          }
          const pvc = await this.$store.dispatch(`cluster/create`, {
            type:       PVC,
            apiVersion: 'v1',
            kind:       'PersistentVolumeClaim',
            metadata:   pvcYamlData.metadata,
            spec:       pvcYamlData.spec
          });
          const pod = await this.$store.dispatch(`cluster/create`, {
            type:       POD,
            apiVersion: 'v1',
            kind:       'Pod',
            metadata:   podYamlData.metadata,
            spec:       podYamlData.spec
          });

          if (this.form.radio === 'true' && exitVolumes.length === 0 ) {
            await pvc.save();
          }
          await pod.save();
          this.handleClose();
          this.$message({
            type:    'success',
            message: `${ this.t('pai.labels.success') }`,
          });
          this.$router.push({
            name:   `${ PAI }-c-cluster-clouddesktop`,
            params: {
              product: PAI,
              cluster: this.currentCluster,
            }
          });
        } else {
          this.installCloudDesk();
        }
      } catch (e) {
        console.log(e);
      }
    },
    installCloudDesk() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-apps-charts-chart`,
        params: {
          product: PAI,
          cluster: this.currentCluster,
        },
        query: {
          [REPO_TYPE]: 'cluster',
          [REPO]:      'iboot',
          [CHART]:     'clouddesk',
          [VERSION]:   '1.1.8',
        }
      });
      this.handleClose();
    },
    linkToInstallCloudDesk() {
      this.installCloudDesk();
    },
    linkToPaistore() {
      this.$router.push({
        name:   `${ PAI }-c-cluster-apps-charts-chart`,
        params: {
          product: PAI,
          cluster: this.currentCluster,
        },
        query: {
          [REPO_TYPE]: 'cluster',
          [REPO]:      'iboot',
          [CHART]:     'paistore',
          [VERSION]:   '102.3.0+up1.5.1',
        }
      });
      this.handleClose();
    },
  }
};
</script>

<template>
  <div class="vmForm">
    <el-dialog
      v-if="installedCloudDeskApp"
      :title="t('pai.apps.vm.install')+`：${currentApp.desktopName}`"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <el-descriptions
        :column="1"
        :colon="false"
      >
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.vmset.nameSpace') }}
          </template>
          <el-select
            v-model="form.namespace"
            filterable
            style="width: 364px;"
          >
            <el-option
              v-for="(item) in namespaceOptions"
              :key="item"
              :label="item"
              :value="item"
            />
          </el-select>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>{{ t('pai.edit.machine.name') }}
          </template>
          <el-input
            v-model="form.alias"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            QoS
          </template>
          <el-radio-group v-model="qosRadio">
            <el-radio
              v-model="qosRadio"
              label="bestEffort"
            >
              {{ t('pai.vmset.lock.share')+'['+t('pai.vmset.lock.bestEffort')+']' }}
            </el-radio>
            <el-radio
              v-model="qosRadio"
              label="guaranteed"
            >
              {{ t('pai.vmset.lock.exclusive')+'['+t('pai.vmset.lock.guaranteed')+']' }}
            </el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span>CPU
          </template>
          <UnitInput
            v-model="form.cpu"
            :output-modifier="true"
            :base-unit="t('suffix.cores')"
            :required="true"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span> {{ t('pai.vmset.ram') }}
          </template>
          <UnitInput
            v-model="form.ram"
            :input-exponent="3"
            :increment="1024"
            :output-modifier="true"
            :required="true"
            :min="1"
            style="width: 364px;"
          />
        </el-descriptions-item>
        <el-descriptions-item>
          <template slot="label">
            <span style="color: red">*</span> {{ t('pai.apps.container.createPersistentStorage') }}
          </template>
          <el-radio-group
            v-model="form.radio"
            style="margin-top: 8px"
          >
            <el-radio
              v-model="form.radio"
              label="true"
              :disabled="!isSharedStorage"
            >
              {{ t('pai.edit.machine.yes') }}
            </el-radio>
            <el-radio
              v-model="form.radio"
              label="false"
            >
              {{ t('pai.edit.machine.no') + ' ( '+t('pai.edit.machine.unSaveData')+' ) ' }}
            </el-radio>
          </el-radio-group>
        </el-descriptions-item>
        <el-descriptions-item v-if="form.radio==='true' && isSharedStorage">
          <template slot="label">
            {{ t('pai.apps.container.cloudStorageDir') }}
          </template>
          <div class="image-des">
            <p>{{ `/home/${principal.loginName}` }}</p>
            <p class="storageTip">
              <i class="el-icon-warning-outline" />{{ t('pai.vmset.tips.saveToCloud') }}
            </p>
          </div>
        </el-descriptions-item>
        <el-descriptions-item v-if="!isSharedStorage">
          <p style="fontSize: 12px; color: #E99D42;">
            <i class="el-icon-warning-outline" />{{ t('pai.vmset.tips.installPaistore') + t('pai.vmset.tips.pleaseInstall') }}
            <a @click="linkToPaistore">{{ t('pai.menu.storage.paiStore') }}</a>
          </p>
        </el-descriptions-item>
      </el-descriptions>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="handleCreate"
        >{{ t('pai.detail.vmset.confirm') }}</el-button>
      </span>
    </el-dialog>
    <el-dialog
      v-else
      :title="t('pai.apps.vm.install')+`：${currentApp.desktopName}`"
      :visible.sync="dialogVisible"
      width="550px"
      :before-close="handleClose"
    >
      <h3 style="margin-top: 30px">
        {{ t('pai.vmset.tips.pleaseInstall') }}
        <a @click="linkToInstallCloudDesk">{{ t('pai.vmset.tips.installCloudDesk') }}</a>
      </h3>
      <span
        slot="footer"
        class="dialog-footer"
      >
        <el-button @click="handleClose">{{ t('pai.detail.vmset.cancel') }}</el-button>
        <el-button
          type="primary"
          @click="handleCreate"
        >{{ t('pai.detail.vmset.confirm') }}</el-button>
      </span>
    </el-dialog>
  </div>
</template>

<style lang="scss" scoped>
.vmForm{
  ::v-deep .el-dialog__body, .theme-light .el-dialog__body {
    padding: 20px 20px 40px 20px;
  }

  ::v-deep .el-form-item__label {
    margin-top: 0px;
  }
  ::v-deep .el-form-item__error {
    padding-top: 0px;
  }
  ::v-deep .el-descriptions-item__label{
    width: 105px;
  }
  ::v-deep .el-descriptions__body .el-descriptions__table{
    width: unset;
  }

  span{
    text-align: left;
    line-height: 36px;
  }
  .image-des{
    width: 361px;
    height: 32px;
    border-radius: 3px;
    text-align: left;
    background: #CECECE;
    p{
      margin-top: 5px;
      margin-left: 7px;
    }
    .storageTip {
      font-size: 12px;
      margin-left: 4px;
      margin-top: 16px;
      color: #E99D42;
    }
  }
  .dialog-footer{
    margin-top: 100px;
  }
}
</style>
