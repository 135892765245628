import { render, staticRenderFns } from "./mysql-td.vue?vue&type=template&id=b1519980&scoped=true"
import script from "./mysql-td.vue?vue&type=script&lang=js"
export * from "./mysql-td.vue?vue&type=script&lang=js"
import style0 from "./mysql-td.vue?vue&type=style&index=0&id=b1519980&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/cli-service/node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "b1519980",
  null
  
)

export default component.exports