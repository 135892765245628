<script>
import { mapGetters } from 'vuex';
import { PRODUCT_NAME } from '../../../config/pai';
import { MANAGEMENT, CATALOG } from '@shell/config/types';
import { PAI_CATALOG } from '~/pkg/pai/config/types';
import ContainerCloudDesktopInstall from '../../../components/ContainerCloudDesktopInstall.vue';
import svgIcon from '~/pkg/pai/assets/images/cloudDesktop/svgIcon';
export default {
  components: { ContainerCloudDesktopInstall },
  async fetch() {
    await this.$store.dispatch('catalog/load', { force: true, reset: true });
    await this.$store.dispatch('pai-common/getCloudDesktop');
    const isRancher = await this.$store.getters['isRancher'];
    if (isRancher) {
      await this.$store.dispatch('cluster/findAll', { type: CATALOG.APP });
    } else {
      await this.$store.dispatch('cluster/findAll', { type: PAI_CATALOG.HELM_CHART });
    }
  },
  data() {
    return {
      loadImgSrc: `this.src = "${ require('../../../assets/images/home/empty.svg') }"`,
      PRODUCT_NAME,
      desktopRepoPath: '',
      currentApp: {},
    };
  },
  computed: {
    ...mapGetters([{ t: 'i18n/t' }, 'isRancher']),
    ...mapGetters(['currentCluster']),
    filterNamespaces() {
      return this.$parent.getFilterNamespaces();
    },
    currentCluster() {
      if (this.$store.getters['currentCluster']) {
        return this.$store.getters['currentCluster'].metadata.name;
      } else if (this.$store.getters['management/byId'](MANAGEMENT.CLUSTER, 'local') !== undefined) {
        return 'local';
      } else {
        return null;
      }
    },
    containerCloudDesktopVisible() {
      return this.$store.state['pai-common'].currentModal === 'ContainerCloudDesktopInstall';
    },
    cloudDesktopList() {
      const arr = [];

      try {
        const data = this.$store.state['pai-common'].cloudDesktopList.filter(v=> v?.config && v?.config?.Labels && JSON.parse(v?.config?.Labels).name );
        for (const key in data) {
          const configLabels = JSON.parse(data[key]?.config?.Labels)

          arr.push({
            id:          key,
            desktopName: configLabels?.name,
            repoPath:    data[key]['repo_path'],
            tag:         data[key].tag,
            devShm:      !!configLabels?.shm,
            icon:        svgIcon[configLabels?.name]? svgIcon[configLabels?.name]: svgIcon['default']
          });
        }

        return arr;
      } catch (e) {
        console.log(e);

        return [];
      }
    },
    installedCloudDeskApp() {
      // 是否安装了云桌面应用(clouddesk)
      if (this.isRancher) {
        return !!this.$store.getters['cluster/all'](CATALOG.APP).find((i) => i.name === 'clouddesk');
      } else {
        return !!this.$store.getters['cluster/all'](PAI_CATALOG.HELM_CHART).find((i) => i.name === 'clouddesk');
      }
    },
    isSharedStorage() {
      // 是否安装了存储(paistore)
      if (this.isRancher) {
        return !!this.$store.getters['cluster/all'](CATALOG.APP).find((i) => i.name === 'paistore');
      } else {
        return !!this.$store.getters['cluster/all'](PAI_CATALOG.HELM_CHART).find((i) => i.name === 'paistore');
      }
    },
  },
  methods: {
    showDesktopInstall(value) {
      this.$store.dispatch('pai-common/updateState', { currentModal: 'ContainerCloudDesktopInstall' });
      this.currentApp = value;
    },
    handelCloseCreate() {
      this.$store.dispatch('pai-common/updateState', { currentModal: '' });
    },
  },
};
</script>
<template>
  <div>
    <div class="title">
      {{ t('pai.apps.container.cloudDesktop') }}
    </div>
    <div
      v-loading="$fetchState.pending"
      class="list"
    >
      <div
        v-for="(item) in cloudDesktopList.slice(0, 3)"
        :key="`${item.repoPath}:${item.tag}`"
        class="item"
        @mouseenter="desktopRepoPath = `${item.repoPath}:${item.tag}`"
      >
        <div class="box">
          <div
            :onerror="loadImgSrc"
            v-html="item.icon"
            class="appIcon"
          >
            {{ item.icon }}
          </div>
        </div>
        <div
          v-if="desktopRepoPath === `${item.repoPath}:${item.tag}`"
          class="modalBox"
          @mouseleave="desktopRepoPath = ''"
          @click="showDesktopInstall(item)"
        >
          <img src="../../../assets/images/cloudDesktop/install.svg">
          {{ t('pai.apps.vm.install') }}
        </div>
        <span class="name">
          {{ item.desktopName }}
        </span>
      </div>
      <div class="item">
        <div
          v-if="currentCluster"
          class="box"
          @click="$router.push({name:'pai-c-cluster-clouddesktop',params:{product:PRODUCT_NAME,cluster:currentCluster}})"
        >
          {{ t('pai.labels.moer') }}>>
        </div>
      </div>
    </div>
    <container-cloud-desktop-install
      v-if="containerCloudDesktopVisible"
      :on-close="handelCloseCreate"
      :desktop-id="desktopRepoPath"
      :current-app="currentApp"
      :installedCloudDeskApp="installedCloudDeskApp"
      :isSharedStorage="isSharedStorage"
    />
  </div>
</template>
<style lang="scss" scoped>
.title {
  color: #0C5489;
  font-size: 18px;
  margin-bottom: 10px;
}

.list {
  display: flex;
  flex-wrap: wrap;

  .item {
    margin-right: 10px;
    margin-bottom: 10px;

    .box {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      background: #EFEFEF;
      border: 1px solid #CECECE;
      cursor: pointer;
      .appIcon {
        height: 65px;
        transform: scale(0.85);
      }
    }
    .modalBox {
      width: 80px;
      height: 80px;
      display: flex;
      justify-content: center;
      align-items: center;
      cursor: pointer;
      background: #3D7EAD;
      opacity: 0.9;
      color: #fff;
      margin-top: -80px;
    }

    span {
      display: block;
      text-align: center;
      margin-top: 10px;
      font-size: 12px;
      max-width: 80px;
    }
  }
}
</style>
